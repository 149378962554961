import { globalPostService, globalGetService } from "../../utils/GlobalApiServices";
import { TAddUser } from "../../types";
import { IFormState } from "./components/AddUpdateUserDialog";
import { IdeactiveId, IResendInviteUserId } from "./components/UserListItem";

export function getAllUsers(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`users/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUserDetails(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`user`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllResearchers(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`users/?researcher=true`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function inviteNewUser(query: TAddUser): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`invite-user/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateUser(query: IFormState, params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`user/${params}/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deactivateUser(query: IdeactiveId): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`user/deactivate/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function activateUser(query: IdeactiveId): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`user/reactivate/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resendInviteUser(query: IResendInviteUserId): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`invite/resend/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
