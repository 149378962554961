import { FC } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { VulnerabilityReport } from "../components";
import { CustomLink } from "../../../shared_ui_components";
import { getImage } from "../../../utils";

const VulnerabilityDetail: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.clientVulnerabilityDetail}>
      <div className={styles.userHeaderBlk}>
        <Container maxWidth="lg" className={styles.containerBlk}>
          <div className={styles.headerLeftBlk}>
            <CustomLink to="#" className="" onClick={() => navigate(-1)}>
              <p className={styles.backBtn}>
                <img src={getImage("arrow_left_grey.svg")} alt="" />
                <span className="paragraph">Back to scan details</span>
              </p>
            </CustomLink>
          </div>
        </Container>
      </div>
      <VulnerabilityReport />
    </div>
  );
};
export default VulnerabilityDetail;
