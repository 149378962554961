import { ActionMap } from "../types";
import { TClientState } from "./GlobalContext";
import { TProjectResponse } from "../types";
export type ClientType = {
  uuid: string;
  name: string;
  logo: string;
  users: Array<string>;
  projects: TProjectResponse[];
};

export enum CLIENT_ACTIONS {
  Get = "GET_All_CLIENTS",
  Add = "ADD_NEW_CLIENTS",
  Update = "UPDATE_CLIENTS",
}

type ClientPayload = {
  [CLIENT_ACTIONS.Get]: undefined;
  [CLIENT_ACTIONS.Add]: undefined;
  [CLIENT_ACTIONS.Update]: undefined;
};

export type ClientActions = ActionMap<ClientPayload>[keyof ActionMap<ClientPayload>];

export const ClientReducers = (state: TClientState, action: ClientActions | any) => {
  switch (action.type) {
    case CLIENT_ACTIONS.Get:
      return {
        ...state,
        clientData: [...action.payload].reverse(),
        loading: false,
        showNoState: action.payload.length > 0 ? false : true,
      };

    case CLIENT_ACTIONS.Add:
      return {
        ...state,
        clientData: [action.payload, ...state.clientData],
        loading: false,
        showNoState: false,
      };

    case CLIENT_ACTIONS.Update:
      return {
        ...state,
        clientData: state.clientData.map((client) => {
          return client.uuid === action.payload.uuid ? { ...client, ...action.payload } : { ...client };
        }),
        loading: false,
        showNoState: false,
      };

    default:
      return state;
  }
};
