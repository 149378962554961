/* eslint-disable no-lone-blocks */
import { FC, SyntheticEvent, useState, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  CustomButton,
  CustomInput,
  CustomSelect,
  CustomMutipleSelect,
} from "../../../shared_ui_components";
import styles from "../../../assets/styles/home.module.scss";
import { errorCodes, regExpression } from "../../../constants";
import { fieldValidation } from "../../../utils/formValidations";
import { GlobalContext } from "../../../context/GlobalContext";
import { useParams, useNavigate } from "react-router-dom";
import { getSingleProject, updateProject } from "../apiServices";
import { TAutoComplete, Errors, TProjectResponse, TClients } from "../../../types";
import { toastFlashMessage, projectNameList } from "../../../utils";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";
import { Shimmer } from ".";

type IState = {
  name: string;
  description?: string;
  client?: string | null | TAutoComplete;
  appOptions?: readonly TAutoComplete[];
  client_users?: any;
  removed_users?: any;
};

const UpdateProjectForm: FC = (props) => {
  const {
    state: { clientLists, roleLists },
    dispatch,
  } = useContext(GlobalContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IState>({
    client: null,
    name: "",
    description: "",
    appOptions: [],
    client_users: [],
    removed_users: [],
  });
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  /* To show the existing data while editing*/
  useEffect(() => {
    if (id) {
      getSingleProject(`${id}`).then(
        (response: Partial<{ statusCode: number; data: TProjectResponse; message: string }>) => {
          if (response.statusCode === 200) {
            if (roleLists?.roleData?.permission?.includes("EDIT_PROJECT")) {
              const projectDetails = response?.data;
              setFormData((form) => ({
                ...form,
                client: {
                  label: projectDetails?.client?.name ? projectDetails?.client?.name : "",
                  value: projectDetails?.client?.uuid ? projectDetails?.client?.uuid : "",
                } as string | null | TAutoComplete,
                name: projectDetails?.name ? projectDetails?.name : "",
                description: projectDetails?.description ? projectDetails?.description : "",
                appOptions:
                  (clientLists?.clientData &&
                    formData?.client &&
                    (projectNameList(
                      clientLists?.clientData as TClients[],
                      (formData?.client as HTMLInputElement).value
                    ) as TAutoComplete[])) ||
                  ([] as readonly TAutoComplete[]),
                client_users: projectDetails?.users
                  ?.filter((_users) => _users?.role?.id === 5)
                  ?.map((users) => users?.email),
                removed_users: projectDetails?.users
                  ?.filter((_users) => _users?.role?.id === 5)
                  ?.map((users) => {
                    return { email: users.email, uuid: users.uuid };
                  }),
              }));
              setLoading(false);
            } else {
              navigate(`/projects/${id}`);
            }
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoading(false);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleChange = (key: string, val: TAutoComplete | string | any) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const handleCancel = () => {
    navigate(`/projects/${id}`);
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validateNewInput: {
      [key: string]: string;
    };
    validateNewInput = {
      name: errorCode["projectName"][
        fieldValidation({
          ...errorCode["projectNameObj"],
          fieldval: formData.name as string,
        })
      ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      const removedUsers = formData.removed_users?.filter(
        (ele: { email: string }) => !formData.client_users.includes(ele.email)
      );

      const form_data = {
        client: formData?.client && (formData?.client as HTMLInputElement)["value"],
        name: formData.name,
        description: formData.description,
        project_id: id,
        client_users: formData?.client_users?.join(","),
        removed_users: removedUsers?.map((user: { uuid: string }) => user.uuid).join(","),
      };
      updateProject(form_data, id as string).then(
        (response: Partial<{ statusCode: number; data: TProjectResponse; message: string }>) => {
          if (response.statusCode === 200) {
            dispatch({
              type: PROJECT_ACTIONS.Update,
              payload: response.data,
            });
            setLoader(false);
            navigate(`/projects/${id}`);
            toastFlashMessage(response.message as string, "success");
          } else {
            setLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  const handleValue = (event: any) => {
    switch (event.key) {
      case ",":
      case " ":
        {
          event.preventDefault();
          event.stopPropagation();
          if (event.target.value.trim()) {
            if (regExpression.email.test(event.target.value) === false) {
              setEmail("");
            } else {
              handleChange?.("client_users", [...formData.client_users, event.target.value]);
              setEmail("");
            }
          }
        }
        break;
      default:
    }
  };

  return (
    <>
      {loading ? (
        <Shimmer type="updateProject" />
      ) : (
        <div className={styles.formDetail}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <p className={styles.formTitle + " supportText_semiBold"}>Client Name *</p>
                <CustomSelect
                  placeholder="Select Client *"
                  className={styles.inputBlk}
                  options={[]}
                  value={formData.client as HTMLInputElement}
                  onChange={(event: SyntheticEvent, newValue?: string | TAutoComplete | null) => {
                    handleChange("client", newValue as string | TAutoComplete);
                  }}
                  isDisable={true}
                  noMargin="noMargin"
                  error={error.client}
                />
              </Grid>
              <Grid item md={12}>
                <CustomInput
                  label="Project Name *"
                  className={styles.inputBlk}
                  placeholder="Enter Project Name"
                  noMargin="noMargin"
                  value={formData.name ? formData.name : ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                  error={error.name}
                />
              </Grid>
              <Grid item md={12}>
                <CustomInput
                  multiline
                  label="Description"
                  className={styles.inputBlk}
                  placeholder="Enter Description"
                  noMargin="noMargin"
                  value={formData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  error={error.description}
                />
              </Grid>
              <Grid item md={12}>
                <p className={styles.formTitle + " supportText_semiBold"}>Invite Client on this Project</p>
                <CustomMutipleSelect
                  options={[]}
                  onChange={(event: SyntheticEvent, val) => {
                    if (regExpression.email.test(val.slice(-1) as any) === false) {
                      setEmail("");
                      setFormData((form) => ({
                        ...form,
                        client_users: val.slice(0, val.length - 1),
                      }));
                    } else {
                      handleChange(
                        "client_users",
                        val.map((ele) => ele)
                      );
                      setEmail("");
                    }
                  }}
                  placeholder="Enter email address, if multiple use comma or space separated"
                  handleValue={handleValue}
                  value={formData.client_users}
                  inputText={email.trim()}
                  handleChangeText={(e: SyntheticEvent) =>
                    setEmail((e.target as HTMLInputElement).value.trim())
                  }
                />
              </Grid>
            </Grid>
            <div className={styles.buttonWrapper}>
              <CustomButton className="outlinedBtn" children="Cancel" type="button" onClick={handleCancel} />
              <CustomButton
                className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
                children="Update"
                type="submit"
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default UpdateProjectForm;
