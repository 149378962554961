import { FC, SyntheticEvent } from "react";
import { Link } from "react-router-dom";

type IProps = {
  href?: string;
};

const LinkRenderer: FC<IProps> = (props) => {
  const handleOpenLink = (e: SyntheticEvent) => {
    e.preventDefault();
    if (props && props?.href?.includes("/")) {
      window.open(props?.href);
    } else {
      window.open("https://" + props?.href);
    }
  };
  return (
    <Link to="#" onClick={handleOpenLink} target="_blank" className="markdown-link">
      {props.children}
    </Link>
  );
};

export default LinkRenderer;
