import Vulnerability from "./containers/Vulnerability";
import UpdateVulnerabilities from "./containers/UpdateVulnerabilities";
import UpdateVulnerabilityCategory from "./containers/UpdateVulnerabilityCategory";
import VulnerabilityInstance from "./containers/VulnerabilityInstance";
import VulnerabilityCategoryInstances from "./containers/VulnerabilityCategoryInstances";
import { TRoutesType } from "../../types";
import PageLayoutHoc from "../../hocs/pageLayoutHoc";

import EditScanDetail from "../home/containers/EditScanDetail";
import ScanAnalysisDetails from "../home/containers/ScanAnalysisDetails";
import AddVulnerability from "../home/containers/AddVulnerability";
import UpdateVulnerability from "../home/containers/UpdateVulnerability";
import VulnerabilityDetail from "../home/containers/VulnerabilityDetail";

const VulnerabilityHomePage = PageLayoutHoc(Vulnerability);
const VulnerabilityUpdatePage = PageLayoutHoc(UpdateVulnerabilities);
const VulnerabilityCategoryUpdatePage = PageLayoutHoc(UpdateVulnerabilityCategory);
const VulnerabilityCategoryInstance = PageLayoutHoc(VulnerabilityCategoryInstances);
const VulnerabilityInstances = PageLayoutHoc(VulnerabilityInstance);

const EditScanPage = PageLayoutHoc(EditScanDetail);
const ScanAnalysisPage = PageLayoutHoc(ScanAnalysisDetails);
const AddVulnerabilityPage = PageLayoutHoc(AddVulnerability);
const UpdateVulnerabilityPage = PageLayoutHoc(UpdateVulnerability);
const VulnerabilityDetailPage = PageLayoutHoc(VulnerabilityDetail);

export const vulnerabilitiesRoutes: Array<TRoutesType> = [
  {
    path: "/vulnerabilities",
    element: <VulnerabilityHomePage />,
    key: "Vulnerability",
  },
  {
    path: "/vulnerabilities/:id/update",
    element: <VulnerabilityUpdatePage />,
    key: "update-vulnerabilities",
  },
  {
    path: "/vulnerability-category/:id/update",
    element: <VulnerabilityCategoryUpdatePage />,
    key: "update-vulnerability-category",
  },
  {
    path: "/vulnerability-category/:id/occurences",
    element: <VulnerabilityCategoryInstance />,
    key: "vulnerabilities-category-instance",
  },
  {
    path: "/vulnerabilities/:id/occurences/:occurenceId",
    element: <VulnerabilityInstances />,
    key: "vulnerabilities-instance",
  },

  {
    path: "/vulnerabilities/:id/update-scan/:scanId",
    element: <EditScanPage />,
    key: "edit-scan-detail",
  },
  {
    path: "/vulnerabilities/:id/scan-details/:scanId",
    element: <ScanAnalysisPage />,
    key: "scan-analysis",
  },

  {
    path: "/vulnerabilities/:id/scan-details/:scanId/findings-details/:analysisId",
    element: <VulnerabilityDetailPage />,
    key: "vulnerability-detail",
  },
  {
    path: "/vulnerabilities/:id/scan-details/:scanId/add-findings",
    element: <AddVulnerabilityPage />,
    key: "add-vulnerability",
  },
  {
    path: "/vulnerabilities/:id/scan-details/:scanId/update-findings/:analysisId",
    element: <UpdateVulnerabilityPage />,
    key: "update-vulnerability",
  },
];
