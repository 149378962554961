import { Component, ErrorInfo, ReactNode } from "react";
import { Link } from "react-router-dom";
import { getImage } from "../utils";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            background: "#fafafa",
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={getImage("404_error.svg")} alt="page_not_found" />
            <p style={{ padding: "12px 0" }} className="heading4">
              Error 404 - Something went wrong.
            </p>
            <Link to="/" onClick={() => this.setState({ hasError: false })} className="primaryBtn">
              Return to Home
            </Link>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
