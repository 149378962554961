import { FC, Fragment, ChangeEvent, useEffect, useState } from "react";
import styles from "../../../assets/styles/client.module.scss";
import { Grid } from "@mui/material";
import { ClientsProjectListItem, Shimmer } from ".";
import { TProjectResponse } from "../../../types";
import { Search } from "../../../shared_elements";
import { NoResult, CustomButton } from "../../../shared_ui_components";
import { getImage } from "../../../utils";

type TSearchType = {
  val?: string;
  list?: TProjectResponse[];
};

type IProps = {
  projects?: TProjectResponse[];
  handleUpdateClientSingleProject?: (p: TProjectResponse | {}) => void;
  handleAddClientProjectDialog?: () => void;
};

const ClientsProjectList: FC<IProps> = ({
  projects,
  handleUpdateClientSingleProject,
  handleAddClientProjectDialog,
}) => {
  const [search, setSearch] = useState<TSearchType>({
    val: "",
    list: [],
  });
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (projects) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [projects]);

  const handleSearch = (val: string) => {
    let str = val.toLowerCase();
    let searchList = projects?.filter((clientProject) =>
      (clientProject?.name as string)?.toLowerCase().includes(str)
    );
    setSearch(() => ({
      val: str,
      list: searchList,
    }));
  };

  const handleClearSearch = () => {
    setSearch(() => ({
      val: "",
      list: [],
    }));
  };

  return (
    <>
      <div className={styles.projectListView}>
        <div className={styles.projectListViewTitleWrapper}>
          <p className={styles.projectListViewTitle + " heading6 headingTextOverflow"}>
            {search?.val ? (
              `Showing ${search?.list?.length} ${
                search?.list?.length === 1 || search?.list?.length === 0 ? "result" : "results"
              } for "${search?.val}" `
            ) : (
              <>
                Projects <span>{projects?.length}</span>
              </>
            )}
          </p>
          {!loader && (
            <>
              {search.val ? (
                <span onClick={handleClearSearch} className={styles.clearSearch + " paragraph"}>
                  Clear search
                </span>
              ) : (
                ""
              )}
              <div className={styles.projectSearchWrapper}>
                <Search
                  text={search.val as string}
                  placeholder="Search for projects"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
                />
                <CustomButton
                  className=" primaryBtn"
                  children="Create a Project"
                  type="button"
                  onClick={handleAddClientProjectDialog}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <Grid container spacing={2} className={styles.listCardHeader + " labelText_semiBold"}>
        <Grid item md={3}>
          <p>Project Name</p>
        </Grid>
        <Grid item md={2}>
          <p>Scan ID </p>
        </Grid>
        <Grid item md={2}>
          <p>Security Researcher</p>
        </Grid>
        <Grid item md={2}>
          <p>Due Date</p>
        </Grid>
        <Grid item md={2}>
          <p>Scan Status</p>
        </Grid>
        <Grid item md={1}>
          Action
        </Grid>
      </Grid>
      {loader ? (
        <Shimmer type="clientProjects" />
      ) : (
        <>
          {search?.val ? (
            <>
              {search?.list?.length === 0 ? (
                <NoResult
                  image={getImage("no_file_found.svg")}
                  title="No Result Found"
                  subtext={<Fragment>We didn't find any client project that matched your search</Fragment>}
                />
              ) : (
                <div className={styles.listCardFooter}>
                  {search?.list?.map((clientProject) => {
                    return (
                      <Fragment key={clientProject.uuid}>
                        <ClientsProjectListItem
                          {...clientProject}
                          handleUpdateClientSingleProject={handleUpdateClientSingleProject}
                          handleSearch={handleSearch}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <div className={styles.listCardFooter}>
              {projects?.map((clientProject) => {
                return (
                  <Fragment key={clientProject.uuid}>
                    <ClientsProjectListItem
                      {...clientProject}
                      handleUpdateClientSingleProject={handleUpdateClientSingleProject}
                    />
                  </Fragment>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ClientsProjectList;
