import { FC, SyntheticEvent } from "react";
import { TextField, Autocomplete, createFilterOptions, FilterOptionsState } from "@mui/material";
import { TAutoComplete } from "../types";

type IProps = {
  value: { label?: string; value?: string };
  onChange: (event: SyntheticEvent, newValue: string | TAutoComplete | null) => void;
  filterOptions:
    | ((p: TAutoComplete[], state: FilterOptionsState<TAutoComplete>) => TAutoComplete[])
    | undefined;
  options: readonly TAutoComplete[];
  error?: string;
  noMargin?: string;
  placeholder: string;
};

export const filter = createFilterOptions<TAutoComplete>();

const CustomCreatableSelect: FC<IProps> = ({
  value,
  onChange,
  filterOptions,
  options,
  noMargin,
  error,
  placeholder,
}) => {
  return (
    <div className={`inputGroup ${error && "errorGroup"} ${noMargin && "noMargin"}`}>
      <Autocomplete
        ListboxProps={{
          style: {
            maxHeight: "100px",
            border: "1px solid #E6E6E7",
            boxShadow: "0px 2px 8px #EAEBEC",
            borderRadius: "4px",
          },
        }}
        autoSelect
        value={value as string | TAutoComplete | null | undefined}
        onChange={onChange}
        filterOptions={filterOptions}
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={options}
        getOptionLabel={(option: TAutoComplete) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option?.inputValue) {
            return option?.inputValue;
          }
          // Regular option
          return option?.value as string;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.label}>
            {option?.value}
          </li>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} error={error ? true : false} autoComplete="off" />
        )}
      />
      {error && <h6 className="errorMsg absolute">{error}</h6>}
    </div>
  );
};

export default CustomCreatableSelect;
