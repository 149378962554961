import { FC } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { EditScanForm } from "../components";
import { getImage } from "../../../utils";

const EditScanDetail: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="lg">
        <div className={styles.editScanDetailPageWrapper}>
          <div className={styles.editScanTitle}>
            <div className={styles.backLink + " supportText_semiBold"} onClick={() => navigate(-1)}>
              <img src={getImage("arrow_down.svg")} alt="" />
              Back to details
            </div>
            <p className="heading3">Update Scan Request</p>
          </div>
          <EditScanForm />
        </div>
      </Container>
    </>
  );
};
export default EditScanDetail;
