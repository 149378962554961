import { FC, useEffect } from "react";
import { AuthLayout, PasswordRecoveryForm } from "../components";
import styles from "../../../assets/styles/auth.module.scss";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../utils";

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (getUserInfo()?.user) {
      navigate("/projects");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AuthLayout>
        <div className={styles.passwordRecoveryFormWrapper}>
          <PasswordRecoveryForm />
        </div>
      </AuthLayout>
    </>
  );
};

export default ForgotPassword;
