import { ActionMap } from "../types";
import { TUserState } from "./GlobalContext";

export enum USER_ACTIONS {
  Get = "GET_All_USERS",
  Add = "ADD_NEW_USERS",
  Update = "UPDATE_USERS",
}

type UserPayload = {
  [USER_ACTIONS.Get]: undefined;
  [USER_ACTIONS.Add]: undefined;
  [USER_ACTIONS.Update]: undefined;
};
export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export const UserReducers = (state: TUserState, action: UserActions | any) => {
  switch (action.type) {
    case USER_ACTIONS.Get:
      return {
        ...state,
        userData: [...action.payload],
        loading: false,
        showNoState: action.payload.length > 0 ? false : true,
      };

    case USER_ACTIONS.Add:
      return {
        ...state,
        userData: [action.payload, ...state.userData],
        loading: false,
        showNoState: false,
      };

    case USER_ACTIONS.Update:
      return {
        ...state,
        userData: state?.userData?.map((user) => {
          return user?.uuid === action?.payload?.uuid ? { ...user, ...action.payload } : { ...user };
        }),
        loading: false,
        showNoState: false,
      };

    default:
      return state;
  }
};
