import { FC, useContext } from "react";
import styles from "../../../assets/styles/home.module.scss";
import { Skeleton, Stack } from "@mui/material";
import { Grid } from "@mui/material";
import { IShimmerProps } from "../../../types";
import { GlobalContext } from "../../../context/GlobalContext";

const Shimmer: FC<IShimmerProps> = ({ type }) => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const roleId = roleLists?.roleData?.id;
  return (
    <>
      {type === "projectHeader" && (
        <>
          <div className={styles.projectStatusWrapper}>
            <div className={`${styles.statusBtn}  heading6`}>
              <Skeleton
                variant="text"
                width={150}
                height={25}
                style={{
                  display: "block",
                  marginTop: "8px",
                }}
              />
            </div>
          </div>
        </>
      )}
      {type === "projectList" && (
        <>
          {[...Array(10)].map((item, key) => {
            return (
              <div className={styles.projectListCardContainer + " paragraph"} key={key}>
                <Grid container spacing={2} className={styles.listCardItem}>
                  <Grid item md={3} className={styles.projectName}>
                    <Skeleton variant="circular" height={32} />
                    <Skeleton
                      variant="text"
                      width={180}
                      height={25}
                      style={{
                        display: "block",
                        marginLeft: 4,
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton
                      variant="text"
                      width={170}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>

                  <Grid item md={2}>
                    <Skeleton
                      variant="text"
                      width={170}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton
                      variant="text"
                      width={170}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton
                      variant="text"
                      width={170}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={1} className={" paragraph"}>
                    <Skeleton
                      variant="text"
                      width={60}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </>
      )}

      {type === "gridLists" && (
        <>
          {[...Array(15)].map((item, key) => {
            return (
              <Grid item md={4} key={key}>
                <div className={styles.projectGridCardContainer}>
                  <div className={styles.projectOrgWrapper}>
                    <Grid container alignItems="flex-start">
                      <Grid item md={2}>
                        <Skeleton variant="circular" height={48} width={48} />
                      </Grid>
                      <Grid item md={7}>
                        <div className={styles.projectOrgDetails}>
                          <div className={styles.orgName + " paragraph_semiBold"}>
                            <Skeleton
                              variant="text"
                              width={180}
                              height={25}
                              style={{
                                display: "block",
                              }}
                            />
                          </div>
                          <div className={styles.orgUrl + " supportText"}>
                            <Skeleton
                              variant="text"
                              width={160}
                              height={25}
                              style={{
                                display: "block",
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={3}>
                        <div className={styles.orgVersion + " supportText"}>
                          <Skeleton
                            variant="text"
                            width={80}
                            height={25}
                            style={{
                              display: "block",
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={styles.orgDescriptionWrapper}>
                    <div className={styles.orgDescription + " supportText"}>
                      <Skeleton
                        variant="text"
                        width={340}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.scanDetailsWrapper}>
                    <div className={styles.scanFileText + " labelText_semiBold"}>
                      <Skeleton
                        variant="text"
                        width={340}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.scanWrapper}>
                    <div className={styles.scanStatusWrapper}>
                      <div className={styles.scanStatusText + " paragraph_semiBold"}>
                        <Skeleton
                          variant="text"
                          width={340}
                          height={25}
                          style={{
                            display: "block",
                            marginLeft: "-12px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
        </>
      )}

      {type === "singleProjectDetails" && (
        <>
          <div className={styles.productTitleBlk}>
            <div className={styles.productHeaderWrapper} style={{ paddingTop: "16px" }}>
              <div className={styles.productHeaderLeft}>
                <Skeleton variant="circular" width="48px" height="48px" />
                <div>
                  <Skeleton
                    variant="text"
                    width={200}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={styles.productDescription + " paragraph"}>
                <Skeleton
                  variant="text"
                  width={560}
                  height={20}
                  style={{
                    display: "block",
                  }}
                />
              </div>
              <Skeleton
                variant="text"
                width={220}
                height={20}
                style={{
                  display: "block",
                  marginTop: "-16px",
                }}
              />
            </div>
          </div>
        </>
      )}

      {type === "scanLists" && (
        <>
          {[...Array(10)].map((item, key) => {
            return (
              <div className={styles.scanListItemWrapper} key={key}>
                <Grid container alignItems="center">
                  <Grid item md={1} className={styles.scanStatusBlk}>
                    <p className="paragraph">
                      <Skeleton
                        variant="text"
                        width={60}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </p>
                  </Grid>

                  {roleId !== 5 && (
                    <Grid item md={1.5} className={styles.scanStatusBlk}>
                      <p className="paragraph">
                        <Skeleton
                          variant="text"
                          width={120}
                          height={25}
                          style={{
                            display: "block",
                          }}
                        />
                      </p>
                    </Grid>
                  )}
                  <Grid item md={2} className={styles.scanStatusBlk}>
                    <Skeleton
                      variant="text"
                      width={160}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={roleId === 5 ? 2 : 1.5} className={styles.scanStatusBlk}>
                    <p className="paragraph">
                      <Skeleton
                        variant="text"
                        width={roleId === 5 ? 160 : 120}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </p>
                  </Grid>
                  <Grid item md={roleId === 5 ? 2 : 1.5} className={styles.scanStatusBlk}>
                    <p className="paragraph">
                      <Skeleton
                        variant="text"
                        width={roleId === 5 ? 160 : 120}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </p>
                  </Grid>
                  <Grid item md={2} className={styles.scanStatusBlk}>
                    <p className="paragraph paragraphOverflow">
                      <Skeleton
                        variant="text"
                        width={160}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </p>
                  </Grid>
                  <Grid item md={roleId === 5 ? 2 : 1.5} className={styles.scanStatusBlk}>
                    <p className="paragraph">
                      <Skeleton
                        variant="text"
                        width={roleId === 5 ? 280 : 120}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </p>
                  </Grid>
                  {roleId !== 5 && (
                    <Skeleton
                      variant="text"
                      width={90}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  )}
                </Grid>
              </div>
            );
          })}
        </>
      )}
      {type === "scanList" && (
        <>
          {[...Array(5)].map((item, key) => {
            return (
              <div className={styles.scanListItemWrapper} key={key + 1}>
                <Grid container spacing={2} alignItems="center">
                  {[...Array(4)].map((item, key) => {
                    return (
                      <Grid item md={3} key={key + 10}>
                        <Skeleton
                          variant="text"
                          width={200}
                          height={40}
                          style={{
                            display: "block",
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            );
          })}
        </>
      )}

      {type === "scanListDetails" && (
        <>
          {[...Array(1)].map((item, key) => {
            return (
              <>
                <div className={styles.scanListItemWrapper} key={key + 1}>
                  <Grid container spacing={2} alignItems="center">
                    {[...Array(4)].map((item, key) => {
                      return (
                        <Grid item md={3} key={key}>
                          <Skeleton
                            variant="text"
                            width={200}
                            height={30}
                            style={{
                              display: "block",
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>

                <div className={styles.analysisTitleWrapper}>
                  <p className={styles.scanListViewTitle + " heading6"}>
                    {roleId === 5 ? "Findings details" : "Findings"}
                    <span></span>
                  </p>
                  <div className={styles.analysisTitleRightBlk}></div>
                </div>
                <div className={styles.analysisWrapper}>
                  <div className={styles.analysisFilterWrapper}>
                    <Skeleton
                      variant="text"
                      width={400}
                      height={40}
                      style={{
                        display: "block",
                        margin: "0px 4px",
                      }}
                    />
                  </div>
                  <Grid container className={styles.analysisHeader + " labelText_semiBold"}>
                    <Grid item md={1}>
                      <p>SL.NO</p>
                    </Grid>
                    <Grid item md={4}>
                      <p>TITLE</p>
                    </Grid>
                    <Grid item md={3}>
                      <p>{roleId === 5 ? "CATEGORY" : "SEVERITY"}</p>
                    </Grid>
                    <Grid item md={3}>
                      <p>{roleId === 5 ? "SEVERITY" : "CVSSv3 SCORE"}</p>
                    </Grid>
                    <Grid item md={1}>
                      <p>ACTION</p>
                    </Grid>
                  </Grid>
                  {[...Array(10)].map((item, key) => {
                    return (
                      <div className={styles.scanListItemWrapper} key={key + 99}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item md={1}>
                            <Skeleton
                              variant="text"
                              width={80}
                              height={25}
                              style={{
                                display: "block",
                              }}
                            />
                          </Grid>
                          <Grid item md={4}>
                            <Skeleton
                              variant="text"
                              width={360}
                              height={25}
                              style={{
                                display: "block",
                              }}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Skeleton
                              variant="text"
                              width={270}
                              height={25}
                              style={{
                                display: "block",
                                marginLeft: "-8px",
                              }}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Skeleton
                              variant="text"
                              width={270}
                              height={25}
                              style={{
                                display: "block",
                                marginLeft: "-8px",
                              }}
                            />
                          </Grid>
                          <Grid item md={1}>
                            <Skeleton
                              variant="text"
                              width={70}
                              height={25}
                              style={{
                                display: "block",
                                marginLeft: "-8px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </>
      )}

      {type === "analysisList" && (
        <>
          <div className={styles.formDetail}>
            <div className={styles.titleWrapper}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Skeleton
                    variant="text"
                    width={400}
                    height={40}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Skeleton
                    variant="text"
                    width={400}
                    height={40}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={styles.metricsScore}>
              <div className={styles.metricsHeader}>
                <Stack sx={{ width: "220px" }}>
                  <div className="heading6">CVSS Metrics</div>
                  <div className={styles.headerScore + " paragraph"}>
                    <Skeleton
                      variant="text"
                      width={200}
                      height={40}
                      style={{
                        display: "block",
                      }}
                    />
                  </div>
                </Stack>
              </div>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Stack>
                    <div className={styles.metricsTitle + " supportText_semiBold"}>Attack Vector (AV)</div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "280px" }}>
                      {[0, 1, 2, 3].map((_, idx) => {
                        return <Skeleton variant="text" width={60} height={50} style={{}} key={idx} />;
                      })}
                    </div>
                  </Stack>
                </Grid>
                <Grid item md={5}>
                  <Stack>
                    <div className={styles.metricsTitle + " supportText_semiBold"}>Scope (S)</div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "140px" }}>
                      {[0, 1].map((_, idx) => {
                        return <Skeleton variant="text" width={60} height={50} style={{}} key={idx} />;
                      })}
                    </div>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack>
                    <div className={styles.metricsTitle + " supportText_semiBold"}>
                      Attack Complexity (AC)
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "140px" }}>
                      {[0, 1].map((_, idx) => {
                        return <Skeleton variant="text" width={60} height={50} style={{}} key={idx} />;
                      })}
                    </div>
                  </Stack>
                </Grid>
                <Grid item md={5}>
                  <Stack>
                    <div className={styles.metricsTitle + " supportText_semiBold"}>Confidentiality (C)</div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "210px" }}>
                      {[0, 1, 2].map((_, idx) => {
                        return <Skeleton variant="text" width={60} height={50} style={{}} key={idx} />;
                      })}
                    </div>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack>
                    <div className={styles.metricsTitle + " supportText_semiBold"}>
                      Privileges Required (PR)
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "210px" }}>
                      {[0, 1, 2].map((_, idx) => {
                        return <Skeleton variant="text" width={60} height={50} style={{}} key={idx} />;
                      })}
                    </div>
                  </Stack>
                </Grid>
                <Grid item md={5}>
                  <Stack>
                    <div className={styles.metricsTitle + " supportText_semiBold"}>Integrity (I)</div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "210px" }}>
                      {[0, 1, 2].map((_, idx) => {
                        return <Skeleton variant="text" width={60} height={50} style={{}} key={idx} />;
                      })}
                    </div>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack>
                    <div className={styles.metricsTitle + " supportText_semiBold"}>User Interaction (UI)</div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "140px" }}>
                      {[0, 1].map((_, idx) => {
                        return <Skeleton variant="text" width={60} height={50} style={{}} key={idx} />;
                      })}
                    </div>
                  </Stack>
                </Grid>

                <Grid item md={5}>
                  <Stack>
                    <div className={styles.metricsTitle + " supportText_semiBold"}>Availability (A)</div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "210px" }}>
                      {[0, 1, 2].map((_, idx) => {
                        return <Skeleton variant="text" width={60} height={50} style={{}} key={idx} />;
                      })}
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div className={styles.riskWrapper}>
              <Grid container spacing={2}>
                <Grid item md={12} sx={{ marginBottom: "8px" }}>
                  <div className={styles.formTitle + " supportText_semiBold"}>OWASP Category </div>
                  <Skeleton
                    variant="text"
                    width={500}
                    height={40}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>

                <Grid item md={12}>
                  <div className={styles.formTitle + " supportText_semiBold"}>Override Risk </div>
                  <Skeleton
                    variant="text"
                    width={500}
                    height={40}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={styles.editorWrapper}>
              <p className={styles.formTitle + " supportText_semiBold"}>Description</p>
              <Grid container>
                <Grid item md={12} sx={{ marginTop: "-86px", paddingLeft: "8px" }}>
                  <div className={styles.editorBlk}>
                    <Skeleton
                      variant="text"
                      width={800}
                      height={400}
                      style={{
                        display: "block",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.editorWrapper}>
              <p className={styles.formTitle + " supportText_semiBold"}>Proof of Concepts</p>
              <Grid container>
                <Grid item md={12} sx={{ marginTop: "-86px", paddingLeft: "8px" }}>
                  <div className={styles.editorBlk}>
                    <Skeleton
                      variant="text"
                      width={800}
                      height={400}
                      style={{
                        display: "block",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.editorWrapper}>
              <p className={styles.formTitle + " supportText_semiBold"}>Remediation</p>
              <Grid container>
                <Grid item md={12} sx={{ marginTop: "-86px", paddingLeft: "8px" }}>
                  <div className={styles.editorBlk}>
                    <Skeleton
                      variant="text"
                      width={800}
                      height={400}
                      style={{
                        display: "block",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.buttonWrapper}>
              <Skeleton
                variant="text"
                width={60}
                height={50}
                style={{
                  display: "block",
                }}
              />
              <Skeleton
                variant="text"
                width={60}
                height={50}
                style={{
                  display: "block",
                }}
              />
            </div>
          </div>
        </>
      )}

      {type === "reportLists" && (
        <>
          {[...Array(10)].map((item, key) => {
            return (
              <div className={styles.reportListCardContainer + " paragraph"} key={key}>
                <Grid container spacing={2} className={styles.reportListItem}>
                  <Grid item md={1} className={styles.projectId}>
                    <Skeleton
                      variant="text"
                      width={70}
                      height={25}
                      style={{
                        display: "block",
                        marginTop: "8px",
                      }}
                    />
                  </Grid>
                  <Grid item md={3} className={styles.projectName}>
                    <Skeleton
                      variant="text"
                      width={250}
                      height={25}
                      style={{
                        display: "block",
                        marginTop: "8px",
                      }}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <Skeleton
                      variant="text"
                      width={70}
                      height={25}
                      style={{
                        display: "block",
                        marginTop: "8px",
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton
                      variant="text"
                      width={180}
                      height={25}
                      style={{
                        display: "block",
                        marginTop: "8px",
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton
                      variant="text"
                      width={150}
                      height={25}
                      style={{
                        display: "block",
                        marginTop: "8px",
                      }}
                    />
                  </Grid>
                  <Grid item md={3} className={styles.reportDetails}>
                    <Skeleton
                      variant="text"
                      width={250}
                      height={25}
                      style={{
                        display: "block",
                        marginTop: "8px",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </>
      )}

      {type === "vulnerabilityReport" && (
        <div className={styles.VulnerabilityReportWrapper}>
          <div className={styles.reportHeader}>
            <Skeleton
              variant="text"
              width={250}
              height={30}
              style={{
                display: "block",
                marginTop: "8px",
              }}
            />
            <div className={styles.riskBlk}>
              <Skeleton
                variant="text"
                width={150}
                height={30}
                style={{
                  display: "block",
                  marginTop: "8px",
                }}
              />
            </div>
          </div>
          <div className={styles.implicationBlk}>
            <p className={styles.implicationTitle + " heading6"}>
              <Skeleton
                variant="text"
                width={900}
                height={20}
                style={{
                  display: "block",
                  marginTop: "8px",
                }}
              />
              <Skeleton
                variant="text"
                width={900}
                height={20}
                style={{
                  display: "block",
                  marginTop: "8px",
                }}
              />
            </p>
            <p className="paragraph">
              <Skeleton
                variant="text"
                width={900}
                height={20}
                style={{
                  display: "block",
                  marginTop: "8px",
                }}
              />
              <Skeleton
                variant="text"
                width={900}
                height={20}
                style={{
                  display: "block",
                  marginTop: "8px",
                }}
              />
            </p>
          </div>
          <div className={styles.descBlk}>
            <p className={styles.descTitle + " heading6"}>Description</p>
            <div className={styles.descOuterBlk}>
              <p className="paragraph">
                <Skeleton
                  variant="text"
                  width={600}
                  height={40}
                  style={{
                    display: "block",
                    marginTop: "8px",
                  }}
                />
              </p>
            </div>
          </div>
          <div className={styles.reportStatusBlk}>
            <p className={styles.scanTitle + " heading6"}>Scan Status</p>
            <div className={styles.scanListItemWrapper}>
              <Grid container alignItems="center">
                <Grid item md={3} className={styles.scanStatusBlk}>
                  <p className={styles.scanTitle + " supportText_semiBold"}>Scan status</p>
                  <p className={styles.scanIconBlk}>
                    <Skeleton
                      variant="text"
                      width={200}
                      height={25}
                      style={{
                        display: "block",
                        marginTop: "8px",
                      }}
                    />
                  </p>
                </Grid>
                <Grid item md={3} className={styles.scanStatusBlk}>
                  <p className={styles.scanTitle + " supportText_semiBold"}>due date</p>
                  <Skeleton
                    variant="text"
                    width={200}
                    height={25}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
                <Grid item md={3} className={styles.scanStatusBlk}>
                  <p className={styles.scanTitle + " supportText_semiBold"}>engagement type</p>
                  <Skeleton
                    variant="text"
                    width={200}
                    height={25}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
                <Grid item md={3} className={`${styles.scanStatusBlk} ${styles.scanScoreBlk}`}>
                  <p className={styles.scanTitle + " supportText_semiBold"}>methodology</p>
                  <p className={styles.scanScore + " labelText_semiBold"}>
                    <Skeleton
                      variant="text"
                      width={200}
                      height={25}
                      style={{
                        display: "block",
                        marginTop: "8px",
                      }}
                    />
                  </p>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={styles.regulatoryBlk}>
            <p className={styles.scanTitle + " heading6"}>Regulatory</p>
            <div className={styles.regulatoryBody}>
              <Grid container alignItems="center" className={styles.titleBlk}>
                <Grid item md={4} className="paragraph_semiBold">
                  <p>CVSSv3</p>
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" className={styles.metricsBlk}>
                <Grid item md={4} className="paragraph_semiBold">
                  <p>CVSS Metrics</p>
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" className={styles.middleBlk}>
                <Grid item md={4} className="paragraph_semiBold">
                  <p>OWASP</p>
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" className={styles.middleBlk}>
                <Grid item md={4} className="paragraph_semiBold">
                  <p>CWE</p>
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" className={styles.lastBlk}>
                <Grid item md={4} className="paragraph_semiBold">
                  <p>GDPR</p>
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
                <Grid item md={4} className="paragraph">
                  <Skeleton
                    variant="text"
                    width={140}
                    height={30}
                    style={{
                      display: "block",
                      marginTop: "8px",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={styles.pocBlk}>
            <p className={styles.descTitle + " heading6"}>Proof Of Concept</p>
            <div className={styles.descOuterBlk}>
              <p className="paragraph">
                <Skeleton
                  variant="text"
                  width={600}
                  height={40}
                  style={{
                    display: "block",
                    marginTop: "8px",
                  }}
                />
              </p>
            </div>
          </div>
          <div className={styles.remediationBlk}>
            <p className={styles.descTitle + " heading6"}>Remediation</p>
            <div className={styles.descOuterBlk}>
              <p className="paragraph">
                <Skeleton
                  variant="text"
                  width={600}
                  height={40}
                  style={{
                    display: "block",
                    marginTop: "8px",
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      )}

      {type === "editScanDetails" && (
        <div className={styles.formDetail}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Project name</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <p className={styles.formTitle + " supportText_semiBold"}>Engagement Type</p>
              <Skeleton
                variant="text"
                width={360}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <p className={styles.formTitle + " supportText_semiBold"}>Methodology</p>
              <Skeleton
                variant="text"
                width={360}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <p className={styles.formTitle + " supportText_semiBold"}>Request Date</p>
              <Skeleton
                variant="text"
                width={360}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <p className={styles.formTitle + " supportText_semiBold"}>Due Date</p>
              <Skeleton
                variant="text"
                width={360}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <p className={styles.formTitle + " supportText_semiBold"}>Type of Scan</p>
              <Skeleton
                variant="text"
                width={360}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <p className={styles.formTitle + " supportText_semiBold"}>Url</p>
              <Skeleton
                variant="text"
                width={360}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Requested By</p>
              <Skeleton
                variant="text"
                width={720}
                height={180}
                style={{
                  display: "block",
                  marginTop: "-32px",
                }}
              />
            </Grid>
            <Grid item md={12} sx={{ marginTop: "-24px" }}>
              <p className={styles.formTitle + " supportText_semiBold"}>Assigned To</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Security Score</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Status</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
          </Grid>
          <div className={styles.buttonWrapper}>
            <Skeleton
              variant="text"
              width={50}
              height={55}
              style={{
                display: "block",
              }}
            />
            <Skeleton
              variant="text"
              width={50}
              height={55}
              style={{
                display: "block",
              }}
            />
          </div>
        </div>
      )}

      {type === "updateProject" && (
        <div className={styles.formDetail}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Client Name *</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Project Name *</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Description</p>
              <Skeleton
                variant="text"
                width={720}
                height={180}
                style={{
                  display: "block",
                  marginTop: "-32px",
                }}
              />
            </Grid>
            <Grid item md={12} sx={{ marginTop: "-24px" }}>
              <p className={styles.formTitle + " supportText_semiBold"}>Invite Users</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
          </Grid>
          <div className={styles.buttonWrapper}>
            <Skeleton
              variant="text"
              width={50}
              height={55}
              style={{
                display: "block",
              }}
            />
            <Skeleton
              variant="text"
              width={50}
              height={55}
              style={{
                display: "block",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Shimmer;
