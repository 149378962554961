import { FC, useContext, useRef, useState } from "react";
import {
  Avatar,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { getUserInfo, toastFlashMessage, getImage } from "../../../utils";
import { EditProfileDialog, AccountSettingDialog } from "../components";
import { ConfirmationDialog } from "../../../shared_ui_components";
import { TOAST_ACTIONS } from "../../../context/ToastReducer";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";
import { logoutApi } from "../apiServices";

const ProfileDropdown: FC = () => {
  const anchorRef = useRef<any>(null);
  const navigate = useNavigate();
  const userInfo = getUserInfo();

  const [open, setOpen] = useState(false);
  const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
  const [openAccountSetting, setOpenAccountSetting] = useState(false);
  const [openLogPop, setLogPop] = useState(false);
  const { dispatch } = useContext(GlobalContext);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef?.current && anchorRef?.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseProfileUpdate = () => {
    setOpenProfileUpdate(false);
    setOpenAccountSetting(false);
  };

  const logout = () => {
    logoutApi().then((response: Partial<{ statusCode: number; message: string }>) => {
      if (response.statusCode === 200) {
        dispatch({
          type: TOAST_ACTIONS.Clear,
          payload: "",
        });
        dispatch({
          type: PROJECT_ACTIONS.Clear,
        });
        localStorage.clear();
        navigate("/signin");
        toastFlashMessage(response.message as string, "success");
      } else {
        toastFlashMessage(response.message as string, "error");
      }
    });
  };

  return (
    <div className="profileWrapper">
      <>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="profileWrapper"
        >
          {userInfo && userInfo?.user?.profile_pic ? (
            <img className="profilePic" src={userInfo?.user?.profile_pic} alt="Profile pic" />
          ) : (
            <Avatar className="avatar">
              {userInfo?.user?.first_name
                ? userInfo?.user?.first_name.substring(0, 1).toUpperCase()
                : userInfo?.user?.email
                ? userInfo?.user?.email.substring(0, 1).toUpperCase()
                : ""}
            </Avatar>
          )}

          <img className="caret" src={getImage("arrow_down.svg")} alt="img" />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className="profileDropdown"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <Box
                  sx={{
                    position: "relative",
                    mt: "4px",
                    "&::before": {
                      backgroundColor: "white",
                      content: '""',
                      display: "block",
                      position: "absolute",
                      width: 12,
                      height: 12,
                      top: -6,
                      transform: "rotate(45deg)",
                      right: "30px",
                      borderTop: "1px solid #E6E6E7",
                      borderLeft: "1px solid #E6E6E7",
                      zIndex: 2,
                      borderRadius: "3px",
                    },
                  }}
                />
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="menu-list-grow">
                    <MenuItem
                      onClick={() => {
                        setOpenProfileUpdate(true);
                        handleToggle();
                      }}
                      className="menuItem"
                    >
                      <img className="defaultImg" src={getImage("edit_profile_default.svg")} alt=" " />
                      <img className="activeImg" src={getImage("edit_profile_active.svg")} alt=" " />
                      <span className="supportText menuText">Edit Profile</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenAccountSetting(true);
                        handleToggle();
                      }}
                      className="menuItem"
                    >
                      <img className="defaultImg" src={getImage("account_settings_default.svg")} alt=" " />
                      <img className="activeImg" src={getImage("account_settings_active.svg")} alt=" " />
                      <span className="supportText menuText">Account Settings</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setLogPop(true);
                        handleToggle();
                      }}
                      className="menuItem supportText"
                    >
                      <img className="defaultImg" src={getImage("logout_default.svg")} alt=" " />
                      <img className="activeImg" src={getImage("logout_active.svg")} alt=" " />
                      <span className="supportText menuText">Logout</span>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {openLogPop ? (
          <ConfirmationDialog
            text="Logout"
            subText="Are you sure you want to log out?"
            image="logout_icon.svg"
            cancelBtnText="Cancel"
            successBtnText="Logout"
            cancelBtnClass="outlinedBtn"
            successBtnClass="primaryBtn"
            handleSuccess={logout}
            handleCancel={() => setLogPop(false)}
          />
        ) : null}
        {openProfileUpdate ? (
          <EditProfileDialog open={openProfileUpdate} onClose={handleCloseProfileUpdate} />
        ) : null}
        {openAccountSetting ? (
          <AccountSettingDialog open={openAccountSetting} onClose={handleCloseProfileUpdate} />
        ) : null}
      </>
    </div>
  );
};

export default ProfileDropdown;
