import { FC, useState, useEffect, useContext } from "react";
import styles from "../../../assets/styles/home.module.scss";
import { Grid, Container } from "@mui/material";
import { ReportListItem, Shimmer } from ".";
import { useParams, useNavigate } from "react-router-dom";
import { showReportLists } from "../apiServices";
import { toastFlashMessage, getImage } from "../../../utils";
import { TReports } from "../../../types";
import { deleteReport } from "../apiServices";
import { GlobalContext } from "../../../context/GlobalContext";

const ReportList: FC = () => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [showMore, setShowMore] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [loader, setLoader] = useState(true);
  const [reportLists, setReportLists] = useState<TReports>({});

  useEffect(() => {
    const onScroll = (e: Event) => {
      setScrollTop((e.target as HTMLDocument).documentElement.scrollTop);
      setScrolling((e.target as HTMLDocument).documentElement.scrollTop > scrollTop);
    };
    const win: Window = window;
    win.addEventListener("scroll", onScroll);

    return () => win.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (id) {
      showReportLists(`${id}`).then(
        (response: Partial<{ statusCode: number; data: TReports; message: string }>) => {
          if (response.statusCode === 200) {
            setLoader(false);
            setReportLists(response.data as TReports);
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoader(false);
            navigate(`/projects/${id}`);
          }
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDeleteReport = (id: number) => {
    deleteReport(`${id}`).then((response) => {
      if (response.statusCode === 200) {
        setReportLists((reports) => ({
          ...reports,
          reports: reportLists?.reports?.filter((item) => item.id !== id),
        }));
        toastFlashMessage(response.message, "success");
      } else {
        toastFlashMessage(response.message, "error");
      }
    });
  };

  useEffect(() => {
    if (reportLists?.reports?.filter((item) => item.report_file).length === 0) {
      navigate(`/projects/${id}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportLists]);

  return (
    <>
      <div className={`${styles.productStickyHeader} ${scrolling ? styles.scrollEffect : null}`}>
        <Container maxWidth="lg">
          {loader ? (
            <Shimmer type="singleProjectDetails" />
          ) : (
            <div className={styles.productTitleBlk}>
              <div className={styles.productTitle}>
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                  className={styles.productTitleLink + " paragraph"}
                >
                  {" "}
                  Projects
                </div>{" "}
                / <span>{reportLists?.project?.name}</span>
              </div>
              <div className={styles.productHeaderWrapper}>
                <div className={styles.productHeaderLeft}>
                  <div>
                    <p className="heading4">{reportLists?.project?.name}</p>
                    <p className={styles.subTitle + " supportText"}>{reportLists?.project?.url}</p>
                  </div>
                </div>
                <div className={styles.productHeaderRight}>
                  {roleLists?.roleData?.id === 5 ? (
                    <>
                      <p className={styles.productFileId + " supportText_semiBold"}>
                        VERSION : <span>{String(reportLists?.project?.version).split("").join(".")}</span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className={styles.researcherDetail + " supportText_semiBold"}>
                        RESEARCHER :
                        <span className="paragraph_semiBold">
                          {reportLists?.project?.researcher && (
                            <img
                              src={
                                reportLists?.project?.researcher?.profile_pic
                                  ? reportLists?.project?.researcher?.profile_pic
                                  : getImage("user_profile.svg")
                              }
                              alt=" "
                            />
                          )}

                          {reportLists?.project?.researcher?.first_name}
                        </span>
                      </p>
                      <p className={styles.productFileId + " supportText_semiBold"}>
                        VERSION : <span>{String(reportLists?.project?.version).split("").join(".")}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div>
                <p className={styles.productDescription + " paragraph"}>
                  {reportLists?.project?.description && reportLists?.project?.description?.length > 160 ? (
                    <>
                      {showMore
                        ? reportLists?.project?.description
                        : `${reportLists?.project?.description?.substring(0, 160)}...`}
                      <span onClick={() => setShowMore(!showMore)}>
                        {showMore ? " show less" : " show more"}
                      </span>
                    </>
                  ) : (
                    reportLists?.project?.description
                  )}
                </p>
              </div>
            </div>
          )}
        </Container>
      </div>
      <Container maxWidth="lg">
        <div className={styles.productDetailPageWrapper}>
          <div className={styles.scanListView}>
            <div className={styles.scanListViewTitleWrapper}>
              <p className={styles.scanListViewTitle + " heading6"}>
                {"Reports"}
                <span>{reportLists?.reports?.filter((item) => item.report_file).length}</span>
              </p>
            </div>
            <Grid container spacing={2} className={styles.reportListWrapper + " labelText_semiBold"}>
              <Grid item md={1}>
                <p>file ID</p>
              </Grid>
              <Grid item md={3}>
                <p>title</p>
              </Grid>
              <Grid item md={1}>
                <p>version</p>
              </Grid>
              <Grid item md={2}>
                <p>Date</p>
              </Grid>
              <Grid item md={2}>
                <p>Status</p>
              </Grid>
            </Grid>
            {loader ? (
              <Shimmer type="reportLists" />
            ) : (
              reportLists?.reports &&
              reportLists?.reports?.length > 0 &&
              reportLists?.reports?.map((item, index) => {
                return (
                  <ReportListItem
                    key={index}
                    {...item}
                    projectName={reportLists?.project?.name}
                    handleDeleteReport={handleDeleteReport}
                  />
                );
              })
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default ReportList;
