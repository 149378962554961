import { createContext, useReducer, Dispatch, FC } from "react";
import { saveStore } from "../store/GlobalStore";
import { toastListsReducer, ToastActions, ToastType } from "./ToastReducer";
import { ClientReducers, ClientActions, ClientType } from "./ClientReducer";
import { ProjectReducers } from "./ProjectReducer";
import { UserReducers } from "./UserReducer";
import { VulnerabilityReducers } from "./VulnerabilityReducer";
import { RoleReducers } from "./RoleReducer";
import { TUsers, TProjectResponse, TVulnerabilityCategory, TRoleType } from "../types";
import { roleDetails, isAtob } from "../utils";
import { Navigate } from "react-router-dom";

const _isAtob = localStorage.getItem("userInfo") ? (
  isAtob(
    `${JSON.parse(localStorage.getItem("userInfo") || "").access?.substring(
      JSON.parse(localStorage.getItem("userInfo") || "").access?.lastIndexOf("$") + 1,
      JSON.parse(localStorage.getItem("userInfo") || "")?.access?.length
    )}`
  )
) : (
  <Navigate to="/signin" />
);

type TPagination = {
  total?: number;
  number_of_pages?: number;
  current_page?: number;
  per_page?: number;
};

interface TCommonState {
  loading: boolean;
  showNoState: boolean;
}

export interface TClientState extends TCommonState {
  clientData: ClientType[];
}

export interface TProjectState extends TCommonState {
  projectData: TProjectResponse[];
  pagination?: TPagination;
}
export interface TUserState extends TCommonState {
  userData: TUsers[];
}

export interface TRoleState {
  roleData: TRoleType;
}

export interface TVulnerabilityState extends TCommonState {
  vulnerabilityData: TVulnerabilityCategory[];
}

type InitialStateType = {
  toastLists: Array<ToastType>;
  clientLists: TClientState;
  projectLists: TProjectState;
  userLists: TUserState;
  vulneralibityLists: TVulnerabilityState;
  roleLists: TRoleState;
};

const initialState = {
  toastLists: [],
  clientLists: {
    clientData: [],
    loading: true,
    showNoState: false,
  },
  projectLists: {
    projectData: [],
    loading: true,
    pagination: {},
    showNoState: false,
  },
  userLists: {
    userData: [],
    loading: true,
    showNoState: false,
  },
  vulneralibityLists: {
    vulnerabilityData: [],
    loading: true,
    showNoState: false,
  },
  roleLists: {
    roleData: {
      id: localStorage.getItem("userInfo") ? roleDetails(_isAtob as string)?.id : null,
      name: "",
      code: "",
      permission: [],
    },
  },
};

export const GlobalContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

const mainReducer = (
  { toastLists, clientLists, projectLists, userLists, vulneralibityLists, roleLists }: InitialStateType,
  action: ToastActions | ClientActions
) => ({
  toastLists: toastListsReducer(toastLists, action),
  clientLists: ClientReducers(clientLists, action),
  projectLists: ProjectReducers(projectLists, action),
  userLists: UserReducers(userLists, action),
  vulneralibityLists: VulnerabilityReducers(vulneralibityLists, action),
  roleLists: RoleReducers(roleLists, action),
});

const GlobalContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const { Provider } = GlobalContext;
  saveStore({ state, dispatch });
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
export default GlobalContextProvider;
