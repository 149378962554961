/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, ChangeEvent, SyntheticEvent, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/styles/client.module.scss";
import { Dialog, Grid } from "@mui/material";
import { errorCodes } from "../../../constants";
import { CustomButton, CustomLink, CustomInput } from "../../../shared_ui_components";
import { Errors, TClientDetail, T } from "../../../types";
import { addNewClient, updateClient } from "../apiServices";
import { toastFlashMessage, capitalizeFirstLetter, listingAllProjects, getImage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidations";
import { GlobalContext } from "../../../context/GlobalContext";
import { CLIENT_ACTIONS } from "../../../context/ClientReducer";

type TDialogProps = {
  open: boolean;
  onClose: () => void;
  type: string;
  companyName?: string;
  companyLogo?: string;
  id?: string;
  handleUpdateClient?: (name: string, logo: string) => void;
};

type TformData = {
  name: string;
};

const AddNewClientDialog: FC<TDialogProps> = ({
  open,
  onClose,
  type,
  companyName,
  companyLogo,
  id,
  handleUpdateClient,
}): JSX.Element => {
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<TformData>({
    name: "",
  });
  const [image, setImage] = useState<string | Blob>("");
  const [displayImage, setDisplayImage] = useState(getImage("profile_placeholder.svg"));
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [validateLoader, setValidatorLoader] = useState(false);

  useEffect(() => {
    if (companyName) {
      setFormData((form: TformData) => ({
        ...form,
        name: companyName,
      }));
    }
  }, [companyName]);

  const handleCancel = () => {
    if (companyName) {
      onClose();
    } else {
      onClose();
      setFormData({
        name: "",
      });
      setError(() => ({
        ...formData,
        name: "",
      }));
    }
    setDisplayImage(getImage("profile_placeholder.svg"));
    setValidatorLoader(false);
  };

  const handleImgChange = (e?: ChangeEvent) => {
    const target = e?.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setImage(file);
    setDisplayImage(URL.createObjectURL(file));
  };

  const handleTextChange = (key: string, val: string) => {
    setFormData((formData) => ({
      ...formData,
      [key]: val,
    }));
    setError(() => ({
      ...formData,
      [key]: "",
    }));
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validateNewInput: {
      [key: string]: string;
    };
    validateNewInput = {
      name: errorCode["name"][
        fieldValidation({
          ...errorCode["nameObj"],
          fieldval: formData.name,
        })
      ],
    };

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setValidatorLoader(true);
      let form_data: FormData = new FormData();
      form_data.append("logo", image);
      form_data.append("name", formData.name);
      if (type === "Add New Client") {
        addNewClient(form_data).then(
          (response: Partial<{ statusCode: number; data: TClientDetail; message: string }>) => {
            if (response.statusCode === 200) {
              dispatch({
                type: CLIENT_ACTIONS.Add,
                payload: response.data,
              });
              setValidatorLoader(false);
              toastFlashMessage(response.message as string, "success");
              handleCancel();
              listingAllProjects();
            } else {
              setValidatorLoader(false);
              toastFlashMessage(response.message as string, "error");
            }
          }
        );
      } else {
        updateClient(form_data, `${id}`).then(
          (response: Partial<{ statusCode: number; data: TClientDetail; message: string }>) => {
            if (response.statusCode === 200) {
              dispatch({
                type: CLIENT_ACTIONS.Update,
                payload: response.data,
              });
              setValidatorLoader(false);
              toastFlashMessage(response.message as string, "success");
              handleUpdateClient?.(response?.data?.name as string, response?.data?.logo as string);
              handleCancel();
              listingAllProjects();
            } else {
              setValidatorLoader(false);
              toastFlashMessage(response.message as string, "error");
            }
          }
        );
      }
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} sx={{ borderRadius: "4px" }}>
      <div className={styles.dialogWrapper}>
        <div className={styles.headerTitle}>
          <p className="heading5">{type}</p>
          <img src={getImage("close_icon.svg")} alt="close" onClick={handleCancel} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.formDetail}>
            <p className="supportText_semiBold">Company Logo</p>
            <div className={styles.companyLogoButton}>
              <img
                src={
                  displayImage !== getImage("profile_placeholder.svg")
                    ? displayImage
                    : companyLogo ?? getImage("profile_placeholder.svg")
                }
                alt=""
              />
              <div className={styles.importButtonWrapper}>
                <CustomLink className={styles.importButton + " outlinedBtn "} children="Update Logo" to="" />
                <input
                  type="file"
                  title=""
                  accept="image/png, image/jpg, image/jpeg"
                  className={styles.inputFile}
                  onChange={handleImgChange}
                />
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <CustomInput
                  label="Company Name *"
                  className={styles.inputBlk}
                  placeholder="Enter Company Name"
                  onChange={(e) => handleTextChange("name", e.target.value)}
                  noMargin="noMargin"
                  value={capitalizeFirstLetter(formData.name)}
                  error={error.name}
                />
              </Grid>
            </Grid>
          </div>
          <div className={styles.buttonWrapper}>
            <CustomButton className="outlinedBtn" children="Cancel" type="button" onClick={handleCancel} />
            <CustomButton
              className={`${validateLoader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
              children={type === "Add New Client" ? "Create" : "Update"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddNewClientDialog;
