import { FC, useContext } from "react";
import { Grid } from "@mui/material";
import styles from "../../../assets/styles/home.module.scss";
import { GlobalContext } from "../../../context/GlobalContext";

const ScanListHeader: FC = () => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const roleId = roleLists?.roleData?.id;
  return (
    <div className={styles.scanListItemHeader}>
      {roleId === 5 ? (
        <Grid container className={styles.scanTitle + " supportText_semiBold"}>
          <Grid item md={1}>
            <p>SCAN ID</p>
          </Grid>
          <Grid item md={2}>
            <p>SECURITY SCORE</p>
          </Grid>
          <Grid item md={2}>
            <p>SCAN TYPE</p>
          </Grid>
          <Grid item md={2}>
            <p>COMPLETION DATE</p>
          </Grid>
          <Grid item md={2}>
            <p>SCAN STATUS</p>
          </Grid>
          <Grid item md={2}>
            <p>REPORT</p>
          </Grid>
          <Grid item md={1}>
            <p>ACTION</p>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={styles.scanTitle + " supportText_semiBold"}>
          <Grid item md={1}>
            <p>SCAN ID</p>
          </Grid>
          <Grid item md={1.5}>
            <p>SCAN TYPE</p>
          </Grid>
          <Grid item md={2}>
            <p>URL</p>
          </Grid>
          <Grid item md={1.5}>
            <p>ASSIGNED TO</p>
          </Grid>

          <Grid item md={1.5}>
            <p>DUE DATE</p>
          </Grid>
          <Grid item md={2}>
            <p>REQUESTED BY</p>
          </Grid>
          <Grid item md={1.5}>
            <p>SCAN STATUS</p>
          </Grid>
          <Grid item md={1}>
            <p>ACTION</p>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ScanListHeader;
