import { FC, MouseEvent, useState, useContext } from "react";
import { Grid } from "@mui/material";
import styles from "../../../assets/styles/client.module.scss";
import { ConfirmationDialog, CustomMoreMenu } from "../../../shared_ui_components";
import { TUsers, TUserRoles } from "../../../types";
import { ClientAddUpdateUserDialog } from ".";
import { deactivateUser, resendInviteUser, activateUser } from "../../users/apiServices";
import { USER_ACTIONS } from "../../../context/UserReducer";
import { GlobalContext } from "../../../context/GlobalContext";
import { toastFlashMessage, getImage } from "../../../utils";

type IProps = {
  uuid?: string;
  first_name?: string | null;
  email?: string | null;
  role?: TUserRoles;
  profile_pic?: string | null;
  status?: number;
  clientId?: string;
  handleUpdateClientUsers?: (users: TUsers) => void;
  handleSearch?: (p: string) => void;
};

const ClientUserListItem: FC<IProps> = ({
  uuid,
  first_name,
  email,
  role,
  profile_pic,
  status,
  clientId,
  handleUpdateClientUsers,
  handleSearch,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [openLogPop, setLogPop] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddUserDialog = () => {
    setOpenAddUser(!openAddUser);
  };
  const handleLog = (val: boolean) => {
    setLogPop(val);
  };

  const handleResendInvite = () => {
    const resendInviteUserId = {
      user_id: uuid ? uuid : "",
      client: clientId,
    };
    resendInviteUser(resendInviteUserId).then(
      (response: Partial<{ statusCode: number; data: TUsers; message: string }>) => {
        if (response.statusCode === 200) {
          toastFlashMessage(response.message as string, "success");
        } else {
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };

  const handleActivateUser = () => {
    const deactiveId = {
      user_id: uuid ? uuid : "",
      client: clientId,
    };
    activateUser(deactiveId).then(
      (response: Partial<{ statusCode: number; data: TUsers; message: string }>) => {
        if (response.statusCode === 200) {
          dispatch({
            type: USER_ACTIONS.Update,
            payload: response.data,
          });
          handleUpdateClientUsers?.(response.data as TUsers);
          handleSearch?.("");
          toastFlashMessage(response.message as string, "success");
          setLogPop(false);
        } else {
          setLogPop(false);
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };

  const handleDeactivateUser = () => {
    const _deactiveUser = {
      user_id: uuid ? uuid : "",
      client: clientId,
    };
    deactivateUser(_deactiveUser).then(
      (response: Partial<{ statusCode: number; data: TUsers; message: string }>) => {
        if (response.statusCode === 200) {
          dispatch({
            type: USER_ACTIONS.Update,
            payload: response.data,
          });
          handleUpdateClientUsers?.(response.data as TUsers);
          handleSearch?.("");
          toastFlashMessage(response.message as string, "success");
          setLogPop(false);
        } else {
          setLogPop(false);
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };

  return (
    <div className={styles.userListItem}>
      <Grid container className={status === 2 ? styles.disableRow : ""}>
        <Grid item md={3}>
          <p className={styles.userName + " paragraph_semiBold"}>
            <img src={profile_pic ? profile_pic : getImage("user_profile.svg")} alt=" " />
            <span>{first_name ? first_name : email?.substring(0, email.lastIndexOf("@"))}</span>
          </p>
        </Grid>
        <Grid item md={3}>
          <p className={styles.userDetail + " supportText14"}>{email}</p>
        </Grid>
        <Grid item md={3}>
          <p className={styles.userDetail + " supportText14"}>{role?.name}</p>
        </Grid>
        <Grid item md={2} className={styles.userStatus + " paragraph_semiBold"}>
          <>
            <img
              src={
                status === 0
                  ? getImage("status_low.svg")
                  : status === 1
                  ? getImage("status_success.svg")
                  : status === 2
                  ? getImage("status_deactivate.svg")
                  : null
              }
              alt="status"
            />
            <span
              className={
                status === 0
                  ? styles.pending
                  : status === 1
                  ? styles.success
                  : status === 2
                  ? styles.deactivated
                  : ""
              }
            >
              {status === 0 ? " Pending " : status === 1 ? " Accepted " : status === 2 ? "Deactivated" : ""}
            </span>
          </>{" "}
        </Grid>
        <Grid item md={1}>
          <img
            className={styles.moreIcon}
            aria-controls="simple-menu"
            onClick={handleClick}
            src={getImage("more_menu.svg")}
            alt=" "
          />
          {status === 0 ? (
            <CustomMoreMenu
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleLogOut={handleLog}
              handleClick1={handleAddUserDialog}
              handleClick2={handleResendInvite}
              noOfItems={2}
              status={1}
              message1="Update user details"
              message2="Resend invite"
              image1="user_edit.svg"
              image2="resend_invite.svg"
            />
          ) : status === 1 ? (
            <CustomMoreMenu
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleLogOut={handleLog}
              handleClick1={handleAddUserDialog}
              noOfItems={2}
              message1="Update user details"
              message2="Deactivate user"
              image1="user_edit.svg"
              image2="delete_user.svg"
            />
          ) : (
            <CustomMoreMenu
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleClick1={handleActivateUser}
              noOfItems={1}
              message1="Activate user"
              image1="user_edit.svg"
            />
          )}
        </Grid>
      </Grid>
      {openLogPop && (
        <ConfirmationDialog
          text="Deactivate user"
          subText="Are you sure you want to deactivate this user?"
          image="deactivate_user.svg"
          cancelBtnText="Cancel"
          successBtnText="Deactivate"
          cancelBtnClass="outlinedBtn"
          successBtnClass="secondaryBtn"
          handleSuccess={handleDeactivateUser}
          handleCancel={() => setLogPop(false)}
        />
      )}

      <ClientAddUpdateUserDialog
        type="Update User"
        open={openAddUser}
        onClose={handleAddUserDialog}
        clientId={clientId}
        userFirstName={first_name ? first_name : ""}
        userEmail={email ? email : ""}
        userId={uuid ? uuid : ""}
        handleUpdateClientUsers={handleUpdateClientUsers}
        handleSearch={handleSearch}
      />
    </div>
  );
};

export default ClientUserListItem;
