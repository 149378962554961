import { FC, useState } from "react";
import { Dialog, MenuItem, Grid, Stack, Switch, styled } from "@mui/material";
import { CustomButton, SelectDropdown, CustomInput } from "../../../shared_ui_components";
import { notificationSettings } from "../apiServices";
import { TUserInfo, TUserInfoProp } from "../../../types";
import { getUserInfo, toastFlashMessage, getImage } from "../../../utils";

type TAccountSettingProps = {
  open: boolean;
  onClose: (value: string) => void;
};

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#3F74EC" : "#3F74EC",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const AccountSettingDialog: FC<TAccountSettingProps> = ({ open, onClose }) => {
  let userInfo = getUserInfo();
  const [step, setStep] = useState(2);
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState({
    general_notifications: userInfo?.user?.general_notifications
      ? userInfo?.user?.general_notifications
      : false,
    security_notifications: userInfo?.user?.security_notifications
      ? userInfo?.user?.security_notifications
      : false,
    email_notifications: userInfo?.user?.email_notifications ? userInfo?.user?.email_notifications : false,
  });
  const [formData, setFormData] = useState({
    notifications_pause_days: userInfo?.user?.notifications_pause_days
      ? userInfo?.user?.notifications_pause_days
      : 0,
  });

  const handleSwitch = (key: string, val: boolean) => {
    setChecked({
      ...checked,
      [key]: !val,
    });
  };

  const handleChange = (key: string, val: string) => {
    setFormData({
      ...formData,
      [key]: val,
    });
  };

  const handleCancel = () => {
    onClose("false");
  };
  const handleNotificationSetting = () => {
    setLoader(true);
    const form_data = {
      ...checked,
      ...formData,
    };
    notificationSettings(form_data).then(
      (response: Partial<{ statusCode: number; data: TUserInfo; message: string }>) => {
        if (response.statusCode === 200) {
          userInfo = {
            ...userInfo,
            user: {
              ...userInfo?.user,
              general_notifications: response?.data?.general_notifications,
              security_notifications: response?.data?.security_notifications,
              email_notifications: response?.data?.email_notifications,
              notifications_pause_days: response?.data?.notifications_pause_days,
            },
          } as TUserInfoProp | null;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          toastFlashMessage(response.message as string, "success");
          setLoader(false);
          handleCancel();
        } else {
          setLoader(false);
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };
  const handleUpload = () => {};
  const handleTextChange = () => {};
  return (
    <Dialog onClose={onClose} open={open} sx={{ borderRadius: "4px" }}>
      <div className="AccountSettingDialogWrapper">
        <div className="headerTitle">
          <p className="heading5">Account Settings</p>
          <img src={getImage("close_icon.svg")} alt="close" onClick={handleCancel} />
        </div>
        <div className="settingTabWrapper">
          <CustomButton
            className={`tabLink ${step === 2 && " active"} heading6`}
            type="button"
            onClick={() => setStep(2)}
          >
            Notification Settings <span></span>
          </CustomButton>
        </div>
        <div className="formDetail">
          {step === 1 ? (
            <Grid container spacing={2}>
              <Grid item md={12} sx={{ display: "flex" }}>
                <Stack>
                  <p className="formTitle supportText_semiBold">Company logo</p>
                  <div className="profileWrapper">
                    <img className="profileImg" src={getImage("project_icon.svg")} alt="user" />
                    <CustomButton
                      children="Change logo"
                      className="uploadBtn outlinedBtn"
                      type="button"
                      onClick={handleUpload}
                    />
                    <input type="file" accept="image/png, image/jpeg, image/jpg" className="inputFile" />
                  </div>
                </Stack>
              </Grid>
              <Grid item md={12}>
                <p className="formTitle supportText_semiBold">Company name</p>
                <CustomInput
                  className="inputBlk"
                  placeholder="Emirates Airlines"
                  onChange={handleTextChange}
                  noMargin="noMargin"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item md={12}>
                <p className="formTitle supportText_semiBold">Pause Notification</p>
                <div className="fieldWrapper">
                  <SelectDropdown
                    value={formData.notifications_pause_days}
                    onChange={(e) => handleChange("notifications_pause_days", e.target.value)}
                  >
                    <MenuItem value={0} disabled>
                      Select Time Period
                    </MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </SelectDropdown>
                </div>
              </Grid>
              <Grid item md={12}>
                <div className="fieldWrapper">
                  <p className="formTitle supportText_semiBold">General Notifications</p>
                  <p className="formText">
                    Recieve general notifications such as, addition or deletion of an asset. It may also
                    include remainders to turn auto-discovery.
                  </p>
                  <CustomSwitch
                    className="switchBtn"
                    size="small"
                    checked={checked.general_notifications}
                    onChange={() => handleSwitch("general_notifications", checked.general_notifications)}
                  />
                </div>
              </Grid>
              <Grid item md={12}>
                <div className="fieldWrapper">
                  <p className="formTitle supportText_semiBold">Security Notifications</p>
                  <p className="formText">
                    Recieve security notifications on any critical or high risk APIs discovered. It may also
                    include security notifications regarding a domain or sub-domain.
                  </p>
                  <CustomSwitch
                    className="switchBtn"
                    size="small"
                    checked={checked.security_notifications}
                    onChange={() => handleSwitch("security_notifications", checked.security_notifications)}
                  />
                </div>
              </Grid>
              <Grid item md={12}>
                <div className="fieldWrapper">
                  <p className="formTitle supportText_semiBold">Email Notifications</p>
                  <p className="formText">
                    Recieve general and security notifications to your registered email address.
                  </p>
                  <CustomSwitch
                    className="switchBtn"
                    size="small"
                    checked={checked.email_notifications}
                    onChange={() => handleSwitch("email_notifications", checked.email_notifications)}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        <div className="buttonWrapper">
          <CustomButton className="outlinedBtn" children="Cancel" type="button" onClick={handleCancel} />
          <CustomButton
            className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
            children={`${loader ? "Updating..." : "Update"}`}
            type="button"
            onClick={handleNotificationSetting}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default AccountSettingDialog;
