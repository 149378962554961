/* eslint-disable jsx-a11y/role-supports-aria-props */
import { FC, MouseEvent, SyntheticEvent, useState, useEffect, useContext } from "react";
import { Container, Grid, Tooltip } from "@mui/material";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { CustomLink, NoResult, CustomMoreMenu } from "../../../shared_ui_components";
import { Search } from "../../../shared_elements";
import styles from "../../../assets/styles/home.module.scss";
import { AnalysisList, Shimmer, ScanDetailChart } from "../components";
import { getSingleScanDetails, deleteFindings } from "../apiServices";
import { toastFlashMessage, getImage } from "../../../utils";
import { TScans } from "../../../types";
import { generateReport } from "../apiServices";
import { GlobalContext } from "../../../context/GlobalContext";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";

const ScanAnalysisDetails: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    state: { roleLists },
    dispatch,
  } = useContext(GlobalContext);

  const { id, scanId } = useParams();
  const roleId = roleLists?.roleData?.id;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusBtn, setStatusBtn] = useState(1);
  const [singleScanDetails, setSingleScanDetails] = useState<TScans>();
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenLink = (e: SyntheticEvent) => {
    e.preventDefault();
    if (singleScanDetails?.url?.includes("http")) {
      window.open(singleScanDetails?.url);
    } else {
      window.open("https://" + singleScanDetails?.url);
    }
  };

  const handleGenerateReport = () => {
    setLoader(true);
    generateReport(`${scanId}`).then(
      (response: Partial<{ statusCode: number; data: TScans; message: string }>) => {
        if (response.statusCode === 200) {
          setLoader(false);
          toastFlashMessage(response.message as string, "success");
        } else {
          setLoader(false);
          toastFlashMessage(response.message as string, "error");
          navigate(-1);
        }
      }
    );
  };

  const handleEditScanDetails = () => {
    if (location.pathname.includes("vulnerabilities")) {
      navigate(`/vulnerabilities/${id}/update-scan/${scanId}`);
    } else {
      navigate(`/projects/${id}/update-scan/${scanId}`);
    }
  };

  const handleAddFindings = () => {
    if (location.pathname.includes("vulnerabilities")) {
      navigate(`/vulnerabilities/${id}/scan-details/${scanId}/add-findings`);
    } else {
      navigate(`/projects/${id}/scan-details/${scanId}/add-findings`);
    }
  };

  /* Calling Single Project Detail API  */
  useEffect(() => {
    if (scanId) {
      getSingleScanDetails(`${scanId}`).then(
        (response: Partial<{ statusCode: number; data: TScans; message: string }>) => {
          if (response.statusCode === 200) {
            setLoader(false);
            if (roleId === 5 && response.data?.status !== 3) {
              navigate(`/projects/${id}`);
            } else {
              const data = response.data as TScans;
              localStorage.setItem('attachments',  JSON.stringify(data.attachments));
              setSingleScanDetails(data);
            }
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoader(false);
            navigate(`/projects/${id}`);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanId]);

  const handleDeleteAnalysis = (id: number) => {
    deleteFindings(id as number).then(
      (response: Partial<{ statusCode: number; data: number; message: string }>) => {
        if (response.statusCode === 200) {
          setSingleScanDetails((scan) => ({
            ...scan,
            analysis: singleScanDetails?.analysis?.filter((item) => item.id !== id),
          }));
          toastFlashMessage(response.message as string, "success");
        } else {
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };

  return (
    <>
      <div className={styles.productStickyHeader + " " + styles.scanDetailNoSticky}>
        <Container maxWidth="lg">
          {!loader &&
            (location.pathname.includes("vulnerabilities") ? (
              <div className={styles.headerWrapper}>
                <Container maxWidth="lg">
                  <div className={styles.backWrapper}>
                    <div className={styles.backBtn} onClick={() => navigate(-1)}>
                      <p className={styles.btnText}>
                        <img src={getImage("arrow_left_grey.svg")} alt=" " />
                        Back to Occurences
                      </p>
                    </div>
                  </div>
                </Container>
              </div>
            ) : (
              <div className={styles.productTitleBlk}>
                <div className={styles.productTitle}>
                  <CustomLink
                    className="paragraph"
                    onClick={() => {
                      dispatch({
                        type: PROJECT_ACTIONS.Loading,
                        payload: true,
                      });
                    }}
                    to="/projects"
                  >
                    Projects{" "}
                  </CustomLink>
                  /
                  <CustomLink to={`/projects/${id}`} className="paragraph">
                    {" "}
                    {singleScanDetails?.project?.name}{" "}
                  </CustomLink>
                  / <span>Scan Details</span>
                </div>
              </div>
            ))}
        </Container>
      </div>
      <Container maxWidth="lg">
        <div className={styles.productDetailPageWrapper}>
          <div className={styles.scanListView}>
            <div className={styles.scanListViewTitleWrapper}>
              <p className={styles.scanListViewTitle + " heading6"}>
                {statusBtn === 1 ? "Scan Details" : "Reports"} {statusBtn === 2 && <span>{"6"}</span>}
              </p>
              {statusBtn === 2 && (
                <Search
                  text={search}
                  placeholder="Search reports"
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
            </div>
            {loader ? (
              <Shimmer type="scanListDetails" />
            ) : (
              <>
                <div className={styles.scanListItemWrapper + " " + styles.scanItemWrapper}>
                  <Grid container alignItems="center">
                    {roleId === 5 ? (
                      <>
                        <Grid item md={3} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>SCAN STATUS</p>
                          <p className={styles.scanIconBlk}>
                            <img
                              src={
                                singleScanDetails?.status === 0
                                  ? getImage("status_pending.svg")
                                  : singleScanDetails?.status === 1
                                  ? getImage("status_progress.svg")
                                  : singleScanDetails?.status === 2
                                  ? getImage("delete_user.svg")
                                  : singleScanDetails?.status === 3
                                  ? getImage("status_success.svg")
                                  : null
                              }
                              alt=""
                            />
                            <span
                              className={
                                singleScanDetails?.status === 0
                                  ? styles.pending
                                  : singleScanDetails?.status === 1
                                  ? styles.progress
                                  : singleScanDetails?.status === 2
                                  ? styles.discarded
                                  : singleScanDetails?.status === 3
                                  ? styles.success
                                  : ""
                              }
                            >
                              {singleScanDetails?.status === 0
                                ? "Pending"
                                : singleScanDetails?.status === 1
                                ? "In Progress"
                                : singleScanDetails?.status === 2
                                ? "Discarded"
                                : singleScanDetails?.status === 3
                                ? "Completed"
                                : ""}
                            </span>
                          </p>
                        </Grid>
                        <Grid item md={3} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>URL</p>
                          {singleScanDetails?.url ? (
                            singleScanDetails?.url.length > 20 ? (
                              <Tooltip title={singleScanDetails?.url} placement="top">
                                <Link to="#" onClick={handleOpenLink} target="_blank" className="paragraph">
                                  {singleScanDetails?.url?.substring(0, 20)}...
                                </Link>
                              </Tooltip>
                            ) : (
                              <Link to="#" onClick={handleOpenLink} target="_blank" className="paragraph">
                                {singleScanDetails?.url}
                              </Link>
                            )
                          ) : (
                            <p className="paragraph">N/A</p>
                          )}
                        </Grid>
                        <Grid item md={3} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>ENGAGEMENT TYPE</p>
                          <p className="paragraph">
                            {singleScanDetails?.engagement_type ? singleScanDetails?.engagement_type : "N/A"}
                          </p>
                        </Grid>
                        <Grid item md={3} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>METHODOLOGY</p>
                          <p className="paragraph">
                            {singleScanDetails?.methodology === 1 ? "Greybox Testing" : "Blackbox Testing"}
                          </p>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item md={2} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>SCAN STATUS</p>
                          <p className={styles.scanIconBlk}>
                            <img
                              src={
                                singleScanDetails?.status === 0
                                  ? getImage("status_pending.svg")
                                  : singleScanDetails?.status === 1
                                  ? getImage("status_progress.svg")
                                  : singleScanDetails?.status === 2
                                  ? getImage("delete_user.svg")
                                  : singleScanDetails?.status === 3
                                  ? getImage("status_success.svg")
                                  : null
                              }
                              alt=""
                            />
                            <span
                              className={
                                singleScanDetails?.status === 0
                                  ? styles.pending
                                  : singleScanDetails?.status === 1
                                  ? styles.progress
                                  : singleScanDetails?.status === 2
                                  ? styles.discarded
                                  : singleScanDetails?.status === 3
                                  ? styles.success
                                  : ""
                              }
                            >
                              {singleScanDetails?.status === 0
                                ? "Pending"
                                : singleScanDetails?.status === 1
                                ? "In Progress"
                                : singleScanDetails?.status === 2
                                ? "Discarded"
                                : singleScanDetails?.status === 3
                                ? "Completed"
                                : ""}
                            </span>
                          </p>
                        </Grid>

                        <Grid item md={1.5} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>SCAN TYPE</p>
                          <p className="paragraph">
                            {singleScanDetails?.scan_type === 1
                              ? "New Scan"
                              : singleScanDetails?.scan_type === 2
                              ? "Detailed Scan"
                              : "Rescan"}
                          </p>
                        </Grid>
                        <Grid item md={2} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>URL</p>
                          {singleScanDetails?.url ? (
                            singleScanDetails?.url.length > 20 ? (
                              <Tooltip title={singleScanDetails?.url} placement="top">
                                <Link to="#" onClick={handleOpenLink} target="_blank" className="paragraph">
                                  {singleScanDetails?.url?.substring(0, 20)}...
                                </Link>
                              </Tooltip>
                            ) : (
                              <Link to="#" onClick={handleOpenLink} target="_blank" className="paragraph">
                                {singleScanDetails?.url}
                              </Link>
                            )
                          ) : (
                            <p className="paragraph">N/A</p>
                          )}
                        </Grid>
                        <Grid item md={2} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>ASSIGNED TO</p>
                          <p className="paragraph">
                            {singleScanDetails?.owner
                              ? singleScanDetails?.owner?.last_name
                                ? singleScanDetails?.owner?.first_name?.concat(
                                    " " + singleScanDetails?.owner?.last_name
                                  )
                                : singleScanDetails?.owner?.first_name
                              : "N/A"}
                          </p>
                        </Grid>
                        <Grid item md={2} className={styles.scanStatusBlk}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>DUE DATE</p>
                          <p className="paragraph">
                            {singleScanDetails?.due_date?.split("-").reverse().join("/")}
                          </p>
                        </Grid>
                        <Grid item md={2} className={`${styles.scanStatusBlk} ${styles.scanScoreBlk}`}>
                          <p className={styles.scanTitle + " supportText_semiBold_grey"}>SECURITY SCORE</p>
                          <p className={styles.scanScore + " labelText"}>
                            <span
                              className={
                                typeof singleScanDetails?.security_score === "number"
                                  ? singleScanDetails?.security_score >= 0 &&
                                    singleScanDetails?.security_score <= 6
                                    ? styles.scoreClolorCritical
                                    : singleScanDetails?.security_score >= 6.1 &&
                                      singleScanDetails?.security_score <= 8
                                    ? styles.scoreClolorHigh
                                    : singleScanDetails?.security_score >= 8.1 &&
                                      singleScanDetails?.security_score <= 9.9
                                    ? styles.scoreClolorMedium
                                    : singleScanDetails?.security_score === 10
                                    ? styles.scoreClolorNone
                                    : ""
                                  : styles.scoreClolorNone
                              }
                            >
                              {singleScanDetails?.security_score === 0 ||
                              singleScanDetails?.security_score === null
                                ? "0.0"
                                : singleScanDetails?.security_score}
                            </span>{" "}
                            <span className={styles.scoreNames}>
                              {typeof singleScanDetails?.security_score === "number"
                                ? singleScanDetails?.security_score >= 0 &&
                                  singleScanDetails?.security_score <= 6
                                  ? "Highly Vulnerable"
                                  : singleScanDetails?.security_score >= 6.1 &&
                                    singleScanDetails?.security_score <= 8
                                  ? "Vulnerable"
                                  : singleScanDetails?.security_score >= 8.1 &&
                                    singleScanDetails?.security_score <= 9.9
                                  ? "Be Preventive"
                                  : singleScanDetails?.security_score === 10
                                  ? "Secure"
                                  : ""
                                : "Secure"}
                            </span>
                          </p>
                        </Grid>
                        <Grid item md={0.5} className={styles.scanStatusBlk}>
                          {singleScanDetails?.status !== 2 ? (
                            singleScanDetails?.status === 3 ? (
                              singleScanDetails?.permissions?.can_edit_scan ? (
                                <>
                                  <img
                                    className={styles.moreIcon}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    src={getImage("more_menu.svg")}
                                    alt=" "
                                  />
                                  <CustomMoreMenu
                                    open={open}
                                    anchorEl={anchorEl}
                                    arrow="arrow"
                                    hoverState="withHover"
                                    corner="no"
                                    handleClose={handleClose}
                                    handleClick1={handleEditScanDetails}
                                    handleLogOut={handleGenerateReport}
                                    noOfItems={2}
                                    message1="Update Scan Details"
                                    message2="Generate Report"
                                    image1="edit_gray.svg"
                                    image2="generate_report_grey.svg"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    className={styles.moreIcon}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    src={getImage("more_menu.svg")}
                                    alt=" "
                                  />

                                  <CustomMoreMenu
                                    open={open}
                                    anchorEl={anchorEl}
                                    arrow="arrow"
                                    hoverState="withHover"
                                    corner="no"
                                    handleClose={handleClose}
                                    handleClick1={handleGenerateReport}
                                    noOfItems={1}
                                    message1="Generate Report"
                                    image1="generate_report_grey.svg"
                                  />
                                </>
                              )
                            ) : (
                              singleScanDetails?.permissions?.can_edit_scan && (
                                <Link to={`/projects/${id}/update-scan/${scanId}`}>
                                  <img
                                    className={styles.moreIcon}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    src={getImage("edit_blue.svg")}
                                    alt=" "
                                  />
                                </Link>
                              )
                            )
                          ) : null}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>

                {singleScanDetails?.status === 3 && singleScanDetails?.report_file !== null && (
                    <ScanDetailChart
                    analysis={singleScanDetails?.analysis}
                    startedAt={singleScanDetails?.created_at}
                    securityScore={singleScanDetails?.security_score}
                    version={singleScanDetails?.version}
                    status={singleScanDetails?.status}
                    reportFile={singleScanDetails?.report_file}
                    reportPassword={singleScanDetails?.report_password}
                    passed_count={singleScanDetails?.passed_count}
                    />
                )}
                
                {singleScanDetails?.analysis && singleScanDetails?.analysis?.length > 0 ? (
                  <>
                    <AnalysisList
                      analysis={singleScanDetails?.analysis}
                      permissions={singleScanDetails?.permissions?.can_edit_analysis}
                      permissionsAdd={singleScanDetails?.permissions?.can_add_analysis}
                      status={singleScanDetails?.status}
                      scan_type={singleScanDetails?.scan_type}
                      handleDeleteAnalysis={handleDeleteAnalysis}
                    />
                  </>
                ) : (
                  <>
                    <div className={styles.analysisTitleWrapper}>
                      <p className={styles.scanListViewTitle + " heading6"}>
                        {roleId === 5 ? "Findings details" : "Findings"}
                        <span>0</span>
                      </p>
                    </div>
                    {singleScanDetails?.permissions?.can_add_analysis &&
                    singleScanDetails?.status !== 2 &&
                    singleScanDetails?.status !== 3 &&
                    "ADD_VULNERABILITY" ? (
                      <NoResult
                        image={getImage("no_file_found.svg")}
                        title="No Findings Added"
                        btnText="Add Findings"
                        onClick={handleAddFindings}
                      />
                    ) : (
                      <NoResult image={getImage("no_file_found.svg")} title="No Findings Added" />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
export default ScanAnalysisDetails;
