import { FC } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from "../../../assets/styles/home.module.scss";
import { Skeleton } from "@mui/material";
import { IShimmerProps } from "../../../types";
const Shimmer: FC<IShimmerProps> = ({ type }) => {
  return (
    <>
      {type === "notificationList" && (
        <>
          <div className="drawerMessage">
            <Skeleton
              variant="text"
              width={150}
              height={25}
              style={{
                display: "block",
                marginTop: "8px",
              }}
            />
            <Skeleton
              variant="text"
              width={150}
              height={25}
              style={{
                display: "block",
                marginTop: "8px",
              }}
            />
          </div>
          <div className="drawerBody">
            {[...Array(8)].map((item, index) => {
              return (
                <div
                  className={`outerBlk ${item?.status === 0 ? " unreadBackground" : " readBackground"}`}
                  key={index}
                >
                  <div className="notificationListItem">
                    <div className="drawerContent">
                      <div className="drawerContentLeft">
                        <Skeleton
                          variant="text"
                          width={50}
                          height={70}
                          style={{
                            display: "block",
                            borderRadius: "50%",
                          }}
                        />
                        <div className="contentMiddle">
                          <Skeleton
                            variant="text"
                            width={150}
                            height={25}
                            style={{
                              display: "block",
                              marginTop: "8px",
                            }}
                          />
                          <Skeleton
                            variant="text"
                            width={150}
                            height={25}
                            style={{
                              display: "block",
                              marginTop: "8px",
                            }}
                          />
                        </div>
                      </div>
                      <Skeleton
                        variant="text"
                        width={15}
                        height={25}
                        style={{
                          display: "block",
                          marginTop: "8px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default Shimmer;
