import { FC, useState, useContext, MouseEvent, Fragment } from "react";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog, CustomMoreMenu } from "../../../shared_ui_components";
import { toastFlashMessage, getImage } from "../../../utils";
import { format, parseISO } from "date-fns";
import { TCwe, TGdpr } from "../../../types";
import { deleteSingleVulnerability } from "../apiServices";
import { GlobalContext } from "../../../context/GlobalContext";
import { VULNERABILITY_ACTIONS } from "../../../context/VulnerabilityReducer";
import { AddVulnerabilityDialog } from ".";

type IProps = {
  id?: number;
  name?: string;
  description?: string;
  cwe?: any;
  gdpr?: any;
  instances_count?: number;
  last_instance?: {
    project_name?: string;
    detected_on?: any;
  };
  vulCategoryId?: number;
  vulCategoryName?: string;
};

const VulnerabilityDetails: FC<IProps> = ({
  id,
  name,
  description,
  cwe,
  gdpr,
  instances_count,
  last_instance,
  vulCategoryId,
  vulCategoryName,
}) => {
  const navigate = useNavigate();
  const {
    state: { roleLists },
    dispatch,
  } = useContext(GlobalContext);
  const rolePermission = roleLists?.roleData?.permission;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openVulnerabilityDetail, setOpenVulnerabilityDetail] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleUpdateVulnerability = () => {
    navigate(`/vulnerabilities/${id}/update`);
  };

  const handleViewVulOccurences = () => {
    navigate(`/vulnerabilities/${vulCategoryId}/occurences/${id}`);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
    setOpenVulnerabilityDetail(false);
    setAnchorEl(null);
  };

  const handleDeleteVulnerabilityDialog = () => {
    deleteSingleVulnerability(`${id}`).then((response: Partial<{ statusCode: number; message: string }>) => {
      if (response.statusCode === 200) {
        dispatch({
          type: VULNERABILITY_ACTIONS.Delete,
          payload: { categoryId: vulCategoryId, vulnerabilityId: id },
        });
        toastFlashMessage(response.message as string, "success");
        setOpenDeleteDialog(false);
      } else {
        toastFlashMessage(response.message as string, "error");
      }
    });
  };

  const handleCloseVulnerabilityDialog = () => {
    setOpenVulnerabilityDetail(false);
  };

  const handleHideDeleteVulnerability = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenVulnerabilityDetail(false);
    setOpenDeleteDialog(!openDeleteDialog);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <div className={styles.listItemWrapper} onClick={() => setOpenVulnerabilityDetail(true)}>
        <Grid container alignItems="center">
          <Grid item md={4} sx={{ paddingLeft: "6px" }}>
            <p className="paragraph_semiBold">{name}</p>
          </Grid>
          <Grid item md={3}>
            <p className="paragraph">{last_instance?.project_name ? last_instance?.project_name : "N/A"}</p>
          </Grid>
          <Grid item md={2}>
            <p className="paragraph">
              {last_instance?.detected_on
                ? format(parseISO(last_instance?.detected_on), "dd/MM/yyyy hh:mm a")
                : "N/A"}
            </p>
          </Grid>
          <Grid item md={2}>
            <p className="paragraph">{instances_count}</p>
          </Grid>
          <Grid item md={1}>
            {rolePermission?.includes("EDIT_VULNERABILITY") ? (
              <>
                <img
                  className={styles.moreIcon}
                  aria-controls="simple-menu"
                  onClick={handleClick}
                  src={getImage("more_menu.svg")}
                  alt=" "
                />

                {instances_count !== 0 ? (
                  <CustomMoreMenu
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleLogOut={handleOpenDeleteDialog}
                    handleClick1={handleUpdateVulnerability}
                    handleClick2={handleViewVulOccurences}
                    noOfItems={3}
                    message1="Update vulnerability"
                    message2="View Occurences"
                    message3="Delete vulnerability"
                    image1="edit_gray.svg"
                    image2="eye_open_gray.svg"
                    image3="delete_red.svg"
                  />
                ) : (
                  <CustomMoreMenu
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleLogOut={handleHideDeleteVulnerability}
                    handleClick1={handleUpdateVulnerability}
                    noOfItems={2}
                    message1="Update vulnerability"
                    message2="Delete vulnerability"
                    image1="edit_gray.svg"
                    image2="delete_red.svg"
                  />
                )}
              </>
            ) : (
              instances_count !== 0 && (
                <>
                  <img
                    className={styles.moreIcon}
                    aria-controls="simple-menu"
                    onClick={handleClick}
                    src={getImage("more_menu.svg")}
                    alt=" "
                  />
                  <CustomMoreMenu
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleClick1={handleViewVulOccurences}
                    noOfItems={1}
                    message1="View Occurences"
                    image1="eye_open_gray.svg"
                  />
                </>
              )
            )}
          </Grid>
        </Grid>
      </div>
      {openDeleteDialog && (
        <ConfirmationDialog
          text="Delete vulnerability"
          subText="Are you sure you want to Delete this vulnerability?"
          image="delete_icon.svg"
          cancelBtnText="Cancel"
          successBtnText="Delete"
          cancelBtnClass="outlinedBtn"
          successBtnClass="secondaryBtn"
          handleSuccess={handleDeleteVulnerabilityDialog}
          handleCancel={handleOpenDeleteDialog}
        />
      )}

      {openVulnerabilityDetail && (
        <AddVulnerabilityDialog
          type="show-detail"
          open={openVulnerabilityDetail}
          name={name}
          description={description}
          cwe={cwe}
          gdpr={gdpr}
          categoryId={vulCategoryId}
          categoryName={vulCategoryName}
          onClose={handleCloseVulnerabilityDialog}
        />
      )}
    </Fragment>
  );
};

export default VulnerabilityDetails;
