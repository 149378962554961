import { FC, useState, useMemo, useRef, SyntheticEvent } from "react";
import { Tooltip } from "@mui/material";
import styles from "../../../assets/styles/auth.module.scss";
import { CustomButton, CustomInput } from "../../../shared_ui_components";
import { PasswordValidator } from "../../../shared_elements";
import { errorCodes } from "../../../constants";
import { useNavigate, useLocation } from "react-router-dom";
import { fieldValidation } from "../../../utils/formValidations";
import {
  _Length,
  _LowerCase,
  _UpperCase,
  _Number,
  _SpecialCharacter,
  toastFlashMessage,
  getImage,
} from "../../../utils";
import { Errors, TResetPasswordType } from "../../../types";
import { resetPasswordApi } from "../apiServices";

const SetPasswordForm: FC = () => {
  const navigate = useNavigate();
  const newPasswordRef = useRef<any>(null);
  const confirmPasswordRef = useRef<any>(null);
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");
  const email = new URLSearchParams(search).get("email")?.replace(" ", "+");

  const [formData, setFormData] = useState<TResetPasswordType>({
    email: email ? email : "",
    password: "",
    confirm_password: "",
    token: token ? token : "",
  });
  const [showPasswordHint, setPasswordHint] = useState({
    password: false,
    confirm_password: false,
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm_password: false,
  });
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);

  const handleChange = (key: string, val: string) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const passwordValidation = useMemo(() => {
    return {
      password: {
        minCharacter: _Length(formData.password),
        oneNumber: _Number(formData.password),
        oneUpperCase: _UpperCase(formData.password),
        oneLowerCase: _LowerCase(formData.password),
        oneSpecialCharacter: _SpecialCharacter(formData.password),
      },
      confirm_password: {
        minCharacter: _Length(formData.confirm_password),
        oneNumber: _Number(formData.confirm_password),
        oneUpperCase: _UpperCase(formData.confirm_password),
        oneLowerCase: _LowerCase(formData.confirm_password),
        oneSpecialCharacter: _SpecialCharacter(formData.confirm_password),
      },
    };
  }, [formData]);

  const handlePasswordHint = (key: string, value: boolean) => {
    setPasswordHint({
      ...showPasswordHint,
      [key]: value,
    });
  };

  const handleShowPassword = (key: string, val: boolean) => {
    setShowPassword({
      ...showPassword,
      [key]: !val,
    });
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateNewInput: {
      [key: string]: string;
    } = {
      password:
        errorCode["newPassword"][
          fieldValidation({
            ...errorCode["newPasswordObj"],
            fieldval: formData.password,
          })
        ],
      confirm_password:
        errorCode["newPassword"][
          fieldValidation({
            ...errorCode["newPasswordObj"],
            fieldval: formData.confirm_password,
          })
        ],
    };

    if (formData.password !== formData.confirm_password) {
      validateNewInput.confirm_password = "Your password do not match, Please verify and try again";
    }
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      resetPasswordApi(formData).then(
        (response: Partial<{ statusCode: number; data: any; message: string }>) => {
          if (response.statusCode === 200) {
            toastFlashMessage(response.message as string, "success");
            navigate("/projects");
            setLoader(false);
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoader(false);
          }
        }
      );
    } else {
      setError(validateNewInput);
      setLoader(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CustomInput
        className={`paragraph ${email ? "disabledInputGroup" : null}`}
        label="Email Addresses"
        placeholder="abc@xyz.com"
        onChange={(e) => handleChange("email", e.target.value)}
        value={email ? email : formData.email}
      />
      <div className={styles.passwordWrapper}>
        <Tooltip
          title={
            showPasswordHint.password && (
              <PasswordValidator passwordValidation={passwordValidation.password} />
            )
          }
          arrow
          placement="right-start"
        >
          <CustomInput
            className="paragraph"
            label="Enter new password *"
            placeholder="**********"
            type={showPassword.password ? "text" : "password"}
            inputRef={newPasswordRef}
            onChange={(e) => handleChange("password", e.target.value)}
            error={error.password}
            value={formData.password}
            onFocus={() => {
              handlePasswordHint("password", true);
            }}
            onBlur={() => {
              handlePasswordHint("password", false);
            }}
          />
        </Tooltip>
        <span
          className={styles.showPassword}
          onClick={() => {
            handleShowPassword("password", showPassword.password);
            newPasswordRef?.current?.focus();
          }}
        >
          {showPassword.password ? (
            <img src={getImage("eye-open.svg")} alt="" />
          ) : (
            <img src={getImage("eye-close.svg")} alt="" />
          )}
        </span>
      </div>
      <div className={styles.passwordWrapper}>
        <CustomInput
          className="paragraph"
          label="Re-enter new password *"
          placeholder="**********"
          type={showPassword.confirm_password ? "text" : "password"}
          inputRef={confirmPasswordRef}
          onChange={(e) => handleChange("confirm_password", e.target.value)}
          error={error.confirm_password}
          value={formData.confirm_password}
          onFocus={() => {
            handlePasswordHint("confirm_password", true);
          }}
          onBlur={() => {
            handlePasswordHint("confirm_password", false);
          }}
        />
        <span
          className={styles.showPassword}
          onClick={() => {
            handleShowPassword("confirm_password", showPassword.confirm_password);
            confirmPasswordRef?.current?.focus();
          }}
        >
          {showPassword.confirm_password ? (
            <img src={getImage("eye-open.svg")} alt="" />
          ) : (
            <img src={getImage("eye-close.svg")} alt="" />
          )}
        </span>
      </div>
      <CustomButton
        className={styles.resetSigninButton + `${loader ? " primaryBtn disabledBtn" : " primaryBtn"}`}
        children={loader ? "Updating..." : "Update"}
        type="submit"
      />
    </form>
  );
};
export default SetPasswordForm;
