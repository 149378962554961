import { TValidationProps } from "../utils/formValidations";

type Regex = {
  [key: string]: RegExp;
};

type ErrorCodeString = {
  [key: number]: string;
};

type ErrorCodeType = {
  password: ErrorCodeString;
  email: ErrorCodeString;
  newPassword: ErrorCodeString;
  name: ErrorCodeString;
  users: ErrorCodeString;
  _users: ErrorCodeString;
  first_name: ErrorCodeString;
  last_name: ErrorCodeString;
  designation: ErrorCodeString;
  projectName: ErrorCodeString;
  scan_type: ErrorCodeString;
  url: ErrorCodeString;
  researcher: ErrorCodeString;
  description: ErrorCodeString;
  user_name: ErrorCodeString;
  role: ErrorCodeString;
  engagement_type: ErrorCodeString;
  methodology: ErrorCodeString;
  due_date: ErrorCodeString;
  requested_date: ErrorCodeString;
  category_name: ErrorCodeString;
  title: ErrorCodeString;
  cwe: ErrorCodeString;
  gdpr: ErrorCodeString;
  vulnerability: ErrorCodeString;
  owasp: ErrorCodeString;
  risk_status: ErrorCodeString;
  rescan: ErrorCodeString;
  reason: ErrorCodeString;

  passwordObj: TValidationProps;
  emailObj: TValidationProps;
  newPasswordObj: TValidationProps;
  nameObj: TValidationProps;
  usersObj: TValidationProps;
  _usersObj: TValidationProps;
  first_name_Obj: TValidationProps;
  last_name_Obj: TValidationProps;
  designationObj: TValidationProps;
  projectNameObj: TValidationProps;
  scan_type_Obj: TValidationProps;
  urlObj: TValidationProps;
  researcherObj: TValidationProps;
  descriptionObj: TValidationProps;
  user_name_Obj: TValidationProps;
  roleObj: TValidationProps;
  engagement_type_Obj: TValidationProps;
  methodologyObj: TValidationProps;
  due_date_Obj: TValidationProps;
  requested_date_Obj: TValidationProps;
  category_name_Obj: TValidationProps;
  title_Obj: TValidationProps;
  cwe_Obj: TValidationProps;
  gdpr_Obj: TValidationProps;
  vulnerability_Obj: TValidationProps;
  owasp_Obj: TValidationProps;
  risk_status_Obj: TValidationProps;
  rescan_Obj: TValidationProps;
  reason_Obj: TValidationProps;
};

export const regExpression: Regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~]*)*(#[\w-]*)?(\?.*)?$/,
  password: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
};

export const errorCodes: ErrorCodeType = {
  password: {
    0: "",
    1: "Please enter password",
  },
  passwordObj: {
    requiredFlag: true,
  },

  newPassword: {
    0: "",
    1: "Please enter password",
    4: "Please enter valid password format",
  },
  newPasswordObj: {
    requiredFlag: true,
    regexPattern: regExpression.password,
  },

  email: {
    0: "",
    1: "Please enter email address",
    4: "Please enter valid email address",
  },
  emailObj: {
    requiredFlag: true,
    regexPattern: regExpression.email,
  },
  name: {
    0: "",
    1: "Please enter client name",
  },
  nameObj: {
    requiredFlag: true,
  },
  users: {
    0: "",
    1: "Please enter user email address",
    4: "Please enter valid email address",
  },
  usersObj: {
    requiredFlag: true,
    regexPattern: regExpression.email,
  },
  _users: {
    0: "",
    1: "Please enter user email address",
  },
  _usersObj: {
    requiredFlag: true,
  },
  first_name: {
    0: "",
    1: "Please enter first name",
  },
  first_name_Obj: {
    requiredFlag: true,
  },
  last_name: {
    0: "",
    1: "Please enter last name",
  },
  last_name_Obj: {
    requiredFlag: true,
  },
  designation: {
    0: "",
    1: "Please enter designation",
  },
  designationObj: {
    requiredFlag: true,
  },
  projectName: {
    0: "",
    1: "please enter project name",
  },
  projectNameObj: {
    requiredFlag: true,
  },
  url: {
    0: "",
    1: "Please enter url",
    4: "Please enter valid url",
  },
  urlObj: {
    requiredFlag: true,
    regexPattern: regExpression.url,
  },
  researcher: {
    0: "",
    1: "Please select researcher",
  },
  researcherObj: {
    requiredFlag: true,
  },
  scan_type: {
    0: "",
    1: "Please select scan type",
  },
  scan_type_Obj: {
    requiredFlag: true,
  },
  description: {
    0: "",
    1: "Please enter description",
  },
  descriptionObj: {
    requiredFlag: true,
  },
  user_name: {
    0: "",
    1: "Please enter username",
  },
  user_name_Obj: {
    requiredFlag: true,
  },
  role: {
    0: "",
    1: "Please select role",
  },
  roleObj: {
    requiredFlag: true,
  },
  engagement_type: {
    0: "",
    1: "Please enter type of engagement",
  },
  engagement_type_Obj: {
    requiredFlag: true,
  },
  methodology: {
    0: "",
    1: "Please select methodology type",
  },
  methodologyObj: {
    requiredFlag: true,
  },
  due_date: {
    0: "",
    1: "Please select due date",
  },
  due_date_Obj: {
    requiredFlag: true,
  },
  requested_date: {
    0: "",
    1: "Please select request date",
  },
  requested_date_Obj: {
    requiredFlag: true,
  },
  category_name: {
    0: "",
    1: "Please enter category name",
  },
  category_name_Obj: {
    requiredFlag: true,
  },
  title: {
    0: "",
    1: "Please enter title",
  },
  title_Obj: {
    requiredFlag: true,
  },
  cwe: {
    0: "",
    1: "Please select cwe",
  },
  cwe_Obj: {
    requiredFlag: true,
  },
  gdpr: {
    0: "",
    1: "Please select gdpr",
  },
  gdpr_Obj: {
    requiredFlag: true,
  },
  vulnerability: {
    0: "",
    1: "Please select vulnerability",
  },
  vulnerability_Obj: {
    requiredFlag: true,
  },
  owasp: {
    0: "",
    1: "Please select owasp",
  },
  owasp_Obj: {
    requiredFlag: true,
  },
  risk_status: {
    0: "",
    1: "Please select risk status",
  },
  risk_status_Obj: {
    requiredFlag: true,
  },
  rescan: {
    0: "",
    1: "Please select rescan",
  },
  rescan_Obj: {
    requiredFlag: true,
  },
  reason: {
    0: "",
    1: "Please enter reason",
  },
  reason_Obj: {
    requiredFlag: true,
  },
};
