/* eslint-disable react-hooks/exhaustive-deps */
import { FC, SyntheticEvent, useState, useEffect, useMemo, useContext } from "react";
import { Grid, MenuItem, ToggleButtonGroup, ToggleButton, Stack } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  CustomButton,
  SelectDropdown,
  CustomInput,
  CustomMultipleSelectDropdown,
  CustomSelect,
  CustomEditor,
} from "../../../shared_ui_components";
import styles from "../../../assets/styles/home.module.scss";
import { Shimmer } from ".";
import { errorCodes } from "../../../constants";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getCsvvMetricsList, getOwaspsList, addAnalysis } from "../apiServices";
import { toastFlashMessage, riskStatus, listingAllVulnerabilityCategory } from "../../../utils";
import { Errors, TAutoComplete, TOwasp, TVulnaerbilityLists, TGdpr, TCwe } from "../../../types";
import { fieldValidation } from "../../../utils/formValidations";
import { getAllVulnerability, getCWEsList, getGdprList } from "../../vulnerabilities/apiServices";
import { GlobalContext } from "../../../context/GlobalContext";

type csvvMetrics = {
  id: number;
  name: string;
  options: {
    id: number;
    name: string;
    score: number;
    modify_score: number;
    selected: boolean;
  }[];
};

type IState = {
  title: string;
  vulnerability?: string;
  status: string;
  risk_status?: string | number;
  owasp?: any;
  type?: string;
  cwe?: any;
  gdpr?: any;
  reason: string;
};

export type TAddAnalysis = {
  name: string;
  vulnerability: any;
  risk_status: number | null;
  owasp: any;
  metrics: {
    id: number;
    option_id: number | undefined;
  }[];
  cwe: any;
  gdpr: any;
  score: number;
  description: string;
  description_html: string;
  proof_of_concept: string;
  proof_of_concept_html: string;
  remidation: string;
  remidation_html: string;
  risk_reason: string;
};

const AddVulnerabilityForm: FC = () => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);

  const { id, scanId } = useParams();
  const roleId = roleLists?.roleData?.id;
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState<IState>({
    type: "",
    vulnerability: "",
    title: "",
    status: "",
    risk_status: "",
    owasp: [],
    cwe: [],
    gdpr: [],
    reason: "",
  });
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);

  const [owsapOptions, setOwsapOtions] = useState([]);
  const [vulnerabilityOptions, setVulnerabilityOption] = useState([]);
  const [cweOptions, setCweOptions] = useState([]);
  const [gdprOptions, setGdprOptions] = useState([]);
  const [vulnerabilityLists, setVulnerabilityLists] = useState<TVulnaerbilityLists[] | []>([]);

  const [descriptionVal, setDescriptionVal] = useState("");
  const [des, setDes] = useState("");
  const [pocVal, setPocVal] = useState("");
  const [poc, setPoc] = useState("");
  const [remediationVal, setRemediationVal] = useState("");
  const [remediation, setRemediation] = useState("");
  const [isOverrideRisk, setIsOverrideRisk] = useState(false);

  const [loading, setLoading] = useState(true);

  /* CVSS Calculation Part */
  const [metricsList, setMetricsList] = useState<csvvMetrics[]>([]);
  const [attackComplexity, setAttackComplexity] = useState<number>(0);
  const [privilegesRequired, setPrivilegesRequired] = useState<number>(0);
  const [userInteraction, setUserInteraction] = useState<number>(0);
  const [vectorAttack, setVectorAttack] = useState<number>(0);
  const [confidentiality, setConfidentiality] = useState<number>(0);
  const [integrity, setIntegrity] = useState<number>(0);
  const [availability, setAvailability] = useState<number>(0);
  const [scope, setScope] = useState<number>(0);
  const [modifiedPrivilegesLow, setModifiedPrivilegesLow] = useState<number>(0);
  const [modifiedPrivilegesHigh, setModifiedPrivilegesHigh] = useState<number>(0);

  const cvssMetricsCalcuation = () => {
    const ESC = handleESC();
    const ISC = handleISC(1 - (1 - confidentiality) * (1 - integrity) * (1 - availability));

    if (ISC <= 0) {
      return 0;
    } else {
      if (scope === 6.42) {
        return roundUp(Math.min(ISC + ESC, 10));
      } else {
        return roundUp(Math.min(1.08 * (ISC + ESC), 10));
      }
    }
  };

  const handleESC = () => {
    if (scope !== 6.42 && privilegesRequired === 0.62) {
      return 8.22 * vectorAttack * attackComplexity * modifiedPrivilegesLow * userInteraction;
    } else if (scope !== 6.42 && privilegesRequired === 0.27) {
      return 8.22 * vectorAttack * attackComplexity * modifiedPrivilegesHigh * userInteraction;
    } else {
      return 8.22 * vectorAttack * attackComplexity * privilegesRequired * userInteraction;
    }
  };

  const handleISC = (ISCBase: number) => {
    if (scope === 6.42) {
      return 6.42 * ISCBase;
    } else {
      return 7.52 * (ISCBase - 0.029) - 3.25 * Math.pow(ISCBase - 0.02, 15);
    }
  };

  const roundUp = (val: number) => {
    return Math.ceil(val * 10) / 10;
  };
  /* Calculation ends here */
  const handleChange = (key: string, val: any) => {
    if (key === "vulnerability") {
      const _cwe = vulnerabilityLists?.find((item) => item.id === val?.value)?.cwe;
      const _gdpr = vulnerabilityLists?.find((item) => item.id === val?.value)?.gdpr;
      const _owasp = vulnerabilityLists?.find((item) => item.id === val?.value)?.category?.owasp;
      setFormData((form) => ({
        ...form,
        vulnerability: val,
        owasp: _owasp ? _owasp?.map((item: { name: string, title: string }) => item.name + ' - ' + item.title) : [],
        cwe: _cwe ? _cwe?.map((item: { name: string }) => item.name) : [],
        gdpr: _gdpr ? _gdpr?.map((item: { name: string }) => item.name) : [],
      }));
    } else {
      setFormData({
        ...formData,
        [key]: val,
      });
    }
    setError({
      ...error,
      [key]: "",
    });
  };

  useEffect(() => {
    if (formData?.risk_status !== "") {
      setIsOverrideRisk(true);
      setError({
        ...error,
        reason: "",
      });
    } else {
      setIsOverrideRisk(false);
      setError({
        ...error,
        reason: "",
      });
    }
  }, [formData.risk_status]);

  const handleCsvvApi = () => {
    if (roleId === 5) {
      navigate(`/projects/${id}`);
    } else {
      getCsvvMetricsList().then((response) => {
        if (response.statusCode === 200) {
          setMetricsList(response.data as csvvMetrics[]);
          setVectorAttack(response.data?.find((detail: csvvMetrics) => detail.id === 1)?.options[0].score);
          setAttackComplexity(
            response.data?.find((detail: csvvMetrics) => detail.id === 2)?.options[0].score
          );
          setPrivilegesRequired(
            response.data?.find((detail: csvvMetrics) => detail.id === 3)?.options[0].score
          );
          setUserInteraction(response.data?.find((detail: csvvMetrics) => detail.id === 4)?.options[0].score);
          setScope(response.data?.find((detail: csvvMetrics) => detail.id === 5)?.options[0].score);
          setConfidentiality(response.data?.find((detail: csvvMetrics) => detail.id === 6)?.options[0].score);
          setIntegrity(response.data?.find((detail: csvvMetrics) => detail.id === 7)?.options[0].score);
          setAvailability(response.data?.find((detail: csvvMetrics) => detail.id === 8)?.options[0].score);
          setModifiedPrivilegesLow(
            response.data?.find((detail: csvvMetrics) => detail.id === 3)?.options[1].modify_score
          );
          setModifiedPrivilegesHigh(
            response.data?.find((detail: csvvMetrics) => detail.id === 3)?.options[2].modify_score
          );
          setLoading(false);
        } else {
          toastFlashMessage(response.message, "error");
          setLoading(false);
          navigate(`/projects/${id}`);
        }
      });
    }
  };

  useEffect(() => {
    handleCsvvApi();
    getOwaspsList().then((response) => {
      if (response.statusCode === 200) {
        const data = response.data?.map((ele: { id: number; name: string; title: string }) => {
          return { id: ele?.id, name: ele.title ? ele?.name + " - " + ele.title : ele?.name };
        });
        setOwsapOtions(data || []);
      }
    });
    getAllVulnerability().then((response) => {
      if (response.statusCode === 200) {
        setVulnerabilityLists(response.data as TVulnaerbilityLists[]);
        const data = response?.data?.map((item: { name: string; id: number }) => {
          return { label: item?.name, value: item?.id };
        });

        setVulnerabilityOption(data);
      }
    });

    getCWEsList().then((response) => {
      if (response.statusCode === 200) {
        const data = response?.data?.map((item: { name: string; id: number; description: string }) => {
          return { id: item?.id, name: item?.name, description: item?.description };
        });

        setCweOptions(data || []);
      }
    });
    getGdprList().then((response) => {
      if (response.statusCode === 200) {
        const data = response?.data?.map((item: { name: string; id: number; description: string }) => {
          return { id: item?.id, name: item?.name, description: item?.description};
        });

        setGdprOptions(data || []);
      }
    });
  }, []);

  const owaspId = useMemo(() => {
    const data = owsapOptions
      ?.filter((user: TOwasp) => formData.owasp?.includes(user?.name))
      .map((ele: TOwasp) => ele.id);
    return data;
  }, [formData.owasp]);

  const cweId = useMemo(() => {
    const data = cweOptions
      ?.filter((user: TCwe) => formData.cwe?.includes(user?.name))
      .map((ele: TCwe) => ele.id);
    return data;
  }, [formData.cwe]);

  const gdprId = useMemo(() => {
    const data = gdprOptions
      ?.filter((user: TGdpr) => formData.gdpr?.includes(user?.name))
      .map((ele: TGdpr) => ele.id);
    return data;
  }, [formData.gdpr]);

  const handleEditorDescription = (val: any, html: any) => {
    setDescriptionVal(val);
    setDes(html);
  };

  const handleEditorPoc = (val: any, html: any) => {
    setPocVal(val);
    setPoc(html);
  };

  const handleEditorRemediation = (val: any, html: any) => {
    setRemediationVal(val);
    setRemediation(html);
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validateNewInput: {
      [key: string]: string;
    };
    validateNewInput = {
      title:
        errorCode["title"][
          fieldValidation({
            ...errorCode["title_Obj"],
            fieldval: formData.title,
          })
        ],
      vulnerability:
        errorCode["vulnerability"][
          fieldValidation({
            ...errorCode["vulnerability_Obj"],
            fieldval: formData.vulnerability,
          })
        ],
      owasp:
        errorCode["owasp"][
          fieldValidation({
            ...errorCode["owasp_Obj"],
            fieldval: formData.owasp,
          })
        ],

      cwe: errorCode["cwe"][
        fieldValidation({
          ...errorCode["cwe"],
          fieldval: formData.cwe,
        })
      ],

      reason:
        errorCode["reason"][
          fieldValidation({
            ...errorCode["reason_Obj"],
            fieldval: isOverrideRisk ? formData.reason : "false",
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      const form_data: TAddAnalysis = {
        name: formData.title,
        vulnerability: formData?.vulnerability && (formData?.vulnerability as any)["value"],
        risk_status:
          typeof formData?.risk_status === "string" ? null : (Number(formData?.risk_status) as number),
        risk_reason: isOverrideRisk ? formData.reason : "",
        owasp: owaspId,
        cwe: cweId,
        gdpr: gdprId,
        metrics: [
          {
            id: 1,
            option_id: metricsList
              .find((ele) => ele?.id === 1)
              ?.options?.find((_details) => _details.score === vectorAttack)?.id,
          },
          {
            id: 2,
            option_id: metricsList
              .find((ele) => ele?.id === 2)
              ?.options?.find((_details) => _details.score === attackComplexity)?.id,
          },
          {
            id: 3,
            option_id: metricsList
              .find((ele) => ele?.id === 3)
              ?.options?.find((_details) => _details.score === privilegesRequired)?.id,
          },
          {
            id: 4,
            option_id: metricsList
              .find((ele) => ele?.id === 4)
              ?.options?.find((_details) => _details.score === userInteraction)?.id,
          },
          {
            id: 5,
            option_id: metricsList
              .find((ele) => ele?.id === 5)
              ?.options?.find((_details) => _details.score === scope)?.id,
          },
          {
            id: 6,
            option_id: metricsList
              .find((ele) => ele?.id === 6)
              ?.options?.find((_details) => _details.score === confidentiality)?.id,
          },
          {
            id: 7,
            option_id: metricsList
              .find((ele) => ele?.id === 7)
              ?.options?.find((_details) => _details.score === integrity)?.id,
          },
          {
            id: 8,
            option_id: metricsList
              .find((ele) => ele?.id === 8)
              ?.options?.find((_details) => _details.score === availability)?.id,
          },
        ],
        score: cvssMetricsCalcuation(),
        description: descriptionVal,
        description_html: des,
        proof_of_concept: pocVal,
        proof_of_concept_html: poc,
        remidation: remediationVal,
        remidation_html: remediation,
      };

      addAnalysis(form_data, scanId as string).then(
        (response: Partial<{ statusCode: number; data: TAddAnalysis; message: string }>) => {
          if (response.statusCode === 200) {
            setLoader(false);
            toastFlashMessage(response.message as string, "success");
            listingAllVulnerabilityCategory();
            if (location.pathname.includes("vulnerabilities")) {
              navigate(`/vulnerabilities/${id}/scan-details/${scanId}`);
            } else {
              navigate(`/projects/${id}/scan-details/${scanId}`);
            }
          } else {
            setLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {loading ? (
          <Shimmer type="analysisList" />
        ) : (
          <div className={styles.formDetail}>
            <div className={styles.titleWrapper}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <CustomInput
                    label="Title *"
                    className={styles.inputBlk}
                    placeholder="Enter Title"
                    noMargin="noMargin"
                    value={formData.title}
                    onChange={(e) => handleChange("title", e.target.value as string)}
                    error={error.title}
                  />
                </Grid>
                <Grid item md={6}>
                  <p
                    className={
                      error.vulnerability ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                    }
                  >
                    Select Vulnerability *
                  </p>
                  <CustomSelect
                    placeholder="Select Vulnerability"
                    className={styles.inputBlk}
                    options={vulnerabilityOptions as TAutoComplete[]}
                    value={formData?.vulnerability as TAutoComplete}
                    onChange={(event: SyntheticEvent, newValue: string | TAutoComplete | null) => {
                      handleChange("vulnerability", newValue as string | TAutoComplete);
                    }}
                    noMargin="noMargin"
                    error={error.vulnerability}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={styles.metricsScore}>
              <div className={styles.metricsHeader}>
                <Stack sx={{ width: "220px" }}>
                  <p className="heading6">CVSS Metrics</p>
                  <p className={styles.headerScore + " paragraph"}>
                    <span
                      className={
                        cvssMetricsCalcuation() >= 0.1 && cvssMetricsCalcuation() <= 3.9
                          ? styles.statusLow
                          : cvssMetricsCalcuation() >= 4 && cvssMetricsCalcuation() <= 6.9
                          ? styles.statusMedium
                          : cvssMetricsCalcuation() >= 7 && cvssMetricsCalcuation() <= 8.9
                          ? styles.statusHigh
                          : cvssMetricsCalcuation() >= 9 && cvssMetricsCalcuation() <= 10
                          ? styles.statusCritical
                          : styles.statusNone
                      }
                    >
                      {cvssMetricsCalcuation()}/10
                    </span>{" "}
                    {cvssMetricsCalcuation() >= 0.1 && cvssMetricsCalcuation() <= 3.9
                      ? "Low"
                      : cvssMetricsCalcuation() >= 4 && cvssMetricsCalcuation() <= 6.9
                      ? "Medium"
                      : cvssMetricsCalcuation() >= 7 && cvssMetricsCalcuation() <= 8.9
                      ? "High"
                      : cvssMetricsCalcuation() >= 9 && cvssMetricsCalcuation() <= 10
                      ? "Cricital"
                      : "None"}
                  </p>
                </Stack>
                <CustomButton className="primaryBtn" type="button" onClick={() => handleCsvvApi()}>
                  Set to untested
                </CustomButton>
              </div>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Stack>
                    <span className={styles.metricsTitle + " supportText_semiBold"}>Attack Vector (AV)</span>
                    <ToggleButtonGroup
                      value={vectorAttack}
                      exclusive
                      onChange={(event, value) => {
                        if (value != null) {
                          setVectorAttack(value);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      {metricsList
                        .find((ele) => ele?.id === 1)
                        ?.options?.map((_attackDetails, idx) => {
                          return (
                            <ToggleButton value={_attackDetails.score} aria-label="centered" key={idx}>
                              {_attackDetails.name}
                            </ToggleButton>
                          );
                        })}
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
                <Grid item md={5}>
                  <Stack>
                    <span className={styles.metricsTitle + " supportText_semiBold"}>Scope (S)</span>
                    <ToggleButtonGroup
                      value={scope}
                      exclusive
                      onChange={(event, value) => {
                        if (value != null) {
                          setScope(value);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      {metricsList
                        .find((ele) => ele?.id === 5)
                        ?.options?.map((_details, idx) => {
                          return (
                            <ToggleButton value={_details.score} aria-label="left aligned" key={idx}>
                              {_details.name}
                            </ToggleButton>
                          );
                        })}
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack>
                    <span className={styles.metricsTitle + " supportText_semiBold"}>
                      Attack Complexity (AC)
                    </span>
                    <ToggleButtonGroup
                      value={attackComplexity}
                      exclusive
                      onChange={(event, value) => {
                        if (value != null) {
                          setAttackComplexity(value);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      {metricsList
                        .find((ele) => ele?.id === 2)
                        ?.options?.map((_details, idx) => {
                          return (
                            <ToggleButton value={_details.score} aria-label="left aligned" key={idx}>
                              {_details.name}
                            </ToggleButton>
                          );
                        })}
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
                <Grid item md={5}>
                  <Stack>
                    <span className={styles.metricsTitle + " supportText_semiBold"}>Confidentiality (C)</span>
                    <ToggleButtonGroup
                      value={confidentiality}
                      exclusive={true}
                      onChange={(event, value) => {
                        if (value != null) {
                          setConfidentiality(value);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      {metricsList
                        .find((ele) => ele?.id === 6)
                        ?.options?.map((_details, idx) => {
                          return (
                            <ToggleButton value={_details.score} aria-label="left aligned" key={idx}>
                              {_details.name}
                            </ToggleButton>
                          );
                        })}
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack>
                    <span className={styles.metricsTitle + " supportText_semiBold"}>
                      Privileges Required (PR)
                    </span>
                    <ToggleButtonGroup
                      value={privilegesRequired}
                      exclusive
                      onChange={(event, value) => {
                        if (value != null) {
                          setPrivilegesRequired(value);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      {metricsList
                        .find((ele) => ele?.id === 3)
                        ?.options?.map((_details, idx) => {
                          return (
                            <ToggleButton value={_details.score} aria-label="left aligned" key={idx}>
                              {_details.name}
                            </ToggleButton>
                          );
                        })}
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
                <Grid item md={5}>
                  <Stack>
                    <span className={styles.metricsTitle + " supportText_semiBold"}>Integrity (I)</span>
                    <ToggleButtonGroup
                      value={integrity}
                      exclusive
                      onChange={(event, value) => {
                        if (value != null) {
                          setIntegrity(value);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      {metricsList
                        .find((ele) => ele?.id === 7)
                        ?.options?.map((_details, idx) => {
                          return (
                            <ToggleButton value={_details.score} aria-label="left aligned" key={idx}>
                              {_details.name}
                            </ToggleButton>
                          );
                        })}
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack>
                    <span className={styles.metricsTitle + " supportText_semiBold"}>
                      User Interaction (UI)
                    </span>
                    <ToggleButtonGroup
                      value={userInteraction}
                      exclusive
                      onChange={(event, value) => {
                        if (value != null) {
                          setUserInteraction(value);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      {metricsList
                        .find((ele) => ele?.id === 4)
                        ?.options?.map((_details, idx) => {
                          return (
                            <ToggleButton value={_details.score} aria-label="left aligned" key={idx}>
                              {_details.name}
                            </ToggleButton>
                          );
                        })}
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
                <Grid item md={5}>
                  <Stack>
                    <span className={styles.metricsTitle + " supportText_semiBold"}>Availability (A)</span>
                    <ToggleButtonGroup
                      value={availability}
                      exclusive
                      onChange={(event, value) => {
                        if (value != null) {
                          setAvailability(value);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      {metricsList
                        .find((ele) => ele?.id === 8)
                        ?.options?.map((_details, idx) => {
                          return (
                            <ToggleButton value={_details.score} aria-label="left aligned" key={idx}>
                              {_details.name}
                            </ToggleButton>
                          );
                        })}
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div className={styles.riskWrapper}>
              <Grid container spacing={2}>
                <Grid item md={12} sx={{ marginBottom: "8px" }}>
                  <p
                    className={
                      (error.owasp ? styles.errorFormTitle : styles.formTitle) + " supportText_semiBold"
                    }
                  >
                    OWASP Category *
                  </p>
                  <CustomMultipleSelectDropdown
                    options={owsapOptions}
                    onChange={(event: SyntheticEvent, value) => {
                      handleChange("owasp", value);
                    }}
                    placeholder="Add OWASP"
                    value={formData.owasp}
                    error={error.owasp}
                  />
                </Grid>

                <Grid item md={12} sx={{ marginBottom: "8px" }}>
                  <p
                    className={error.cwe ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"}
                  >
                    CWE *
                  </p>
                  <CustomMultipleSelectDropdown
                    options={cweOptions ? cweOptions : []}
                    onChange={(event: SyntheticEvent, value) => {
                      handleChange("cwe", value);
                    }}
                    placeholder="Add CWE"
                    value={formData.cwe}
                    error={error.cwe}
                  />
                </Grid>
                <Grid item md={12} sx={{ marginBottom: "8px" }}>
                  <p
                    className={
                      error.gdpr ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                    }
                  >
                    GDPR *
                  </p>
                  <CustomMultipleSelectDropdown
                    options={gdprOptions ? gdprOptions : []}
                    onChange={(event: SyntheticEvent, value) => {
                      handleChange("gdpr", value);
                    }}
                    placeholder="Add GDPR"
                    value={formData.gdpr}
                    error={error.gdpr}
                  />
                </Grid>
                <Grid item md={12}>
                  <p
                    className={
                      (error.risk_status ? styles.errorFormTitle : styles.formTitle) + " supportText_semiBold"
                    }
                  >
                    Override Risk
                  </p>
                  <SelectDropdown
                    value={formData.risk_status}
                    onChange={(e) => handleChange("risk_status", e.target.value)}
                    error={error.risk_status}
                    className={styles.inputBlk}
                  >
                    <MenuItem value="" disabled>
                    </MenuItem>
                    {riskStatus &&
                      riskStatus.map((status, idx) => {
                        return (
                          <MenuItem value={status.value} key={idx}>
                            {status.label}
                          </MenuItem>
                        );
                      })}
                  </SelectDropdown>
                </Grid>
                {isOverrideRisk && (
                  <Grid item md={12}>
                    <CustomInput
                      label="Reason *"
                      className={styles.inputBlk}
                      placeholder="Enter Reason"
                      noMargin="noMargin"
                      value={formData.reason}
                      onChange={(e) => handleChange("reason", e.target.value as string)}
                      error={error.reason}
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            <div className={styles.editorWrapper}>
              <p className={styles.formTitle + " supportText_semiBold"}>Description</p>
              <Grid container>
                <Grid item md={12}>
                  <div className={styles.editorBlk}>
                    <CustomEditor
                      handleEditor={handleEditorDescription}
                      placeholder="Add Description"
                      value={descriptionVal}
                      scanId={scanId}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.editorWrapper}>
              <p className={styles.formTitle + " supportText_semiBold"}>Proof of Concepts </p>
              <Grid container>
                <Grid item md={12}>
                  <div className={styles.editorBlk}>
                    <CustomEditor
                      handleEditor={handleEditorPoc}
                      placeholder="Add Proof of Concepts"
                      value={pocVal}
                      scanId={scanId}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.editorWrapper}>
              <p className={styles.formTitle + " supportText_semiBold"}> Remediation </p>
              <Grid container>
                <Grid item md={12}>
                  <div className={styles.editorBlk}>
                    <CustomEditor
                      handleEditor={handleEditorRemediation}
                      placeholder="Add Remediation"
                      value={remediationVal}
                      scanId={scanId}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.buttonWrapper}>
              <CustomButton
                className="outlinedBtn"
                children="Cancel"
                type="button"
                onClick={() => navigate(-1)}
              />
              <CustomButton
                className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
                children="Save"
                type="submit"
              />
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default AddVulnerabilityForm;
