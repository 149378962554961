/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, forwardRef, useContext } from "react";
import { Container, Grid, Dialog, Slide, Tooltip } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { Shimmer } from ".";
import { LinkRenderer } from "../../../shared_ui_components";
import { editAnalysis, getSingleScanDetails } from "../apiServices";
import { toastFlashMessage, toolTipSeverity, toolTipScore, getImage } from "../../../utils";
import { format, parseISO } from "date-fns";
import { TScans, IFindingsProps } from "../../../types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { GlobalContext } from "../../../context/GlobalContext";
//@ts-ignore
import insert from 'markdown-it-ins';
import  'highlight.js/styles/github.css';
import { getGdprList } from "../../vulnerabilities/apiServices";


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VulnerabilityReport: FC = () => {
  const { id, analysisId, scanId } = useParams();
  const navigate = useNavigate();
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const roleId = roleLists?.roleData?.id;
  const [analysisDetail, setAnalysisDetail] = useState<IFindingsProps>();
  const [scanDetail, setScanDetail] = useState<TScans>();
  const [loader, setLoader] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [popupImage, setPopupImage] = useState<any>();

  const handleViewAnalysis = () => {
    setLoader(true);
    editAnalysis(analysisId as string).then((response) => {
      if (response.statusCode === 200) {
        setAnalysisDetail(response.data as IFindingsProps);
        setLoader(false);
      } else {
        setLoader(false);
        toastFlashMessage(response.message, "error");
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    if (analysisId) {
      handleViewAnalysis();
    }
  }, [analysisId]);

  useEffect(() => {
    setLoader(true);
    getSingleScanDetails(`${scanId}`).then(
      (response: Partial<{ statusCode: number; data: TScans; message: string }>) => {
        if (response.statusCode === 200) {
          if (roleId === 5 && response?.data?.status !== 3) {
            navigate(`/projects/${id}`);
          } else {
            setScanDetail(response.data as TScans);
          }
          setLoader(false);
        } else {
          setLoader(false);
          toastFlashMessage(response.message as string, "error");
          navigate(-1);
        }
      }
    );
  }, [scanId]);

  const cvvs_score = analysisDetail?.score as number;

  const handleClose = () => {
    setOpenImage(!openImage);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleImageClick = (e: any) => {
    let el: any = e.target;
    while (el && el !== e.currentTarget && el.tagName !== "IMG") {
      el = el.parentNode;
    }
    if (el && el.tagName === "IMG") {
      setOpenImage(!openImage);
      setPopupImage(el.getAttribute("src"));
    }
  };

  function replaceLink(link: string) {
    const validUUID = new RegExp(
      '^[a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}$'
    );

    if (validUUID.test(link.toLowerCase())) {
      var scanAttachments = JSON.parse(localStorage.getItem('attachments') as string);
      if (scanAttachments) {
        link = scanAttachments[link];
      }
    }
    return link;
  }

  var mdParser = require('markdown-it')({
    replaceLink: replaceLink
  }).use(require('markdown-it-highlightjs'), {inline: true})
    .use(require('markdown-it-replace-link'))
    .use(insert)


  return (
    <Container maxWidth="lg">
      {loader ? (
        <Shimmer type="vulnerabilityReport" />
      ) : (
        <div className={styles.VulnerabilityReportWrapper}>
          <div className={styles.reportHeader}>
            <p className="heading1">{analysisDetail?.name} </p>

            <div className={styles.riskBlk}>
              {analysisDetail?.risk_status === null ? (
                <img
                  src={
                    analysisDetail?.score
                      ? analysisDetail?.score >= 0.1 && analysisDetail?.score <= 3.9
                        ? getImage("risk_status_low.svg")
                        : analysisDetail?.score >= 4 && analysisDetail?.score <= 6.9
                        ? getImage("risk_status_medium.svg")
                        : analysisDetail?.score >= 7 && analysisDetail?.score <= 8.9
                        ? getImage("risk_status_high_new.svg")
                        : analysisDetail?.score >= 9 && analysisDetail?.score <= 10
                        ? getImage("risk_status_critical.svg")
                        : ""
                      : getImage("risk_status_secure.svg")
                  }
                  alt=" "
                />
              ) : (analysisDetail?.risk_status || analysisDetail?.risk_status === 0) &&
                typeof analysisDetail?.previous_risk_status === "number" ? (
                <div>
                  <img
                    src={
                      analysisDetail?.risk_status === 0
                        ? getImage("risk_status_low.svg")
                        : analysisDetail?.risk_status === 1
                        ? getImage("risk_status_medium.svg")
                        : analysisDetail?.risk_status === 2
                        ? getImage("risk_status_high_new.svg")
                        : analysisDetail?.risk_status === 3
                        ? getImage("risk_status_critical.svg")
                        : analysisDetail?.risk_status === 4
                        ? getImage("risk_status_passed.svg")
                        : ""
                    }
                    alt=" "
                  />
                  <span style={{ position: "relative", padding: "2px", cursor: "pointer" }}>
                    <Tooltip
                      title={toolTipSeverity(
                        analysisDetail?.previous_risk_status,
                        analysisDetail?.risk_status
                      )}
                      placement="top"
                    >
                      <img src={getImage("info_icon.svg")} style={{ height: "18px" }} alt="" />
                    </Tooltip>
                  </span>
                </div>
              ) : (
                <img
                  src={
                    analysisDetail?.risk_status === 0
                      ? getImage("risk_status_low.svg")
                      : analysisDetail?.risk_status === 1
                      ? getImage("risk_status_medium.svg")
                      : analysisDetail?.risk_status === 2
                      ? getImage("risk_status_high_new.svg")
                      : analysisDetail?.risk_status === 3
                      ? getImage("risk_status_critical.svg")
                      : analysisDetail?.risk_status === 4
                      ? getImage("risk_status_passed.svg")
                      : ""
                  }
                  alt=" "
                />
              )}
            </div>
          </div>
          <div className={styles.implicationBlk}>
            <p className={styles.implicationTitle + " heading6"}>{analysisDetail?.vulnerability?.name}</p>
            <p className="paragraph">
              {analysisDetail?.vulnerability?.description &&
                analysisDetail?.vulnerability?.description}
            </p>
          </div>
          {analysisDetail?.risk_reason && (
            <div className={styles.descBlk}>
              <p className={styles.descTitle + " heading6"}>Severity Override Reason</p>
              <div className={styles.descOuterBlk}>
                <p className="paragraph">{analysisDetail?.risk_reason}</p>
              </div>
            </div>
          )}
          {analysisDetail?.description && (
            <div className={styles.descBlk}>
              <p className={styles.descTitle + " heading6"}>Description</p>
              <div className={styles.descOuterBlk}>
                <ReactMarkdown
                  children={mdParser.render(analysisDetail?.description) as string}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={{ a: LinkRenderer }}
                  className={styles.markdown}
                />
              </div>
            </div>
          )}
          <div className={styles.reportStatusBlk}>
            <p className={styles.scanTitle + " heading6"}>Scan Status</p>
            <div className={styles.scanListItemWrapper}>
              <Grid container alignItems="center">
                <Grid item md={3} className={styles.scanStatusBlk}>
                  <p className={styles.scanTitle + " supportText_semiBold_grey"}>STATUS</p>
                  <p className={styles.scanIconBlk}>
                    <img
                      src={
                        scanDetail?.status === 0
                          ? getImage("status_pending.svg")
                          : scanDetail?.status === 1
                          ? getImage("status_progress.svg")
                          : scanDetail?.status === 2
                          ? getImage("delete_user.svg")
                          : scanDetail?.status === 3
                          ? getImage("status_success.svg")
                          : null
                      }
                      alt=""
                    />
                    <span
                      className={
                        scanDetail?.status === 0
                          ? styles.pending
                          : scanDetail?.status === 1
                          ? styles.progress
                          : scanDetail?.status === 2
                          ? styles.discarded
                          : scanDetail?.status === 3
                          ? styles.success
                          : ""
                      }
                    >
                      {scanDetail?.status === 0
                        ? "Pending"
                        : scanDetail?.status === 1
                        ? "In Progress"
                        : scanDetail?.status === 2
                        ? "Discarded"
                        : scanDetail?.status === 3
                        ? "Completed"
                        : ""}
                    </span>
                  </p>
                </Grid>
                <Grid item md={3} className={styles.scanStatusBlk}>
                  <p className={styles.scanTitle + " supportText_semiBold_grey"}>DUE DATE</p>
                  <p className="paragraph">
                    {scanDetail?.due_date
                      ? format(parseISO(scanDetail?.due_date), "dd/MM/yyyy hh:mm a")
                      : "N/A"}
                  </p>
                </Grid>
                <Grid item md={3} className={styles.scanStatusBlk}>
                  <p className={styles.scanTitle + " supportText_semiBold_grey"}>ENGAGEMENT TYPE</p>
                  <p className="paragraph">{scanDetail?.engagement_type}</p>
                </Grid>
                <Grid item md={3} className={`${styles.scanStatusBlk} ${styles.scanScoreBlk}`}>
                  <p className={styles.scanTitle + " supportText_semiBold_grey"}>METHODOLOGY</p>
                  <p className={styles.scanScore + " paragraph"}>
                    {scanDetail?.methodology === 1 ? "Greybox Testing" : "Blackbox Testing"}
                  </p>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={styles.regulatoryBlk}>
            <p className={styles.scanTitle + " heading6"}>Regulatory</p>
            <div className={styles.regulatoryBody}>
              <Grid container alignItems="center" className={styles.titleBlk}>
                <Grid item md={3} className="paragraph">
                  <p>CVSSv3 Score</p>
                </Grid>
                <Grid item md={5} className="paragraph">
                  <p className={styles.headerScore + " paragraph"}>
                    {typeof analysisDetail?.risk_status === "number" &&
                    typeof analysisDetail?.previous_risk_status === "number" ? (
                      <>
                        <span
                          className={
                            analysisDetail?.risk_status === 0
                              ? styles.statusLow
                              : analysisDetail?.risk_status === 1
                              ? styles.statusMedium
                              : analysisDetail?.risk_status === 2
                              ? styles.statusHigh
                              : analysisDetail?.risk_status === 3
                              ? styles.statusCritical
                              : styles.scoreNone
                          }
                        >
                          {analysisDetail?.risk_status === 0
                            ? 3.9
                            : analysisDetail?.risk_status === 1
                            ? 6.9
                            : analysisDetail?.risk_status === 2
                            ? 8.9
                            : analysisDetail?.risk_status === 3
                            ? 10
                            : "0.0"}
                          /10
                        </span>{" "}
                        {analysisDetail?.risk_status === 0
                          ? "Low"
                          : analysisDetail?.risk_status === 1
                          ? "Medium"
                          : analysisDetail?.risk_status === 2
                          ? "High"
                          : analysisDetail?.risk_status === 3
                          ? "Critical"
                          : "Passed"}
                        <span style={{ padding: "4px" }}>
                          <Tooltip
                            title={toolTipScore(
                              analysisDetail?.previous_risk_status,
                              analysisDetail?.risk_status
                            )}
                            placement="top"
                          >
                            <img
                              src={getImage("info_icon.svg")}
                              style={{ height: "18px", position: "relative", top: "3px", cursor: "pointer" }}
                              alt=""
                            />
                          </Tooltip>
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span
                          className={
                            cvvs_score >= 0.1 && cvvs_score <= 3.9
                              ? styles.statusLow
                              : cvvs_score >= 4 && cvvs_score <= 6.9
                              ? styles.statusMedium
                              : cvvs_score >= 7 && cvvs_score <= 8.9
                              ? styles.statusHigh
                              : cvvs_score >= 9 && cvvs_score <= 10
                              ? styles.statusCritical
                              : styles.statusNone
                          }
                        >
                          {cvvs_score}/10
                        </span>{" "}
                        {cvvs_score
                          ? cvvs_score >= 0.1 && cvvs_score <= 3.9
                            ? "Low"
                            : cvvs_score >= 4 && cvvs_score <= 6.9
                            ? "Medium"
                            : cvvs_score >= 7 && cvvs_score <= 8.9
                            ? "High"
                            : cvvs_score >= 9 && cvvs_score <= 10
                            ? "Cricital"
                            : "None"
                          : "Secure"}{" "}
                      </>
                    )}
                  </p>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" className={styles.metricsBlk}>
                <Grid item md={3} className="paragraph_semiBold">
                  <p>CVSS Metrics</p>
                </Grid>
                <Grid item md={3} className="paragraph">
                  {analysisDetail?.cvss_metrics?.map((cvss, id) => {
                    return <p key={id}>{cvss?.name}</p>;
                  })}
                </Grid>
                <Grid item md={3} className="paragraph">
                  {analysisDetail?.cvss_metrics?.map((cvss, id) => {
                    return (
                      <p>
                        {
                          cvss?.options.find((opt) => {
                            return opt?.selected === true;
                          })?.name
                        }
                      </p>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid container alignItems="center" className={styles.middleBlk}>
                <Grid item md={3} className="paragraph_semiBold">
                  <p>OWASP</p>
                </Grid>
                <Grid item md={3} className="paragraph">
                  {analysisDetail?.owasp?.map((owa) => {
                    return <p>{owa?.name}</p>;
                  })}
                </Grid>
                <Grid item md={6} className="paragraph">
                  {analysisDetail?.owasp?.map((owa) => {
                    return <p>{owa?.title ? owa?.title : "N/A"}</p>;
                  })}
                </Grid>
              </Grid>
              {analysisDetail?.cwe?.length ? (
              <Grid container alignItems="center" className={styles.middleBlk}>
                <Grid item md={3} className="paragraph_semiBold">
                  <p>CWE</p>
                </Grid>
                <Grid item md={3} className="paragraph">
                  {analysisDetail?.cwe?.map((_cwe) => {
                    return <p>{_cwe?.name}</p>;
                  })}
                </Grid>
                <Grid item md={4} className="paragraph">
                  {analysisDetail?.cwe?.map((_cwe) => {
                    return <p>{_cwe?.description}</p>;
                  })}
                </Grid>
              </Grid>
              ) : <></>}
              {analysisDetail?.gdpr?.length ? (
                <Grid container alignItems="center" className={styles.lastBlk}>
                  <Grid item md={3} className="paragraph_semiBold">
                    <p>GDPR</p>
                  </Grid>
                  <Grid item md={3} className="paragraph">
                    {analysisDetail?.gdpr?.map((_gdpr) => {
                      return <p>{_gdpr?.name}</p>;
                    })}
                  </Grid>
                  <Grid item md={4} className="paragraph">
                    {analysisDetail?.gdpr?.map((_gdpr) => {
                      return <p>{_gdpr?.description ? _gdpr?.description : "N/A"}</p>;
                    })}
                  </Grid>
                </Grid>
              ) : <></>}
            </div>
          </div>

          {analysisDetail?.proof_of_concept && (
            <div className={styles.pocBlk}>
              <p className={styles.descTitle + " heading6"}>Proof Of Concept</p>
              <div className={styles.descOuterBlk}>
                <ReactMarkdown
                  children={mdParser.render(analysisDetail?.proof_of_concept) as string}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={{ a: LinkRenderer }}
                  className={styles.markdown}
                />
              </div>
            </div>
          )}
          {analysisDetail?.remidation && (
            <div className={styles.remediationBlk}>
              <p className={styles.descTitle + " heading6"}>Remediation</p>
              <div className={styles.descOuterBlk}>
                <ReactMarkdown
                  children={mdParser.render(analysisDetail?.remidation) as string}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={{ a: LinkRenderer }}
                  className={styles.markdown}
                />
              </div>
            </div>
          )}
          <Dialog open={openImage} TransitionComponent={Transition} className="fullImageDialogContainer">
            <div>
              <div className="fullImageHeader">
                <img
                  className="closeIcon"
                  src={getImage("close_icon_white.svg")}
                  alt=" "
                  onClick={handleClose}
                />
                {popupImage && <img className="fullImageView" src={getImage(`${popupImage}`)} alt=" " />}
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </Container>
  );
};

export default VulnerabilityReport;
