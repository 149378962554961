import Client from "./containers/Client";
import { TRoutesType } from "../../types";
import PageLayoutHoc from "../../hocs/pageLayoutHoc";
import ClientsProjectDetails from "./containers/ClientsProjectDetails";
import ClientUsers from "./containers/ClientUsers";

const ClientPage = PageLayoutHoc(Client);
const ClientProjectDetail = PageLayoutHoc(ClientsProjectDetails);
const ClientUser = PageLayoutHoc(ClientUsers);

export const clientsRoutes: Array<TRoutesType> = [
  {
    path: "/clients",
    element: <ClientPage />,
    key: "clients",
  },
  {
    path: "/clients/:id",
    element: <ClientProjectDetail />,
    key: "clients",
  },
  {
    path: "/clients/:id/users",
    element: <ClientUser />,
    key: "clients",
  },
];
