import { FC, ChangeEvent, useState, useEffect, useContext, MouseEvent } from "react";
import { Container, Pagination } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NoResult, CustomButton, CustomMoreMenu, ConfirmationDialog } from "../../../shared_ui_components";
import styles from "../../../assets/styles/home.module.scss";
import { ScanListItem, CreateScanRequestDialog, Shimmer, ScanListHeader } from "../components";
import {
  getSingleProject,
  getSingleProjectWithPage,
  getSingleProjectWithoutPage,
  deactivateProject,
} from "../apiServices";
import { toastFlashMessage, getImage } from "../../../utils";
import { TProjectResponse, TScans, TScanType } from "../../../types";
import { GlobalContext } from "../../../context/GlobalContext";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";

const ProductDetail: FC = () => {
  const {
    state: { roleLists },
    dispatch,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [singleProjectDetail, setSingleProjectDetail] = useState<TProjectResponse>({});
  const [showMore, setShowMore] = useState(false);
  const [openScanCreate, setOpenScanCreate] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [loader, setLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openLogPop, setLogPop] = useState(false);
  const [updateProject, setUpdateProject] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [scanLists, setScanLists] = useState<TScanType>({});

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLog = (val: boolean) => {
    setLogPop(true);
  };
  const handleUpdateProject = () => {
    setUpdateProject(!updateProject);
    navigate(`/projects/${id}/update-project`);
  };

  const handleCreateScan = () => {
    setOpenScanCreate(!openScanCreate);
  };

  useEffect(() => {
    const onScroll = (e: Event) => {
      setScrollTop((e.target as HTMLDocument).documentElement.scrollTop);
      setScrolling((e.target as HTMLDocument).documentElement.scrollTop > scrollTop);
    };
    const win: Window = window;
    win.addEventListener("scroll", onScroll);

    return () => win.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const getAllProjectDetails = (value?: number) => {
    getSingleProject(`${id}`).then(
      (response: Partial<{ statusCode: number; data: TProjectResponse; message: string }>) => {
        if (response.statusCode === 200) {
          setSingleProjectDetail(response.data as TProjectResponse);
          (value ? getSingleProjectWithPage(`${id}`, value) : getSingleProjectWithoutPage(`${id}`)).then(
            (response: Partial<{ statusCode: number; data: TScanType; message: string }>) => {
              if (response.statusCode === 200) {
                setLoader(false);
                setScanLists(response?.data as TScanType);
              }
            }
          );
          /* Storing recently selected last 4 project list in LS */
          if (searchParams.get("search")) {
            let recentlySearchedProjects = JSON.parse(
              localStorage.getItem("recentlySearchedProjects") as string
            );
            if (recentlySearchedProjects && recentlySearchedProjects.length > 0) {
              const removeDuplicateProject = recentlySearchedProjects.filter(
                (project: { _id: string; name: string }) => project._id !== id
              );
              const updatedProjectLists = [
                ...[{ _id: id, name: response?.data?.name }],
                ...removeDuplicateProject,
              ];
              localStorage.setItem(
                "recentlySearchedProjects",
                JSON.stringify(updatedProjectLists.slice(0, 4))
              );
            } else {
              recentlySearchedProjects = [{ _id: id, name: response?.data?.name }];
              localStorage.setItem("recentlySearchedProjects", JSON.stringify(recentlySearchedProjects));
            }
          }
        } else {
          toastFlashMessage(response.message as string, "error");
          setLoader(false);
          navigate("/projects");
        }
      }
    );
  };

  const getScanData = () => {
    const _CurrentPage = searchParams.get("page");
    if (_CurrentPage) {
      setPage(Number(_CurrentPage));
      getAllProjectDetails(Number(_CurrentPage));
    } else {
      getAllProjectDetails();
    }
  };

  /* Calling Single Project Detail API  */
  useEffect(() => {
    if (id) {
      getScanData();
      return () => {
        setSingleProjectDetail({});
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /* Adding scan details to singleProjectDetails*/
  const handleAddScanDetails = (addNewScan: TScans[]) => {
    setScanLists((scan) => ({
      ...scan,
      list: addNewScan,
    }));
  };

  /* Deleting SingleProjectDetails */
  const handleDeactivateproject = () => {
    deactivateProject(singleProjectDetail?.uuid as string).then(
      (response: Partial<{ statusCode: number; data: TProjectResponse; message: string }>) => {
        if (response.statusCode === 200) {
          dispatch({
            type: PROJECT_ACTIONS.Update,
            payload: response.data,
          });
          navigate("/projects");
          toastFlashMessage(response.message as string, "success");
          setLogPop(false);
        } else {
          setLogPop(false);
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };

  /* For handling Pages */
  const handlePageChange = (value: number) => {
    setLoader(true);
    dispatch({
      type: PROJECT_ACTIONS.Loading,
      payload: true,
    });
    navigate({
      pathname: `/projects/${id}`,
      search: `?page=${value}`,
    });
    setPage(value as number);
    getSingleProjectWithPage(`${id}`, value).then((response: any) => {
      if (response.statusCode === 200) {
        setScanLists(response.data);
        setLoader(false);
      }
    });
  };
  /* -----end---- */

  return (
    <>
      <div className={`${styles.productStickyHeader} ${scrolling ? styles.scrollEffect : null}`}>
        <Container maxWidth="lg">
          {loader ? (
            <Shimmer type="singleProjectDetails" />
          ) : (
            <div className={styles.productTitleBlk}>
              <div className={styles.productTitle}>
                <div
                  onClick={() => {
                    dispatch({
                      type: PROJECT_ACTIONS.Loading,
                      payload: true,
                    });
                    navigate("/projects");
                  }}
                  className={styles.productTitleLink + " paragraph"}
                >
                  {" "}
                  Projects
                </div>{" "}
                / <span>{singleProjectDetail?.name}</span>
              </div>
              <div className={styles.productHeaderWrapper}>
                <div className={styles.productHeaderLeft}>
                  <img
                    src={
                      singleProjectDetail?.client?.logo
                        ? singleProjectDetail?.client?.logo
                        : getImage("profile_placeholder.svg")
                    }
                    alt=" "
                  />
                  <div className={styles.productProjectName}>
                    <p className="heading4">{singleProjectDetail?.name}</p>
                  </div>
                </div>
                <div className={styles.productHeaderRight}>
                  {roleLists?.roleData?.id === 5 && (
                    <>
                      {roleLists?.roleData?.permission?.includes("EDIT_PROJECT") && (
                        <img
                          aria-controls="simple-menu"
                          src={getImage("more_menu.svg")}
                          onClick={handleClick}
                          alt=" "
                        />
                      )}
                      <CustomMoreMenu
                        anchorEl={anchorEl}
                        open={open}
                        handleClose={handleClose}
                        handleLogOut={handleLog}
                        noOfItems={2}
                        message1="Update Project Details"
                        message2="Deactivate Project"
                        image1="user_edit.svg"
                        image2="delete_user.svg"
                        handleClick1={handleUpdateProject}
                      />
                      {openLogPop && (
                        <ConfirmationDialog
                          text="Deactivate Project"
                          subText="Are you sure you want to deactivate this project?"
                          image="delete_icon.svg"
                          cancelBtnText="Cancel"
                          successBtnText="Deactivate"
                          cancelBtnClass="outlinedBtn"
                          successBtnClass="secondaryBtn"
                          handleSuccess={handleDeactivateproject}
                          handleCancel={() => setLogPop(false)}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                <p className={styles.productDescription + " paragraph"}>
                  {singleProjectDetail?.description && singleProjectDetail?.description?.length > 160 ? (
                    <>
                      {showMore
                        ? singleProjectDetail?.description
                        : `${singleProjectDetail?.description?.substring(0, 160)}...`}
                      <span onClick={() => setShowMore(!showMore)}>
                        {showMore ? " show less" : " show more"}
                      </span>
                    </>
                  ) : (
                    singleProjectDetail?.description
                  )}
                </p>
              </div>
            </div>
          )}
        </Container>
      </div>
      <Container maxWidth="lg">
        <div className={styles.productDetailPageWrapper}>
          <div className={styles.scanListView}>
            <div className={styles.scanListViewTitleWrapper}>
              <p className={styles.scanListViewTitle + " heading6"}>
                {"Scans"}
                <span>{scanLists?.pagination?.total}</span>
              </p>

              {scanLists && scanLists?.list?.length !== 0 && (
                <div className={styles.scanWrapper}>
                  {scanLists &&
                    scanLists?.list?.every(
                      (scan_status) => scan_status.status === 3 || scan_status.status === 2
                    ) &&
                    roleLists?.roleData?.permission?.includes("ADD_SCAN_REQUEST") && (
                      <CustomButton
                        className=" primaryBtn"
                        children="Create Scan Request"
                        type="button"
                        onClick={handleCreateScan}
                      />
                    )}
                </div>
              )}
            </div>
            {scanLists?.list?.length !== 0 && <ScanListHeader />}
            {loader ? (
              <Shimmer type="scanLists" />
            ) : (
              <>
                {
                  <>
                    {scanLists?.list?.map((scanDetails, index) => {
                      return (
                        <ScanListItem
                          key={index}
                          {...scanDetails}
                          projectId={singleProjectDetail?.uuid as string}
                        />
                      );
                    })}
                  </>
                }
                {singleProjectDetail?.scans?.length === 0 &&
                  (roleLists?.roleData?.permission?.includes("ADD_SCAN_REQUEST") ? (
                    <NoResult
                      image={getImage("no_file_found.svg")}
                      title="No Scan Request Yet"
                      btnText="Create Scan Request"
                      onClick={handleCreateScan}
                    />
                  ) : (
                    <NoResult image={getImage("no_file_found.svg")} title="No Scan Request Yet" />
                  ))}
              </>
            )}
          </div>
        </div>
      </Container>
      {!loader && typeof scanLists?.pagination?.total === "number" && scanLists?.pagination?.total > 10 && (
        <div className={styles.paginationBlk}>
          <Pagination
            count={scanLists?.pagination?.number_of_pages}
            page={page as number}
            variant="outlined"
            shape="rounded"
            onChange={(event: ChangeEvent<unknown>, page: number) => handlePageChange(page)}
          />
        </div>
      )}
      {openScanCreate && (
        <CreateScanRequestDialog
          open={openScanCreate}
          onClose={handleCreateScan}
          projectName={singleProjectDetail?.name as string}
          projectId={singleProjectDetail?.uuid as string}
          handleAddScanDetails={handleAddScanDetails}
        />
      )}
    </>
  );
};
export default ProductDetail;
