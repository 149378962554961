import { FC, ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";
import queryString from "query-string";
import { getUserInfo } from "../utils";
interface Props {
  children: ReactElement<any, any>;
}

const PrivateRoute: FC<Props> = ({ children }) => {
  let location = useLocation();
  const userInfo = getUserInfo();

  const isUserLoggedIn = () => {
    if (userInfo?.access) {
      return true;
    } else {
      const querys = queryString.parse(location.search);
      const redirection = querys.redirection;
      if (redirection) {
        localStorage.setItem("redirection", location.pathname);
      }
      return false;
    }
  };

  return isUserLoggedIn() ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
