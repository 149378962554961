import { FC } from "react";
import styles from "../../../assets/styles/auth.module.scss";
import { getImage } from "../../../utils";

type TAuthLayoutProps = {
  children?: React.ReactNode;
};

const AuthLayout: FC<TAuthLayoutProps> = ({ children }) => {
  return (
    <section className={styles.authSection + " backgroundProp"}>
      <img src={getImage("auth_background_right.png")} className={styles.bg_right} alt="bg_right" />
      <div className={styles.logoWrapper + " textCenter"}>
        <img src={getImage("logo.svg")} alt="logo" />
      </div>
      {children}
      <div className={styles.copyrightWrapper}>
        <img src={getImage("Copyright.svg")} alt="logo" />
        <p className={styles.copyrightText + " labelText"}>2021 AppKnox. All rights reserved.</p>
      </div>
      <img src={getImage("auth_background_left.png")} className={styles.bg_left} alt="bg_right" />
    </section>
  );
};
export default AuthLayout;
