import { FC, Fragment, ChangeEvent, useState, useContext } from "react";
import { Grid } from "@mui/material";
import styles from "../../../assets/styles/user.module.scss";
import { Search } from "../../../shared_elements";
import { NoResult } from "../../../shared_ui_components";
import { GlobalContext } from "../../../context/GlobalContext";
import { UserListItem, Shimmer } from ".";
import { TUsers } from "../../../types";
import { getImage } from "../../../utils";

type TSearchType = {
  val?: string;
  list?: TUsers[];
};

const UserDashboard: FC = () => {
  const {
    state: { userLists },
  } = useContext(GlobalContext);
  const [search, setSearch] = useState<TSearchType>({
    val: "",
    list: [],
  });

  const handleSearch = (val: string) => {
    let str = val.toLowerCase();
    let searchList = userLists?.userData?.filter(
      (user) =>
        user?.email?.toLowerCase().includes(str) ||
        user?.first_name?.toLowerCase().includes(str) ||
        user?.role?.name?.toLowerCase().includes(str)
    );
    setSearch(() => ({
      val: str,
      list: searchList,
    }));
  };

  const handleClearSearch = () => {
    setSearch(() => ({
      val: "",
      list: [],
    }));
  };

  return (
    <>
      <div className={styles.userTitleWrapper}>
        <h2 className="heading3 headingTextOverflow">
          {" "}
          {search?.val
            ? `Showing ${search?.list?.length} ${
                search?.list?.length === 1 || search?.list?.length === 0 ? "result" : "results"
              } for "${search?.val}" `
            : `All Users`}
        </h2>
        <div className={styles.userSearchAndViewWrapper}>
          {!userLists?.loading && (
            <>
              {search.val ? (
                <span onClick={handleClearSearch} className={styles.clearSearch + " paragraph"}>
                  Clear search
                </span>
              ) : (
                ""
              )}
              <Search
                text={search.val as string}
                placeholder="Search for users"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.userListWrapper}>
        <Grid container>
          <Grid item md={3}>
            <p>USER NAME</p>
          </Grid>
          <Grid item md={3}>
            <p>EMAIL ID</p>
          </Grid>
          <Grid item md={3}>
            <p>USER ROLE</p>
          </Grid>
          <Grid item md={2}>
            <p>INVITE STATUS</p>
          </Grid>
          <Grid item md={1}>
            ACTION
          </Grid>
        </Grid>
      </div>
      {userLists?.showNoState ? (
        <NoResult
          image={getImage("no_file_found.svg")}
          title="No Users Found"
          btnText="Invite User"
          link="/clients"
        />
      ) : userLists?.loading ? (
        <Shimmer type="usersList" />
      ) : (
        <>
          {search?.val ? (
            <>
              {search?.list?.length === 0 ? (
                <NoResult
                  image={getImage("no_file_found.svg")}
                  title="No Result Found"
                  subtext={<Fragment>We didn't find any users that matched your search</Fragment>}
                />
              ) : (
                <div className={styles.userListWrapperFooter}>
                  {search?.list?.map((users: TUsers) => {
                    return (
                      <Fragment key={users.uuid}>
                        <UserListItem {...users} handleSearch={handleSearch} />
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <div className={styles.userListWrapperFooter}>
              {userLists?.userData?.map((users) => {
                return (
                  <Fragment key={users.uuid}>
                    <UserListItem {...users} />
                  </Fragment>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserDashboard;
