import { FC } from "react";
import { Container } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { UpdateProjectForm } from "../components";
import { getImage } from "../../../utils";

const EditScanDetail: FC = () => {
  const { id } = useParams();
  return (
    <>
      <Container maxWidth="lg">
        <div className={styles.updateProjectDetailPageWrapper}>
          <div className={styles.editScanTitle}>
            <Link className={styles.backLink + " supportText_semiBold"} to={`/projects/${id}`}>
              <img src={getImage("arrow_down.svg")} alt="" />
              Back to project details
            </Link>
            <p className="heading3">Update Project Details</p>
          </div>
          <UpdateProjectForm />
        </div>
      </Container>
    </>
  );
};
export default EditScanDetail;
