import { TRoutesType } from "../../types";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import SignIn from "./containers/SignIn";
import SetPassword from "./containers/SetPassword";

export const authRoutes: Array<TRoutesType> = [
  {
    path: "/signin",
    element: <SignIn />,
    key: "signin",
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    key: "ForgotPassword",
  },
  {
    path: "/passwordreset",
    element: <ResetPassword />,
    key: "ResetPassword",
  },
  {
    path: "/setpassword",
    element: <SetPassword />,
    key: "SetPassword",
  },
];
