import { globalPostService } from "../../utils/GlobalApiServices";
import { TSigninType, TForgotPasswordType, TResetPasswordType } from "../../types";

export function signInApi(query: TSigninType): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`auth/login/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function forgetPasswordApi(query: TForgotPasswordType): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`auth/forgot-password/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetPasswordApi(query: TResetPasswordType): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`auth/reset-password/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
