import { FC, ReactNode, KeyboardEvent } from "react";
import { Select, SelectChangeEvent } from "@mui/material";

type FormControl = /*unresolved*/ any;

type TSelectDropdownProps = {
  children?: ReactNode;
  className?: string;
  onChange?: ((event: SelectChangeEvent<string>, child: ReactNode) => void) | undefined;
  value?: string | number;
  error?: string;
  onKeyPress?: (event: KeyboardEvent<FormControl>) => void;
  disabled?: boolean;
};

const SelectDropdown: FC<TSelectDropdownProps> = ({
  children,
  onChange,
  value,
  error,
  onKeyPress,
  disabled,
}) => {
  return (
    <>
      <Select
        value={value as string}
        className="selectDropdown"
        onChange={onChange}
        displayEmpty
        onKeyPress={onKeyPress}
        disabled={disabled}
        inputProps={{ "aria-label": "Without label" }}
        style={{
          border: error ? "1px solid #cf2116" : "",
          backgroundColor: error ? "#ffecee" : "",
        }}
      >
        {children}
      </Select>
      {error && <h6 className="errorMsg absolute">{error}</h6>}
    </>
  );
};

export default SelectDropdown;