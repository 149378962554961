import { globalPostService, globalGetService } from "../../utils/GlobalApiServices";
import {
  TUpdatePassword,
  TUpdateProfile,
  TUpdateNotificationSettings,
  TNotificationUpdate,
} from "../../types";

export function updatePassword(query: TUpdatePassword): any {
  return new Promise((resolve, reject) => {
    globalPostService(`password/change/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function updateUserProfile(query: TUpdateProfile): any {
  return new Promise((resolve, reject) => {
    globalPostService(`profile/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function notificationSettings(query: TUpdateNotificationSettings): any {
  return new Promise((resolve, reject) => {
    globalPostService(`notification-settings/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function notificationUpdate(query: TNotificationUpdate): any {
  return new Promise((resolve, reject) => {
    globalPostService(`update-notification/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function UnreadNotificationCount(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`notifications/un-read/count/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function notificationList(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`notifications/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function logoutApi(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`logout/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
