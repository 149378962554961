import { FC, useState, ChangeEvent, SyntheticEvent, useContext } from "react";
import { Grid, Stack, Avatar } from "@mui/material";
import { errorCodes } from "../../../constants";
import { CustomButton, CustomInput, CustomLink } from "../../../shared_ui_components";
import { getUserInfo, toastFlashMessage, getImage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidations";
import { Errors, TUserInfoProp, TUserInfo } from "../../../types";
import { updateUserProfile } from "../apiServices";
import { GlobalContext } from "../../../context/GlobalContext";

type IUpdateProifileProps = {
  step: number;
  updateStep: (val: number) => void;
  handleCancel: () => void;
};

const UpdateProfile: FC<IUpdateProifileProps> = ({ step, updateStep, handleCancel }) => {
  let userInfo = getUserInfo();
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const [displayImage, setDisplayImage] = useState(
    userInfo?.user?.profile_pic ? userInfo?.user?.profile_pic : null
  );
  const [image, setImage] = useState<string | Blob>("");
  const [formData, setFormData] = useState({
    first_name: userInfo?.user?.first_name ? userInfo?.user?.first_name : "",
    last_name: userInfo?.user?.last_name ? userInfo?.user?.last_name : "",
    email: userInfo?.user?.email ? userInfo?.user?.email : "",
    designation: userInfo?.user?.designation ? userInfo?.user?.designation : "",
  });
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);

  const handleChange = (key: string, val: string) => {
    setFormData((formData) => ({
      ...formData,
      [key]: val,
    }));
    setError((errors) => ({
      ...errors,
      [key]: "",
    }));
  };

  const handleImgChange = (e?: ChangeEvent) => {
    const target = e?.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setImage(file);
    setDisplayImage(URL.createObjectURL(file));
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validateNewInput: {
      [key: string]: string;
    };

    validateNewInput = {
      first_name:
        errorCode["first_name"][
          fieldValidation({
            ...errorCode["first_name_Obj"],
            fieldval: formData.first_name,
          })
        ],
    };

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      let form_data: FormData = new FormData();
      image
        ? form_data.append("profile_pic", image)
        : form_data.append("profile_pic", userInfo?.user?.profile_pic as string);
      form_data.append("first_name", formData.first_name);
      form_data.append("last_name", formData.last_name);
      form_data.append("designation", formData.designation);
      updateUserProfile(form_data).then(
        (response: Partial<{ statusCode: number; data: TUserInfo; message: string }>) => {
          if (response.statusCode === 200) {
            setLoader(false);
            toastFlashMessage(response.message as string, "success");
            userInfo = {
              ...userInfo,
              user: {
                ...userInfo?.user,
                first_name: response?.data?.first_name,
                last_name: response?.data?.last_name,
                designation: response?.data?.designation,
                profile_pic: response?.data?.profile_pic,
              },
            } as TUserInfoProp | null;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            handleCancel();
          } else {
            setLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="formDetail">
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ display: "flex" }}>
              {roleLists?.roleData?.id !== 5 && (
                <Stack>
                  <p className="formTitle supportText_semiBold">Profile Image</p>
                  <div className="profileWrapper">
                    {displayImage ? (
                      <img className="profileImg" src={displayImage} alt="user" />
                    ) : (
                      <Avatar className="avatar">
                        {userInfo?.user?.first_name
                          ? userInfo?.user?.first_name.substring(0, 1).toUpperCase()
                          : userInfo?.user?.email
                          ? userInfo?.user?.email.substring(0, 1).toUpperCase()
                          : ""}
                      </Avatar>
                    )}
                    <CustomLink className="uploadBtn outlinedBtn" children="Change image" to="" />
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      className="inputFile"
                      onChange={handleImgChange}
                    />
                  </div>
                </Stack>
              )}
            </Grid>
            <Grid item md={6} sx={{ marginBottom: "12px" }}>
              <CustomInput
                className="inputBlk"
                label="First Name *"
                placeholder="John"
                onChange={(e) => handleChange("first_name", e.target.value)}
                noMargin="noMargin"
                value={formData.first_name}
                error={error.first_name}
              />
            </Grid>
            <Grid item md={6} sx={{ marginBottom: "12px" }}>
              <CustomInput
                className="inputBlk"
                label="Last Name"
                placeholder="last name"
                onChange={(e) => handleChange("last_name", e.target.value)}
                noMargin="noMargin"
                value={formData.last_name}
              />
            </Grid>
            <Grid item md={6} sx={{ marginBottom: "12px" }}>
              <CustomInput
                className={"disabledInputGroup"}
                label="Email ID *"
                placeholder="abc@xyz.com"
                noMargin="noMargin"
                value={formData.email}
              />
            </Grid>
            <Grid item md={6} sx={{ marginBottom: "12px" }}>
              <CustomInput
                className="inputBlk"
                label="Designation"
                placeholder="DevOps Engineer"
                onChange={(e) => handleChange("designation", e.target.value)}
                noMargin="noMargin"
                value={formData.designation}
              />
            </Grid>
            <Grid item md={12}>
              <p className="formTitle supportText_semiBold">Change Password</p>
              <div className="changePasswordWrapper" onClick={() => updateStep(2)}>
                <CustomInput className="disabledInputGroup" placeholder="********" noMargin="noMargin" />
                <img src={getImage("arrow_right.svg")} alt="click" />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="buttonWrapper">
          <CustomButton
            className="outlinedBtn"
            children={step === 1 ? "Cancel" : "Back"}
            type="button"
            onClick={handleCancel}
          />
          <CustomButton
            className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
            children={loader ? "Updating" : "Update"}
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default UpdateProfile;
