/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useContext } from "react";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { Tooltip } from "@mui/material";
import { deleteSingleVulnerability } from "../apiServices";
import { ConfirmationDialog } from "../../../shared_ui_components";
import { toastFlashMessage, getImage } from "../../../utils";
import { TVulnerabilityCategory } from "../../../types";
import { GlobalContext } from "../../../context/GlobalContext";

type IState = {
  id?: number;
  description?: string;
  name?: string;
  handleDeleteVulnerability?: (id: number) => void;
};

const VulnerablityInVulCategory: FC<IState> = ({ id, description, name, handleDeleteVulnerability }) => {
  const { dispatch } = useContext(GlobalContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const handleDeleteVulnerabilityDialog = () => {
    handleDeleteVulnerability?.(id as number);
    setOpenDeleteDialog(false);
  };

  return (
    <div className={styles.vulnerabilityList}>
      <p className={styles.listTitle + " paragraph_semiBold"}>
        {name && name.length >= 30 ? (
          <Tooltip title={name as string} arrow>
            <span> {name && name.length < 30 ? name : `${name?.substring(0, 30)}...`} </span>
          </Tooltip>
        ) : (
          name
        )}

        <span className="paragraph"> (CWE-307) </span>
      </p>
      <Tooltip title={description as string} arrow>
        <p className={styles.subText + " paragraph"}>
          {description && description.length < 50 ? description : `${description?.substring(0, 50)}...`}
        </p>
      </Tooltip>

      <img src={getImage("delete_icon_grey.svg")} alt=" " onClick={handleOpenDeleteDialog} />
      {openDeleteDialog && (
        <ConfirmationDialog
          text="Delete vulnerability"
          subText="Are you sure you want to Delete this vulnerability?"
          image="delete_icon.svg"
          cancelBtnText="Cancel"
          successBtnText="Delete"
          cancelBtnClass="outlinedBtn"
          successBtnClass="secondaryBtn"
          handleSuccess={handleDeleteVulnerabilityDialog}
          handleCancel={() => {
            handleOpenDeleteDialog();
          }}
        />
      )}
    </div>
  );
};

export default VulnerablityInVulCategory;
