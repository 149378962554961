import { FC, MouseEvent, ChangeEvent, useState, useContext, Fragment } from "react";
import { Grid } from "@mui/material";
import { Search } from "../../../shared_elements";
import { CustomButton, CustomMoreMenu, NoResult } from "../../../shared_ui_components";
import styles from "../../../assets/styles/vulnerability.module.scss";
import {
  VulnerabilityAccordianLists,
  AddVulnerabilityDialog,
  Shimmer,
  AddVulnerabilityCategoryDialog,
} from ".";
import { GlobalContext } from "../../../context/GlobalContext";
import { TVulnerabilityCategory } from "../../../types";
import { getImage } from "../../../utils";

type TSearchType = {
  val?: string;
  list?: TVulnerabilityCategory[];
};

const VulnerabilityDashboard: FC = () => {
  const {
    state: { vulneralibityLists, roleLists },
  } = useContext(GlobalContext);
  const rolePermission = roleLists?.roleData?.permission;

  const [search, setSearch] = useState<TSearchType>({
    val: "",
    list: [],
  });

  const [openAddVulnerability, setOpenAddVulnerability] = useState(false);
  const [openAddVulnerabilityCategory, setOpenAddVulnerabilityCategory] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddVulnerabilityDialog = () => {
    handleClose();
    setOpenAddVulnerability(!openAddVulnerability);
  };
  const handleAddVulnerabilityCategoryDialog = () => {
    handleClose();
    setOpenAddVulnerabilityCategory(!openAddVulnerabilityCategory);
  };

  const handleSearch = (val: string) => {
    let str = val.toLowerCase();

    let searchList = vulneralibityLists?.vulnerabilityData?.filter(
      (_vulnerability) =>
        _vulnerability?.name?.toLowerCase().includes(str) ||
        _vulnerability?.vulnerabilities?.some((ele) => ele?.name?.toLowerCase().includes(str))
    );
    setSearch(() => ({
      val: str,
      list: searchList,
    }));
  };

  const handleClearSearch = () => {
    setSearch(() => ({
      val: "",
      list: [],
    }));
  };

  return (
    <div className={styles.vulnerabilityDashboardWrapper}>
      <div className={styles.vulnerabilityTitleWrapper}>
        <h2 className="heading3">
          Vulnerability Categories<span>{vulneralibityLists?.vulnerabilityData?.length}</span>
        </h2>
        <div className={styles.vulnerabilitySearchAndViewWrapper}>
          {!vulneralibityLists?.loading && (
            <>
              {search.val ? (
                <span onClick={handleClearSearch} className={styles.clearSearch + " paragraph"}>
                  Clear search
                </span>
              ) : (
                ""
              )}
              <Search
                text={search.val as string}
                placeholder="Search for vulnerability"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
              />
              <div className={styles.vulnerabilityAddNewWrapper}>
                {rolePermission?.includes("ADD_VULNERABILITY") && (
                  <CustomButton className=" primaryBtn" children="Add New" type="button" />
                )}
                <img
                  className={styles.moreIcon}
                  aria-controls="simple-menu"
                  onClick={handleClick}
                  src={getImage("more_menu.svg")}
                  alt=" "
                />
                <CustomMoreMenu
                  open={open}
                  anchorEl={anchorEl}
                  arrow="withoutArrow"
                  hoverState="withHover"
                  corner="no"
                  handleClose={handleClose}
                  handleLogOut={handleAddVulnerabilityCategoryDialog}
                  handleClick1={handleAddVulnerabilityDialog}
                  noOfItems={2}
                  message1="Vulnerability"
                  message2="Vulnerability Category"
                />
                {openAddVulnerability && (
                  <AddVulnerabilityDialog
                    open={openAddVulnerability}
                    onClose={handleAddVulnerabilityDialog}
                  />
                )}
                {openAddVulnerabilityCategory && (
                  <AddVulnerabilityCategoryDialog
                    type="Add New Vulnerability Category"
                    open={openAddVulnerabilityCategory}
                    onClose={handleAddVulnerabilityCategoryDialog}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.vulnerabilityHeader}>
        <Grid container>
          <Grid item md={9}>
            <p className={styles.vulnerabilityText + " labelText"}>CATEGORY</p>
          </Grid>
          <Grid item md={2}>
            <p className={styles.vulnerabilityText2 + " labelText"}>OCCURENCES</p>
          </Grid>
          <Grid item md={1}>
            <p className={styles.vulnerabilityText2 + " labelText"}>ACTION</p>
          </Grid>
        </Grid>
      </div>
      <div>
        {vulneralibityLists?.showNoState ? (
          rolePermission?.includes("ADD_VULNERABILITY") ? (
            <NoResult image={getImage("no_file_found.svg")} title="No Vulnerabilities Found" />
          ) : (
            <NoResult image={getImage("no_file_found.svg")} title="No Vulnerabilities Found" />
          )
        ) : vulneralibityLists?.loading ? (
          <Shimmer type="vulnerabilityCategoryList" />
        ) : (
          <>
            {search?.val ? (
              <>
                {search?.list?.length === 0 ? (
                  <NoResult
                    image={getImage("no_file_found.svg")}
                    title="No Result Found"
                    subtext={<Fragment>We didn't find any vulnerabilities that matched your search</Fragment>}
                  />
                ) : (
                  search?.list?.map((_vulnerability: TVulnerabilityCategory) => {
                    return (
                      <Fragment key={_vulnerability.id}>
                        <VulnerabilityAccordianLists {..._vulnerability} handleSearch={handleSearch} />
                      </Fragment>
                    );
                  })
                )}
              </>
            ) : (
              vulneralibityLists?.vulnerabilityData?.map((_vulnerability: TVulnerabilityCategory) => {
                return (
                  <Fragment key={_vulnerability.id}>
                    <VulnerabilityAccordianLists {..._vulnerability} />
                  </Fragment>
                );
              })
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VulnerabilityDashboard;
