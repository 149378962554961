/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useEffect } from "react";
import { AuthLayout, SetPasswordForm } from "../components";
import styles from "../../../assets/styles/auth.module.scss";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../utils";

const SetPassword: FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);

  const updateStep = (value: number) => {
    setStep(value);
  };

  useEffect(() => {
    if (getUserInfo()?.user) {
      navigate("/projects");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <div className={styles.resetPasswordWrapper}>
        <div className={styles.ResetPasswordForm + " formWrapper"}>
          <h1 className={styles.formTitle + " heading3 textCenter"}>Update password</h1>
          <SetPasswordForm />
        </div>
      </div>
    </AuthLayout>
  );
};
export default SetPassword;
