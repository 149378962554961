import { FC, SyntheticEvent, useState, useContext } from "react";
import styles from "../../../assets/styles/home.module.scss";
import { Grid, Tooltip } from "@mui/material";
import { TUsers, TPermissions, TAnalysis } from "../../../types";
import { CustomLink } from "../../../shared_ui_components";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { GlobalContext } from "../../../context/GlobalContext";
import { getImage } from "../../../utils";

type IProps = {
  id?: number;
  status?: number;
  owner?: TUsers;
  due_date?: string;
  security_score?: number;
  engagement_type?: string;
  methodology?: number;
  projectId?: string;
  analysis?: TAnalysis[];
  requested_by?: string;
  report_password?: string | null;
  report_file?: string | null;
  permissions?: TPermissions;
  updated_at?: string;
  requested_users?: string;
  url?: string;
  scan_type?: number;
};

const ScanListItem: FC<IProps> = ({
  id,
  status,
  owner,
  due_date,
  security_score,
  projectId,
  permissions,
  report_password,
  report_file,
  analysis,
  updated_at,
  requested_users,
  url,
  scan_type,
}) => {
  const [toolTipOpen2, setToolTipOpen2] = useState(false);
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const roleId = roleLists?.roleData?.id;
  const handleDownloadReport = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(report_file as string);
  };

  const handleOpenLink = (e: SyntheticEvent) => {
    e.preventDefault();
    if (url?.includes("http")) {
      window.open(url);
    } else {
      window.open("https://" + url);
    }
  };

  const handleCopy2 = (e: SyntheticEvent, text?: string) => {
    e.stopPropagation();
    e.preventDefault();
    setToolTipOpen2(!toolTipOpen2);
    navigator.clipboard.writeText(text as string);
    setTimeout(() => {
      setToolTipOpen2(false);
    }, 1000);
  };

  return (
    <>
      <Link
        to={
          roleId === 5
            ? status === 3
              ? `/projects/${projectId}/scan-details/${id}`
              : "#"
            : `/projects/${projectId}/scan-details/${id}`
        }
      >
        <div className={styles.scanListItemWrapper}>
          <Grid container alignItems="center">
            <Grid item md={1} className={styles.scanStatusBlk}>
              <p className="paragraph">{id}</p>
            </Grid>

            {roleId === 5 ? (
              <>
                <Grid item md={2} className={styles.scanStatusBlk + " " + styles.scanScoreBlk}>
                  <p className={styles.scanScore + " labelText"}>
                    <span
                      className={
                        typeof security_score === "number"
                          ? security_score >= 0 && security_score <= 6
                            ? styles.scoreClolorCritical
                            : security_score >= 6.1 && security_score <= 8
                            ? styles.scoreClolorHigh
                            : security_score >= 8.1 && security_score <= 9.9
                            ? styles.scoreClolorMedium
                            : security_score === 10
                            ? styles.scoreClolorNone
                            : ""
                          : styles.scoreClolorNone
                      }
                    >
                      {security_score === 0 || security_score === null ? "0.0" : security_score}
                    </span>{" "}
                    <span className={styles.scoreNames}>
                      {typeof security_score === "number"
                        ? security_score >= 0 && security_score <= 6
                          ? "Highly Vulnerable"
                          : security_score >= 6.1 && security_score <= 8
                          ? "Vulnerable"
                          : security_score >= 8.1 && security_score <= 9.9
                          ? "Be Preventive"
                          : security_score === 10
                          ? "Secure"
                          : ""
                        : "Secure"}
                    </span>
                  </p>
                </Grid>
                <Grid item md={2} className={styles.scanStatusBlk}>
                  <p className={styles.moveLeft + " paragraph"}>
                    {scan_type === 1 ? "New Scan" : scan_type === 2 ? "Detailed Scan" : "Rescan"}
                  </p>
                </Grid>
                <Grid item md={2} className={styles.scanStatusBlk}>
                  <p className="paragraph">
                    {updated_at ? format(parseISO(updated_at), "dd/MM/yyyy hh:mm a") : "N/A"}
                  </p>
                </Grid>
                <Grid item md={2} className={styles.scanStatusBlk}>
                  <p className={styles.scanListStatus}>
                    <img
                      src={
                        status === 0
                          ? getImage("status_pending.svg")
                          : status === 1
                          ? getImage("status_progress.svg")
                          : status === 2
                          ? getImage("delete_user.svg")
                          : status === 3
                          ? getImage("status_success.svg")
                          : null
                      }
                      alt=""
                    />
                    <span
                      className={
                        status === 0
                          ? styles.pending
                          : status === 1
                          ? styles.progress
                          : status === 2
                          ? styles.discarded
                          : status === 3
                          ? styles.success
                          : ""
                      }
                    >
                      {status === 0
                        ? "Pending"
                        : status === 1
                        ? "In Progress"
                        : status === 2
                        ? "Discarded"
                        : status === 3
                        ? "Completed"
                        : ""}
                    </span>
                  </p>
                </Grid>
                <Grid item md={2} className={styles.scanReportBlk}>
                  {status === 3 && report_file ? (
                    <>
                      <img src={getImage("download_icon.svg")} onClick={handleDownloadReport} alt="" />
                      {report_password && (
                        <div className={styles.reportPassword + " supportText_semiBold"}>
                          <span className="paragraph_semiBold">{report_password}</span>
                          <Tooltip
                            title={toolTipOpen2 ? "password copied" : ""}
                            open={toolTipOpen2}
                            placement="top"
                          >
                            <div
                              className={styles.copyLink}
                              onClick={(e) =>
                                handleCopy2(e, report_password ? report_password : "No password link")
                              }
                            >
                              <img src={getImage("copy_link_icon.svg")} alt=" " />
                              copy
                            </div>
                          </Tooltip>
                        </div>
                      )}
                    </>
                  ) : (
                    <p className="paragraph">N/A</p>
                  )}
                </Grid>
                <Grid item md={1} className={styles.scanReportBlk}>
                  {status === 3 && <p className={styles.client_viewDetailLink_align}>View details</p>}
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={1.5} className={styles.scanStatusBlk}>
                  <p className="paragraph">
                    {scan_type === 1 ? "New Scan" : scan_type === 2 ? "Detailed Scan" : "Rescan"}
                  </p>
                </Grid>
                <Grid item md={2} className={styles.scanStatusBlk}>
                  {url ? (
                    url.length > 20 ? (
                      <Tooltip title={url} placement="top">
                        <p className="paragraph">
                          {url?.substring(0, 20)}...
                        </p>
                      </Tooltip>
                    ) : (
                      <p className="paragraph">
                        {url}
                      </p>
                    )
                  ) : (
                    <p className="paragraph">N/A</p>
                  )}
                </Grid>
                <Grid item md={1.5} className={styles.scanStatusBlk}>
                  <p className="paragraph">
                    {owner?.first_name
                      ? owner?.last_name
                        ? owner?.first_name?.concat(" " + owner?.last_name)
                        : owner?.first_name
                      : "N/A"}
                  </p>
                </Grid>

                <Grid item md={1.5} className={styles.scanStatusBlk}>
                  <p className="paragraph">{due_date?.split("-").reverse().join("/")}</p>
                </Grid>
                <Grid item md={2} className={styles.scanStatusBlk}>
                  <p className="paragraph paragraphOverflow">
                    {requested_users ? (
                      requested_users?.split(",")?.length > 1 ? (
                        <>
                          {requested_users?.split(",").slice(0, 1)}{" "}
                          <Tooltip
                            title={requested_users
                              ?.split(",")
                              ?.slice(1, requested_users?.split(",")?.length)
                              ?.join(", ")}
                            placement="top-start"
                          >
                            <span className={styles.moreButton}>
                              +{requested_users?.split(",")?.length - 1} more
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        requested_users
                      )
                    ) : (
                      "N/A"
                    )}
                  </p>
                </Grid>
                <Grid item md={1} className={styles.scanStatusBlk}>
                  <p className={styles.scanListStatus}>
                    <img
                      src={
                        status === 0
                          ? getImage("status_pending.svg")
                          : status === 1
                          ? getImage("status_progress.svg")
                          : status === 2
                          ? getImage("delete_user.svg")
                          : status === 3
                          ? getImage("status_success.svg")
                          : null
                      }
                      alt=""
                    />
                    <span
                      className={
                        status === 0
                          ? styles.pending
                          : status === 1
                          ? styles.progress
                          : status === 2
                          ? styles.discarded
                          : status === 3
                          ? styles.success
                          : styles.none
                      }
                    >
                      {status === 0
                        ? "Pending"
                        : status === 1
                        ? "In Progress"
                        : status === 2
                        ? "Discarded"
                        : status === 3
                        ? "Completed"
                        : "N/A"}
                    </span>
                  </p>
                </Grid>
                <Grid
                  item
                  md={1.5}
                  className={styles.scanStatusBlk}
                  style={{ display: "flex", justifyContent: "left", alignItems: "left" }}
                >
                  <p className={styles.viewDetailLink_align}>View details</p>
                 </Grid>
              </>
            )}
          </Grid>
        </div>
      </Link>
    </>
  );
};

export default ScanListItem;
