import { FC } from "react";
import MdEditor, { Plugins } from "react-markdown-editor-lite";
import { uploadEditorFile } from "../features/home/apiServices";
import { toastFlashMessage } from "../utils";
//@ts-ignore
import insert from 'markdown-it-ins';
import  'highlight.js/styles/github.css';


import "react-markdown-editor-lite/lib/index.css";

type TTextEditorProps = {
  handleEditor?: (description?: any, html?: any) => void;
  placeholder?: string;
  value?: string;
  scanId?: any;
};

const CustomEditor: FC<TTextEditorProps> = ({ handleEditor, value, placeholder, scanId }) => {
  const PLUGINS = undefined;
  let mdEditor: any = undefined;

  MdEditor.use(Plugins.TabInsert, {
    tabMapValue: 1, // note that 1 means a '\t' instead of ' '.
  });

  const handleEditorChange = (it: any, event: any) => {
    handleEditor?.(it.text, it.html);
  };

  const handleImageUpload = (file: File) => {
    return new Promise((resolve) => {
      const data = new FormData();
      data.append("file", file);
      data.append("scanId", scanId);
      uploadEditorFile(data).then((response) => {
        if (response.statusCode === 200) {
          var scanAttachments = JSON.parse(localStorage.getItem('attachments') as string);
          if (scanAttachments) {
            scanAttachments[response.data.uuid] = response.data.url;
            localStorage.setItem('attachments', JSON.stringify(scanAttachments));
          } else {
            localStorage.setItem('attachments', JSON.stringify(response.data.attachments));
          }
          resolve(response.data.uuid);
        } else {
          toastFlashMessage(response.message, "error");
        }
      });
    });
  };

 function replaceLink(link: string) {
    const validUUID = new RegExp(
      '^[a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}$'
    );

    if (validUUID.test(link.toLowerCase())) {
      var scanAttachments = JSON.parse(localStorage.getItem('attachments') as string);
      if (scanAttachments) {
        link = scanAttachments[link];
      }
    }
    return link;
  }

  var mdParser = require('markdown-it')({
    replaceLink: replaceLink
  }).use(require('markdown-it-highlightjs'), {inline: true})
    .use(require('markdown-it-replace-link'))
    .use(insert)
    

  return (
    <div className="demo-wrap">
      <div className="editor-wrap">
        <MdEditor
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ref={(node) => (mdEditor = node || undefined)}
          value={value ? value.replace(/\t/g, "\u00a0") : ""}
          style={{ height: "250px", width: "100%", whiteSpace: "nowrap" }}
          renderHTML={(text) => mdParser.render(text)}
          plugins={PLUGINS}
          placeholder={placeholder}
          config={{
            view: {
              menu: true,
              md: true,
              html: true,
              fullScreen: true,
              hideMenu: false,
            },
            table: {
              maxRow: 8,
              maxCol: 6,
            },
            syncScrollMode: ["leftFollowRight", "rightFollowLeft"],
          }}
          onChange={handleEditorChange}
          onImageUpload={handleImageUpload}
        />
      </div>
    </div>
  );
};

export default CustomEditor;
