import { FC, useState, useEffect } from "react";
import { AuthLayout, ResetPasswordText, ResetPasswordForm } from "../components";
import styles from "../../../assets/styles/auth.module.scss";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../utils";

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);

  const updateStep = (value: number) => {
    setStep(value);
  };

  useEffect(() => {
    if (getUserInfo()?.user) {
      navigate("/projects");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <div className={styles.resetPasswordWrapper}>
        <div className={styles.ResetPasswordForm + " formWrapper"}>
          <h1 className={styles.formTitle + " heading3 textCenter"}>
            {step === 1 ? "Password reset" : "Reset password"}
          </h1>
          {step === 1 ? <ResetPasswordText updateStep={updateStep} /> : <ResetPasswordForm />}
        </div>
      </div>
    </AuthLayout>
  );
};
export default ResetPassword;
