import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { Grid } from "@mui/material";
import { TProjectResponse } from "../../../types";
import { format, parseISO } from "date-fns";
import { GlobalContext } from "../../../context/GlobalContext";
import { getImage } from "../../../utils";

const ProjectGridCard: FC<TProjectResponse> = ({
  uuid,
  name,
  url,
  client,
  status,
  search,
  last_scan,
  researcher,
  completed_scans,
}) => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const roleId = roleLists?.roleData?.id;

  return (
    <>
      <Link
        to={
          status === 2
            ? `/projects`
            : search
            ? { pathname: `/projects/${uuid}`, search: `?search=${name}` }
            : `/projects/${uuid}`
        }
      >
        <div
          className={
            status === 2
              ? styles.disableRow + " " + styles.projectGridCardContainer
              : styles.projectGridCardContainer
          }
        >
          <div className={styles.projectOrgWrapper}>
            <Grid container alignItems="flex-start">
              <Grid item md={2}>
                <img src={client?.logo ? client?.logo : getImage("profile_placeholder.svg")} alt="logo" />
              </Grid>
              <Grid item md={7}>
                <div className={styles.projectOrgDetails}>
                  <p className={styles.orgName + " paragraph_semiBold"}>{name}</p>
                </div>
              </Grid>
              <Grid item md={3}>
                <p className={styles.scanId + " labelText_semiBold"}>
                  {roleId !== 5 && (
                    <>
                      Scan ID:{" "}
                      <span className={styles.scanIdNum}>
                        {" "}
                        {last_scan?.id
                            ? last_scan?.id
                            : localStorage.getItem("view-Project-status") &&
                              JSON.parse(localStorage.getItem("view-Project-status") || "") ===
                                "status=completed"
                            ? last_scan?.id
                            : "N/A"
                        }
                      </span>
                    </>
                  )}
                </p>
              </Grid>
            </Grid>
          </div>
          <div className={styles.scanDetailsWrapper}>
            <p className={styles.scanFileText + " labelText_semiBold"}>
              {roleId === 5 && (
                <>
                  TOTAL SCANS COMPLETED :{" "}
                  <span className={styles.scanFileNum}> {completed_scans ? completed_scans : "N/A"}</span>
                </>
              )}
            </p>
          </div>
          <div className={styles.scanDetailsWrapper}>
            <p className={styles.scanFileText + " labelText_semiBold"}>
              {roleId === 5 ? (
                <>
                  TOTAL ISSUES IN LAST SCAN :{" "}
                  <span className={styles.scanFileNum}>
                    {last_scan?.analysis ? last_scan?.analysis.length : "N/A"}
                  </span>
                </>
              ) : (
                <>
                  SECURITY RESEARCHER:{" "}
                  <span className={styles.scanFileNum}>
                    {researcher
                      ? researcher.last_name
                        ? researcher.first_name?.concat(" " + researcher.last_name)
                        : researcher.first_name
                      : "N/A"}
                  </span>
                </>
              )}
            </p>
          </div>
          <div className={styles.scanDetailsWrapper}>
            <p className={styles.scanFileText + " labelText_semiBold"}>
              <>
                CREATED AT :{" "}
                <span className={styles.scanFileNum}>
                  {last_scan?.created_at ? format(parseISO(last_scan?.created_at), "dd/MM/yyyy") : "N/A"}
                </span>
              </>
            </p>
          </div>
          <div className={styles.scanDetailsWrapper}>
            <p className={styles.scanFileText + " labelText_semiBold"}>
              {roleId === 5 ? (
                <>
                  LAST SCAN:{" "}
                  <span className={styles.scanFileNum}>
                    {last_scan?.updated_at
                      ? format(parseISO(last_scan?.updated_at), "dd/MM/yyyy hh:mm a")
                      : "N/A"}
                  </span>
                </>
              ) : (
                <>
                  {" "}
                  DUE DATE:{" "}
                  <span className={styles.scanFileNum}>
                    {last_scan?.due_date ? last_scan?.due_date?.split("-").reverse().join("/") : "N/A"}
                  </span>
                </>
              )}
            </p>
          </div>

          <div className={styles.scanWrapper}>
            <p className={styles.scanStatusLabel + " labelText_semiBold"}> SCAN STATUS: </p>
            <div className={styles.scanStatusWrapper}>
              <img
                src={
                  last_scan === null || last_scan?.status === 0
                    ? getImage("status_pending.svg")
                    : last_scan?.status === 1
                    ? getImage("status_progress.svg")
                    : last_scan?.status === 2
                    ? getImage("delete_user.svg")
                    : last_scan?.status === 3
                    ? getImage("status_success.svg")
                    : null
                }
                alt=""
              />
              <p className={styles.scanStatusText + " paragraph_semiBold"}>
                {last_scan === null || last_scan?.status === 0
                  ? "Pending"
                  : last_scan?.status === 1
                  ? "In Progress"
                  : last_scan?.status === 2
                  ? "Discarded"
                  : last_scan?.status === 3
                  ? "Completed"
                  : null}
              </p>
              {last_scan !== null && last_scan?.status !== 0 && (
                <div className={styles.scanResultWrapper}>
                  <span
                    className={
                      styles.scoreColor +
                      " " +
                      (last_scan?.security_score
                        ? last_scan?.security_score >= 0 &&
                        last_scan?.security_score <= 6
                        ? styles.scoreClolorCritical
                        : last_scan?.security_score >= 6.1 &&
                        last_scan?.security_score <= 8
                        ? styles.scoreClolorHigh
                        : last_scan?.security_score >= 8.1 &&
                        last_scan?.security_score <= 9.9
                        ? styles.scoreClolorMedium
                        : last_scan?.security_score === 10
                        ? styles.scoreClolorNone
                        : ""
                      : styles.scoreClolorNone) +
                      " heading4"
                    }
                  >
                    {last_scan === null
                      ? "0.00"
                      : last_scan?.security_score === null
                      ? "0.00"
                      : last_scan?.security_score}
                  </span>{" "}
                  <p className={styles.scoreNames}>
                  {typeof last_scan?.security_score === "number"
                      ? last_scan?.security_score >= 0 &&
                      last_scan?.security_score <= 6
                        ? "Highly Vulnerable"
                        : last_scan?.security_score >= 6.1 &&
                        last_scan?.security_score <= 8
                        ? "Vulnerable"
                        : last_scan?.security_score >= 8.1 &&
                        last_scan?.security_score <= 9.9
                        ? "Be Preventive"
                        : last_scan?.security_score === 10
                        ? "Secure"
                        : ""
                      : last_scan !== null && last_scan?.status !== 0 && "Secure"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProjectGridCard;
