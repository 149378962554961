import { globalPostService, globalGetService, globalDeleteService } from "../../utils/GlobalApiServices";
import { IStateVulCategory } from "./components/AddVulnerabilityCategoryDialog";
import { IStateVulnerability } from "./components/AddVulnerabilityDialog";

export function getAllVulnerabilityCategory(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`vulnerability-categories/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllVulnerability(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`vulnerabilities/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addVulnerabilityCategory(query: IStateVulCategory): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`vulnerability-category/create/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addVulnerability(query: IStateVulnerability): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`vulnerability/create/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleVulnerabilityCategory(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`vulnerability-category/${params}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleVulnerability(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`vulnerability/${params}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateVulnerabilityCategory(params: string, query: IStateVulCategory): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`vulnerability-category/${params}/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateVulnerability(params: string, query: IStateVulCategory): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`vulnerability/${params}/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getCWEsList(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`cwes/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getGdprList(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`gdprs/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteSingleVulnerability(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalDeleteService(`vulnerability/${params}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteVulnerabilityCategory(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalDeleteService(`vulnerability-category/${params}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getVulnerabilityCategoryInstances(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`vulnerability-category/${params}/instances/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getVulnerabilityInstances(params: string, id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`vulnerability-category/${params}/instances/?vulnerability=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
