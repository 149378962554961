import { FC, SyntheticEvent, useState, useContext, useEffect } from "react";
import { Dialog, Grid } from "@mui/material";
import styles from "../../../assets/styles/home.module.scss";
import { errorCodes } from "../../../constants";
import { CustomButton, CustomInput } from "../../../shared_ui_components";
import { fieldValidation } from "../../../utils/formValidations";
import { toastFlashMessage, getImage } from "../../../utils";
import { Errors, TUsers } from "../../../types";
import { inviteNewUser, updateUser } from "../../users/apiServices";
import { GlobalContext } from "../../../context/GlobalContext";
import { USER_ACTIONS } from "../../../context/UserReducer";

type TAddUpdateUserProps = {
  open: boolean;
  onClose: (value: string) => void;
  type: string;
  userFirstName?: string;
  userEmail?: string;
  clientId?: string;
  userId?: string;
  handleAddClientUsers?: (val: TUsers) => void;
  handleUpdateClientUsers?: (val: TUsers) => void;
  handleSearch?: (p: string) => void;
};

type IFormState = {
  first_name: string;
  email: string;
  role: string;
  client: string;
};

const AddUpdateUserDialog: FC<TAddUpdateUserProps> = ({
  type,
  open,
  onClose,
  userFirstName,
  userEmail,
  clientId,
  userId,
  handleAddClientUsers,
  handleUpdateClientUsers,
  handleSearch,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [formData, setFormData] = useState<IFormState>({
    first_name: "",
    email: "",
    role: "5",
    client: "",
  });
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);

  /* Up[dating User Details*/
  useEffect(() => {
    setFormData((form) => ({
      ...form,
      client: clientId ? clientId : "",
      first_name: userFirstName ? userFirstName : "",
      email: userEmail ? userEmail : "",
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const handleChange = (key: string, val: string) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const resetFormData = () => {
    setFormData({
      ...formData,
      first_name: "",
      email: "",
    });
  };

  const handleCancel = () => {
    onClose("false");
    resetFormData();
    setError({});
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateNewInput: {
      [key: string]: string;
    } = {
      first_name:
        errorCode["user_name"][
          fieldValidation({
            ...errorCode["user_name_Obj"],
            fieldval: formData.first_name,
          })
        ],
      email:
        errorCodes["email"][
          fieldValidation({
            ...errorCode["emailObj"],
            fieldval: formData.email,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      if (type === "Invite New Users") {
        let form_data_client: FormData = new FormData();
        form_data_client.append("first_name", formData.first_name);
        form_data_client.append("email", formData.email);
        form_data_client.append("role", "5");
        form_data_client.append("client", clientId as string);
        inviteNewUser(form_data_client).then(
          (response: Partial<{ statusCode: number; data: TUsers; message: string }>) => {
            if (response.statusCode === 200) {
              dispatch({
                type: USER_ACTIONS.Add,
                payload: response.data,
              });
              setLoader(false);
              toastFlashMessage(response.message as string, "success");
              handleAddClientUsers?.(response.data as TUsers);
              handleSearch?.("");
              handleCancel();
            } else {
              setLoader(false);
              toastFlashMessage(response.message as string, "error");
            }
          }
        );
      } else {
        updateUser(formData, userId as string).then(
          (response: Partial<{ statusCode: number; data: TUsers; message: string }>) => {
            if (response.statusCode === 200) {
              dispatch({
                type: USER_ACTIONS.Update,
                payload: response.data,
              });
              setLoader(false);
              toastFlashMessage(response.message as string, "success");
              handleUpdateClientUsers?.(response.data as TUsers);
              handleSearch?.("");
              handleCancel();
            } else {
              setLoader(false);
              toastFlashMessage(response.message as string, "error");
            }
          }
        );
      }
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} sx={{ borderRadius: "4px" }}>
      <div className={styles.dialogWrapper}>
        <div className={styles.headerTitle}>
          <p className="heading5">{type}</p>
          <img src={getImage("close_icon.svg")} alt="close" onClick={handleCancel} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.formDetail}>
            <Grid container spacing={2}>
              <Grid item md={12} sx={{ display: "flex" }}>
                <img
                  style={{ objectFit: "cover", borderRadius: "4px" }}
                  src={getImage("user_profile.svg")}
                  alt="user"
                />
                <div style={{ marginLeft: "16px", width: "100%" }}>
                  <CustomInput
                    label="User Name *"
                    className={styles.inputBlk}
                    placeholder="eg, John Doe"
                    noMargin="noMargin"
                    value={formData.first_name}
                    onChange={(e) => handleChange("first_name", e.target.value)}
                    error={error.first_name}
                  />
                </div>
              </Grid>
              <Grid item md={12}>
                <CustomInput
                  label="Email ID *"
                  className={styles.inputBlk}
                  placeholder="abc@xyz.com"
                  noMargin="noMargin"
                  value={formData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  error={error.email}
                />
              </Grid>
            </Grid>
          </div>
          <div className={styles.buttonWrapper} style={{ borderTop: "1px solid #E6E6E7" }}>
            <CustomButton className="outlinedBtn" children="Cancel" type="button" onClick={handleCancel} />
            <CustomButton
              className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
              children={type === "Invite New User" ? "Invite" : "Update"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddUpdateUserDialog;
