import { FC, SyntheticEvent, FocusEventHandler } from "react";
import {
  Chip,
  Autocomplete,
  TextField,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@mui/material";
import { T } from "../types";
import { getImage } from "../utils";

type TMutipleSelecProps = {
  className?: string;
  onChange?:
    | ((
        event: SyntheticEvent<Element, Event>,
        value: (string | T)[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any> | undefined,
        key?: string
      ) => void)
    | undefined;
  options: ReadonlyArray<T> | [];
  placeholder?: string;
  error?: string;
  noMargin?: string;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  value?: (string | T)[];
  handleValue?: (p: any) => void;
  inputText?: string;
  handleChangeText?: (e: SyntheticEvent) => void;
  disabled?: boolean;
};

const CustomMutipleSelect: FC<TMutipleSelecProps> = ({
  onChange,
  options,
  placeholder,
  error,
  onFocus,
  onBlur,
  value,
  handleValue,
  inputText,
  handleChangeText,
  disabled,
}) => {
  return (
    <>
      <Autocomplete
        multiple
        id="tags-outlined"
        onChange={onChange}
        options={options}
        autoSelect
        value={value}
        className="multipleSelect"
        freeSolo
        filterSelectedOptions
        renderTags={(value: (string | T)[], getTagProps) =>
          value.map((option: string | T, index: number) => (
            <Chip
              variant="outlined"
              deleteIcon={<img src={getImage("delete_blue_icon.svg")} alt=" " />}
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        disabled={disabled}
        inputValue={inputText}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={placeholder}
              error={error ? true : false}
              onFocus={onFocus}
              onBlur={onBlur}
              style={{ backgroundColor: error ? "#ffecee" : "" }}
              InputProps={{
                ...params.InputProps,
                onKeyDown: handleValue,
              }}
              onChange={handleChangeText}
            />
          );
        }}
      />
      {error && <h6 className="errorMsg absolute">{error}</h6>}
    </>
  );
};

export default CustomMutipleSelect;
