import { FC } from "react";
import { Container } from "@mui/material";
import { useNavigate, Navigate } from "react-router-dom";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { UpdateVulnerabilityForm } from "../components";
import { getImage } from "../../../utils";

type TRole = {
  role: number;
};

const UpdateVulnerabilities: FC<TRole> = (props) => {
  const navigate = useNavigate();
  const role = props?.role;

  return (
    <>
      {role === 1 || role === 3 || role === 4 ? (
        <Container maxWidth="lg">
          <div className={styles.updateVulnerabilityWrapper}>
            <div className={styles.title}>
              <div className={styles.backLink + " supportText_semiBold"} onClick={() => navigate(-1)}>
                <img src={getImage("arrow_down.svg")} alt="" />
                Back to vulnerabilities
              </div>
              <p className="heading3">Update Vulnerability</p>
            </div>
            <UpdateVulnerabilityForm />
          </div>
        </Container>
      ) : (
        <Navigate to="/projects" />
      )}
    </>
  );
};

export default UpdateVulnerabilities;
