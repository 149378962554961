import { FC } from "react";
import { Container } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { UpdateVulnerabilityCategoryForm } from "../components";
import { getImage } from "../../../utils";

type TRole = {
  role: number;
};

const UpdateVulnerabilityCategory: FC<TRole> = (props) => {
  const role = props?.role;
  return (
    <>
      {role === 1 || role === 3 || role === 4 ? (
        <Container maxWidth="lg">
          <div className={styles.updateVulnerabilityWrapper}>
            <div className={styles.title}>
              <Link className={styles.backLink + " supportText_semiBold"} to="/vulnerabilities">
                <img src={getImage("arrow_down.svg")} alt=" " />
                Back to vulnerabilities
              </Link>
              <p className="heading3">Update Vulnerability Category</p>
            </div>
            <UpdateVulnerabilityCategoryForm />
          </div>
        </Container>
      ) : (
        <Navigate to="/projects" />
      )}
    </>
  );
};

export default UpdateVulnerabilityCategory;
