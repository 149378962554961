/* eslint-disable no-useless-concat */
import { FC, SyntheticEvent, useContext, useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Errors, TVulnerabilityResponse, TAutoComplete, TCwe, TGdpr } from "../../../types";
import { CustomButton, CustomInput, CustomMultipleSelectDropdown, CustomSelect } from "../../../shared_ui_components";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { errorCodes } from "../../../constants";
import { getSingleVulnerability, updateVulnerability, getCWEsList, getGdprList } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidations";
import { GlobalContext } from "../../../context/GlobalContext";
import { VULNERABILITY_ACTIONS } from "../../../context/VulnerabilityReducer";
import { Shimmer } from ".";

type IStateVulnerability = {
  name?: string;
  description?: string;
  category?: string | null | TAutoComplete;
  cwe?: any;
  gdpr?: any;
};

const UpdateVulnerabilityForm: FC = () => {
  const navigate = useNavigate();
  const {
    dispatch,
    state: { vulneralibityLists },
  } = useContext(GlobalContext);
  const { id } = useParams();
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);
  const [cweOptions, setCweOptions] = useState([]);
  const [gdprOptions, setGdprOptions] = useState([]);
  const [formData, setFormData] = useState<IStateVulnerability>({
    name: "",
    description: "",
    category: "",
    cwe: [],
    gdpr: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getSingleVulnerability(`${id}`).then(
        (response: Partial<{ statusCode: number; data: TVulnerabilityResponse; message: string }>) => {
          if (response.statusCode === 200) {
            const vulnerabilityDetails = response?.data;
            setFormData((form) => ({
              ...form,
              name: vulnerabilityDetails?.name,
              description: vulnerabilityDetails?.description,
              category: {
                label: vulnerabilityDetails?.category?.name ? vulnerabilityDetails?.category?.name : "",
                value: vulnerabilityDetails?.category?.id ? vulnerabilityDetails?.category?.id : "",
              } as string | null | TAutoComplete,
              cwe: vulnerabilityDetails?.cwe?.map((item: { name: string }) => item.name),
              gdpr: vulnerabilityDetails?.gdpr?.map((item: { name: string }) => item.name)
            }));
            setLoading(false);
          } else {
            setLoading(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
      getCWEsList().then((response: Partial<{ statusCode: number; data: any; message: string }>) => {
        if (response.statusCode === 200) {
          if (response.data) {
            const data = response?.data?.map((item: { name: string; id: number; description: string }) => {
              return { id: item?.id, name: item?.name, description: item?.description };
            });
    
            setCweOptions(data || []);
          }
        } else {
          toastFlashMessage(response.message as string, "error");
        }
      });
      getGdprList().then((response: Partial<{ statusCode: number; data: any; message: string }>) => {
        if (response.statusCode === 200) {
          if (response.data) {
            const data = response?.data?.map((item: {name: string, id: number}) => {
              return {id: item?.id, name: item?.name};
            });
            setGdprOptions(data || []);
          }
        } else {
          toastFlashMessage(response.message as string, "error");
        }
      });
    }
  }, [id]);

  const cweId = useMemo(() => {
    const data = cweOptions
      ?.filter((user: TCwe) => formData.cwe?.includes(user?.name))
      .map((ele: TCwe) => ele.id);
    return data;
  }, [formData.cwe]);

  const gdprId = useMemo(() => {
    const data = gdprOptions
      ?.filter((user: TGdpr) => formData.gdpr?.includes(user?.name))
      .map((ele: TGdpr) => ele.id);
    return data;
  }, [formData.gdpr]);

  const handleChange = (key: string, val: string | TAutoComplete) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const handleUpdateVulnerability = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateNewInput: {
      [key: string]: string;
    } = {
      name: errorCode["title"][
        fieldValidation({
          ...errorCode["title_Obj"],
          fieldval: formData.name,
        })
      ],
      category:
        errorCode["category_name"][
          fieldValidation({
            ...errorCode["category_name_Obj"],
            fieldval: formData.category,
          })
        ],
      cwe: errorCode["cwe"][
        fieldValidation({
          ...errorCode["cwe_Obj"],
          fieldval: formData.cwe,
        })
      ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      const form_data = {
        name: formData.name,
        description: formData.description,
        category: formData?.category && (formData?.category as HTMLInputElement)["value"],
        cwe: cweId,
        gdpr: gdprId
      };
      updateVulnerability(`${id}`, form_data).then(
        (response: Partial<{ statusCode: number; data: TVulnerabilityResponse; message: string }>) => {
          if (response.statusCode === 200) {
            dispatch({
              type: VULNERABILITY_ACTIONS.UpdateVulnerability,
              payload: response.data,
            });
            setLoader(false);
            toastFlashMessage(response.message as string, "success");
            navigate("/vulnerabilities");
          } else {
            setLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <>
      {loading ? (
        <Shimmer type="editVulnerability" />
      ) : (
        <div className={styles.formDetail}>
          <form onSubmit={handleUpdateVulnerability}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <CustomInput
                  label="Title *"
                  className={styles.inputBlk}
                  placeholder="Brute force"
                  noMargin="noMargin"
                  value={formData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  error={error.name}
                />
              </Grid>
              <Grid item md={12}>
                <p
                  className={
                    `${error.category ? styles.errorFormTitle : styles.formTitle}` + " supportText_semiBold"
                  }
                >
                  Vulnerability Category *
                </p>
                <CustomSelect
                  placeholder="Select Category *"
                  className={styles.inputBlk}
                  options={
                    vulneralibityLists?.vulnerabilityData?.map((details) => {
                      return { label: details?.name, value: details?.id };
                    }) as TAutoComplete[]
                  }
                  value={formData.category as HTMLInputElement}
                  onChange={(event: SyntheticEvent, newValue: string | TAutoComplete | null) => {
                    handleChange("category", newValue as string | TAutoComplete);
                  }}
                  noMargin="noMargin"
                  error={error.category}
                />
              </Grid>
              <Grid item md={12} sx={{ marginBottom: "8px" }}>
                <p
                  className={error.cwe ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"}
                >
                  CWE *
                </p>
                <CustomMultipleSelectDropdown
                  options={cweOptions ? cweOptions : []}
                  onChange={(event: SyntheticEvent, value) => {
                    handleChange("cwe", value);
                  }}
                  placeholder="Add CWE"
                  value={formData.cwe}
                  error={error.cwe}
                />
              </Grid>
              <Grid item md={12} sx={{ marginBottom: "8px" }}>
                <p
                  className={
                    error.gdpr ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                  }
                >
                  GDPR *
                </p>
                <CustomMultipleSelectDropdown
                  options={gdprOptions ? gdprOptions : []}
                  onChange={(event: SyntheticEvent, value) => {
                    handleChange("gdpr", value);
                  }}
                  placeholder="Add GDPR"
                  value={formData.gdpr}
                  error={error.gdpr}
                />
              </Grid>
              <Grid item md={12}>
                <CustomInput
                  label="Description"
                  className={styles.inputBlk}
                  multiline
                  placeholder="Description for the vulnerability"
                  noMargin="noMargin"
                  value={formData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  error={error.description}
                />
              </Grid>
            </Grid>
            <div className={styles.buttonWrapper}>
              <CustomButton
                className="outlinedBtn"
                children="Cancel"
                type="button"
                onClick={() => navigate("/vulnerabilities")}
              />
              <CustomButton
                className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
                children={`${loader ? "Updating..." : "Update"}`}
                type="submit"
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default UpdateVulnerabilityForm;
