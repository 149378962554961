import { ActionMap } from "../types";
import { TRoleState } from "./GlobalContext";

export enum ROLE_ACTIONS {
  Get = "GET_All_ROLES",
}

type RolePayload = {
  [ROLE_ACTIONS.Get]: undefined;
};

export type RoleActions = ActionMap<RolePayload>[keyof ActionMap<RolePayload>];

export const RoleReducers = (state: TRoleState, action: RoleActions | any) => {
  switch (action.type) {
    case ROLE_ACTIONS.Get:
      return {
        ...state,
        roleData: action.payload,
      };
    default:
      return state;
  }
};
