import { FC } from "react";
import { Popover, Typography, Box } from "@mui/material";
import { getImage } from "../utils";

type TCustomMoreMenu = {
  anchorEl: null | HTMLElement;
  open: boolean;
  arrow?: string;
  hoverState?: string;
  corner?: string;
  handleClose: (e?: any) => void;
  handleLogOut?: (e: any) => void;
  handleClick1?: () => void;
  handleClick2?: () => void;
  noOfItems?: number;
  status?: number;
  message1?: string;
  message2?: string;
  message3?: string;
  image1?: string;
  image2?: string;
  image3?: string;
};

const CustomMoreMenu: FC<TCustomMoreMenu> = ({
  anchorEl,
  open,
  arrow,
  hoverState,
  corner,
  handleClose,
  handleLogOut,
  handleClick1,
  handleClick2,
  noOfItems,
  status,
  message1,
  message2,
  message3,
  image1,
  image2,
  image3,
}) => {
  return (
    <>
      <Popover
        id="simple-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: "3px",
          },
        }}
      >
        {arrow !== "withoutArrow" ? (
          <Box
            sx={{
              position: "relative",
              mt: "10px",
              "&::before": {
                backgroundColor: "white",
                content: '""',
                display: "block",
                position: "absolute",
                width: 12,
                height: 12,
                top: -6,
                transform: "rotate(45deg)",
                right: "10px",
                borderTop: "1px solid #E6E6E7",
                borderLeft: "1px solid #E6E6E7",
                zIndex: 2,
                borderRadius: "3px",
              },
            }}
          />
        ) : (
          <Box
            sx={{
              position: "relative",
              mt: "10px",
              visibility: "hidden",
            }}
          />
        )}
        {noOfItems === 1 ? (
          <Typography
            sx={{
              padding: "12px 16px",
              backgroundColor: "white",
              border: "1px solid #E6E6E7",
              zIndex: 1,
              borderRadius: "6px",
              display: "flex",
              gap: "8px",
              color: "#808388",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#FAFAFA",
              },
            }}
            onClick={() => {
              if (handleClick1) handleClick1();
              handleClose();
            }}
          >
            {image1 && <img src={getImage(`${image1}`)} alt=" " />}
            {message1}
          </Typography>
        ) : noOfItems === 2 ? (
          <>
            <Typography
              sx={{
                padding: "12px 16px",
                backgroundColor: "white",
                border: "1px solid #E6E6E7",
                zIndex: 1,
                borderTopLeftRadius: `${corner === "no" ? null : "6px"}`,
                borderTopRightRadius: `${corner === "no" ? null : "6px"}`,
                borderBottom: "none",
                display: "flex",
                gap: "8px",
                color: "#808388",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#FAFAFA",
                  color: `${hoverState === "withHover" ? "#3F74EC" : null}`,
                },
              }}
              onClick={() => {
                if (handleClick1) handleClick1();
                handleClose();
              }}
            >
              {image1 && <img src={getImage(`${image1}`)} alt=" " />}
              {message1}
            </Typography>
            <Typography
              sx={{
                padding: "12px 16px",
                backgroundColor: "white",
                border: "1px solid #E6E6E7",
                zIndex: 1,
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
                borderTop: "none",
                display: "flex",
                gap: "8px",
                color: `${status === 1 ? "#808388" : hoverState === "withHover" ? "#808388" : "#CF2116"}`,
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#FAFAFA",
                  color: `${hoverState === "withHover" ? "#3F74EC" : null}`,
                },
              }}
              onClick={(e) => {
                if (status === 1) {
                  if (handleClick2) handleClick2();
                } else {
                  if (handleLogOut) handleLogOut(e);
                }
                handleClose();
              }}
            >
              {image2 && <img src={getImage(`${image2}`)} alt=" " />}
              {message2}
            </Typography>
          </>
        ) : noOfItems === 3 ? (
          <>
            <Typography
              sx={{
                padding: "12px 16px",
                backgroundColor: "white",
                border: "1px solid #E6E6E7",
                zIndex: 1,
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
                borderBottom: "none",
                display: "flex",
                gap: "8px",
                color: "#808388",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#FAFAFA",
                },
              }}
              onClick={(e) => {
                if (handleClick1) handleClick1();
                handleClose(e);
              }}
            >
              {image1 && <img src={getImage(`${image1}`)} alt=" " />}
              {message1}
            </Typography>
            <Typography
              sx={{
                padding: "12px 16px",
                backgroundColor: "white",
                border: "1px solid #E6E6E7",
                zIndex: 1,
                borderBottom: "none",
                borderTop: "none",
                display: "flex",
                gap: "8px",
                color: "#808388",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#FAFAFA",
                },
              }}
              onClick={(e) => {
                if (handleClick2) handleClick2();
                handleClose(e);
              }}
            >
              {image2 && <img src={getImage(`${image2}`)} alt=" " />}
              {message2}
            </Typography>
            <Typography
              sx={{
                padding: "12px 16px",
                backgroundColor: "white",
                border: "1px solid #E6E6E7",
                zIndex: 1,
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
                borderTop: "none",
                display: "flex",
                gap: "8px",
                color: "#CF2116",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#FAFAFA",
                },
              }}
              onClick={(e) => {
                handleClose(e);
                if (handleLogOut) handleLogOut(true);
              }}
            >
              {image3 && <img src={getImage(`${image3}`)} alt=" " />}
              {message3}
            </Typography>
          </>
        ) : null}
      </Popover>
    </>
  );
};

export default CustomMoreMenu;
