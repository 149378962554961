import { FC } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { AddVulnerabilityForm } from "../components";
import { getImage } from "../../../utils";

const AddVulnerability: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container maxWidth="lg">
        <div className={styles.addVulnerabilityPageWrapper}>
          <div className={styles.editScanTitle}>
            <div className={styles.backLink + " supportText_semiBold"} onClick={() => navigate(-1)}>
              <img src={getImage("arrow_down.svg")} alt="" />
              Back to details
            </div>
            <p className="heading3">Add Findings</p>
          </div>
          <AddVulnerabilityForm />
        </div>
      </Container>
    </>
  );
};
export default AddVulnerability;
