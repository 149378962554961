import { FC, useState, useContext, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, Grid } from "@mui/material";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { ConfirmationDialog, CustomMoreMenu } from "../../../shared_ui_components";
import { VulnerabilityDetails } from ".";
import { TVulnerability } from "../../../types";
import { toastFlashMessage, getImage } from "../../../utils";
import { deleteVulnerabilityCategory } from "../apiServices";
import { GlobalContext } from "../../../context/GlobalContext";
import { VULNERABILITY_ACTIONS } from "../../../context/VulnerabilityReducer";

type IProps = {
  handleSearch?: (p: string) => void;
  id?: number;
  name?: string;
  description?: string;
  vulnerabilities?: TVulnerability[];
};

const VulnerabilityAccordianLists: FC<IProps> = ({ id, name, description, vulnerabilities }) => {
  const navigate = useNavigate();
  const {
    state: { roleLists },
    dispatch,
  } = useContext(GlobalContext);
  const rolePermission = roleLists?.roleData?.permission;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleUpdateVulnerability = () => {
    navigate(`/vulnerability-category/${id}/update`);
  };

  const handleVulCategoryInstance = () => {
    navigate(`/vulnerability-category/${id}/occurences`);
  };

  const handleDeleteVulnerabilityCategory = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const handleDeleteVulCategory = () => {
    deleteVulnerabilityCategory(`${id}`).then(
      (response: Partial<{ statusCode: number; message: string }>) => {
        if (response.statusCode === 200) {
          dispatch({
            type: VULNERABILITY_ACTIONS.DeleteCategory,
            payload: { categoryId: id },
          });
          toastFlashMessage(response.message as string, "success");
          handleDeleteVulnerabilityCategory();
        } else {
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };

  const totalInstances = (): number => {
    let sum = 0;
    vulnerabilities?.forEach((count) => {
      if (count?.instances_count) {
        sum += count?.instances_count;
      } else {
      }
    });
    return sum;
  };

  return (
    <div className={styles.accordionWrapper}>
      <Accordion
        className={styles.accordionListItem}
      >
        <AccordionSummary
          expandIcon={
            vulnerabilities && vulnerabilities?.length > 0 ? (
              <img src={getImage("arrow_down.svg")} alt=" " />
            ) : (
              <img src={getImage("arrow_down_white.svg")} alt=" " />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems="center">
            <Grid item md={9} className={styles.accordionHeader}>
              <h3 className={styles.headerTitle + " heading6"}>{name}</h3>
              <p className={styles.headerText + " paragraph"}>{description}</p>
            </Grid>
            <Grid item md={2}>
              <p className="paragraph_semiBold">{totalInstances()}</p>
            </Grid>
            <Grid item md={1}>
              {rolePermission?.includes("EDIT_VULNERABILITY") ? (
                <>
                  <img
                    className={styles.moreIcon}
                    aria-controls="simple-menu"
                    onClick={handleClick}
                    src={getImage("more_menu.svg")}
                    alt=" "
                  />
                  {totalInstances() === 0 ? (
                    <CustomMoreMenu
                      open={open}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      handleLogOut={handleDeleteVulnerabilityCategory}
                      handleClick1={handleUpdateVulnerability}
                      noOfItems={2}
                      message1="Update vulnerability category"
                      message2="Delete vulnerability category"
                      image1="edit_gray.svg"
                      image2="delete_red.svg"
                    />
                  ) : (
                    <CustomMoreMenu
                      open={open}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      handleLogOut={handleDeleteVulnerabilityCategory}
                      handleClick1={handleUpdateVulnerability}
                      handleClick2={handleVulCategoryInstance}
                      noOfItems={3}
                      message1="Update vulnerability category"
                      message2="View Occurences"
                      message3="Delete vulnerability category"
                      image1="edit_gray.svg"
                      image2="eye_open_gray.svg"
                      image3="delete_red.svg"
                    />
                  )}
                </>
              ) : (
                totalInstances() !== 0 && (
                  <>
                    <img
                      className={styles.moreIcon}
                      aria-controls="simple-menu"
                      onClick={handleClick}
                      src={getImage("more_menu.svg")}
                      alt=" "
                    />
                    <CustomMoreMenu
                      open={open}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      handleClick1={handleVulCategoryInstance}
                      noOfItems={1}
                      message1="View Occurences"
                      image1="eye_open_gray.svg"
                    />
                  </>
                )
              )}
            </Grid>
          </Grid>
          {openDeleteDialog && (
            <ConfirmationDialog
              text="Delete vulnerability category"
              subText="Are you sure you want to Delete this vulnerability category?"
              image="delete_icon.svg"
              cancelBtnText="Cancel"
              successBtnText="Delete"
              cancelBtnClass="outlinedBtn"
              successBtnClass="secondaryBtn"
              handleSuccess={handleDeleteVulCategory}
              handleCancel={() => {
                handleDeleteVulnerabilityCategory();
              }}
            />
          )}
        </AccordionSummary>
        {vulnerabilities && vulnerabilities.length > 0 && (
          <AccordionDetails>
            <div className={styles.VulnerabilityDetailsWrapper}>
              <div className={styles.headerWrapper + " supportText_semiBold_vul"}>
                <Grid container>
                  <Grid item md={4}>
                    <p>
                      VULNERABILITIES <span>{vulnerabilities?.length}</span>
                    </p>
                  </Grid>
                  <Grid item md={3}>
                    <p>LAST INSTANCE</p>
                  </Grid>
                  <Grid item md={2}>
                    <p>DETECTED ON</p>
                  </Grid>
                  <Grid item md={2}>
                    <p>OCCURENCES</p>
                  </Grid>
                  <Grid item md={1}>
                    ACTION
                  </Grid>
                </Grid>
              </div>
              {vulnerabilities &&
                vulnerabilities?.map((details, idx) => {
                  return (
                    <VulnerabilityDetails key={idx} {...details} vulCategoryId={id} vulCategoryName={name} />
                  );
                })}
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
};

export default VulnerabilityAccordianLists;
