import { FC, ChangeEvent, Fragment, useState, useContext } from "react";
import styles from "../../../assets/styles/client.module.scss";
import { Grid } from "@mui/material";
import { Search } from "../../../shared_elements";
import { ClientsGridCard, AddNewClientDialog, Shimmer } from ".";
import { NoResult } from "../../../shared_ui_components";
import { GlobalContext } from "../../../context/GlobalContext";
import { ClientType } from "../../../context/ClientReducer";
import { getImage } from "../../../utils";

type TSearchType = {
  val: string;
  list: Array<ClientType>;
};

const ClientsDashboard: FC = () => {
  const {
    state: { clientLists },
  } = useContext(GlobalContext);

  const [search, setSearch] = useState<TSearchType>({
    val: "",
    list: [],
  });
  const [addNewClient, setAddNewClient] = useState(false);

  const handleSearch = (val: string) => {
    let str = val.toLowerCase();
    let searchList: Array<ClientType> = clientLists?.clientData?.filter((client: { name: string }) =>
      client?.name?.toLowerCase().includes(str)
    );
    setSearch(() => ({
      val: str,
      list: searchList,
    }));
  };

  const handleAddNewClient = () => {
    setAddNewClient(!addNewClient);
  };

  const handleClearSearch = () => {
    setSearch(() => ({
      val: "",
      list: [],
    }));
  };

  return (
    <>
      <div className={styles.clientDashboardWrapper}>
        <div className={styles.clientTitleWrapper}>
          <h2 className="heading3 headingTextOverflow">
            {" "}
            {search?.val
              ? `Showing ${search?.list?.length} ${
                  search?.list?.length === 1 || search?.list?.length === 0 ? "result" : "results"
                } for "${search?.val}" `
              : `All Clients`}
          </h2>
          <div className={styles.clientSearchAndViewWrapper}>
            {!clientLists?.loading && !clientLists?.showNoState && (
              <>
                {search.val ? (
                  <span onClick={handleClearSearch} className={styles.clearClientSearch + " paragraph"}>
                    Clear search
                  </span>
                ) : (
                  ""
                )}
                <Search
                  text={search.val}
                  placeholder="Search for clients"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
                />
              </>
            )}
          </div>
        </div>
        {clientLists?.showNoState ? (
          <NoResult
            image={getImage("no_file_found.svg")}
            title="No Client Found"
            btnText="Add New Client"
            onClick={handleAddNewClient}
            link="/clients"
          />
        ) : (
          <>
            {clientLists?.loading ? (
              <Grid container spacing={1} direction="row" alignItems="center">
                <Shimmer type="clientsList" />
              </Grid>
            ) : search.val ? (
              <>
                {search.list.length === 0 ? (
                  <NoResult
                    image={getImage("no_file_found.svg")}
                    title="No Result Found"
                    subtext={<Fragment>We didn't find any client that matched your search</Fragment>}
                  />
                ) : (
                  <Grid container spacing={1} direction="row" alignItems="center">
                    {search.list.map((clients, idx) => (
                      <Grid item md={3} key={idx}>
                        <ClientsGridCard {...clients} />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            ) : (
              <Grid container spacing={1} direction="row" alignItems="center" sx={{ marginBottom: "40px" }}>
                {clientLists?.clientData?.map((clients, idx) => (
                  <Grid item md={3} key={idx}>
                    <ClientsGridCard {...clients} />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </div>
      <AddNewClientDialog type="Add New Client" open={addNewClient} onClose={handleAddNewClient} />
    </>
  );
};

export default ClientsDashboard;
