import { FC } from "react";
import DateFnsAdapter from "@date-io/date-fns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

type IPropsDatePicker = {
  value?: Date | null;
  onChange: (date: Date | null | undefined, keyboardInputValue?: string | undefined) => void;
  className?: string;
  error?: string;
  noMargin?: string;
  minDate?: Date;
  disabled?: boolean;
};

const CustomDatePicker: FC<IPropsDatePicker> = ({ value, onChange, error, noMargin, minDate, disabled }) => {
  return (
    <div className={`inputGroup ${error && "errorGroup"} ${noMargin && "noMargin"}`}>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <DatePicker
          disablePast
          minDate={minDate}
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
          disabled={disabled}
        />
      </LocalizationProvider>
      {error && <h6 className="errorMsg absolute">{error}</h6>}
    </div>
  );
};

export default CustomDatePicker;
