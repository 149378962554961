import { FC, SyntheticEvent, useEffect, useState, useContext } from "react";
import { Dialog, MenuItem, Grid } from "@mui/material";
import styles from "../../../assets/styles/home.module.scss";
import { errorCodes, regExpression } from "../../../constants";
import {
  CustomButton,
  SelectDropdown,
  CustomInput,
  CustomDatePicker,
  CustomMutipleSelect,
  CustomSelect,
} from "../../../shared_ui_components";
import { Errors, TAutoComplete, T, TScans } from "../../../types";
import {
  methodologyTypeOptions,
  toastFlashMessage,
  getUserInfo,
  scanTypeOptions,
  scanTypeOptions2,
  getImage,
} from "../../../utils";
import { GlobalContext } from "../../../context/GlobalContext";
import { fieldValidation } from "../../../utils/formValidations";
import { addNewScan, getSingleProject } from "../apiServices";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";
import { useParams } from "react-router-dom";

type TCreateScanProps = {
  open: boolean;
  onClose: (value: string) => void;
  projectName: string;
  projectId: string;
  handleAddScanDetails?: (val: TScans[]) => void;
};

export type IStateScanForm = {
  engagement_type: string;
  methodology: string | number;
  request_date?: Date;
  due_date: Date | null;
  requested_by: (string | T)[] | [];
  owner: string | null | TAutoComplete;
  scan_type: string | number;
  url: string;
  rescan: string | null | TAutoComplete;
};

export type TAddScan = {
  engagement_type: string;
  methodology: number;
  request_date: string | undefined;
  due_date: string | undefined;
  requested_by: string;
  owner: string | null;
  scans?: TScans[];
  status?: number;
  project?: {
    name: string;
    status: number;
    uuid: string;
    version: number;
  };
  scan_type?: number;
  url: string;
  rescan?: number | String;
  id?: number;
  permissions?: {
    can_add_analysis: boolean;
    can_edit_analysis: boolean;
    can_edit_scan: boolean;
  } | null;
  security_score?: string | number | null;
};

const CreateScanRequestDialog: FC<TCreateScanProps> = ({
  open,
  onClose,
  projectName,
  projectId,
  handleAddScanDetails,
}): JSX.Element => {
  const { id } = useParams();
  const {
    state: { userLists },
    dispatch,
  } = useContext(GlobalContext);
  const [formData, setFormData] = useState<IStateScanForm>({
    engagement_type: "",
    methodology: "",
    request_date: new Date(),
    due_date: null,
    requested_by: [],
    scan_type: "",
    owner: null,
    url: "",
    rescan: null,
  });
  const [researchersOptions, setResearchersOptions] = useState<TAutoComplete[] | []>([]);
  const [rescanOptions, setRescanOptions] = useState<TAutoComplete[] | []>([]);
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [validateLoader, setValidatorLoader] = useState(false);
  const [email, setEmail] = useState("");

  /* Filter security reseacher & manangers from userslist */
  useEffect(() => {
    const _id: number[] = [4];
    const data = userLists?.userData
      ?.filter((user) => _id?.includes(user?.role?.id as number))
      ?.map((_user) => {
        return {
          label: _user?.last_name
            ? _user?.first_name?.concat(" " + _user?.last_name).concat(" - " + _user.email)
            : _user?.first_name?.concat(" - " + _user.email),
          value: _user?.uuid,
        };
      });
    setResearchersOptions(data as TAutoComplete[]);
  }, [userLists]);

  useEffect(() => {
    setFormData((form) => ({
      ...form,
      requested_by: [...form.requested_by, getUserInfo()?.user?.email] as string[],
      request_date: new Date(),
    }));
    reScanOptionList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reScanOptionList = () => {
    getSingleProject(id as string).then((response) => {
      if (response.statusCode === 200) {
        const scanList = response.data.scans
          .filter((ele: { status: number }) => ele.status !== 2)
          .map((_scan: { request_date: string; id: number }) => {
            return {
              label: _scan.id + " - " + _scan.request_date?.split("-")?.reverse()?.join("/"),
              value: _scan.id,
            };
          });

        setRescanOptions(scanList);
      }
    });
  };

  const handleChange = (key: string, val: string | Date | (string | T)[] | TAutoComplete | any) => {
    if (key === "rescan") {
      getSingleProject(id as string).then((response) => {
        if (response.statusCode === 200) {
          const scanList = response.data.scans.find((_scan: { id: number }) => _scan.id === val?.value);
          setFormData({
            ...formData,
            url: scanList.url ? scanList.url : "",
            rescan: val,
          });
        }
      });
    }
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const handleCancel = () => {
    onClose("false");
    setValidatorLoader(false);
  };

  const handleFocus = () => {
    Object.entries(error).forEach((val, index) =>
      setError((err) => ({
        ...err,
        requested_by: "",
      }))
    );
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validateNewInput: {
      [key: string]: string;
    };
    validateNewInput = {
      methodology:
        errorCodes["methodology"][
          fieldValidation({
            ...errorCode["methodologyObj"],
            fieldval: formData?.methodology,
          })
        ],
      due_date:
        errorCodes["due_date"][
          fieldValidation({
            ...errorCode["due_date_Obj"],
            fieldval: formData?.due_date,
          })
        ],
      request_date:
        errorCodes["requested_date"][
          fieldValidation({
            ...errorCode["requested_date_Obj"],
            fieldval: formData?.request_date,
          })
        ],
      scan_type:
        errorCodes["scan_type"][
          fieldValidation({
            ...errorCode["scan_type_Obj"],
            fieldval: formData?.scan_type,
          })
        ],
      requested_by:
        errorCodes["_users"][
          fieldValidation({
            ...errorCode["_usersObj"],
            fieldval: formData.requested_by,
          })
        ],
      url: errorCodes["url"][
        fieldValidation({
          ...errorCode["urlObj"],
          fieldval: formData.url,
        })
      ],
      rescan:
        errorCodes["rescan"][
          fieldValidation({
            ...errorCode["rescan_Obj"],
            fieldval:
              formData.scan_type === 3 && reScanOptionList.length > 0
                ? typeof formData?.rescan === "string"
                  ? (formData.rescan as string)
                  : formData?.rescan && (formData?.rescan as HTMLInputElement)["value"]
                : "0",
          })
        ],
    };

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setValidatorLoader(true);
      const form_data = {
        engagement_type: formData.engagement_type,
        methodology: Number(formData?.methodology),
        request_date: formData?.request_date?.toISOString().slice(0, 10),
        due_date: formData?.due_date?.toISOString().slice(0, 10),
        requested_by: Array.from(new Set(formData?.requested_by))?.join(","),
        scan_type: Number(formData?.scan_type),
        owner: formData?.owner ? (formData?.owner as HTMLInputElement)["value"] : "",
        url: formData.url,
        rescan: formData?.rescan ? (formData?.rescan as HTMLInputElement)["value"] : "",
      };
      addNewScan(form_data, projectId as string).then(
        (response: Partial<{ statusCode: number; data: TAddScan; message: string }>) => {
          if (response.statusCode === 200) {
            dispatch({
              type: PROJECT_ACTIONS.Update,
              payload: response.data,
            });
            handleAddScanDetails?.(response?.data?.scans as TScans[]);
            setValidatorLoader(false);
            toastFlashMessage(response.message as string, "success");
            handleCancel();

          } else {
            setValidatorLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  const handleValue = (event: any) => {
    switch (event.key) {
      case ",":
      case " ":
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.trim()) {
          if (regExpression.email.test(event.target.value) === false) {
            setEmail("");
          } else {
            handleChange?.("requested_by", [...formData.requested_by, event.target.value]);
            setEmail("");
          }
        }
        break;
      default:
    }
  };
  return (
    <Dialog onClose={onClose} open={open} sx={{ borderRadius: "4px" }}>
      <div className={styles.dialogWrapper}>
        <div className={styles.headerTitle}>
          <p className="heading5">Create Scan Request</p>
          <img src={getImage("close_icon.svg")} alt="close" onClick={handleCancel} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.formDetail}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <p className={styles.formTitle + " supportText_semiBold"}>Project name</p>
                <CustomInput
                  className={styles.inputBlk}
                  placeholder="Enter Project Name"
                  noMargin="noMargin"
                  value={projectName}
                  disabled={true}
                />
              </Grid>

              <Grid item md={6}>
                <CustomInput
                  label="Engagement type"
                  className={styles.inputBlk}
                  placeholder="eg, Web application security audit"
                  onChange={(e) => handleChange("engagement_type", e.target.value)}
                  noMargin="noMargin"
                  error={error.engagement_type}
                />
              </Grid>
              <Grid item md={6}>
                <p
                  className={
                    error.methodology
                      ? styles.errorFormTitle
                      : styles.removePadding + "  supportText_semiBold"
                  }
                  style={error.methodology ? { marginTop: "-8px" } : {}}
                >
                  Methodology *
                </p>
                <SelectDropdown
                  value={formData.methodology}
                  onChange={(e) => handleChange("methodology", e.target.value)}
                  error={error.methodology}
                  className={styles.inputBlk}
                >
                  <MenuItem value="" disabled>
                    <span className="disabled" style={{ fontSize: "16px" }}>
                      Select Methodology Type
                    </span>
                  </MenuItem>
                  {methodologyTypeOptions &&
                    methodologyTypeOptions.map((methodology, idx) => {
                      return (
                        <MenuItem value={methodology.value} key={idx}>
                          {methodology.label}
                        </MenuItem>
                      );
                    })}
                </SelectDropdown>
              </Grid>
              <Grid item md={6}>
                <p
                  className={
                    error.request_date ? styles.errorFormTitle : styles.formTitle + "  supportText_semiBold"
                  }
                >
                  Request date *
                </p>
                <CustomDatePicker
                  className={styles.inputBlk}
                  onChange={(newValue: Date | null | undefined) => {
                    handleChange("request_date", newValue as Date);
                  }}
                  noMargin="noMargin"
                  value={formData.request_date}
                  error={error.request_date}
                />
              </Grid>
              <Grid item md={6}>
                <p
                  className={
                    error.due_date ? styles.errorFormTitle : styles.formTitle + "  supportText_semiBold"
                  }
                >
                  Due date *
                </p>
                <CustomDatePicker
                  className={styles.inputBlk}
                  onChange={(newValue: Date | null | undefined) => {
                    handleChange("due_date", newValue as Date);
                  }}
                  minDate={formData.request_date}
                  noMargin="noMargin"
                  value={formData.due_date}
                  error={error.due_date}
                />
              </Grid>
              <Grid item md={6} sx={{ marginTop: "-8px" }}>
                <p
                  className={
                    error.scan_type ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                  }
                >
                  Type of Scan *
                </p>
                <SelectDropdown
                  value={formData.scan_type}
                  onChange={(e) => handleChange("scan_type", e.target.value)}
                  error={error.scan_type}
                  className={styles.inputBlk}
                >
                  <MenuItem value="" disabled>
                    <span className="disabled" style={{ fontSize: "16px" }}>
                      Select Scan Type
                    </span>
                  </MenuItem>
                  {(rescanOptions.length > 0 ? scanTypeOptions : scanTypeOptions2).map((scanType, idx) => {
                    return (
                      <MenuItem value={scanType.value} key={idx}>
                        {scanType.label}
                      </MenuItem>
                    );
                  })}
                </SelectDropdown>
              </Grid>
              <Grid item md={6}>
                <CustomInput
                  label="URL *"
                  className={styles.inputBlk}
                  placeholder="Enter Url"
                  noMargin="noMargin"
                  value={formData.url}
                  onChange={(e) => handleChange("url", e.target.value)}
                  error={error.url}
                />
              </Grid>
              {formData.scan_type === 3 && rescanOptions.length > 0 && (
                <Grid item md={12} sx={{ marginTop: "-8px" }}>
                  <p
                    className={
                      error.rescan ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                    }
                  >
                    Select Re-scan
                  </p>
                  <CustomSelect
                    placeholder="Select Re-scan"
                    className={styles.inputBlk}
                    options={rescanOptions}
                    value={formData.rescan as HTMLInputElement}
                    onChange={(event: SyntheticEvent, newValue: string | TAutoComplete | null) => {
                      handleChange("rescan", newValue as string | TAutoComplete);
                    }}
                    noMargin="noMargin"
                    error={error.rescan}
                  />
                </Grid>
              )}
              <Grid item md={12} sx={{ marginTop: "-8px" }}>
                <p
                  className={
                    error.requested_by ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                  }
                >
                  Requested by *
                </p>
                <CustomMutipleSelect
                  options={[]}
                  onChange={(event: SyntheticEvent, val) => {
                    if (regExpression.email.test(val.slice(-1) as any) === false) {
                      setEmail("");
                      setFormData((form) => ({
                        ...form,
                        requested_by: val.slice(0, val.length - 1),
                      }));
                    } else {
                      handleChange(
                        "requested_by",
                        val.map((ele) => ele)
                      );
                      setEmail("");
                    }
                  }}
                  handleValue={handleValue}
                  value={[...(new Set(formData.requested_by) as any)]}
                  inputText={email.trim()}
                  handleChangeText={(e: SyntheticEvent) =>
                    setEmail((e.target as HTMLInputElement).value.trim())
                  }
                  placeholder="Enter email address, if multiple use comma or space separated"
                  error={error.requested_by}
                  onFocus={handleFocus}
                />
              </Grid>

              <Grid item md={12} sx={{ marginTop: "-8px" }}>
                <p
                  className={error.owner ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"}
                >
                  Assigned to
                </p>
                <CustomSelect
                  placeholder="Select Researcher"
                  className={styles.inputBlk}
                  options={researchersOptions}
                  value={formData.owner as HTMLInputElement}
                  onChange={(event: SyntheticEvent, newValue: string | TAutoComplete | null) => {
                    handleChange("owner", newValue as string | TAutoComplete);
                  }}
                  noMargin="noMargin"
                  error={error.owner}
                />
              </Grid>
            </Grid>
          </div>
          <div className={styles.buttonWrapper}>
            <CustomButton className="outlinedBtn" children="Cancel" type="button" onClick={handleCancel} />
            <CustomButton
              className={`${validateLoader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
              children="Create Request"
              type="submit"
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default CreateScanRequestDialog;
