import { FC, useState, useEffect, useContext } from "react";
import { Container, Grid } from "@mui/material";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { CustomLink } from "../../../shared_ui_components";
import { getVulnerabilityInstances } from "../apiServices";
import { TInstances } from "../../../types";
import { toastFlashMessage, getImage } from "../../../utils";
import { InstanceListItem, Shimmer } from "../components";
import { GlobalContext } from "../../../context/GlobalContext";

type TRole = {
  role: number;
};

const VulnerabilityInstance: FC<TRole> = (props) => {
  const role = props?.role;
  const { id, occurenceId } = useParams();
  const navigate = useNavigate();
  const {
    state: { vulneralibityLists },
  } = useContext(GlobalContext);
  const [instances, setInstances] = useState<TInstances[] | []>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id && occurenceId) {
      getVulnerabilityInstances(`${id}`, `${occurenceId}`).then(
        (response: Partial<{ statusCode: number; data: TInstances[] | []; message: string }>) => {
          if (response.statusCode === 200) {
            setInstances(response?.data as TInstances[] | []);
            setLoading(false);
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoading(false);
            navigate("/vulnerabilities");
          }
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, occurenceId]);

  return (
    <>
      {role === 1 || role === 3 || role === 4 ? (
        <div className={styles.vulnerabilityInstanceWrapper}>
          <div className={styles.headerWrapper}>
            <Container maxWidth="lg">
              <div className={styles.backWrapper}>
                <CustomLink to="/vulnerabilities" className={styles.backBtn}>
                  <p className={styles.btnText}>
                    <img src={getImage("arrow_left_grey.svg")} alt=" " />
                    Back to Vulnerabilities
                  </p>
                </CustomLink>
              </div>
            </Container>
          </div>
          <Container maxWidth="lg">
            <p className={styles.headerBlk + " heading3"}>
              Occurences for “
              {vulneralibityLists?.vulnerabilityData?.find((_vul) => _vul.id === Number(id))?.name}”
            </p>
            <Grid container spacing={2} className={styles.instanceListHeader + " labelText_semiBold"}>
              <Grid item md={1}>
                <p>ID</p>
              </Grid>
              <Grid item md={2.5}>
                <p>PROJECT</p>
              </Grid>
              <Grid item md={3.5}>
                <p>VULNERABILITY</p>
              </Grid>
              <Grid item md={2}>
                <p>SEVERITY</p>
              </Grid>
              <Grid item md={2}>
                <p>STATUS</p>
              </Grid>
              <Grid item md={1}>
                ACTION
              </Grid>
            </Grid>

            {loading ? (
              <Shimmer type="occurencesList" />
            ) : (
              instances &&
              instances?.map((item, index) => {
                return <InstanceListItem key={index} {...item} />;
              })
            )}
          </Container>
        </div>
      ) : (
        <Navigate to="/projects" />
      )}
    </>
  );
};

export default VulnerabilityInstance;
