import { FC, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { CustomButton } from ".";
import { getImage } from "../utils";

type TConfirmationDialogProps = {
  text: string;
  subText?: string;
  image?: string;
  cancelBtnText?: string;
  successBtnText?: string;
  cancelBtnClass?: string;
  successBtnClass?: string;
  handleSuccess: () => void;
  handleCancel: () => void;
};

const ConfirmationDialog: FC<TConfirmationDialogProps> = ({
  text,
  subText,
  image,
  cancelBtnText,
  successBtnText,
  cancelBtnClass,
  successBtnClass,
  handleSuccess,
  handleCancel,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        className="confirmationDialog"
      >
        <DialogTitle className="dialogTop" id="scroll-dialog-title">
          <img className="backgroundImage" src={getImage("comfirm_bg.svg")} alt="bg" />
          {image ? (
            <img className="logo" src={getImage(`${image}`)} alt="" />
          ) : (
            <img className="logo" src={getImage("logout_icon.svg")} alt="" />
          )}
        </DialogTitle>
        <DialogContent className="dialogBottom">
          <p className="heading3 textCenter">{text}</p>
          <p className="paragraph subText">{subText}</p>
          <div className="btnWrapper">
            <CustomButton
              children={cancelBtnText ? cancelBtnText : "No"}
              className={`${cancelBtnClass ? cancelBtnClass : "outlinedBtn"}`}
              onClick={() => handleCancel()}
              type="button"
            />
            <CustomButton
              children={loader ? "Please wait..." : successBtnText ? successBtnText : "Yes"}
              className={` ${successBtnClass ? successBtnClass : "primaryBtn"} ${
                loader ? "disabledBtn" : ""
              }`}
              onClick={() => {
                setLoader(true);
                handleSuccess();
              }}
              type="button"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ConfirmationDialog;
