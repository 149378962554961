import { FC } from "react";
import { Container } from "@mui/material";
import { VulnerabilityDashboard } from "../components";
import { Navigate } from "react-router-dom";

type TRole = {
  role: number;
};

const Vulnerability: FC<TRole> = (props) => {
  const role = props?.role;

  return (
    <>
      <Container maxWidth="lg">
        {role === 1 || role === 3 || role === 4 ? <VulnerabilityDashboard /> : <Navigate to="/projects" />}
      </Container>
    </>
  );
};
export default Vulnerability;
