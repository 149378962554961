import { FC, ChangeEvent, FocusEventHandler } from "react";
import { InputBase } from "@mui/material";
import { getImage } from "../utils";

type TSearchProps = {
  text: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: () => void;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
};

const Search: FC<TSearchProps> = ({ text, placeholder, onChange, onKeyPress, onFocus, onBlur }) => {
  return (
    <div className="inputGroup searchGroup">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="search">
          <InputBase
            placeholder={placeholder ?? "Search"}
            className="searchArea"
            inputProps={{ "aria-label": "search" }}
            value={text}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <div className="searchIcon">
            <img src={getImage("search_icon.svg")} alt="" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Search;
