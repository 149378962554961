import React, { FC } from "react";
import { getImage } from "../utils";

type TProps = {
  passwordValidation: {
    minCharacter: boolean;
    oneNumber: boolean;
    oneUpperCase: boolean;
    oneLowerCase: boolean;
    oneSpecialCharacter: boolean;
  };
};

const PasswordValidator: FC<TProps> = ({ passwordValidation }) => {
  return (
    <>
      <div className="validatorWrapper">
        <h5 className="title paragraph_semiBold">The password must include</h5>
        <p className="conditionList supportText">
          <img src={getImage(`${passwordValidation.minCharacter ? "tick-green" : "close-red"}.svg`)} alt="" />
          <span>8</span> characters minimum
        </p>
        <p className="conditionList supportText">
          <img src={getImage(`${passwordValidation.oneNumber ? "tick-green" : "close-red"}.svg`)} alt="" />
          <span>1</span> number minimum
        </p>
        <p className="conditionList supportText">
          <img src={getImage(`${passwordValidation.oneUpperCase ? "tick-green" : "close-red"}.svg`)} alt="" />
          <span>1</span> uppercase letter (A-Z)
        </p>
        <p className="conditionList supportText">
          <img src={getImage(`${passwordValidation.oneLowerCase ? "tick-green" : "close-red"}.svg`)} alt="" />
          <span>1</span> lowercase letter (a-z)
        </p>
        <p className="conditionList supportText">
          <img
            src={getImage(`${passwordValidation.oneSpecialCharacter ? "tick-green" : "close-red"}.svg`)}
            alt=""
          />
          <span>1</span> special character
        </p>
      </div>
    </>
  );
};

export default PasswordValidator;
