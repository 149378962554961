import { globalGetService, globalPostService } from "../../utils/GlobalApiServices";
import { TAddClient, TUpdateClient } from "../../types";

export function getAllClients(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`clients/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function addNewClient(query: TAddClient): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`client/create/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateClient(query: TUpdateClient, params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`client/${params}/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleClientDetails(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`client/${params}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleClientUsers(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`users/?client=${params}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
