import { FC } from "react";
import { Button } from "@mui/material";

type TCustomButtonProps = {
  children: React.ReactNode;
  leftIcon?: React.ReactNode;
  className: string;
  onClick?: () => void;
  type: "button" | "submit" | "reset" | undefined;
};

const CustomButton: FC<TCustomButtonProps> = ({ children, leftIcon, className, onClick, type }) => {
  return (
    <Button className={className} onClick={onClick} type={type}>
      {leftIcon ? leftIcon : null}
      {children}
    </Button>
  );
};

export default CustomButton;
