/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import { FC, SyntheticEvent, useState, useContext, useEffect } from "react";
import { Grid, MenuItem } from "@mui/material";
import {
  CustomButton,
  SelectDropdown,
  CustomInput,
  CustomDatePicker,
  CustomMutipleSelect,
  CustomSelect,
} from "../../../shared_ui_components";
import styles from "../../../assets/styles/home.module.scss";
import { errorCodes, regExpression } from "../../../constants";
import { GlobalContext } from "../../../context/GlobalContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getSingleScanDetails, updateSingleScanDetails, getSingleProject } from "../apiServices";
import { TAddScan } from "./CreateScanRequestDialog";
import { toastFlashMessage, methodologyTypeOptions, scanTypeOptions, scanTypeOptions2 } from "../../../utils";
import { Errors, TAutoComplete, T } from "../../../types";
import { fieldValidation } from "../../../utils/formValidations";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";
import { Shimmer } from ".";

type IStateScanForm = {
  app_name?: string;
  engagement_type?: string;
  methodology: string | number;
  request_date?: Date;
  due_date?: Date | null;
  requested_by?: (string | T)[] | [];
  owner?: null | TAutoComplete | string;
  scan_type: string | number;
  status?: string;
  url?: string;
  rescan: string | null | TAutoComplete;
  security_score: string;
};

export type TUpdateScan = {
  engagement_type: string | undefined;
  methodology: number;
  request_date: string | undefined;
  due_date: string | undefined;
  requested_by: string | undefined;
  owner: string | null | undefined;
  status: number | undefined;
  scan_type: number;
  url?: string;
  rescan: string | null | undefined;
  security_score?: number | null;
};

const EditScanForm: FC = (props) => {
  const {
    state: { userLists, roleLists },
    dispatch,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id, scanId } = useParams();
  const roleId = roleLists?.roleData?.id;

  const [formData, setFormData] = useState<IStateScanForm>({
    app_name: "",
    engagement_type: "",
    methodology: "",
    request_date: new Date(),
    due_date: null,
    requested_by: [],
    scan_type: "",
    owner: "",
    status: "",
    url: "",
    rescan: null,
    security_score: "",
  });
  const [researchersOptions, setResearchersOptions] = useState<TAutoComplete[] | []>([]);
  const [rescanOptions, setRescanOptions] = useState<TAutoComplete[] | []>([]);
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [validateLoader, setValidatorLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [scanLength, setScanLength] = useState(1);

  useEffect(() => {
    getSingleScanDetails(`${scanId}`).then(
      (response: Partial<{ statusCode: number; data: TAddScan; message: string }>) => {
        if (response.statusCode === 200) {
          const scanDetails = response?.data;
          if (scanDetails?.permissions?.can_edit_scan) {
            getSingleProject(id as string).then((response) => {
              if (response.statusCode === 200) {
                setScanLength(response.data.scans.length);
                const data = response.data.scans.find(
                  (_scan: { id: number }) => _scan.id === scanDetails?.rescan
                );
                if (data) {
                  setFormData((form) => ({
                    ...form,
                    rescan: {
                      label: data.id + " - " + data.request_date?.split("-")?.reverse()?.join("/"),
                      value: data.id,
                    },
                  }));
                  setLoading(false);
                } else {
                  setLoading(false);
                }
                const scanList = response.data.scans
                  .filter((ele: { status: number }) => ele.status !== 2)
                  .map((_scan: { request_date: string; id: number }) => {
                    return {
                      label: _scan.id + " - " + _scan.request_date?.split("-")?.reverse()?.join("/"),
                      value: _scan.id,
                    };
                  });
                const data2 = scanList.filter(
                  (_scan: { label: string }) =>
                    _scan.label !==
                    scanDetails?.id + " - " + scanDetails?.request_date?.split("-")?.reverse()?.join("/")
                );
                setRescanOptions(data2);
              }
            });
            const requested_user_email = scanDetails?.requested_by?.split(",");
            setFormData((form) => ({
              ...form,
              app_name: scanDetails?.project?.name,
              engagement_type: scanDetails?.engagement_type,
              methodology: String(scanDetails?.methodology),
              request_date: new Date(scanDetails?.request_date as string),
              due_date: new Date(scanDetails?.due_date as string),
              requested_by: scanDetails?.requested_by
                ? (Array.from(new Set(requested_user_email)) as (string | T)[])
                : [],
              owner: scanDetails?.owner
                ? {
                  label: scanDetails?.owner?.["last_name" as any]
                    ? scanDetails?.owner?.["first_name" as any].concat(
                      " " + scanDetails?.owner?.["last_name" as any]
                    )
                    : scanDetails?.owner?.["first_name" as any],
                  value: scanDetails?.owner?.["uuid" as any] ? scanDetails?.owner?.["uuid" as any] : "",
                }
                : "",
              scan_type: scanDetails?.scan_type ? String(scanDetails?.scan_type) : "",
              status: String(scanDetails?.status),
              url: scanDetails?.url,
              security_score: String(scanDetails?.security_score as string),
            }));
          } else {
            navigate(`/projects/${id}`);
          }
        } else {
          toastFlashMessage(response.message as string, "error");
          setLoading(false);
          navigate(-1);
        }
      }
    );
  }, [id]);

  /* Filter security reseacher & manangers from userslist */
  useEffect(() => {
    const _id: number[] = [4];
    const data = userLists?.userData
      ?.filter((user) => _id?.includes(user?.role?.id as number))
      ?.map((_user) => {
        return {
          label: _user?.last_name
            ? _user?.first_name?.concat(" " + _user?.last_name).concat(" - " + _user.email)
            : _user?.first_name?.concat(" - " + _user.email),
          value: _user?.uuid,
        };
      });
    setResearchersOptions(data as TAutoComplete[]);
  }, [userLists]);

  const handleChange = (key: string, val: string | Date | (string | T)[] | TAutoComplete | any) => {
    if (key === "rescan") {
      getSingleProject(id as string).then((response) => {
        if (response.statusCode === 200) {
          const scanList = response.data.scans.find((_scan: { id: number }) => _scan.id === val?.value);
          setFormData({
            ...formData,
            url: scanList.url ? scanList.url : "",
            rescan: val,
          });
        }
      });
    }
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validateNewInput: {
      [key: string]: string;
    };
    validateNewInput = {
      methodology:
        errorCodes["methodology"][
        fieldValidation({
          ...errorCode["methodologyObj"],
          fieldval: formData?.methodology,
        })
        ],
      due_date:
        errorCodes["due_date"][
        fieldValidation({
          ...errorCode["due_date_Obj"],
          fieldval: formData?.due_date,
        })
        ],
      request_date:
        errorCodes["requested_date"][
        fieldValidation({
          ...errorCode["requested_date_Obj"],
          fieldval: formData?.request_date,
        })
        ],
      requested_by:
        errorCodes["_users"][
        fieldValidation({
          ...errorCode["_usersObj"],
          fieldval: formData.requested_by,
        })
        ],
      scan_type:
        errorCodes["scan_type"][
        fieldValidation({
          ...errorCode["scan_type_Obj"],
          fieldval: formData?.scan_type,
        })
        ],
      url: errorCodes["url"][
        fieldValidation({
          ...errorCode["urlObj"],
          fieldval: formData.url,
        })
      ],
      rescan:
        errorCodes["rescan"][
        fieldValidation({
          ...errorCode["rescan_Obj"],
          fieldval:
            formData.scan_type === 3
              ? typeof formData?.rescan === "string"
                ? (formData.rescan as string)
                : formData?.rescan && (formData?.rescan as HTMLInputElement)["value"]
              : "0",
        })
        ],
    };

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setValidatorLoader(true);
      const form_data: TUpdateScan = {
        engagement_type: formData.engagement_type,
        methodology: Number(formData?.methodology),
        request_date: formData?.request_date?.toISOString().slice(0, 10),
        due_date: formData?.due_date?.toISOString().slice(0, 10),
        requested_by: formData?.requested_by?.join(","),
        scan_type: Number(formData?.scan_type),
        owner: formData?.owner ? (formData?.owner as HTMLInputElement)["value"] : "",
        status: Number(formData?.status),
        url: formData.url,
        rescan: formData?.rescan ? (formData?.rescan as HTMLInputElement)["value"] : "",
        security_score: Number(formData?.security_score),
      };

      updateSingleScanDetails(form_data, scanId as string).then(
        (response: Partial<{ statusCode: number; data: TUpdateScan; message: string }>) => {
          if (response.statusCode === 200) {
            dispatch({
              type: PROJECT_ACTIONS.Update,
              payload: response.data,
            });
            setValidatorLoader(false);
            if (location.pathname.includes("vulnerabilities")) {
              navigate(`/vulnerabilities/${id}/scan-details/${scanId}`);
            } else {
              navigate(-1);
            }
            toastFlashMessage(response.message as string, "success");
          } else {
            setValidatorLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  const handleValue = (event: any) => {
    switch (event.key) {
      case ",":
      case " ":
        {
          event.preventDefault();
          event.stopPropagation();
          if (event.target.value.trim()) {
            if (regExpression.email.test(event.target.value) === false) {
              setEmail("");
            } else {
              handleChange?.("requested_by", [...(formData.requested_by as string[]), event.target.value]);
              setEmail("");
            }
          }
        }
        break;
      default:
    }
  };

  const handleFocus = () => {
    Object.entries(error).forEach((val, index) =>
      setError((err) => ({
        ...err,
        requested_by: "",
      }))
    );
  };

  return (
    <>
      {loading ? (
        <Shimmer type="editScanDetails" />
      ) : (
        <div className={styles.formDetail}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <p className={styles.formTitle + " supportText_semiBold"}>Project name</p>
                <CustomInput
                  className={styles.inputBlk}
                  placeholder="Enter Project Name"
                  noMargin="noMargin"
                  value={formData?.app_name}
                  disabled={true}
                />
              </Grid>
              <Grid item md={6}>
                <CustomInput
                  label="Engagement type"
                  className={styles.inputBlk}
                  placeholder="eg, Web application security audit"
                  onChange={(e) => handleChange("engagement_type", e.target.value)}
                  noMargin="noMargin"
                  error={error.engagement_type}
                  value={formData.engagement_type}
                  disabled={roleId === 1 || roleId === 2 ? false : true}
                />
              </Grid>
              <Grid item md={6}>
                <p
                  className={
                    error.methodology
                      ? styles.errorFormTitle
                      : styles.removePadding + "  supportText_semiBold"
                  }
                  style={{ paddingBottom: "8px" }}
                >
                  Methodology *
                </p>
                <SelectDropdown
                  value={formData.methodology}
                  onChange={(e) => handleChange("methodology", e.target.value)}
                  error={error.methodology}
                  className={styles.inputBlk}
                  disabled={roleId === 1 || roleId === 2 ? false : true}
                >
                  <MenuItem value="" disabled>
                    <span className="disabled" style={{ fontSize: "16px" }}>
                      Select Methodology Type
                    </span>
                  </MenuItem>
                  {methodologyTypeOptions &&
                    methodologyTypeOptions.map((methodology, idx) => {
                      return (
                        <MenuItem value={methodology.value} key={idx}>
                          {methodology.label}
                        </MenuItem>
                      );
                    })}
                </SelectDropdown>
              </Grid>
              <Grid item md={6}>
                <p
                  className={
                    error.request_date ? styles.errorFormTitle : styles.formTitle + "  supportText_semiBold"
                  }
                >
                  Request date *
                </p>
                <CustomDatePicker
                  className={styles.inputBlk}
                  onChange={(newValue: Date | null | undefined) => {
                    handleChange("request_date", newValue as Date);
                  }}
                  noMargin="noMargin"
                  value={formData.request_date}
                  error={error.request_date}
                  disabled={roleId === 1 || roleId === 2 ? false : true}
                />
              </Grid>
              <Grid item md={6}>
                <p
                  className={
                    error.due_date ? styles.errorFormTitle : styles.formTitle + "  supportText_semiBold"
                  }
                >
                  Due date *
                </p>
                <CustomDatePicker
                  className={styles.inputBlk}
                  onChange={(newValue: Date | null | undefined) => {
                    handleChange("due_date", newValue as Date);
                  }}
                  noMargin="noMargin"
                  value={formData.due_date}
                  error={error.due_date}
                  minDate={formData.request_date}
                  disabled={roleId === 1 || roleId === 2 ? false : true}
                />
              </Grid>
              <Grid item md={6}>
                <p
                  className={
                    error.scan_type ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                  }
                >
                  Type of Scan *
                </p>
                <SelectDropdown
                  value={formData.scan_type}
                  onChange={(e) => handleChange("scan_type", e.target.value)}
                  error={error.scan_type}
                  className={styles.inputBlk}
                  disabled={roleId === 1 || roleId ? false : true}
                >
                  <MenuItem value="" disabled>
                    <span className="disabled" style={{ fontSize: "16px" }}>
                      Select Scan Type
                    </span>
                  </MenuItem>
                  {(scanLength === 1 ? scanTypeOptions2 : scanTypeOptions).map((scanType, idx) => {
                    return (
                      <MenuItem value={scanType.value} key={idx}>
                        {scanType.label}
                      </MenuItem>
                    );
                  })}
                </SelectDropdown>
              </Grid>
              <Grid item md={6}>
                <CustomInput
                  label="URL *"
                  className={styles.inputBlk}
                  placeholder="Enter Url"
                  noMargin="noMargin"
                  value={formData.url}
                  onChange={(e) => handleChange("url", e.target.value)}
                  error={error.url}
                  disabled={roleId || roleId ? false : true}
                />
              </Grid>
              {formData.scan_type === 3 && (
                <Grid item md={12} sx={{ marginBottom: "8px" }}>
                  <p
                    className={
                      error.rescan ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                    }
                  >
                    Select Scan *
                  </p>
                  <CustomSelect
                    placeholder="Select Re-scan"
                    className={styles.inputBlk}
                    options={rescanOptions}
                    value={formData.rescan as HTMLInputElement}
                    onChange={(event: SyntheticEvent, newValue: string | TAutoComplete | null) => {
                      handleChange("rescan", newValue as string | TAutoComplete);
                    }}
                    noMargin="noMargin"
                    error={error.rescan}
                  />
                </Grid>
              )}
              <Grid item md={12} sx={{ marginBottom: "8px" }}>
                <p
                  className={
                    (error.requested_by ? styles.errorFormTitle : styles.formTitle) + " supportText_semiBold"
                  }
                >
                  Requested by *
                </p>

                <CustomMutipleSelect
                  options={[]}
                  onChange={(event: SyntheticEvent, val) => {
                    if (regExpression.email.test(val.slice(-1) as any) === false) {
                      setEmail("");
                      setFormData((form) => ({
                        ...form,
                        requested_by: val.slice(0, val.length - 1),
                      }));
                    } else {
                      handleChange(
                        "requested_by",
                        val.map((ele) => ele)
                      );
                      setEmail("");
                    }
                  }}
                  handleValue={handleValue}
                  value={[...(new Set(formData.requested_by) as any)]}
                  inputText={email.trim()}
                  handleChangeText={(e: SyntheticEvent) =>
                    setEmail((e.target as HTMLInputElement).value.trim())
                  }
                  placeholder="Enter email address, if multiple use comma or space separated"
                  error={error.requested_by}
                  onFocus={handleFocus}
                  disabled={roleId === 1 || roleId === 2 ? false : true}
                />
              </Grid>

              <Grid item md={12}>
                <p
                  className={error.owner ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"}
                >
                  Assigned to
                </p>
                <CustomSelect
                  placeholder="Select Researcher"
                  className={styles.inputBlk}
                  options={researchersOptions as TAutoComplete[]}
                  value={formData.owner as HTMLInputElement}
                  onChange={(event: SyntheticEvent, newValue: string | TAutoComplete | null) => {
                    handleChange("owner", newValue as string | TAutoComplete);
                  }}
                  noMargin="noMargin"
                  error={error.owner}
                  isDisable={roleId === 1 || roleId === 2 ? false : true}
                />
              </Grid>
              <Grid item md={12}>
                <p className={styles.formTitle + " supportText_semiBold"}>Security Score</p>
                <CustomInput
                  className={styles.inputBlk}
                  placeholder="Enter Security Score"
                  noMargin="noMargin"
                  value={formData?.security_score}
                  onChange={(e) => handleChange("security_score", e.target.value)}
                  type="number"
                />
              </Grid>
              <Grid item md={12}>
                <p className={styles.formTitle + " supportText_semiBold"}>Status</p>
                <SelectDropdown
                  value={formData.status}
                  onChange={(e) => handleChange("status", e.target.value)}
                  error={error.status}
                  className={styles.inputBlk}
                >
                  <MenuItem value={0}>
                    Pending
                  </MenuItem>
                  <MenuItem value={1}>
                    In Progress
                  </MenuItem>
                  <MenuItem value={2} disabled={roleId === 3 || roleId === 4 ? true : false}>
                    Discarded
                  </MenuItem>
                  <MenuItem value={3} disabled={roleId === 2 ? true : false}>
                    Completed
                  </MenuItem>
                </SelectDropdown>
              </Grid>
            </Grid>
            <div className={styles.buttonWrapper}>
              <CustomButton
                className="outlinedBtn"
                children="Cancel"
                type="button"
                onClick={() => navigate(-1)}
              />
              <CustomButton
                className={`${validateLoader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
                children="Update"
                type="submit"
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default EditScanForm;
