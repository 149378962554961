import { FC } from "react";
import { Link } from "react-router-dom";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { Grid } from "@mui/material";
import { getImage } from "../../../utils";

type IProps = {
  id?: number;
  name?: string;
  project: {
    uuid?: string;
    name?: string;
    version?: number;
    status?: number;
  };
  scan?: {
    due_date: string;
    id: number;
    owner: number;
    request_date: string;
    status: number;
  };
  vulnerability?: {
    id?: number;
    name?: string;
    description?: string;
  };
  score?: number;
  status?: number;
  risk_status?: number;
};

const InstanceListItem: FC<IProps> = ({ id, project, vulnerability, status, risk_status, scan, score }) => {
  return (
    <Link to={`/vulnerabilities/${project?.uuid}/scan-details/${scan?.id}`}>
      <div className={styles.projectListCardContainer + " paragraph"}>
        <Grid container spacing={2} className={styles.listCardItem}>
          <Grid item md={1} className={styles.projectId}>
            <p>{id}</p>
          </Grid>
          <Grid item md={2.5} className={styles.projectName}>
            <span>{project?.name}</span>
          </Grid>
          <Grid item md={3.5}>
            {vulnerability?.name}
          </Grid>
          <Grid item md={2} className={styles.projectRisk}>
            {risk_status === null ? (
              <img
                src={
                  score
                    ? score >= 0.1 && score <= 3.9
                      ? getImage("risk_status_low.svg")
                      : score >= 4 && score <= 6.9
                      ? getImage("risk_status_medium.svg")
                      : score >= 7 && score <= 8.9
                      ? getImage("risk_status_high_new.svg")
                      : score >= 9 && score <= 10
                      ? getImage("risk_status_critical.svg")
                      : ""
                    : getImage("risk_status_secure.svg")
                }
                alt=" "
              />
            ) : risk_status ? (
              <img
                src={
                  risk_status === 0
                    ? getImage("risk_status_low.svg")
                    : risk_status === 1
                    ? getImage("risk_status_medium.svg")
                    : risk_status === 2
                    ? getImage("risk_status_high_new.svg")
                    : risk_status === 3
                    ? getImage("risk_status_critical.svg")
                    : getImage("risk_status_secure.svg")
                }
                alt=" "
              />
            ) : (
              <img src={getImage("risk_status_low.svg")} alt=" " />
            )}
          </Grid>
          <Grid item md={2} className={styles.projectStatus + " supportText_semiBold"}>
            <img
              className={styles.statusImg}
              src={
                scan?.status === 0
                  ? getImage("status_pending.svg")
                  : scan?.status === 1
                  ? getImage("status_progress.svg")
                  : scan?.status === 2
                  ? getImage("delete_user.svg")
                  : scan?.status === 3
                  ? getImage("status_success.svg")
                  : null
              }
              alt="status"
            />
            <p
              className={
                scan?.status === 0
                  ? styles.pending
                  : scan?.status === 1
                  ? styles.progress
                  : scan?.status === 2
                  ? styles.discarded
                  : scan?.status === 3
                  ? styles.success
                  : ""
              }
            >
              {scan?.status === 0
                ? "Pending"
                : scan?.status === 1
                ? "In Progress"
                : scan?.status === 2
                ? "Discarded"
                : scan?.status === 3
                ? "Completed"
                : ""}
            </p>
          </Grid>
          <Grid item md={1} className={styles.viewDetailLink + " paragraph"}>
            <p>View details</p>
          </Grid>
        </Grid>
      </div>
    </Link>
  );
};

export default InstanceListItem;
