/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, forwardRef } from "react";
import { Container, Grid, Dialog, Slide, Tooltip } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import {
  TVulnerabilityResponse,
  TFindings,
  csvvMetrics,
  TGdpr,
  TCwe,
  TOwasp,
  TVulnerability,
} from "../../../types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { LinkRenderer } from "../../../shared_ui_components";
import { toolTipSeverity, toolTipScore, getImage } from "../../../utils";
//@ts-ignore
import insert from 'markdown-it-ins';
import  'highlight.js/styles/github.css';

type IProps = {
  id?: number;
  cvss_metrics?: csvvMetrics[];
  cwe?: TCwe[];
  description?: string;
  description_html?: string;
  gdpr?: TGdpr[];
  name?: string;
  risk_status?: number;
  owasp?: TOwasp[];
  vulnerability?: TVulnerabilityResponse;
  proof_of_concept?: string;
  proof_of_concept_html?: string;
  remidation?: string;
  remidation_html?: string;
  score?: number;
  risk_reason?: string;
  previous_risk_status?: number;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VulnerabilityDetails: FC<IProps> = ({
  id,
  name,
  risk_status,
  score,
  cvss_metrics,
  cwe,
  description,
  description_html,
  gdpr,
  owasp,
  proof_of_concept,
  proof_of_concept_html,
  remidation,
  remidation_html,
  vulnerability,
  risk_reason,
  previous_risk_status,
}) => {
  const navigate = useNavigate();
  const scanId = useParams().scanId;
  const uuid = useParams().id;
  const [openImage, setOpenImage] = useState(false);
  const [popupImage, setPopupImage] = useState<any>();

  const handleClose = () => {
    setOpenImage(!openImage);
  };

  const handleImageClick = (e: any) => {
    let el: any = e.target;
    while (el && el !== e.currentTarget && el.tagName !== "IMG") {
      el = el.parentNode;
    }
    if (el && el.tagName === "IMG") {
      setOpenImage(!openImage);
      setPopupImage(el.getAttribute("src"));
    }
  };

  const cvvs_score = score as number;

  function replaceLink(link: string) {
    const validUUID = new RegExp(
      '^[a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}$'
    );

    if (validUUID.test(link.toLowerCase())) {
      var scanAttachments = JSON.parse(localStorage.getItem('attachments') as string);
      if (scanAttachments) {
        link = scanAttachments[link];
      }
    }
    return link;
  }

  var mdParser = require('markdown-it')({
    replaceLink: replaceLink
  }).use(require('markdown-it-highlightjs'), {inline: true})
    .use(require('markdown-it-replace-link'))
    .use(insert)

  return (
    <Container maxWidth="lg">
      <div className={styles.VulnerabilityDetailWrapper}>
        <div className={styles.reportHeader}>
          <p className="heading4">{name} </p>
          <div className={styles.riskBlk}>
            {risk_status === null ? (
              <img
                src={
                  score
                    ? score >= 0.1 && score <= 3.9
                      ? getImage("risk_status_low.svg")
                      : score >= 4 && score <= 6.9
                      ? getImage("risk_status_medium.svg")
                      : score >= 7 && score <= 8.9
                      ? getImage("risk_status_high_new.svg")
                      : score >= 9 && score <= 10
                      ? getImage("risk_status_critical.svg")
                      : ""
                    : getImage("risk_status_secure.svg")
                }
                alt=" "
              />
            ) : (risk_status || risk_status === 0) && typeof previous_risk_status === "number" ? (
              <div>
                <img
                  src={
                    risk_status === 0
                      ? getImage("risk_status_low.svg")
                      : risk_status === 1
                      ? getImage("risk_status_medium.svg")
                      : risk_status === 2
                      ? getImage("risk_status_high_new.svg")
                      : risk_status === 3
                      ? getImage("risk_status_critical.svg")
                      : risk_status === 4
                      ? getImage("risk_status_passed.svg")
                      : ""
                  }
                  alt=" "
                />
                <span style={{ position: "relative", padding: "2px" }}>
                  <Tooltip title={toolTipSeverity(previous_risk_status, risk_status)} placement="top">
                    <img
                      src={getImage("info_icon.svg")}
                      style={{ height: "18px", cursor: "pointer" }}
                      alt=""
                    />
                  </Tooltip>
                </span>
              </div>
            ) : (
              <img
                src={
                  risk_status === 0
                    ? getImage("risk_status_low.svg")
                    : risk_status === 1
                    ? getImage("risk_status_medium.svg")
                    : risk_status === 2
                    ? getImage("risk_status_high_new.svg")
                    : risk_status === 3
                    ? getImage("risk_status_critical.svg")
                    : risk_status === 4
                    ? getImage("risk_status_passed.svg")
                    : ""
                }
                alt=" "
              />
            )}
          </div>
        </div>
        <div className={styles.implicationBlk}>
          <p className={styles.implicationTitle + " heading6"}>{vulnerability?.name}</p>
          <p className="paragraph">{vulnerability?.description && vulnerability?.description}</p>
        </div>
        {risk_reason && (
          <div className={styles.descBlk}>
            <p className={styles.descTitle + " heading6"}>Severity Override Reason</p>
            <div className={styles.descOuterBlk}>
              <p className="paragraph">{risk_reason}</p>
            </div>
          </div>
        )}
        {description && (
          <div className={styles.descBlk}>
            <p className={styles.descTitle + " heading6"}>Description</p>
            <div className={styles.descOuterBlk}>
              <ReactMarkdown
                children={mdParser.render(description) as string}
                components={{ a: LinkRenderer }}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                className={styles.markdown}
              />
            </div>
          </div>
        )}
        <div className={styles.regulatoryBlk}>
          <p className={styles.scanTitle + " heading6"}>Regulatory</p>
          <div className={styles.regulatoryBody}>
            <Grid container alignItems="center" className={styles.titleBlk}>
              <Grid item md={4} className="paragraph_semiBold">
                <p>CVSSv3</p>
              </Grid>
              <Grid item md={4} className="paragraph">
                <p className={styles.headerScore + " paragraph"}>
                  {typeof risk_status === "number" && typeof previous_risk_status === "number" ? (
                    <>
                      {" "}
                      <span
                        className={
                          risk_status === 0
                            ? styles.statusLow
                            : risk_status === 1
                            ? styles.statusMedium
                            : risk_status === 2
                            ? styles.statusHigh
                            : risk_status === 3
                            ? styles.statusCritical
                            : styles.statusNone
                        }
                      >
                        {risk_status === 0
                          ? 3.9
                          : risk_status === 1
                          ? 6.9
                          : risk_status === 2
                          ? 8.9
                          : risk_status === 3
                          ? 10
                          : "0.0"}
                        /10
                      </span>{" "}
                      {risk_status === 0
                        ? "Low"
                        : risk_status === 1
                        ? "Medium"
                        : risk_status === 2
                        ? "High"
                        : risk_status === 3
                        ? "Critical"
                        : "Secure"}
                      <span style={{ padding: "4px" }}>
                        <Tooltip title={toolTipScore(previous_risk_status, risk_status)} placement="top">
                          <img
                            src={getImage("info_icon.svg")}
                            style={{ height: "18px", position: "relative", top: "3px", cursor: "pointer" }}
                            alt=""
                          />
                        </Tooltip>
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className={
                          cvvs_score >= 0.1 && cvvs_score <= 3.9
                            ? styles.statusLow
                            : cvvs_score >= 4 && cvvs_score <= 6.9
                            ? styles.statusMedium
                            : cvvs_score >= 7 && cvvs_score <= 8.9
                            ? styles.statusHigh
                            : cvvs_score >= 9 && cvvs_score <= 10
                            ? styles.statusCritical
                            : styles.statusNone
                        }
                      >
                        {cvvs_score}/10
                      </span>{" "}
                      {cvvs_score
                        ? cvvs_score >= 0.1 && cvvs_score <= 3.9
                          ? "Low"
                          : cvvs_score >= 4 && cvvs_score <= 6.9
                          ? "Medium"
                          : cvvs_score >= 7 && cvvs_score <= 8.9
                          ? "High"
                          : cvvs_score >= 9 && cvvs_score <= 10
                          ? "Cricital"
                          : "None"
                        : "Secure"}{" "}
                    </>
                  )}
                </p>
              </Grid>
            </Grid>
            <Grid container alignItems="flex-start" className={styles.metricsBlk}>
              <Grid item md={4} className="paragraph_semiBold">
                <p>CVSS Metrics</p>
              </Grid>
              <Grid item md={4} className="paragraph">
                {cvss_metrics?.map((cvss, id) => {
                  return <p key={id}>{cvss?.name}</p>;
                })}
              </Grid>
              <Grid item md={4} className="paragraph">
                {cvss_metrics?.map((cvss, id) => {
                  return (
                    <p>
                      {
                        cvss?.options.find((opt) => {
                          return opt?.selected === true;
                        })?.name
                      }
                    </p>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={styles.middleBlk}>
              <Grid item md={4} className="paragraph_semiBold">
                <p>OWASP</p>
              </Grid>
              <Grid item md={4} className="paragraph">
                {owasp?.map((owa) => {
                  return <p>{owa?.name}</p>;
                })}
              </Grid>
              <Grid item md={4} className="paragraph">
                {owasp?.map((owa) => {
                  return <p>{owa?.title ? owa?.title : "N/A"}</p>;
                })}
              </Grid>
            </Grid>
            {cwe?.length ? (
            <Grid container alignItems="center" className={styles.middleBlk}>
              <Grid item md={4} className="paragraph_semiBold">
                <p>CWE</p>
              </Grid>
              <Grid item md={4} className="paragraph">
                {cwe?.map((_cwe) => {
                  return <p>{_cwe?.name}</p>;
                })}
              </Grid>
              <Grid item md={4} className="paragraph">
                {cwe?.map((_cwe) => {
                  return <p>{_cwe?.description}</p>;
                })}
              </Grid>
            </Grid>
            ) : <></>}
            {gdpr?.length ? (
              <Grid container alignItems="center" className={styles.lastBlk}>
                <Grid item md={4} className="paragraph_semiBold">
                  <p>GDPR</p>
                </Grid>
                <Grid item md={4} className="paragraph">
                  {gdpr?.map((_gdpr) => {
                    return <p>{_gdpr?.name}</p>;
                  })}
                </Grid>
                <Grid item md={4} className="paragraph">
                  {gdpr?.map((_gdpr) => {
                    return <p>{_gdpr?.description ? _gdpr?.description : "N/A"}</p>;
                  })}
                </Grid>
              </Grid>
            ) : <></>}
          </div>
        </div>
        {proof_of_concept && (
          <div className={styles.pocBlk}>
            <p className={styles.descTitle + " heading6"}>Proof Of Concept</p>
            <div className={styles.descOuterBlk}>
              <p className="paragraph">
                <div
                  onClick={handleImageClick}
                />
                <ReactMarkdown
                  children={mdParser.render(proof_of_concept) as string}
                  components={{ a: LinkRenderer }}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  className={styles.markdown}
                />
              </p>
            </div>
          </div>
        )}
        {remidation && (
          <div className={styles.remediationBlk}>
            <p className={styles.descTitle + " heading6"}>Remediation</p>
            <div className={styles.descOuterBlk}>
              <p className="paragraph">
                <div
                  onClick={handleImageClick}
                />
                <ReactMarkdown
                  children={mdParser.render(remidation) as string}
                  components={{ a: LinkRenderer }}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  className={styles.markdown}
                />
              </p>
            </div>
          </div>
        )}
        <Dialog open={openImage} TransitionComponent={Transition} className="fullImageDialogContainer">
          <div>
            <div className="fullImageHeader">
              <img
                className="closeIcon"
                src={getImage("close_icon_white.svg")}
                alt=" "
                onClick={handleClose}
              />
              {popupImage && <img className="fullImageView" src={getImage(`${popupImage}`)} alt=" " />}
            </div>
          </div>
        </Dialog>
      </div>
    </Container>
  );
};

export default VulnerabilityDetails;
