import { ComponentType, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Header, PageNotFound, DashboardTabs } from "../shared_elements";
import { GlobalContext } from "../context/GlobalContext";

export default function pageLayoutHoc<T>(HocComponent: ComponentType<any>) {
  function PageLayoutHoc(props: T) {
    const {
      state: { roleLists },
    } = useContext(GlobalContext);
    const { id, scanId, analysisId, occurenceId } = useParams();
    const location = useLocation();
    const getRoleId = roleLists?.roleData?.id;

    const withHeaderRoutes = [
      "/projects",
      "/projects/",
      `/projects/${id}`,
      `/projects/${id}/update-scan/${scanId}`,
      `/projects/${id}/scan-details/${scanId}`,
      `/projects/${id}/scan-details/${scanId}/add-findings`,
      `/projects/${id}/scan-details/${scanId}/update-findings/${analysisId}`,
      `/projects/${id}/update-project`,
      `/projects/${id}/reports`,
      "/vulnerabilities",
      "/vulnerabilities/",
      `/vulnerabilities/${id}/update`,
      `/vulnerability-category/${id}/update`,
      `/vulnerabilities/${id}/update-scan/${scanId}`,
      `/vulnerabilities/${id}/scan-details/${scanId}`,
      `/vulnerabilities/${id}/scan-details/${scanId}/add-findings`,
      `/vulnerabilities/${id}/scan-details/${scanId}/update-findings/${analysisId}`,
      "/clients",
      "/clients/",
      `/clients/${id}`,
      "/users",
      "/users/",
    ];

    const withoutHeaderRoutes = [
      `/clients/${id}/users`,
      `/projects/${id}/scan-details/${scanId}/findings-details/${analysisId}`,
      `/vulnerabilities/${id}/scan-details/${scanId}/findings-details/${analysisId}`,
      `/vulnerability-category/${id}/occurences`,
      `/vulnerabilities/${id}/occurences/${occurenceId}`,
    ];

    return (
      <div>
        {withHeaderRoutes.includes(location.pathname) ? (
          <>
            <Header />
            <DashboardTabs />
          </>
        ) : withoutHeaderRoutes.includes(location.pathname) ? null : (
          <PageNotFound />
        )}
        <HocComponent role={getRoleId} {...props} />
      </div>
    );
  }
  return PageLayoutHoc;
}
