import { FC, Fragment, ChangeEvent, useState, useContext } from "react";
import { Grid } from "@mui/material";
import styles from "../../../assets/styles/home.module.scss";
import { AnalysisListItem, VulnerabilityListItem } from ".";
import { CustomButton, NoResult } from "../../../shared_ui_components";
import { Search } from "../../../shared_elements";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { TAnalysis } from "../../../types";
import { GlobalContext } from "../../../context/GlobalContext";
import { getImage } from "../../../utils";

type TSearchType = {
  val: string;
  list: TAnalysis[];
};

type IProps = {
  analysis: TAnalysis[] | [];
  permissions?: boolean;
  permissionsAdd?: boolean;
  status?: number;
  scan_type?: number;
  handleDeleteAnalysis: (id: number) => void;
};

const AnalysisList: FC<IProps> = (props) => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const analysisList = props?.analysis;
  const permission = props?.permissions;
  const permissions_add = props?.permissionsAdd;
  const scan_status = props?.status;
  const scan_type = props?.scan_type;
  const { handleDeleteAnalysis } = props;

  const { id, scanId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const roleId = roleLists?.roleData?.id;

  const [filteredRisk, setFilteredRisk] = useState<TAnalysis[] | []>([]);
  const [search, setSearch] = useState<TSearchType>({
    val: "",
    list: [],
  });
  const [filterStatus, setFilterStatus] = useState(true);
  const [filterBtn, setFilterBtn] = useState<string | number>("all");

  const handleSearch = (val: string) => {
    let str = val.toLowerCase();
    let searchList = (filteredRisk.length > 0 ? filteredRisk : analysisList)?.filter((details) => {
      return details?.name?.toLowerCase().includes(str);
    });
    setSearch(() => ({
      val: str,
      list: searchList,
    }));
  };
  const handleRisKFilter = (val: number | string, num1?: number, num2?: number) => {
    if (val === "all") {
      setFilteredRisk(analysisList);
      setFilterStatus(true);
    } else if (num1 && num2) {
      setFilteredRisk(
        analysisList?.filter(
          (status) =>
            status?.risk_status === val ||
            (status?.risk_status === null && status?.score && status?.score >= num1 && status?.score <= num2)
        )
      );
      setFilterStatus(false);
    } else {
      setFilteredRisk(
        analysisList?.filter(
          (status) => status?.risk_status === val || (status?.risk_status === null && status?.score === 0)
        )
      );
      setFilterStatus(false);
    }
    setFilterBtn(val);
  };

  const handleAddFindings = () => {
    if (location.pathname.includes("vulnerabilities")) {
      navigate(`/vulnerabilities/${id}/update-scan/${scanId}`);
    } else {
      navigate(`/projects/${id}/scan-details/${scanId}/add-findings`);
    }
  };

  return (
    <>
      <div className={styles.analysisTitleWrapper}>
        <p className={styles.scanListViewTitle + " heading6"}>
          {roleId === 5 ? "Findings details" : "Findings"}
          <span>{analysisList?.length}</span>
        </p>
        <div className={styles.analysisTitleRightBlk}>
          <Search
            text={search.val}
            placeholder="Search for vulnerability details"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
          />
          {scan_status !== 3 && scan_status !== 2 && permissions_add && (
            <CustomButton
              className=" primaryBtn"
              children="Add Findings"
              type="button"
              onClick={handleAddFindings}
            />
          )}
        </div>
      </div>
      <div className={styles.analysisWrapper}>
        <div className={styles.analysisFilterWrapper}>
          <CustomButton
            className={`${styles.filterBtn}  ${filterBtn === "all" ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => handleRisKFilter("all")}
          >
            All<span className="labelText">{analysisList.length}</span>
          </CustomButton>
          <CustomButton
            className={`${styles.filterBtn} ${filterBtn === 3 ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => handleRisKFilter(3, 9, 10)}
          >
            Critical risk{" "}
            <span className="labelText">
              {
                (analysisList
                  ?.filter((status) => status?.risk_status === 3)
                  .concat(
                    analysisList?.filter(
                      (status) =>
                        status?.risk_status === null &&
                        status?.score &&
                        status?.score >= 9 &&
                        status?.score <= 10
                    )
                  )).length
              }
            </span>
          </CustomButton>
          <CustomButton
            className={`${styles.filterBtn} ${filterBtn === 2 ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => handleRisKFilter(2, 7, 8.9)}
          >
            High risk{" "}
            <span className="labelText">
              {
                (analysisList
                  ?.filter((status) => status?.risk_status === 2)
                  .concat(
                    analysisList?.filter(
                      (status) =>
                        status?.risk_status === null &&
                        status?.score &&
                        status?.score >= 7 &&
                        status?.score <= 8.9
                    )
                  )).length
              }
            </span>
          </CustomButton>
          <CustomButton
            className={`${styles.filterBtn} ${filterBtn === 1 ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => handleRisKFilter(1, 4, 6.9)}
          >
            Medium risk{" "}
            <span className="labelText">
              {
                (analysisList
                  ?.filter((status) => status?.risk_status === 1)
                  .concat(
                    analysisList?.filter(
                      (status) =>
                        status?.risk_status === null &&
                        status?.score &&
                        status?.score >= 4 &&
                        status?.score <= 6.9
                    )
                  )).length
              }
            </span>
          </CustomButton>
          <CustomButton
            className={`${styles.filterBtn} ${filterBtn === 0 ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => handleRisKFilter(0, 0.1, 3.9)}
          >
            Low risk{" "}
            <span className="labelText">
              {
                (analysisList
                  ?.filter((status) => status?.risk_status === 0)
                  .concat(
                    analysisList?.filter(
                      (status) =>
                        status?.risk_status === null &&
                        status?.score &&
                        status?.score >= 0.1 &&
                        status?.score <= 3.9
                    )
                  )).length
              }
            </span>
          </CustomButton>
          <CustomButton
            className={`${styles.filterBtn} ${filterBtn === 4 ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => handleRisKFilter(4, 0, 0)}
          >
            Passed{" "}
            <span className="labelText">
              {
                (analysisList
                  ?.filter((status) => status?.risk_status === 4)
                  .concat(
                    analysisList?.filter((status) => status?.risk_status === null && status?.score === 0)
                  )).length
              }
            </span>
          </CustomButton>
        </div>
        <Grid container className={styles.analysisHeader + " labelText_semiBold"}>
          <Grid item md={1}>
            <p>SL.NO</p>
          </Grid>
          <Grid item md={roleId === 5 ? (scan_type === 3 ? 3.5 : 4) : scan_type === 3 ? 4 : 5}>
            <p>TITLE</p>
          </Grid>
          <Grid
            item
            md={roleId === 5 ? (scan_type === 3 ? 2.5 : 2.5) : scan_type === 3 ? 2.5 : 3}
            style={{ marginLeft: "-8px" }}
          >
            <p>{roleId === 5 ? "CATEGORY" : "SEVERITY"}</p>
          </Grid>
          <Grid
            item
            md={roleId === 5 ? (scan_type === 3 ? 1.5 : 2) : 2}
            style={roleId !== 5 ? { marginLeft: "-4px" } : {}}
          >
            <p>{roleId === 5 ? "SEVERITY" : "CVSSv3 SCORE"}</p>
          </Grid>
          {roleId === 5 && (
            <Grid item md={1.5}>
              <p>CVSSv3 SCORE</p>
            </Grid>
          )}
          {scan_type === 3 && (
            <Grid item md={roleId === 5 ? (scan_type === 3 ? 1 : 1.5) : 1.5} sx={{ paddingLeft: "-12px" }}>
              <p>STATUS</p>
            </Grid>
          )}
          <Grid item md={1}>
            ACTION
          </Grid>
        </Grid>
        {search.val ? (
          search.list.length === 0 ? (
            <NoResult
              image={getImage("no_file_found.svg")}
              title="No Result Found"
              subtext={<Fragment>We didn't find any findings that matched your search</Fragment>}
            />
          ) : roleId === 5 ? (
            search.list?.map((details, index) => {
              return (
                <VulnerabilityListItem
                  key={index}
                  {...details}
                  scan_type={scan_type}
                  scan_status={scan_status}
                  index={index + 1}
                />
              );
            })
          ) : (
            search.list?.map((details, index) => {
              return (
                <AnalysisListItem
                  key={index}
                  {...details}
                  permission={permission}
                  scan_status={scan_status}
                  scan_type={scan_type}
                  index={index + 1}
                />
              );
            })
          )
        ) : (
          <>
            {filterStatus === false && filteredRisk.length === 0 ? (
              <NoResult
                image={getImage("no_file_found.svg")}
                title="No Result Found"
                subtext={<Fragment>We didn't find any findings that matched your search</Fragment>}
              />
            ) : roleId === 5 ? (
              (filteredRisk.length > 0 ? filteredRisk : analysisList)?.map((details, index) => {
                return (
                  <VulnerabilityListItem
                    key={index}
                    {...details}
                    scan_type={scan_type}
                    scan_status={scan_status}
                    index={index + 1}
                  />
                );
              })
            ) : (
              (filteredRisk.length > 0 ? filteredRisk : analysisList)?.map((details, index) => {
                return (
                  <AnalysisListItem
                    key={index}
                    {...details}
                    permission={permission}
                    scan_status={scan_status}
                    scan_type={scan_type}
                    handleDeleteAnalysis={handleDeleteAnalysis}
                    index={index + 1}
                  />
                );
              })
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AnalysisList;
