/* eslint-disable jsx-a11y/alt-text */
import { FC, useState, useMemo, useRef, SyntheticEvent, KeyboardEvent, useContext } from "react";
import { Tooltip } from "@mui/material";
import { errorCodes } from "../../../constants";
import { CustomButton, CustomInput } from "../../../shared_ui_components";
import { Errors } from "../../../types";
import { fieldValidation } from "../../../utils/formValidations";
import {
  _Length,
  _LowerCase,
  _UpperCase,
  _Number,
  _SpecialCharacter,
  toastFlashMessage,
  getImage,
} from "../../../utils";
import { PasswordValidator } from "../../../shared_elements";
import { updatePassword, logoutApi } from "../apiServices";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { TOAST_ACTIONS } from "../../../context/ToastReducer";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";

type IUpdatePasswordProps = {
  step: number;
  updateStep: (val: number) => void;
  handleCancel: () => void;
};
type FormControl = /*unresolved*/ any;

const UpdatePassword: FC<IUpdatePasswordProps> = ({ step, updateStep, handleCancel }) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });
  const [showPassword, setShowPassword] = useState({
    current_password: false,
    password: false,
    confirm_password: false,
  });
  const [showPasswordHint, setPasswordHint] = useState({
    password: false,
    confirm_password: false,
  });

  const confirmPasswordRef = useRef<any>(null);
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);

  const handleChange = (key: string, val: string) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };
  const handlePasswordHint = (key: string, value: boolean) => {
    setPasswordHint({
      ...showPasswordHint,
      [key]: value,
    });
  };

  const handleShowPassword = (key: string, val: boolean) => {
    setShowPassword({
      ...showPassword,
      [key]: !val,
    });
  };

  const passwordValidation = useMemo(() => {
    return {
      password: {
        minCharacter: _Length(formData.password),
        oneNumber: _Number(formData.password),
        oneUpperCase: _UpperCase(formData.password),
        oneLowerCase: _LowerCase(formData.password),
        oneSpecialCharacter: _SpecialCharacter(formData.password),
      },
      confirm_password: {
        minCharacter: _Length(formData.confirm_password),
        oneNumber: _Number(formData.confirm_password),
        oneUpperCase: _UpperCase(formData.confirm_password),
        oneLowerCase: _LowerCase(formData.confirm_password),
        oneSpecialCharacter: _SpecialCharacter(formData.confirm_password),
      },
    };
  }, [formData]);

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validateNewInput: {
      [key: string]: string;
    } = {
      current_password:
        errorCode["password"][
          fieldValidation({
            ...errorCode["passwordObj"],
            fieldval: formData.current_password,
          })
        ],
      password:
        errorCode["newPassword"][
          fieldValidation({
            ...errorCode["newPasswordObj"],
            fieldval: formData.password,
          })
        ],
      confirm_password:
        errorCode["newPassword"][
          fieldValidation({
            ...errorCode["newPasswordObj"],
            fieldval: formData.confirm_password,
          })
        ],
    };

    if (formData.password !== formData.confirm_password) {
      validateNewInput.confirm_password = "Your password do not match, Please verify and try again";
    }
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      updatePassword(formData).then(
        (response: Partial<{ statusCode: number; data: any; message: string }>) => {
          if (response.statusCode === 200) {
            logoutApi().then((response: Partial<{ statusCode: number; message: string }>) => {
              if (response.statusCode === 200) {
                dispatch({
                  type: TOAST_ACTIONS.Clear,
                  payload: "",
                });
                dispatch({
                  type: PROJECT_ACTIONS.Clear,
                });
                localStorage.clear();
                navigate("/signin");
              } else {
              }
            });
            setLoader(false);
            toastFlashMessage(response.message as string, "success");
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoader(false);
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<FormControl>) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="editPasswordWrapper">
          <div className="passwordWrapper">
            <CustomInput
              className="paragraph"
              label="Current password *"
              placeholder="**********"
              type={showPassword.current_password ? "text" : "password"}
              inputRef={confirmPasswordRef}
              onChange={(e) => handleChange("current_password", e.target.value)}
              error={error.current_password}
              value={formData.current_password}
              onKeyPress={handleKeyPress}
            />
            <span
              className="showPassword"
              onClick={() => {
                handleShowPassword("current_password", showPassword.current_password);
                confirmPasswordRef?.current?.focus();
              }}
            >
              {showPassword.current_password ? (
                <img src={getImage("eye-open.svg")} />
              ) : (
                <img src={getImage("eye-close.svg")} />
              )}
            </span>
          </div>

          <div className="passwordWrapper">
            <Tooltip
              title={
                showPasswordHint.password && (
                  <PasswordValidator passwordValidation={passwordValidation.password} />
                )
              }
              arrow
              placement="right-start"
            >
              <CustomInput
                className="paragraph"
                label="New password *"
                placeholder="**********"
                type={showPassword.password ? "text" : "password"}
                inputRef={confirmPasswordRef}
                onChange={(e) => handleChange("password", e.target.value)}
                error={error.password}
                value={formData.password}
                onFocus={() => {
                  handlePasswordHint("password", true);
                }}
                onBlur={() => {
                  handlePasswordHint("password", false);
                }}
                onKeyPress={handleKeyPress}
              />
            </Tooltip>
            <span
              className="showPassword"
              onClick={() => {
                handleShowPassword("password", showPassword.password);
                confirmPasswordRef?.current?.focus();
              }}
            >
              {showPassword.password ? (
                <img src={getImage("eye-open.svg")} />
              ) : (
                <img src={getImage("eye-close.svg")} />
              )}
            </span>
          </div>

          <div className="passwordWrapper">
            <CustomInput
              className="paragraph"
              label="Confirm new password *"
              placeholder="**********"
              type={showPassword.confirm_password ? "text" : "password"}
              inputRef={confirmPasswordRef}
              onChange={(e) => handleChange("confirm_password", e.target.value)}
              error={error.confirm_password}
              value={formData.confirm_password}
              onKeyPress={handleKeyPress}
            />
            <span
              className="showPassword"
              onClick={() => {
                handleShowPassword("confirm_password", showPassword.confirm_password);
                confirmPasswordRef?.current?.focus();
              }}
            >
              {showPassword.confirm_password ? (
                <img src={getImage("eye-open.svg")} alt="" />
              ) : (
                <img src={getImage("eye-close.svg")} alt="" />
              )}
            </span>
          </div>
        </div>
        <div className="buttonWrapper">
          <CustomButton
            className="outlinedBtn"
            children={step === 1 ? "Cancel" : "Back"}
            type="submit"
            onClick={() => updateStep(1)}
          />
          <CustomButton
            className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
            children={loader ? "Updating" : "Update"}
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default UpdatePassword;
