import { ActionMap } from "../types";
import { TProjectState } from "./GlobalContext";

export enum PROJECT_ACTIONS {
  Get = "GET_All_PROJECTS",
  Add = "ADD_NEW_PROJECTS",
  Update = "UPDATE_PROJECTS",
  Loading = "LOADING",
  Clear = "CLEAR",
}

type ProjectPayload = {
  [PROJECT_ACTIONS.Get]: undefined;
  [PROJECT_ACTIONS.Add]: undefined;
  [PROJECT_ACTIONS.Update]: undefined;
  [PROJECT_ACTIONS.Loading]: undefined;
  [PROJECT_ACTIONS.Clear]: undefined;
};

export type ClientActions = ActionMap<ProjectPayload>[keyof ActionMap<ProjectPayload>];

export const ProjectReducers = (state: TProjectState, action: ClientActions | any) => {
  switch (action.type) {
    case PROJECT_ACTIONS.Get:
      return {
        ...state,
        projectData: [...action.payload.list],
        loading: false,
        pagination: action.payload?.pagination,
        showNoState: action.payload?.list.length > 0 ? false : true,
      };

    case PROJECT_ACTIONS.Add:
      return {
        ...state,
        projectData: [action.payload, ...state.projectData],
        loading: false,
        showNoState: false,
      };

    case PROJECT_ACTIONS.Update:
      return {
        ...state,
        projectData: state?.projectData?.map((project) => {
          return project?.uuid === action?.payload?.uuid ? { ...project, ...action.payload } : { ...project };
        }),
        loading: false,
        showNoState: false,
      };

    case PROJECT_ACTIONS.Loading:
      return {
        ...state,
        loading: action.payload,
      };
    case PROJECT_ACTIONS.Clear:
      return {
        ...state,
        projectData: [],
        loading: true,
        showNoState: false,
      };

    default:
      return state;
  }
};
