import { FC, SyntheticEvent, useState, useEffect, useContext } from "react";
import { Dialog, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { errorCodes, regExpression } from "../../../constants";
import {
  CustomButton,
  CustomInput,
  CustomSelect,
  CustomMutipleSelect,
} from "../../../shared_ui_components";
import { GlobalContext } from "../../../context/GlobalContext";
import { fieldValidation } from "../../../utils/formValidations";
import {
  toastFlashMessage,
  clientNameList,
  listingAllClients,
  getImage,
} from "../../../utils";
import { addNewProject, updateProject } from "../apiServices";
import { TAutoComplete, Errors, TProjectResponse, TClients, T } from "../../../types";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";

type TDialogProps = {
  open: boolean;
  onClose: (value: string) => void;
  type: string;
  projectId?: string;
  projectName?: string;
  clientName?: string;
  clientId?: string;
  projectUrl?: string;
  projectDescription?: string;
  projectScanType?: string | number;
  clientUsers?: string[];
  clientUsersId?: { email: string; uuid: string }[] | [];
  handleUpdateClientSingleProject?: (p: TProjectResponse) => void;
  handleAddClientProject?: (a: TProjectResponse) => void;
  handleSearch?: (p: string) => void;
};

export type IStateProject = {
  name?: string;
  description?: string;
  client?: string | null | TAutoComplete;
  project_id?: string | boolean | number;
  client_users?: any;
  removed_users?: string;
};

const AddProjectDialog: FC<TDialogProps> = ({
  open,
  onClose,
  type,
  projectId,
  projectName,
  clientName,
  clientId,
  projectUrl,
  projectDescription,
  clientUsers,
  clientUsersId,
  handleUpdateClientSingleProject,
  handleAddClientProject,
  handleSearch,
}): JSX.Element => {
  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const {
    state: { clientLists },
  } = useContext(GlobalContext);

  const [openDetail, setOpenDetail] = useState(false);
  const [formData, setFormData] = useState<IStateProject>({
    client: "",
    name: "",
    description: "",
    project_id: "",
    client_users: [],
  });
  const [email, setEmail] = useState("");
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);

  const resetFormData = () => {
    setFormData({
      ...formData,
      client: "",
      name: "",
      description: "",
      client_users: [],
    });
  };
  const handleCancel = () => {
    if (clientId) {
      onClose("false");
      setError({});
    } else {
      onClose("false");
      resetFormData();
      setError({});
    }
    setLoader(false);
  };

  useEffect(() => {
    if (formData.client) {
      setOpenDetail(true);
    } else {
      setOpenDetail(false);
      resetFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.client]);

  /* To show the existing data while editing*/
  useEffect(() => {
    if (clientId && clientName) {
      setFormData((form) => ({
        ...form,
        client: {
          label: clientName ? clientName : "",
          value: clientId ? clientId : "",
        },
        name: projectName ? projectName : "",
        description: projectDescription,
        client_users: clientUsers ? clientUsers : [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, clientName]);

  const handleChange = (key: string, val: TAutoComplete | string | T | any) => {
    if (key === "name") {
      setFormData({
        ...formData,
        name: typeof val !== "string" ? val && val?.value : val,
        project_id: typeof val !== "string" && val?.label,
      });
    } else {
      setFormData({
        ...formData,
        [key]: val,
      });
    }
    setError({
      ...error,
      [key]: "",
    });
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validateNewInput: {
      [key: string]: string;
    };
    validateNewInput = {
      name: errorCode["projectName"][
        fieldValidation({
          ...errorCode["projectNameObj"],
          fieldval:
            typeof formData?.name === "string"
              ? (formData.name as string)
              : formData?.name && (formData?.name as HTMLInputElement)["value"],
        })
      ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      const form_data: IStateProject = {
        client: formData?.client && (formData?.client as HTMLInputElement)["value"],
        name: formData.name,
        description: formData.description,
        project_id: formData?.project_id ? formData?.project_id : "",
        client_users: formData?.client_users?.join(","),
        removed_users: clientUsersId
          ?.filter((ele) => !formData.client_users.includes(ele.email))
          .map((ele) => ele.uuid)
          .join(","),
      };
      if (type === "Create New Project") {
        addNewProject(form_data).then(
          (response: Partial<{ statusCode: number; data: TProjectResponse; message: string }>) => {
            if (response.statusCode === 200) {
              dispatch({
                type: PROJECT_ACTIONS.Add,
                payload: response.data,
              });
              setLoader(false);
              toastFlashMessage(response.message as string, "success");
              handleCancel();
              if (clientId && clientName) {
                handleAddClientProject?.(response.data as TProjectResponse);
                navigate(`/clients/${clientId}`);
                setFormData({
                  ...formData,
                  name: "",
                  description: "",
                  client_users: [],
                });
              } else {
              }
              listingAllClients();
            } else {
              setLoader(false);
              toastFlashMessage(response.message as string, "error");
            }
          }
        );
      } else {
        updateProject(form_data, projectId as string).then(
          (response: Partial<{ statusCode: number; data: TProjectResponse; message: string }>) => {
            if (response.statusCode === 200) {
              dispatch({
                type: PROJECT_ACTIONS.Update,
                payload: response.data,
              });
              setLoader(false);
              toastFlashMessage(response.message as string, "success");
              onClose("false");
              handleUpdateClientSingleProject?.(response?.data as TProjectResponse);
              handleSearch?.("");
              listingAllClients();
            } else {
              setLoader(false);
              toastFlashMessage(response.message as string, "error");
            }
          }
        );
      }
    } else {
      setError(validateNewInput);
    }
  };

  const handleValue = (event: any) => {
    switch (event.key) {
      case ",":
      case " ":
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.trim()) {
          if (regExpression.email.test(event.target.value) === false) {
            setEmail("");
          } else {
            handleChange?.("client_users", [...formData.client_users, event.target.value]);
            setEmail("");
          }
        }

        break;
      default:
    }
  };

  return (
    <Dialog onClose={onClose} open={open} sx={{ borderRadius: "4px" }}>
      <div className={styles.dialogWrapper}>
        <div className={styles.headerTitle}>
          <p className="heading5">{type}</p>
          <img src={getImage("close_icon.svg")} alt="close" onClick={handleCancel} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.formDetail}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <p className={styles.formTitle + " supportText_semiBold"}>Client *</p>
                <CustomSelect
                  placeholder="Select Client *"
                  className={styles.inputBlk}
                  options={clientNameList(clientLists?.clientData as TClients[]) as TAutoComplete[]}
                  value={formData?.client as HTMLInputElement}
                  onChange={(event: SyntheticEvent, newValue?: string | TAutoComplete | null) => {
                    handleChange("client", newValue as string | TAutoComplete);
                  }}
                  isDisable={clientId ? true : false}
                  noMargin="noMargin"
                  error={error.client}
                />
              </Grid>

              {openDetail && (
                <>
                  <Grid item md={12}>
                    <CustomInput
                      label="Project Name *"
                      className={styles.inputBlk}
                      placeholder="Enter Project Name"
                      noMargin="noMargin"
                      value={formData.name ? formData.name : ""}
                      onChange={(e) => handleChange("name", e.target.value)}
                      error={error.name}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <CustomInput
                      multiline
                      label="Description"
                      className={styles.inputBlk}
                      placeholder="Enter Description"
                      noMargin="noMargin"
                      value={formData.description}
                      onChange={(e) => handleChange("description", e.target.value)}
                      error={error.description}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <p className={styles.formTitle + " supportText_semiBold"}>Invite Client on this Project</p>
                    <CustomMutipleSelect
                      options={[]}
                      onChange={(event: SyntheticEvent, val) => {
                        if (regExpression.email.test(val.slice(-1) as any) === false) {
                          setEmail("");
                          setFormData((form) => ({
                            ...form,
                            client_users: val.slice(0, val.length - 1),
                          }));
                        } else {
                          handleChange(
                            "client_users",
                            val.map((ele) => ele)
                          );
                          setEmail("");
                        }
                      }}
                      placeholder="Enter email address, if multiple use comma or space separated"
                      handleValue={handleValue}
                      value={formData.client_users}
                      inputText={email.trim()}
                      handleChangeText={(e: SyntheticEvent) =>
                        setEmail((e.target as HTMLInputElement).value.trim())
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
          <div className={styles.buttonWrapper}>
            <CustomButton className="outlinedBtn" children="Cancel" type="button" onClick={handleCancel} />
            {openDetail ? (
              <CustomButton
                className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
                children={
                  type === "Create New Project"
                    ? `${loader ? "Creating..." : "Create"}`
                    : `${loader ? "Updating..." : "Update"}`
                }
                type="submit"
              />
            ) : (
              <CustomButton
                className={" primaryBtn disabledBtn"}
                children={`${loader ? "Creating..." : "Create"}`}
                type="button"
              />
            )}
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddProjectDialog;
