import { FC, SyntheticEvent, useState } from "react";
import { CustomButton, CustomInput, CustomLink } from "../../../shared_ui_components";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/styles/auth.module.scss";
import { errorCodes } from "../../../constants";
import { fieldValidation } from "../../../utils/formValidations";
import { Errors, TForgotPasswordType } from "../../../types";
import { forgetPasswordApi } from "../apiServices";
import { toastFlashMessage } from "../../../utils";

const PasswordRecoveryForm: FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState<TForgotPasswordType>({
    email: "",
  });
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);

  const handleChange = (key: string, val: string) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateNewInput: {
      [key: string]: string;
    } = {
      email:
        errorCode["email"][
          fieldValidation({
            ...errorCode["emailObj"],
            fieldval: formData.email,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      forgetPasswordApi({ email: formData.email }).then(
        (response: Partial<{ statusCode: number; data: any; message: string }>) => {
          if (response.statusCode === 200) {
            toastFlashMessage(response.message as string, "success");
            setLoader(false);
            setStep(2);
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoader(false);
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  const handleClick = () => {
    navigate("/signin");
  };

  return (
    <>
      <div className={styles.passwordRecoveryForm + " formWrapper"}>
        <h1 className={styles.formTitle + " heading3 textCenter"}>
          {step === 1 ? "Password recovery" : "Thank you!"}
        </h1>
        <p className={styles.formText + " paragraph"}>
          {step === 1
            ? `Enter your registered email address below to get the
             password reset link `
            : `We've sent password reset instructions to your email 
            address. If no email is received within 10 minutes, check 
            that the submitted address is correct.`}
        </p>
        {step === 1 && (
          <form onSubmit={handleSubmit}>
            <CustomInput
              className="paragraph"
              label="Email Address"
              placeholder="abc@xyz.com"
              onChange={(e) => handleChange("email", e.target.value)}
              error={error.email}
              value={formData.email}
            />
            <CustomButton
              className={styles.submitButton + `${loader ? " primaryBtn disabledBtn" : " primaryBtn"}`}
              children="Submit"
              type="submit"
            />
          </form>
        )}
        {step === 2 && (
          <>
            <CustomButton
              className={styles.continueButton + " primaryBtn"}
              children="Continue"
              type="button"
              onClick={handleClick}
            />
          </>
        )}
        <CustomLink
          className={styles.backToSigninLink + " paragraph_semiBold"}
          children="Go back to Sign in"
          to="/signin"
        />
      </div>
    </>
  );
};

export default PasswordRecoveryForm;
