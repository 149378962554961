import { FC } from "react";
import styles from "../../../assets/styles/client.module.scss";
import { Skeleton } from "@mui/material";
import { Grid } from "@mui/material";
import { IShimmerProps } from "../../../types";

const Shimmer: FC<IShimmerProps> = ({ type }) => {
  return (
    <>
      {type === "clientsList" && (
        <>
          {[...Array(16)].map((item, idx) => (
            <Grid item md={3} key={idx}>
              <div className={styles.clientGridCardContainer}>
                <Skeleton variant="circular" width={36} height={36} />
                <div className="paragraph_semiBold">
                  <Skeleton
                    variant="text"
                    width={180}
                    height={25}
                    style={{
                      display: "block",
                    }}
                  />
                </div>
              </div>
            </Grid>
          ))}
        </>
      )}
      {type === "SingleClientDetails" && (
        <>
          <div className={styles.projectTitleBlk}>
            <div className={styles.projectTitle}>
              <Skeleton
                variant="text"
                width={220}
                height={30}
                style={{
                  display: "block",
                }}
              />
            </div>
            <div className={styles.projectHeaderWrapper}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <div className={styles.projectHeaderLeft}>
                    <Skeleton
                      variant="circular"
                      style={{
                        width: "48px",
                        height: "48px",
                      }}
                    />
                    <Skeleton
                      variant="text"
                      width={350}
                      height={30}
                      style={{
                        display: "block",
                        marginTop: 12,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className={styles.projectHeaderRight}>
                    <div className={styles.projectUsers}>
                      <div className={styles.usersDetail + " supportText_semiBold"}>
                        <Skeleton
                          variant="text"
                          width={180}
                          height={30}
                          style={{
                            display: "block",
                            marginTop: 12,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
      {type === "clientProjects" && (
        <>
          {[...Array(10)].map((item, idx) => (
            <div className={styles.projectListCardContainer + " paragraph"} key={idx}>
              <Grid container spacing={2} className={styles.listCardItem}>
                <Grid item md={3} className={styles.projectName}>
                  <Skeleton
                    variant="text"
                    width={220}
                    height={20}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Skeleton
                    variant="text"
                    width={140}
                    height={20}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Skeleton
                    variant="text"
                    width={140}
                    height={20}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={2} className={styles.projectStatus + " supportText_semiBold"}>
                  <Skeleton
                    variant="text"
                    width={140}
                    height={20}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={2} className={styles.projectStatus + " supportText_semiBold"}>
                  <Skeleton
                    variant="text"
                    width={140}
                    height={20}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={1} className={styles.viewDetailLink + " paragraph"}>
                  <Skeleton
                    variant="text"
                    width={50}
                    height={20}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </>
      )}

      {type === "clientUsersList" && (
        <>
          {[...Array(9)].map((item, idx) => (
            <div className={styles.userListItem} key={idx}>
              <Grid container>
                <Grid item md={3}>
                  <p className={styles.userName + " paragraph_semiBold"}>
                    <Skeleton variant="circular" height={24} width={16} />
                    <Skeleton
                      variant="text"
                      width={200}
                      height={25}
                      style={{
                        display: "block",
                        marginLeft: 10,
                      }}
                    />
                  </p>
                </Grid>
                <Grid item md={3}>
                  <Skeleton
                    variant="text"
                    width={200}
                    height={25}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <Skeleton
                    variant="text"
                    width={200}
                    height={25}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={2} className={styles.userStatus + " supportText_semiBold"}>
                  <Skeleton
                    variant="text"
                    width={160}
                    height={25}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
                <Grid item md={1}>
                  <Skeleton
                    variant="text"
                    width={100}
                    height={25}
                    style={{
                      display: "block",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Shimmer;
