import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { toastFlashMessage } from "../utils";

export const baseUrl = process.env.REACT_APP_BASEURL;

var axiosInstance: AxiosInstance = axios.create();
axiosInstance.defaults.baseURL = baseUrl;
axiosInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    if (localStorage?.getItem("userInfo")) {
      config.headers = {
        Authorization: JSON.parse(localStorage.getItem("userInfo") || `{access : ""}`)["access"],
      };
    }
    return config;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
      return response.data;
    } else if (response.data.statusCode === 1001 || response.data.statusCode === 3002) {
      //token expired
      toastFlashMessage(response.data.message, "error");
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 4000);
    } else {
      return response.data;
    }
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  }
);
export default axiosInstance;
