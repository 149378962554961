/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfileDropdown from "../features/settings/containers/ProfileDropdown";
import NotificationDrawer from "../features/settings/containers/NotificationDrawer";
import { UnreadNotificationCount } from "../features/settings/apiServices";
import { toastFlashMessage, getImage } from "../utils";

const Header: FC = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const handleNotificationPopup = (val: boolean) => {
    setOpenNotification(!openNotification);
  };

  const updateCount = () => {
    UnreadNotificationCount().then(
      (response: Partial<{ statusCode: number; data: number; message: string }>) => {
        if (response.statusCode === 200) {
          setNotificationCount(response?.data as number);
        } else {
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };

  useEffect(() => {
    updateCount();
  }, []);

  return (
    <div className="headerWrapper">
      <div className="headerLeftBlk">
        <Link to="/projects" className="brandLogo">
          <img src={getImage("Appknox_logo.svg")} alt="logo" />
        </Link>
      </div>
      <div className="headerRightBlk">
        <div className="notificationBlk">
          <a
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleNotificationPopup(true);
            }}
          >
            <img className="activeImg" src={getImage("notification_icon.svg")} alt=" " />
            {notificationCount > 0 ? (
              <span className="notificationCount labelText">{notificationCount}</span>
            ) : null}
          </a>
          <NotificationDrawer
            open={openNotification}
            updateCount={updateCount}
            handleCloseNotification={handleNotificationPopup}
          />
        </div>
        <div className="ProfileBlk">
          <ProfileDropdown />
        </div>
      </div>
    </div>
  );
};
export default Header;
