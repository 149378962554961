import { FC, useState } from "react";
import { Dialog } from "@mui/material";
import { UpdatePassword, UpdateProfile } from ".";
import { getImage } from "../../../utils";

type TEditProfileProps = {
  open: boolean;
  onClose: (value: string) => void;
};

const EditProfileDialog: FC<TEditProfileProps> = ({ open, onClose }) => {
  const [step, setStep] = useState(1);

  const handleCancel = () => {
    onClose("false");
  };

  const updateStep = (val: number) => {
    setStep(val);
  };
  return (
    <Dialog onClose={onClose} open={open} sx={{ borderRadius: "4px" }}>
      <div className="editProfileDialogWrapper">
        <div className="headerTitle">
          <p className="heading5">{step === 1 ? "Edit Profile Details" : "Change Password"}</p>
          <img src={getImage("close_icon.svg")} alt="close" onClick={handleCancel} />
        </div>
        {step === 1 ? (
          <UpdateProfile step={step} updateStep={updateStep} handleCancel={handleCancel} />
        ) : (
          <UpdatePassword step={step} updateStep={updateStep} handleCancel={handleCancel} />
        )}
      </div>
    </Dialog>
  );
};

export default EditProfileDialog;
