import { FC, useState, MouseEvent, useContext } from "react";
import styles from "../../../assets/styles/client.module.scss";
import { Grid } from "@mui/material";
import { CustomMoreMenu, ConfirmationDialog } from "../../../shared_ui_components";
import { TProjectResponse } from "../../../types";
import { AddProjectDialog } from "../../home/components";
import { deactivateProject } from "../../home/apiServices";
import { GlobalContext } from "../../../context/GlobalContext";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";
import { toastFlashMessage, getImage } from "../../../utils";
import { Link } from "react-router-dom";

interface IProps extends TProjectResponse {
  handleUpdateClientSingleProject?: (p: TProjectResponse | {}) => void;
  handleDeactivateProject?: () => void;
  handleSearch?: (p: string) => void;
}

const ClientsProjectListItem: FC<IProps> = ({
  uuid,
  name,
  client,
  url,
  description,
  scan_type,
  version,
  researcher,
  handleUpdateClientSingleProject,
  handleSearch,
  status,
  last_scan,
  users,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [openLogPop, setLogPop] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [updateClientProject, setUpdateClientProjec] = useState(false);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLog = (val: boolean) => {
    setLogPop(true);
  };

  const handleDeactivateProject = () => {
    deactivateProject(uuid as string).then(
      (response: Partial<{ statusCode: number; data: TProjectResponse; message: string }>) => {
        if (response.statusCode === 200) {
          dispatch({
            type: PROJECT_ACTIONS.Update,
            payload: response.data,
          });
          handleUpdateClientSingleProject?.(response.data as TProjectResponse);
          handleSearch?.("");
          toastFlashMessage(response.message as string, "success");
          setLogPop(false);
        } else {
          setLogPop(false);
          toastFlashMessage(response.message as string, "error");
        }
      }
    );
  };

  const handleUpdateClientProject = () => {
    setUpdateClientProjec(!updateClientProject);
  };
  return (
    <>
      <Link to={`/projects/${uuid}`}>
        <div className={styles.projectListCardContainer + " paragraph"}>
          <Grid
            container
            spacing={2}
            className={status === 2 ? styles.disableRow + " " + styles.listCardItem : styles.listCardItem}
            alignItems="center"
          >
            <Grid item md={3} className={styles.projectName + " paragraph"}>
              <span>{name}</span>
            </Grid>
            <Grid item md={2}>
              <p>
                {last_scan?.id
                  ? last_scan.status !== 3
                    ? last_scan?.id
                    : localStorage.getItem("view-Project-status") &&
                      JSON.parse(localStorage.getItem("view-Project-status") || "") === "status=completed"
                    ? last_scan?.id
                    : "N/A"
                  : "N/A"}
              </p>
            </Grid>
            <Grid item md={2}>
              {researcher
                ? researcher.last_name
                  ? researcher.first_name?.concat(" " + researcher.last_name)
                  : researcher.first_name
                : "N/A"}
            </Grid>
            <Grid item md={2} className={styles.projectStatus + " paragraph"}>
              {last_scan?.due_date ? last_scan?.due_date?.split("-").reverse().join("/") : "N/A"}
            </Grid>
            <Grid item md={2} className={styles.projectStatus + " paragraph"}>
              <p className={styles.projectStatus}>
                <img
                  src={
                    last_scan?.status === 0
                      ? getImage("status_pending.svg")
                      : last_scan?.status === 1
                      ? getImage("status_progress.svg")
                      : last_scan?.status === 2
                      ? getImage("delete_user.svg")
                      : last_scan?.status === 3
                      ? getImage("status_success.svg")
                      : null
                  }
                  alt=""
                />
                <span
                  className={
                    last_scan?.status === 0
                      ? styles.pending
                      : last_scan?.status === 1
                      ? styles.progress
                      : last_scan?.status === 2
                      ? styles.discarded
                      : last_scan?.status === 3
                      ? styles.success
                      : styles.none
                  }
                >
                  {last_scan?.status === 0
                    ? "Pending"
                    : last_scan?.status === 1
                    ? "In Progress"
                    : last_scan?.status === 2
                    ? "Discarded"
                    : last_scan?.status === 3
                    ? "Completed"
                    : "N/A"}
                </span>
              </p>
            </Grid>

            <Grid item md={1} className={styles.viewDetailLink + " paragraph"}>
              <Link to="#">
                <img
                  className={status === 2 ? styles.disableIcon : styles.moreIcon}
                  aria-controls="simple-menu"
                  onClick={handleClick}
                  src={getImage("more_menu.svg")}
                  alt=" "
                />
                {status !== 2 && (
                  <CustomMoreMenu
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleLogOut={handleLog}
                    noOfItems={2}
                    message1="Update Project Details"
                    message2="Deactivate Project"
                    image1="user_edit.svg"
                    image2="delete_user.svg"
                    handleClick1={handleUpdateClientProject}
                  />
                )}
              </Link>
            </Grid>
          </Grid>
        </div>
      </Link>
      <AddProjectDialog
        type="Update Project Details"
        open={updateClientProject}
        onClose={handleUpdateClientProject}
        projectId={uuid}
        projectName={name as string}
        clientName={client?.name}
        clientId={client?.uuid}
        projectUrl={url}
        projectDescription={description}
        projectScanType={"" + scan_type}
        clientUsers={users?.filter((ele) => ele?.role?.id === 5).map((user) => user.email) as string[]}
        clientUsersId={users
          ?.filter((ele) => ele?.role?.id === 5)
          .map((user) => {
            return { email: user.email, uuid: user.uuid } as { email: string; uuid: string };
          })}
        handleUpdateClientSingleProject={handleUpdateClientSingleProject}
        handleSearch={handleSearch}
      />
      {openLogPop && (
        <ConfirmationDialog
          text="Deactivate project"
          subText="Are you sure you want to deactivate this project?"
          image="deactivate_user.svg"
          cancelBtnText="Cancel"
          successBtnText="Deactivate"
          cancelBtnClass="outlinedBtn"
          successBtnClass="secondaryBtn"
          handleCancel={() => setLogPop(false)}
          handleSuccess={handleDeactivateProject}
        />
      )}
    </>
  );
};

export default ClientsProjectListItem;
