/* eslint-disable no-useless-computed-key */
import { FC, useState, useEffect, KeyboardEvent, MouseEvent } from "react";
import { Drawer } from "@mui/material";
import { Notifications } from "../components";
import { getImage } from "../../../utils";

type IProps = {
  open?: boolean;
  updateCount?: () => void;
  handleCloseNotification?: (val: boolean) => void;
};

type Anchor = "right";

type IState = {
  right: boolean;
};

const NotificationDrawer: FC<IProps> = ({ open, handleCloseNotification, updateCount }) => {
  const [state, setState] = useState<IState>({
    right: false,
  });

  useEffect(() => {
    setState({ ...state, ["right"]: open as boolean });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCloseDrawer = () => {
    setState({ ...state, ["right"]: !open as boolean });
  };

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" || (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    handleCloseNotification?.(open);
    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <div key={anchor}>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <div className="notificationDrawer">
              <div className="drawerHeader">
                <h2 className="drawerHeaderTitle heading5">Notifications</h2>
                <img src={getImage("close_icon.svg")} onClick={toggleDrawer(anchor, false)} alt="" />
              </div>
              <Notifications handleToggleDrawer={handleCloseDrawer} updateCount={updateCount} />
            </div>
          </Drawer>
        </div>
      ))}
    </div>
  );
};

export default NotificationDrawer;
