import { FC } from "react";
import styles from "../../../assets/styles/client.module.scss";

import { Link } from "react-router-dom";
import { getImage } from "../../../utils";

type IClientsProps = {
  uuid: string;
  name: string;
  logo: string;
};

const ClientsGridCard: FC<IClientsProps> = ({ uuid, name, logo }) => {
  return (
    <Link to={`/clients/${uuid}`}>
      <div className={styles.clientGridCardContainer}>
        <img src={logo ? logo : getImage("profile_placeholder.svg")} alt="" />
        <p className={styles.clientName + " paragraph_semiBold"}>{name}</p>
      </div>
    </Link>
  );
};

export default ClientsGridCard;
