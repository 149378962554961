import { FC, useContext } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Tooltip } from "@mui/material";
import { Link, useParams, useLocation } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { toolTipSeverity, toolTipScore, getImage } from "../../../utils";
import { VulnerabilityDetails } from ".";
import { IProps } from "./AnalysisListItem";
import { GlobalContext } from "../../../context/GlobalContext";

const VulnerabilityListItem: FC<IProps> = ({
  cvss_metrics,
  cwe,
  description,
  description_html,
  gdpr,
  owasp,
  proof_of_concept,
  proof_of_concept_html,
  remidation,
  remidation_html,
  vulnerability_category,
  id,
  name,
  risk_status,
  score,
  vulnerability,
  permission,
  scan_status,
  is_fixed,
  scan_type,
  risk_reason,
  previous_risk_status,
  index,
}) => {
  const scanId = useParams().scanId;
  const uuid = useParams().id;
  const location = useLocation();
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const roleId = roleLists?.roleData?.id;

  return (
    <>
      <div className={styles.analysisListBlk}>
        <Accordion className={styles.analysisListOuterBlk}>
          <AccordionSummary
            expandIcon={<img src={getImage("arrow_down.svg")} alt="" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={styles.analysisListItem}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={1}>
                <p>{index}</p>
              </Grid>
              <Grid item md={scan_type === 3 ? 3.5 : 4}>
                <p className=" paragraph">
                  {name ? (name?.length > 60 ? `${name?.substring(0, 60)}...` : name) : "N/A"}
                </p>
              </Grid>
              <Grid item md={2.5}>
                <p className="paragraphOverflow paragraph">
                  {vulnerability_category?.name
                    ? vulnerability_category?.name.length > 30
                      ? `${vulnerability_category?.name.substring(0, 30)}...`
                      : vulnerability_category?.name
                    : "N/A"}
                </p>
              </Grid>

              <Grid item md={scan_type === 3 ? 1.5 : 2}>
                <div className={styles.riskBlk}>
                  {risk_status === null ? (
                    <img
                      src={
                        score
                          ? score >= 0.1 && score <= 3.9
                            ? getImage("risk_status_low.svg")
                            : score >= 4 && score <= 6.9
                            ? getImage("risk_status_medium.svg")
                            : score >= 7 && score <= 8.9
                            ? getImage("risk_status_high_new.svg")
                            : score >= 9 && score <= 10
                            ? getImage("risk_status_critical.svg")
                            : ""
                          : getImage("risk_status_secure.svg")
                      }
                      alt=" "
                    />
                  ) : (risk_status || risk_status === 0) && typeof previous_risk_status === "number" ? (
                    <div>
                      <img
                        src={
                          risk_status === 0
                            ? getImage("risk_status_low.svg")
                            : risk_status === 1
                            ? getImage("risk_status_medium.svg")
                            : risk_status === 2
                            ? getImage("risk_status_high_new.svg")
                            : risk_status === 3
                            ? getImage("risk_status_critical.svg")
                            : risk_status === 4
                            ? getImage("risk_status_passed.svg")
                            : ""
                        }
                        alt=" "
                      />
                      <span style={{ position: "relative", padding: "4px" }}>
                        <Tooltip title={toolTipSeverity(previous_risk_status, risk_status)} placement="top">
                          <img src={getImage("info_icon.svg")} style={{ height: "18px" }} alt="" />
                        </Tooltip>
                      </span>
                    </div>
                  ) : (
                    <img
                      src={
                        risk_status === 0
                          ? getImage("risk_status_low.svg")
                          : risk_status === 1
                          ? getImage("risk_status_medium.svg")
                          : risk_status === 2
                          ? getImage("risk_status_high_new.svg")
                          : risk_status === 3
                          ? getImage("risk_status_critical.svg")
                          : risk_status === 4
                          ? getImage("risk_status_passed.svg")
                          : ""
                      }
                      alt=" "
                    />
                  )}
                </div>
              </Grid>

              <Grid item md={1.5}>
                <div className={styles.analysisScore}>
                  {typeof risk_status === "number" && typeof previous_risk_status === "number" ? (
                    <>
                      <p
                        className={
                          risk_status === 0
                            ? styles.scoreColorLow
                            : risk_status === 1
                            ? styles.scoreColorMedium
                            : risk_status === 2
                            ? styles.scoreColorHigh
                            : risk_status === 3
                            ? styles.scoreColorCritical
                            : styles.scoreColorNone
                        }
                      >
                        {risk_status === 0
                          ? 3.9
                          : risk_status === 1
                          ? 6.9
                          : risk_status === 2
                          ? 8.9
                          : risk_status === 3
                          ? 10
                          : "0.0"}
                      </p>
                      <span style={{ position: "relative", padding: "4px" }}>
                        <Tooltip title={toolTipScore(previous_risk_status, risk_status)} placement="top">
                          <img src={getImage("info_icon.svg")} style={{ height: "18px" }} alt="" />
                        </Tooltip>
                      </span>
                    </>
                  ) : (
                    <>
                      <p
                        className={
                          score
                            ? score >= 0.1 && score <= 3.9
                              ? styles.scoreColorLow
                              : score >= 4 && score <= 6.9
                              ? styles.scoreColorMedium
                              : score >= 7 && score <= 8.9
                              ? styles.scoreColorHigh
                              : score >= 9 && score <= 10
                              ? styles.scoreColorCritical
                              : ""
                            : styles.scoreColorNone
                        }
                      >
                        {String(score).length === 1 ? score + ".0" : score}
                      </p>
                    </>
                  )}
                </div>
              </Grid>
              {scan_type === 3 && (
                <Grid item md={1}>
                  <div className={is_fixed ? styles.findingsCompleted : styles.findingsInCompleted}>
                    {is_fixed ? "Fixed" : "N/A"}
                  </div>
                </Grid>
              )}
              <Grid item md={1} sx={{ display: "flex", justifyContent: "normal" }}>
                {scan_status === 3 ? (
                  <Link
                    to={
                      location.pathname.includes("vulnerabilities")
                        ? `/vulnerabilities/${uuid}/scan-details/${scanId}/findings-details/${id}`
                        : `/projects/${uuid}/scan-details/${scanId}/findings-details/${id}`
                    }
                  >
                    <p className={styles.viewDetailLink}>View details</p>
                  </Link>
                ) : (
                  permission &&
                  scan_status !== 2 && (
                    <Link
                      to={
                        location.pathname.includes("vulnerabilities")
                          ? `/vulnerabilities/${uuid}/scan-details/${scanId}/update-findings/${id}`
                          : `/projects/${uuid}/scan-details/${scanId}/update-findings/${id}`
                      }
                    >
                      <img className={styles.editImg} src={getImage("edit_gray.svg")} alt=" " />
                    </Link>
                  )
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.analysisListItemDetailWrapper}>
              <VulnerabilityDetails
                score={score}
                id={id}
                description={description}
                description_html={description_html}
                name={name}
                risk_status={risk_status}
                vulnerability={vulnerability}
                cvss_metrics={cvss_metrics}
                cwe={cwe}
                gdpr={gdpr}
                owasp={owasp}
                proof_of_concept_html={proof_of_concept_html}
                proof_of_concept={proof_of_concept}
                remidation_html={remidation_html}
                remidation={remidation}
                risk_reason={risk_reason}
                previous_risk_status={previous_risk_status}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default VulnerabilityListItem;
