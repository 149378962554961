import { ActionMap, TVulnerability } from "../types";
import { TVulnerabilityState } from "./GlobalContext";

export enum VULNERABILITY_ACTIONS {
  Get = "GET_All_VULNERABILITY CATEGORY",
  Add = "ADD_NEW_VULNERABILITY CATEGORY",
  Update = "UPDATE_VULNERABILITY CATEGORY",
  Delete = "DELETE_VULNERABILITY",
  DeleteCategory = "DELETE_VULNERABILITY_CATEGORY",
  AddVulnerability = "ADD VULNERABILITY",
  UpdateVulnerability = "UPDATE VULNERABILITY",
}

type VulnerabilityPayload = {
  [VULNERABILITY_ACTIONS.Get]: undefined;
  [VULNERABILITY_ACTIONS.Add]: undefined;
  [VULNERABILITY_ACTIONS.Update]: undefined;
  [VULNERABILITY_ACTIONS.AddVulnerability]: undefined;
  [VULNERABILITY_ACTIONS.UpdateVulnerability]: undefined;
  [VULNERABILITY_ACTIONS.Delete]: undefined;
  [VULNERABILITY_ACTIONS.DeleteCategory]: undefined;
};
export type VulnerabilityActions = ActionMap<VulnerabilityPayload>[keyof ActionMap<VulnerabilityPayload>];

export const VulnerabilityReducers = (state: TVulnerabilityState, action: VulnerabilityActions | any) => {
  switch (action.type) {
    case VULNERABILITY_ACTIONS.Get:
      return {
        ...state,
        vulnerabilityData: [...action.payload],
        loading: false,
        showNoState: action.payload.length > 0 ? false : true,
      };

    case VULNERABILITY_ACTIONS.Add:
      return {
        ...state,
        vulnerabilityData: [action.payload, ...state.vulnerabilityData],
        loading: false,
        showNoState: false,
      };

    case VULNERABILITY_ACTIONS.Update:
      return {
        ...state,
        vulnerabilityData: state?.vulnerabilityData?.map((_vulnerability) => {
          return _vulnerability?.id === action?.payload?.id
            ? { ..._vulnerability, ...action.payload }
            : { ..._vulnerability };
        }),
        loading: false,
        showNoState: false,
      };

    case VULNERABILITY_ACTIONS.AddVulnerability:
      return {
        ...state,
        vulnerabilityData: state?.vulnerabilityData?.map((_vulnerability) => {
          return _vulnerability?.id === action?.payload?.category?.id
            ? {
                ..._vulnerability,
                id: action?.payload?.category?.id,
                name: action?.payload?.category?.name,
                description: action?.payload?.category?.description,
                owasp: action?.payload?.category?.owasp,
                vulnerabilities: [
                  ...(_vulnerability?.vulnerabilities as TVulnerability[]),
                  {
                    id: action?.payload?.id,
                    name: action?.payload?.name,
                    description: action?.payload?.description,
                    instances_count: 0,
                    last_instance: {
                      detected_on: "",
                      project_name: "",
                    },
                  },
                ],
              }
            : { ..._vulnerability };
        }),
        loading: false,
        showNoState: false,
      };

    case VULNERABILITY_ACTIONS.UpdateVulnerability:
      return {
        ...state,
        vulnerabilityData: state?.vulnerabilityData?.map((_vulnerability) => {
          return _vulnerability?.id === action?.payload?.category?.id
            ? {
                ..._vulnerability,
                id: action?.payload?.category?.id,
                name: action?.payload?.category?.name,
                description: action?.payload?.category?.description,
                owasp: action?.payload?.category?.owasp,
                vulnerabilities: _vulnerability?.vulnerabilities?.map((item) => {
                  return item.id === action?.payload?.id
                    ? {
                        ...item,
                        id: action?.payload?.id,
                        name: action?.payload?.name,
                        description: action?.payload?.description,
                        instances_count: action?.payload?.instances_count,
                        last_instance: {
                          detected_on: action?.payload?.last_instance?.detected_on,
                          project_name: action?.payload?.last_instance?.project_name,
                        },
                      }
                    : { ...item };
                }),
              }
            : { ..._vulnerability };
        }),
        loading: false,
        showNoState: false,
      };

    case VULNERABILITY_ACTIONS.Delete:
      return {
        ...state,
        vulnerabilityData: state?.vulnerabilityData?.map((_vulnerability) => {
          return _vulnerability?.id === action?.payload?.categoryId
            ? {
                ..._vulnerability,
                vulnerabilities: _vulnerability?.vulnerabilities?.filter(
                  (_vulId) => _vulId.id !== action?.payload?.vulnerabilityId
                ),
              }
            : { ..._vulnerability };
        }),
        loading: false,
        showNoState: false,
      };

    case VULNERABILITY_ACTIONS.DeleteCategory:
      return {
        ...state,
        vulnerabilityData: state?.vulnerabilityData?.filter((_vulnerability) => {
          return _vulnerability?.id !== action?.payload?.categoryId;
        }),
        loading: false,
        showNoState: false,
      };

    default:
      return state;
  }
};
