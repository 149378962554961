import { FC, MouseEvent, useState, useEffect } from "react";
import styles from "../../../assets/styles/client.module.scss";
import { Container, Grid, AvatarGroup, Avatar, Tooltip } from "@mui/material";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { CustomLink, CustomMoreMenu, NoResult } from "../../../shared_ui_components";
import { ClientsProjectList, AddNewClientDialog, Shimmer } from "../components";
import { TClientDetail, TProjectResponse } from "../../../types";
import { toastFlashMessage, getImage } from "../../../utils";
import { getSingleClientDetails } from "../apiServices";
import { AddProjectDialog } from "../../home/components";

type TRole = {
  role: number;
};

const ClientsProjectDetails: FC<TRole> = (props) => {
  const role = props.role;
  const { id } = useParams();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openLogPop, setLogPop] = useState(false);
  const [openUpdateClient, setOpenUpdateClient] = useState(false);
  const [openClientAddProject, setOpenClientAddProject] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [updateClientDetails, setUpdateClientDetails] = useState<TClientDetail>({});
  const [loader, setLoader] = useState(true);

  /* Calling Single Client Detail API (Includes Projects related that client) */

  const getClientProjectDetails = () => {
    if (id) {
      getSingleClientDetails(`${id}`).then(
        (response: Partial<{ statusCode: number; data: TClientDetail; message: string }>) => {
          if (response.statusCode === 200) {
            setLoader(false);
            setUpdateClientDetails(response.data as TClientDetail);
          } else {
            toastFlashMessage(response.message as string, "error");
            navigate(`/clients/`);
            setLoader(false);
          }
        }
      );
    }
    return () => {
      setUpdateClientDetails({});
    };
  };
  useEffect(() => {
    getClientProjectDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLog = (val: boolean) => {
    setLogPop(val);
  };
  const handleUpdateClientDialog = () => {
    setOpenUpdateClient(!openUpdateClient);
  };

  const handleAddClientProjectDialog = () => {
    setOpenClientAddProject(!openClientAddProject);
  };

  /* Updating Selected Client */
  const handleUpdateClient = (name: string, logo: string) => {
    setUpdateClientDetails({
      ...updateClientDetails,
      logo: logo,
      name: name,
    });
  };

  /* Adding New Client Project */
  const handleAddClientProject = (addNewProject: TProjectResponse) => {
    getClientProjectDetails();
    setUpdateClientDetails((updateClientDetails) => ({
      ...updateClientDetails,
      projects: updateClientDetails?.projects && [addNewProject, ...updateClientDetails?.projects],
    }));
  };

  /* Updating/Deactivating Selected Client Project */
  const handleUpdateClientSingleProject = (p: TProjectResponse) => {
    getClientProjectDetails();
    setUpdateClientDetails({
      ...updateClientDetails,
      projects: updateClientDetails?.projects?.map((item) => {
        return item.uuid === p.uuid ? { ...item, ...p } : { ...item };
      }),
    });
  };

  return (
    <>
      {role === 1 || role === 2 ? (
        <>
          <div className={styles.projectTitleWrapper}>
            <Container maxWidth="lg">
              {loader ? (
                <Shimmer type="SingleClientDetails" />
              ) : (
                <div className={styles.projectTitleBlk}>
                  <p className={styles.projectTitle}>
                    <CustomLink to="/clients" className="paragraph">
                      {" "}
                      Clients
                    </CustomLink>{" "}
                    / <span>{updateClientDetails?.name?.substring(0, 20)}</span>
                  </p>
                  <div className={styles.projectHeaderWrapper}>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <div className={styles.projectHeaderLeft}>
                          <img
                            src={
                              updateClientDetails?.logo
                                ? updateClientDetails?.logo
                                : getImage("profile_placeholder.svg")
                            }
                            alt=" "
                          />
                          <p className="heading3">{updateClientDetails?.name?.substring(0, 20)}</p>
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div className={styles.projectHeaderRight}>
                          <div className={styles.projectUsers}>
                            <div className={styles.usersDetail + " supportText_semiBold"}>
                              <span>
                                USERS:{" "}
                                {updateClientDetails?.users?.filter((_user) => _user?.role?.id === 5)
                                  .length === 0 && " N/A"}
                              </span>
                              <AvatarGroup max={4} className={styles.usersAvatar}>
                                {updateClientDetails?.users?.map((user, idx) => (
                                  <Tooltip
                                    title={
                                      user?.first_name
                                        ? user?.first_name
                                        : (user?.email?.substring(0, user?.email.lastIndexOf("@")) as string)
                                    }
                                    arrow
                                    key={idx}
                                  >
                                    <Avatar
                                      key={user.uuid}
                                      alt=""
                                      sx={{ height: "28px", width: "28px", cursor: "pointer" }}
                                      src={
                                        user?.profile_pic ? user.profile_pic : getImage("user_profile.svg")
                                      }
                                    />
                                  </Tooltip>
                                ))}
                              </AvatarGroup>
                            </div>
                            <CustomLink
                              className={styles.userManager + " paragraph_semiBold"}
                              to={`/clients/${id}/users`}
                            >
                              {updateClientDetails?.users?.filter((_user) => _user?.role?.id === 5).length ===
                              0
                                ? ""
                                : "Manage User"}
                            </CustomLink>
                          </div>
                          <div className={styles.userManagerWrapper}>
                            <img
                              src={getImage("more_menu.svg")}
                              aria-controls="simple-menu"
                              alt="more_icon"
                              onClick={handleClick}
                            />
                            <CustomMoreMenu
                              open={open}
                              anchorEl={anchorEl}
                              handleClose={handleClose}
                              handleLogOut={handleLog}
                              handleClick1={handleUpdateClientDialog}
                              noOfItems={1}
                              message1="Update Client Details"
                              image1="user_edit.svg"
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            </Container>
          </div>
          <Container maxWidth="lg">
            <div className={styles.projectDetailPageWrapper}>
              {updateClientDetails?.projects?.length === 0 ? (
                <NoResult
                  image={getImage("no_file_found.svg")}
                  title="No Projects Added"
                  btnText="Create Project"
                  onClick={handleAddClientProjectDialog}
                />
              ) : (
                <ClientsProjectList
                  projects={updateClientDetails?.projects}
                  handleUpdateClientSingleProject={handleUpdateClientSingleProject}
                  handleAddClientProjectDialog={handleAddClientProjectDialog}
                />
              )}

              <AddNewClientDialog
                type="Update Client Details"
                open={openUpdateClient}
                onClose={handleUpdateClientDialog}
                companyName={updateClientDetails?.name}
                companyLogo={updateClientDetails?.logo}
                handleUpdateClient={handleUpdateClient}
                id={id}
              />

              <AddProjectDialog
                type="Create New Project"
                open={openClientAddProject}
                onClose={handleAddClientProjectDialog}
                clientName={updateClientDetails?.name}
                clientId={id}
                handleAddClientProject={handleAddClientProject}
              />
            </div>
          </Container>
        </>
      ) : (
        <Navigate to="/projects" />
      )}
    </>
  );
};

export default ClientsProjectDetails;
