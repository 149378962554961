import { FC } from "react";
import { SignInForm, AuthLayout } from "../components";
import styles from "../../../assets/styles/auth.module.scss";

const SignIn: FC = () => {
  return (
    <>
      <AuthLayout>
        <div className={styles.signInFormWrapper}>
          <SignInForm />
        </div>
      </AuthLayout>
    </>
  );
};
export default SignIn;
