import { FC } from "react";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { Skeleton } from "@mui/material";
import { Grid } from "@mui/material";
import { IShimmerProps } from "../../../types";

const Shimmer: FC<IShimmerProps> = ({ type }) => {
  return (
    <>
      {type === "vulnerabilityCategoryList" && (
        <>
          {[...Array(10)].map((item, idx) => {
            return (
              <div className={styles.accordionWrapper} key={idx} style={{ background: "white" }}>
                <Grid container alignItems="center">
                  <Grid item md={8} className={styles.accordionHeader}>
                    <div className={styles.headerTitle + " heading6"}>
                      <Skeleton
                        variant="text"
                        width={590}
                        height={25}
                        style={{
                          display: "block",
                          marginLeft: 10,
                        }}
                      />
                    </div>
                    <div className={styles.headerText + " paragraph"}>
                      <Skeleton
                        variant="text"
                        width={200}
                        height={25}
                        style={{
                          display: "block",
                          marginLeft: 10,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className="paragraph_semiBold">
                      <Skeleton
                        variant="text"
                        width={120}
                        height={25}
                        style={{
                          display: "block",
                          marginLeft: 70,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1}>
                    <Skeleton
                      variant="text"
                      width={80}
                      height={25}
                      style={{
                        position: "relative",
                        display: "block",
                        left: "-30px",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </>
      )}
      {type === "occurencesList" && (
        <>
          {[...Array(10)].map((item, idx) => {
            return (
              <div className={styles.projectListCardContainer + " paragraph"} key={idx}>
                <Grid container spacing={2} className={styles.listCardItem}>
                  <Grid item md={1} className={styles.projectId}>
                    <Skeleton
                      variant="text"
                      width={200}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={2.5} className={styles.projectName}>
                    <Skeleton
                      variant="text"
                      width={200}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={3.5}>
                    <Skeleton
                      variant="text"
                      width={300}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={2} className={styles.projectRisk}>
                    <Skeleton
                      variant="text"
                      width={100}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={2} className={styles.projectStatus + " supportText_semiBold"}>
                    <Skeleton
                      variant="text"
                      width={130}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                  <Grid item md={1} className={styles.viewDetailLink + " paragraph"}>
                    <Skeleton
                      variant="text"
                      width={70}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </>
      )}
      {type === "editVulnerability" && (
        <div className={styles.formDetail}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Title *</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Vulnerability Category *</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>CWE *</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>GDPR *</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Description</p>
              <Skeleton
                variant="text"
                width={720}
                height={180}
                style={{
                  display: "block",
                  marginTop: "-32px",
                }}
              />
            </Grid>
          </Grid>
          <div className={styles.buttonWrapper}>
            <Skeleton
              variant="text"
              width={50}
              height={55}
              style={{
                display: "block",
              }}
            />
            <Skeleton
              variant="text"
              width={50}
              height={55}
              style={{
                display: "block",
              }}
            />
          </div>
        </div>
      )}

      {type === "editVulnerabilityCategory" && (
        <div className={styles.formDetail}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Category name *</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.formTitle + " supportText_semiBold"}>Description</p>
              <Skeleton
                variant="text"
                width={720}
                height={180}
                style={{
                  display: "block",
                  marginTop: "-32px",
                }}
              />
            </Grid>
            <Grid item md={12} sx={{ marginTop: "-32px" }}>
              <p className={styles.formTitle + " supportText_semiBold"}>OWSAP *</p>
              <Skeleton
                variant="text"
                width={720}
                height={45}
                style={{
                  display: "block",
                }}
              />
            </Grid>
          </Grid>
          <div className={styles.buttonWrapper}>
            <Skeleton
              variant="text"
              width={50}
              height={55}
              style={{
                display: "block",
              }}
            />
            <Skeleton
              variant="text"
              width={50}
              height={55}
              style={{
                display: "block",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Shimmer;
