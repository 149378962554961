/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ChangeEvent, useState, useEffect, useContext } from "react";
import { Grid, Divider, Pagination, ClickAwayListener } from "@mui/material";
import { ProjectListCard, ProjectGridCard, Shimmer, AddProjectDialog } from ".";
import { Search } from "../../../shared_elements";
import { CustomLink, NoResult } from "../../../shared_ui_components";
import styles from "../../../assets/styles/home.module.scss";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  listingAllProjects,
  listingProjectsWithSearchAndPage,
  listingAllProjectsWithPage,
  roleDetails,
  getUserInfo,
  getImage,
} from "../../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PROJECT_ACTIONS } from "../../../context/ProjectReducer";
import { searchProjects } from "../apiServices";

type TSearchType = {
  val: string;
};

const ProjectDashboard: FC = () => {
  const navigate = useNavigate();
  const userInfo = getUserInfo();
  const [searchParams] = useSearchParams();
  const getLastSearchProjects = JSON.parse(localStorage.getItem("recentlySearchedProjects") as string);
  const {
    state: { projectLists, roleLists },
    dispatch,
  } = useContext(GlobalContext);

  const roleId = roleDetails(
    window.atob(
      `${userInfo?.access?.substring(userInfo.access?.lastIndexOf("$") + 1, userInfo?.access?.length)}`
    )
  )?.id;

  const [search, setSearch] = useState<TSearchType>({
    val: "",
  });
  const [viewList, setViewList] = useState<string>(JSON.parse(localStorage.getItem("view-settings") || "1"));
  const [addNewProject, setAddNewProject] = useState(false);
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActive, setIsActive] = useState(true);

  const statusValue = localStorage.getItem("view-Project-status")
    ? JSON.parse(localStorage.getItem("view-Project-status") || "")
    : roleId === 5 || roleId === 2
    ? "my-projects"
    : "my-projects&status=ongoing";
  const [projectFilterStatus, setProjectFilterStatus] = useState<string>(statusValue);

  const [page, setPage] = useState<number>(1);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>();

  /* handling Live Search */
  const handleSearch = (val: string) => {
    if (val.length > 0) {
      if (projectFilterStatus === "my-projects") {
        searchProjects("my-projects", val).then((response) => {
          dispatch({
            type: PROJECT_ACTIONS.Get,
            payload: response.data,
          });
        });
      } else {
        searchProjects(projectFilterStatus, val).then((response) => {
          dispatch({
            type: PROJECT_ACTIONS.Get,
            payload: response.data,
          });
        });
      }
    } else {
      setIsActive(false);
      const isPage = Number(searchParams.get("page"));
      if (isPage) {
        handlePageChange(isPage);
      } else {
        if (roleId === 1) {
          listingAllProjects("all=true");
          setProjectFilterStatus("all=true");
        } else if (roleId === 5 || roleId === 2) {
          localStorage.getItem("view-Project-status")
            ? filterProjectLists(JSON.parse(localStorage.getItem("view-Project-status") || ""))
            : listingAllProjects("my-project");
        } else {
          listingAllProjects(statusValue);
        }
      }
    }
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        handleSearch(search.val as string);
      }, 500)
    );
  }, [search.val]);

  const handleLiveSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: PROJECT_ACTIONS.Loading,
      payload: true,
    });
    const data = e.target.value;
    setSearch(() => ({
      val: data,
    }));
  };

  const handleClearSearch = () => {
    dispatch({
      type: PROJECT_ACTIONS.Loading,
      payload: true,
    });
    setSearch(() => ({
      val: "",
    }));
    setShowSearchDropDown(false);
  };
  /* ----- end ----*/

  /* For handling Pages */
  useEffect(() => {
    const _CurrentPage = searchParams.get("page");
    if (_CurrentPage) {
      setPage(Number(_CurrentPage));
    }
  }, []);

  const handlePageChange = (value: number) => {
    dispatch({
      type: PROJECT_ACTIONS.Loading,
      payload: true,
    });
    navigate({
      pathname: "/projects",
      search: `?page=${value}`,
    });
    setPage(value as number);
    if (search.val) {
      listingProjectsWithSearchAndPage(projectFilterStatus, value, search?.val);
    } else {
      listingAllProjectsWithPage(projectFilterStatus, value);
    }
  };
  /* -----end---- */

  const handleAddNewProject = () => {
    setAddNewProject(!addNewProject);
  };

  /* show projects based on status */
  const filterProjectLists = (status: string) => {
    dispatch({
      type: PROJECT_ACTIONS.Loading,
      payload: true,
    });
    setPage(1);
    setProjectFilterStatus(status);
    localStorage.setItem("view-Project-status", JSON.stringify(status));
    listingAllProjects(status);
  };

  return (
    <div className={styles.projectDashboardWrapper}>
      <div className={styles.projectTitleWrapper}>
        <h2 className="heading3 headingTextOverflow">
          {search?.val
            ? `Showing ${projectLists?.pagination?.total} ${
                projectLists?.projectData?.length === 1 || projectLists?.projectData?.length === 0
                  ? "result"
                  : "results"
              } for "${search?.val}" `
            : "Projects"}
        </h2>
        <div className={styles.projectSearchAndViewWrapper}>
          <>
            {search.val ? (
              <span onClick={handleClearSearch} className={styles.clearSearch + " paragraph"}>
                Clear search
              </span>
            ) : (
              ""
            )}
            <ClickAwayListener onClickAway={() => setShowSearchDropDown(false)}>
              <div className="dropdown">
                <Search
                  text={search?.val as string}
                  placeholder="Search for projects"
                  onChange={handleLiveSearch}
                  onFocus={() => setShowSearchDropDown(true)}
                />
                {showSearchDropDown ? (
                  <div
                    className="dropdown-content"
                    style={{ display: showSearchDropDown ? "block" : "none" }}
                  >
                    <p className="supportText_semiBold">
                      {getLastSearchProjects === null ? "No Recent Searches" : "Recent Searches"}
                    </p>
                    {getLastSearchProjects?.length > 0 &&
                      getLastSearchProjects.map((project: { _id: string; name: string }, idx: number) => {
                        return (
                          <div key={idx} className="dropdown-list">
                            <img src={getImage("recent_search_icon.svg")} alt="" />
                            <CustomLink
                              to={`/projects/${project._id}`}
                              className="dropdown-project-name paragaph"
                            >
                              {project.name}
                            </CustomLink>
                          </div>
                        );
                      })}
                  </div>
                ) : null}
              </div>
            </ClickAwayListener>
            <div className={styles.viewWrapper}>
              <img
                className={viewList === "1" ? "activeImg" : "defaultImg"}
                src={`${
                  viewList === "1" ? getImage("listView_active.svg") : getImage("listView_default.svg")
                }`}
                alt="listView"
                onClick={() => {
                  setViewList("1");
                  localStorage.setItem("view-settings", JSON.stringify(1));
                }}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <img
                className={viewList === "2" ? "activeImg" : "defaultImg"}
                src={`${
                  viewList === "2" ? getImage("gridView_active.svg") : getImage("gridView_default.svg")
                }`}
                alt="GridView"
                onClick={() => {
                  setViewList("2");
                  localStorage.setItem("view-settings", JSON.stringify(2));
                }}
              />
            </div>
          </>
        </div>
      </div>
      <div className={styles.projectStatusWrapper}>
        <div>
          {roleId !== 1 && roleId !== 5 && (
            <CustomLink
              className={`${styles.tabLink} ${
                roleId === 2
                  ? projectFilterStatus === "my-projects" && styles.active
                  : projectFilterStatus === "my-projects&status=ongoing" && styles.active
              } heading6`}
              to="#"
              onClick={() => {
                dispatch({
                  type: PROJECT_ACTIONS.Loading,
                  payload: true,
                });
                setIsActive(false);
                if (search.val) {
                  searchProjects(
                    roleId === 2 ? "my-projects" : "my-projects&status=ongoing",
                    search.val
                  ).then((response) => {
                    dispatch({
                      type: PROJECT_ACTIONS.Get,
                      payload: response.data,
                    });
                  });
                } else {
                  localStorage.setItem(
                    "view-Project-status",
                    JSON.stringify(roleId === 2 ? "my-projects" : "my-projects&status=ongoing")
                  );
                  filterProjectLists(roleId === 2 ? "my-projects" : "my-projects&status=ongoing");
                }
              }}
            >
              My projects <span></span>
            </CustomLink>
          )}

          <CustomLink
            className={`${styles.tabLink} ${
              roleId === 5
                ? projectFilterStatus === "my-projects"
                  ? styles.active
                  : null
                : projectFilterStatus === "all=true"
                ? styles.active
                : null
            } heading6`}
            to="#"
            onClick={() => {
              dispatch({
                type: PROJECT_ACTIONS.Loading,
                payload: projectLists?.showNoState ? true : true,
              });
              setIsActive(false);
              if (roleId === 5) {
                if (search.val) {
                  searchProjects("my-projects", search.val).then((response) => {
                    dispatch({
                      type: PROJECT_ACTIONS.Get,
                      payload: response.data,
                    });
                  });
                } else {
                  listingAllProjects("my-projects");
                }
                setProjectFilterStatus("my-projects");
                localStorage.setItem("view-Project-status", JSON.stringify("my-projects"));
              } else {
                if (search.val) {
                  searchProjects("all-true", search.val).then((response) => {
                    dispatch({
                      type: PROJECT_ACTIONS.Get,
                      payload: response.data,
                    });
                  });
                  setProjectFilterStatus("all=true");
                } else {
                  filterProjectLists("all=true");
                }
              }
            }}
          >
            All projects <span></span>
          </CustomLink>
        </div>
        <div className={styles.statusButtonWrapper}>
          <CustomLink
            className={`${styles.statusBtn} ${projectFilterStatus === "status=inactive" && styles.active}`}
            to="#"
            onClick={() => {
              setProjectFilterStatus("status=inactive");
              setIsActive(false);
              if (search.val) {
                dispatch({
                  type: PROJECT_ACTIONS.Loading,
                  payload: true,
                });
                searchProjects("status=inactive", search.val).then((response) => {
                  dispatch({
                    type: PROJECT_ACTIONS.Get,
                    payload: response.data,
                  });
                });
              } else {
                filterProjectLists("status=inactive");
              }
            }}
          >
            Inactive
          </CustomLink>
          <CustomLink
            className={`${styles.statusBtn} ${projectFilterStatus === "status=ongoing" && styles.active}`}
            to="#"
            onClick={() => {
              setProjectFilterStatus("status=ongoing");
              setIsActive(false);
              if (search.val) {
                dispatch({
                  type: PROJECT_ACTIONS.Loading,
                  payload: true,
                });
                searchProjects("status=ongoing", search.val).then((response) => {
                  dispatch({
                    type: PROJECT_ACTIONS.Get,
                    payload: response.data,
                  });
                });
              } else {
                filterProjectLists("status=ongoing");
              }
            }}
          >
            Ongoing
          </CustomLink>
          <CustomLink
            className={`${styles.statusBtn} ${projectFilterStatus === "status=completed" && styles.active}`}
            to="#"
            onClick={() => {
              setProjectFilterStatus("status=completed");
              setIsActive(false);
              if (search.val) {
                dispatch({
                  type: PROJECT_ACTIONS.Loading,
                  payload: true,
                });
                searchProjects("status=completed", search.val).then((response) => {
                  dispatch({
                    type: PROJECT_ACTIONS.Get,
                    payload: response.data,
                  });
                });
              } else {
                filterProjectLists("status=completed");
              }
            }}
          >
            Completed
          </CustomLink>
        </div>
      </div>
      {projectLists?.showNoState && projectLists?.loading === false ? (
        roleLists?.roleData?.permission?.includes("ADD_PROJECT") ? (
          <NoResult
            image={getImage("no_file_found.svg")}
            title="No Project Found"
            btnText="Create New Project"
            onClick={handleAddNewProject}
            link="/projects"
          />
        ) : (
          <NoResult image={getImage("no_file_found.svg")} title="No Project Found" />
        )
      ) : viewList === "1" ? (
        <ProjectListCard search={search.val && search.val} />
      ) : (
        <Grid container spacing={2} direction="row" alignItems="center" sx={{ marginBottom: "40px" }}>
          {projectLists?.loading ? (
            <Shimmer type="gridLists" />
          ) : (
            projectLists?.projectData.map((project, idx) => (
              <Grid item md={4} key={idx}>
                <ProjectGridCard {...project} search={search.val && search.val} />
              </Grid>
            ))
          )}
        </Grid>
      )}
      {!projectLists?.loading &&
        typeof projectLists?.pagination?.total === "number" &&
        projectLists?.pagination?.total > 15 && (
          <div className={styles.paginationBlk}>
            <Pagination
              count={projectLists?.pagination?.number_of_pages}
              page={page as number}
              variant="outlined"
              shape="rounded"
              onChange={(event: ChangeEvent<unknown>, page: number) => handlePageChange(page)}
            />
          </div>
        )}
      <AddProjectDialog type="Create New Project" open={addNewProject} onClose={handleAddNewProject} />
    </div>
  );
};

export default ProjectDashboard;
