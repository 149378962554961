import { FC } from "react";
import { Container } from "@mui/material";
import styles from "../../../assets/styles/user.module.scss";
import { UserDashboard } from "../components";
import { Navigate } from "react-router-dom";

type TRole = {
  role: number;
};

const User: FC<TRole> = (props) => {
  return (
    <>
      <Container maxWidth="lg">
        <div className={styles.userDashboardWrapper}>
          {props?.role === 1 ? <UserDashboard /> : <Navigate to="/projects" />}
        </div>
      </Container>
    </>
  );
};
export default User;
