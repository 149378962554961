import Home from "./containers/Home";
import ProductDetail from "./containers/ProductDetail";
import EditScanDetail from "./containers/EditScanDetail";
import UpdateProject from "./containers/UpdateProject";
import AddVulnerability from "./containers/AddVulnerability";
import UpdateVulnerability from "./containers/UpdateVulnerability";
import VulnerabilityDetail from "./containers/VulnerabilityDetail";
import ScanAnalysisDetails from "./containers/ScanAnalysisDetails";
import { ReportList } from "./components";

import { TRoutesType } from "../../types";
import PageLayoutHoc from "../../hocs/pageLayoutHoc";

const HomePage = PageLayoutHoc(Home);
const DetailPage = PageLayoutHoc(ProductDetail);
const EditScanPage = PageLayoutHoc(EditScanDetail);
const ScanAnalysisPage = PageLayoutHoc(ScanAnalysisDetails);
const UpdateProjectPage = PageLayoutHoc(UpdateProject);
const AddVulnerabilityPage = PageLayoutHoc(AddVulnerability);
const UpdateVulnerabilityPage = PageLayoutHoc(UpdateVulnerability);
const VulnerabilityDetailPage = PageLayoutHoc(VulnerabilityDetail);
const ReportListPage = PageLayoutHoc(ReportList);

export const homeRoutes: Array<TRoutesType> = [
  {
    path: "/projects",
    element: <HomePage />,
    key: "home",
  },
  {
    path: "/projects/:id",
    element: <DetailPage />,
    key: "product-detail",
  },
  {
    path: "/projects/:id/update-scan/:scanId",
    element: <EditScanPage />,
    key: "edit-scan-detail",
  },
  {
    path: "/projects/:id/scan-details/:scanId",
    element: <ScanAnalysisPage />,
    key: "scan-analysis",
  },
  {
    path: "/projects/:id/update-project",
    element: <UpdateProjectPage />,
    key: "update-project-detail",
  },
  {
    path: "/projects/:id/scan-details/:scanId/findings-details/:analysisId",
    element: <VulnerabilityDetailPage />,
    key: "vulnerability-detail",
  },
  {
    path: "/projects/:id/scan-details/:scanId/add-findings",
    element: <AddVulnerabilityPage />,
    key: "add-vulnerability",
  },
  {
    path: "/projects/:id/scan-details/:scanId/update-findings/:analysisId",
    element: <UpdateVulnerabilityPage />,
    key: "update-vulnerability",
  },
  {
    path: "/projects/:id/reports",
    element: <ReportListPage />,
    key: "reports",
  },
];
