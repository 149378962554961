import { FC, FocusEventHandler, SyntheticEvent } from "react";
import { TextField, Autocomplete, Box } from "@mui/material";
import { TAutoComplete } from "../types";
import { createFilterOptions } from "@mui/material/Autocomplete";

type TSelecProps = {
  onChange: (event: SyntheticEvent, newValue: string | TAutoComplete | null) => void;
  options: TAutoComplete[];
  value: TAutoComplete | null;
  className?: string;
  placeholder?: string;
  error?: string;
  noMargin?: string;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  isDisable?: boolean;
};

const CustomSelect: FC<TSelecProps> = ({
  error,
  placeholder,
  onFocus,
  onBlur,
  value,
  noMargin,
  options,
  onChange,
  isDisable,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: TAutoComplete) => option.label as string,
  });
  return (
    <div className={`inputGroup ${error && "errorGroup"} ${noMargin && "noMargin"}`}>
      <Autocomplete
        disablePortal
        id="free-solo-demo"
        ListboxProps={{
          style: {
            maxHeight: "100px",
            border: "1px solid #E6E6E7",
            boxShadow: "0px 2px 8px #EAEBEC",
            borderRadius: "4px",
          },
        }}
        style={{ paddingRight: "0px" }}
        options={options as readonly TAutoComplete[]}
        filterOptions={filterOptions}
        onChange={onChange}
        disabled={isDisable ? true : false}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error ? true : false}
            onFocus={onFocus}
            onBlur={onBlur}
            style={{ backgroundColor: error ? "#ffecee" : "" }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.value}>
            {option.label}
          </Box>
        )}
        getOptionDisabled={(option) => (isDisable ? true : false)}
        value={value}
      />
      {error && <h6 className="errorMsg absolute">{error}</h6>}
    </div>
  );
};

export default CustomSelect;
