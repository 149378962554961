import { FC } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import styles from "../../../assets/styles/client.module.scss";
import { CustomLink } from "../../../shared_ui_components";
import { ClientUserLists } from "../components";
import { getImage } from "../../../utils";

type TRole = {
  role: number;
};

const ClientUsers: FC<TRole> = (props) => {
  const role = props.role;
  const navigate = useNavigate();
  return (
    <>
      {role === 1 || role === 2 ? (
        <div className={styles.clientUserDetail}>
          <div className={styles.userHeaderWrapper}>
            <Container maxWidth="lg" className={styles.userHeaderContainer}>
              <div className={styles.headerLeftBlk}>
                <CustomLink to="#" className="" onClick={() => navigate(-1)}>
                  <p className={styles.backBtn}>
                    <img src={getImage("arrow_left_grey.svg")} alt="" />
                    <span className="paragraph">Back to Client Details</span>
                  </p>
                </CustomLink>
              </div>
            </Container>
          </div>
          <ClientUserLists />
        </div>
      ) : (
        <Navigate to="/projects" />
      )}
    </>
  );
};

export default ClientUsers;
