import React, { FC, ReactElement } from "react";
import { Grid, Hidden } from "@mui/material";
import { CustomButton } from "../shared_ui_components";
import { getImage } from "../utils";

type TNoResultProps = {
  className?: string;
  title?: string;
  subtext?: ReactElement;
  image?: string;
  btnText?: string;
  link?: string;
  onClick?: () => void;
};

const NoResult: FC<TNoResultProps> = ({ className, title, image, subtext, btnText, link, onClick }) => {
  return (
    <div className={className + " noResultFound flexCentered"}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="contentRow textCenter"
      >
        <Grid item xs={12}>
          {image ? (
            <img className="noStateImg" src={image} alt="No state" />
          ) : (
            <img className="noStateImg" src={getImage("no_file_found.svg")} alt="No state" />
          )}
          {title ? <h5 className="heading6">{title}</h5> : null}
          <Hidden only={["xs", "sm"]}>
            {subtext ? <h6 className="noStateSubHeading">{subtext}</h6> : null}
            {btnText ? (
              <p>
                <CustomButton type="button" className={"primaryBtn"} onClick={onClick}>
                  {btnText ? <span>{btnText}</span> : <span>+ Add New</span>}
                </CustomButton>
              </p>
            ) : null}
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};
export default NoResult;
