import { FC, useState, useEffect, SyntheticEvent, useContext, useMemo } from "react";
import { Dialog, Grid } from "@mui/material";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { errorCodes } from "../../../constants";
import { CustomButton, CustomInput, CustomMultipleSelectDropdown, CustomSelect } from "../../../shared_ui_components";
import { Errors, TAutoComplete, TOwasp } from "../../../types";
import { toastFlashMessage, getImage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidations";
import { addVulnerabilityCategory } from "../apiServices";
import { GlobalContext } from "../../../context/GlobalContext";
import { VULNERABILITY_ACTIONS } from "../../../context/VulnerabilityReducer";
import { getOwaspsList } from "../../home/apiServices";

type TAddVulnerabilityDialogProps = {
  type?: string;
  open: boolean;
  onClose: (value: string) => void;
};

export type IStateVulCategory = {
  name?: string;
  description?: string;
  owasp?: any;
};

const AddVulnerabilityCategoryDialog: FC<TAddVulnerabilityDialogProps> = ({ type, open, onClose }) => {
  const { dispatch } = useContext(GlobalContext);
  const [formData, setFormData] = useState<IStateVulCategory>({
    name: "",
    description: "",
    owasp: [],
  });
  const [owaspOptions, setOwaspOptions] = useState([]);
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getOwaspsList().then((response) => {
      const data = response?.data?.map((_item: { id: number; name: string }) => {
        return {id: _item?.id, name: _item?.name};
      });
      setOwaspOptions(data || []);
    });
  }, []);

  const owaspId = useMemo(() => {
    const data = owaspOptions
      ?.filter((user: TOwasp) => formData.owasp?.includes(user?.name))
      .map((ele: TOwasp) => ele.id);
    return data;
  }, [formData.owasp]);

  const handleChange = (key: string, val: any) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const handleCancel = () => {
    onClose("false");
    setFormData({
      name: "",
      description: "",
      owasp: [],
    });
    setError({});
    setLoader(false);
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateNewInput: {
      [key: string]: string;
    } = {
      name: errorCode["category_name"][
        fieldValidation({
          ...errorCode["category_name_Obj"],
          fieldval: formData.name,
        })
      ],
      owasp:
        errorCode["owasp"][
          fieldValidation({
            ...errorCode["owasp_Obj"],
            fieldval: formData.owasp,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      const form_data = {
        name: formData.name,
        description: formData.description,
        owasp: owaspId,
      };
      addVulnerabilityCategory(form_data).then(
        (response: Partial<{ statusCode: number; data: IStateVulCategory; message: string }>) => {
          if (response.statusCode === 200) {
            dispatch({
              type: VULNERABILITY_ACTIONS.Add,
              payload: response.data,
            });
            setLoader(false);
            toastFlashMessage(response.message as string, "success");
            handleCancel();
          } else {
            setLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} sx={{ borderRadius: "4px" }}>
      <form onSubmit={handleSubmit}>
        <div className={styles.vulnerabilityDialogWrapper}>
          <div className={styles.headerTitle}>
            <p className="heading5">{type}</p>
            <img src={getImage("close_icon.svg")} alt="close" onClick={handleCancel} />
          </div>
          <div className={styles.formDetail}>
            <Grid container spacing={2}>
              <>
                <Grid item md={12}>
                  <CustomInput
                    label="Category name *"
                    className={styles.inputBlk}
                    placeholder="eg, John Doe"
                    noMargin="noMargin"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    error={error.name}
                  />
                </Grid>
              </>
              <Grid item md={12}>
                <CustomInput
                  multiline
                  label="Description"
                  className={styles.inputBlk}
                  placeholder="Description for the vulnerability category"
                  noMargin="noMargin"
                  value={formData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  error={error.description}
                />
              </Grid>

              <Grid item md={12} sx={{ marginTop: "-8px" }}>
                <p
                  className={error.owasp ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"}
                >
                  OWASP *
                </p>
                <CustomMultipleSelectDropdown
                  options={owaspOptions}
                  onChange={(event: SyntheticEvent, value) => {
                    handleChange("owasp", value);
                  }}
                  placeholder="Add OWASP"
                  value={formData.owasp}
                  error={error.owasp}
                />
              </Grid>
            </Grid>
          </div>
          <div className={styles.buttonWrapper} style={{ borderTop: "1px solid #E6E6E7" }}>
            <CustomButton className="outlinedBtn" children="Cancel" type="button" onClick={handleCancel} />
            <CustomButton
              className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
              children={type === "Add New Vulnerability Category" ? "Add" : "Update"}
              type="submit"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default AddVulnerabilityCategoryDialog;
