import { getStore } from "../store/GlobalStore";
import {
  TClientUsers,
  TUserInfoProp,
  TUsers,
  TProjectResponse,
  TClients,
  TVulnerabilityCategory,
} from "../types";
import { getAllClients } from "../features/clients/apiServices";
import {
  getAllProjects,
  getAllProjectsWithSearchPage,
  getAllProjectsWithPage,
} from "../features/home/apiServices";
import { getAllUsers, getUserDetails } from "../features/users/apiServices";
import { getAllVulnerabilityCategory } from "../features/vulnerabilities/apiServices";
import { CLIENT_ACTIONS } from "../context/ClientReducer";
import { PROJECT_ACTIONS } from "../context/ProjectReducer";
import { USER_ACTIONS } from "../context/UserReducer";
import { VULNERABILITY_ACTIONS } from "../context/VulnerabilityReducer";

export const _Length = (val: string) => {
  if (val.length > 7) {
    return true;
  } else {
    return false;
  }
};

export const _LowerCase = (val: string) => {
  return /[a-z]/.test(val);
};

export const _UpperCase = (val: string) => {
  return /[A-Z]/.test(val);
};

export const _Number = (val: string) => {
  return /\d/.test(val);
};

export const _SpecialCharacter = (val: string) => {
  // eslint-disable-next-line no-useless-escape
  const specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialCharacterRegex.test(val);
};

export const toastFlashMessage = (message: string, type: string, delay = 5000) => {
  const { dispatch } = getStore();

  dispatch({
    type: "CLEAR_TOAST",
    payload: "",
  });
  setTimeout(function () {
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: message,
        toastType: type,
      },
    });
    setTimeout(function () {
      dispatch({
        type: "HIDE_TOAST",
        payload: {},
      });
    }, delay);
  }, 200);
};

export const getUserInfo = (): TUserInfoProp | null => {
  var userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
  if (userInfo) {
    if (userInfo["user"]["profile_pic"]) {
      fetch(userInfo["user"]["profile_pic"], {
        method: 'GET'
      }).then((res) => {
        if (res.status !== 200) {
          getUserDetails().then(
            (response: Partial<{ statusCode: number; data: TUsers; message: string }>) => {
              if (response.statusCode === 200) {
                const profile_pic = response?.data?.profile_pic;
                userInfo["user"]["profile_pic"] = profile_pic;
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
              }
            }
          );
        }
      });
    }
    userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
    return userInfo;
  }
  return null;
};

export const listingAllProjects = (status?: string) => {
  const { dispatch } = getStore();
  getAllProjects(status).then(
    (response: Partial<{ statusCode: number; data: TProjectResponse[]; message: string }>) => {
      if (response.statusCode === 200) {
        dispatch({
          type: PROJECT_ACTIONS.Get,
          payload: response.data,
        });
      } else {
        toastFlashMessage(response.message as string, "error");
        if (response.statusCode === 1001 || response.statusCode === 3002) {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/signin";
          }, 3000);
        }
      }
    }
  );
};

export const listingProjectsWithSearchAndPage = (
  status?: string,
  page_number?: string | number,
  value?: string
) => {
  const { dispatch } = getStore();
  getAllProjectsWithSearchPage(status, page_number as string | number, value).then(
    (response: Partial<{ statusCode: number; data: TProjectResponse[]; message: string }>) => {
      if (response.statusCode === 200) {
        dispatch({
          type: PROJECT_ACTIONS.Get,
          payload: response.data,
        });
      } else {
        toastFlashMessage(response.message as string, "error");
        if (response.statusCode === 1001 || response.statusCode === 3002) {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/signin";
          }, 3000);
        }
      }
    }
  );
};

export const listingAllProjectsWithPage = (status?: string, page_number?: string | number) => {
  const { dispatch } = getStore();
  getAllProjectsWithPage(status, page_number as string | number).then(
    (response: Partial<{ statusCode: number; data: TProjectResponse[]; message: string }>) => {
      if (response.statusCode === 200) {
        dispatch({
          type: PROJECT_ACTIONS.Get,
          payload: response.data,
        });
      } else {
        toastFlashMessage(response.message as string, "error");
        if (response.statusCode === 1001 || response.statusCode === 3002) {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/signin";
          }, 3000);
        }
      }
    }
  );
};

export const listingAllClients = () => {
  const { dispatch } = getStore();
  getAllClients().then((response: Partial<{ statusCode: number; data: TClients[]; message: string }>) => {
    if (response.statusCode === 200) {
      dispatch({
        type: CLIENT_ACTIONS.Get,
        payload: response.data,
      });
    } else {
      toastFlashMessage(response.message as string, "error");
    }
  });
};

export const listingAllUsers = () => {
  const { dispatch } = getStore();

  getAllUsers().then((response: Partial<{ statusCode: number; data: TUsers[]; message: string }>) => {
    if (response.statusCode === 200) {
      dispatch({
        type: USER_ACTIONS.Get,
        payload: response.data,
      });
    } else {
      toastFlashMessage(response.message as string, "error");
      if (response.statusCode === 1001 || response.statusCode === 3002) {
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/signin";
        }, 3000);
      }
    }
  });
};

export const listingAllVulnerabilityCategory = () => {
  const { dispatch } = getStore();
  getAllVulnerabilityCategory().then(
    (response: Partial<{ statusCode: number; data: TVulnerabilityCategory[]; message: string }>) => {
      if (response.statusCode === 200) {
        dispatch({
          type: VULNERABILITY_ACTIONS.Get,
          payload: response.data,
        });
      } else {
        toastFlashMessage(response.message as string, "error");
        if (response.statusCode === 1001 || response.statusCode === 3002) {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/signin";
          }, 3000);
        }
      }
    }
  );
};

export const capitalizeFirstLetter = (val: string): string => {
  return val.charAt(0).toUpperCase() + val.slice(1);
};

export const deliveryManagerName = (users: TClientUsers[]): string => {
  const usersList = users
    .filter((user: TClientUsers) => user?.project_role?.value === 1)
    .map((_user: TClientUsers) =>
      _user.first_name ? _user.first_name : _user.email?.substring(0, _user.email.lastIndexOf("@"))
    )
    .join(", ");
  return usersList;
};

export const projectNameList = (clientList: TClients[], clientId: string) => {
  if (clientList && clientId) {
    const appLists = clientList
      ?.find((ele: TClients) => ele.uuid === clientId)
      ?.projects?.map((_el: TProjectResponse) => {
        return { label: _el.uuid, value: _el.name };
      });

    /* Filtering duplicate porject */
    const projectDetailsWithoutDuplicates = [] as any[];
    const duplicateProject = [] as any[];
    // eslint-disable-next-line array-callback-return
    appLists?.map((ele) => {
      if (projectDetailsWithoutDuplicates.length === 0) {
        projectDetailsWithoutDuplicates.push({
          label: ele.label,
          value: ele.value,
        });
      } else {
        const projectIndex = projectDetailsWithoutDuplicates.findIndex((_app) => {
          return _app.value === ele.value;
        });

        if (projectIndex >= 0) {
          duplicateProject.push({
            label: ele.label,
            value: ele.value,
          });
        } else {
          projectDetailsWithoutDuplicates.push({
            label: ele.label,
            value: ele.value,
          });
        }
      }
    });

    return projectDetailsWithoutDuplicates;
  }
};

export const securityNameList = (userList: TUsers[]) => {
  const _id: number[] = [3, 4];
  const data = userList
    ?.filter((user: TUsers) => _id?.includes(user?.role?.id as number))
    ?.map((_user: TUsers) => {
      return { label: _user?.first_name, value: _user?.uuid };
    });
  return data;
};

export const clientNameList = (clientList: TClients[]) => {
  const clientsLists = clientList?.map((client: TClients) => {
    return { label: client?.name, value: client?.uuid };
  });
  return clientsLists;
};

export const vulnerabilityList = (vulneralibityLists: TVulnerabilityCategory[]) => {
  const vulLists = vulneralibityLists?.map((ele) =>
    ele?.vulnerabilities?.map((_el) => {
      return { label: _el.name, value: _el.id };
    })
  );
  return vulLists.flat(Infinity);
};

export const rolesOption = [
  { label: "Administrator", value: 1 },
  { label: "Delivery Manager", value: 2 },
  { label: "Security Team Manager", value: 3 },
  { label: "Security Researcher", value: 4 },
  //{ label: "Client", value: 5 },
];
export const scanTypeOptions = [
  { label: "New Scan", value: 1 },
  { label: "Detailed Scan", value: 2 },
  { label: "Rescan", value: 3 },
];

export const scanTypeOptions2 = [
  { label: "New Scan", value: 1 },
  { label: "Detailed Scan", value: 2 },
];

export const methodologyTypeOptions = [
  {
    value: 1,
    label: "Greybox Testing",
  },
  {
    value: 2,
    label: "Blackbox Testing",
  },
];

export const scanStatusOption = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "In Progress",
  },
  {
    value: 2,
    label: "Discarded",
  },
  {
    value: 3,
    label: "Completed",
  },
];

export const scanStatusOptionDM = [
  {
    value: 2,
    label: "Discarded",
  },
];

export const scanStatusOptionSRM = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "In Progress",
  },
  {
    value: 3,
    label: "Completed",
  },
];

export const debounce = (func: any) => {
  let timer: NodeJS.Timeout | null;
  return function (this: any, ...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(this, args);
    }, 500);
  };
};

export const getSecurityScore = (val?: number) => {
  switch (val) {
    case val && val >= 0 && val <= 6:
      return "Critical";
    case val && val > 6 && val <= 8:
      return "High";
    case val && val > 8 && val < 10:
      return "Medium";
    case val && val === 10:
      return "Secure";
    default:
      return "";
  }
};

/* CVSS metrics details  */
export const attackComplexityDetails = [
  {
    name: "Low (L)",
    value: 0.77,
  },
  {
    name: "High (H)",
    value: 0.44,
  },
];

export const privilegesRequiredDetails = [
  {
    name: "None (N)",
    value: 0.85,
  },
  {
    name: "Low (L)",
    value: 0.62,
  },
  {
    name: "High (H)",
    value: 0.27,
  },
];

export const userInteractionsDetails = [
  {
    name: "None (N)",
    value: 0.85,
  },
  {
    name: "Required (R)",
    value: 0.62,
  },
];

export const vectorAttackDetails = [
  {
    name: "Network (N)",
    value: 0.85,
  },
  {
    name: "Adjacent (A)",
    value: 0.62,
  },
  {
    name: "Local (L)",
    value: 0.55,
  },
  {
    name: "Physical (P)",
    value: 0.2,
  },
];

export const scopeDetails = [
  {
    name: "Unchanged (U)",
    value: 6.42,
  },
  {
    name: "Changed (C)",
    value: 7.52,
  },
];

export const otherDetails = [
  {
    name: "None (N)",
    value: 0,
  },
  {
    name: "Low (L)",
    value: 0.22,
  },
  {
    name: "High (H)",
    value: 0.56,
  },
];
/* end */

export const riskStatus = [
  {
    value: 0,
    label: "Low",
  },
  {
    value: 1,
    label: "Medium",
  },
  {
    value: 2,
    label: "High",
  },
  {
    value: 3,
    label: "Critical",
  },
  {
    value: 4,
    label: "Passed",
  },
  {
    value: "",
    label: "None",
  },
];

export const toolTipSeverity = (previous_risk_status: number, risk_status: number): string => {
  return `Severity override from 
    ${
      previous_risk_status === 0
        ? "Low"
        : previous_risk_status === 1
        ? "Medium"
        : previous_risk_status === 2
        ? "High"
        : previous_risk_status === 3
        ? "Critical"
        : previous_risk_status === 4
        ? "Passed"
        : ""
    } to  ${
    risk_status === 0
      ? "Low"
      : risk_status === 1
      ? "Medium"
      : risk_status === 2
      ? "High"
      : risk_status === 3
      ? "Critical"
      : risk_status === 4
      ? "Passed"
      : ""
  }`;
};

export const toolTipScore = (previous_risk_status: number, risk_status: number): string => {
  return `CVSSv3 Score override from 
    ${
      previous_risk_status === 0
        ? "3.9"
        : previous_risk_status === 1
        ? "6.9"
        : previous_risk_status === 2
        ? "8.9"
        : previous_risk_status === 3
        ? "10"
        : previous_risk_status === 4
        ? "0.0"
        : ""
    } to  ${
    risk_status === 0
      ? "3.9"
      : risk_status === 1
      ? "6.9"
      : risk_status === 2
      ? "8.9"
      : risk_status === 3
      ? "10"
      : risk_status === 4
      ? "0.0"
      : ""
  }`;
};

const role = [
  {
    id: 1,
    name: "Administrator",
    code: "ADMIN",
    permission: [
      "ADD_CLIENT",
      "DELETE_CLIENT",
      "EDIT_CLIENT",
      "ADD_PROJECT",
      "DELETE_PROJECT",
      "EDIT_PROJECT",
      "ADD_VULNERABILITY",
      "DELETE_VULNERABILITY",
      "EDIT_VULNERABILITY",
      "ADD_USER",
      "DELETE_USER",
      "EDIT_USER",
      "ADD_ROLE",
      "DELETE_ROLE",
      "EDIT_ROLE",
      "ADD_SCAN_REQUEST",
      "DELETE_SCAN_REQUEST",
      "EDIT_SCAN_REQUEST",
    ],
  },
  {
    id: 2,
    name: "Delivery Manager",
    code: "DELIVERY_MANAGER",
    permission: [
      "ADD_CLIENT",
      "DELETE_CLIENT",
      "EDIT_CLIENT",
      "ADD_PROJECT",
      "DELETE_PROJECT",
      "EDIT_PROJECT",
      "ADD_SCAN_REQUEST",
      "DELETE_SCAN_REQUEST",
      "EDIT_SCAN_REQUEST",
    ],
  },
  {
    id: 3,
    name: "Security Team Manager",
    code: "SECURITY_TEAM_MANAGER",
    permission: ["ADD_VULNERABILITY", "DELETE_VULNERABILITY", "EDIT_VULNERABILITY", "EDIT_SCAN_REQUEST"],
  },
  {
    id: 4,
    name: "Security Researcher",
    code: "SECURITY_RESEARCHER",
    permission: ["EDIT_SCAN_REQUEST"],
  },
  {
    id: 5,
    name: "Client",
    code: "CLIENT",
    permission: [],
  },
];

export const roleDetails = (code: string) => {
  return role?.find((details) => details.code === code);
};

export const isAtob = (str: string) => {
  try {
    return window.atob(str);
  } catch (e) {
    localStorage.clear();
  }
};

export const getImage = (image: string) => {
  return require(`../assets/images/${image}`);
};
