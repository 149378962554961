import { FC } from "react";
import { CustomButton } from "../../../shared_ui_components";
import styles from "../../../assets/styles/auth.module.scss";

type TProps = {
  updateStep: (num: number) => void;
};

const ResetPasswordText: FC<TProps> = ({ updateStep }) => {
  const handleUpdateStep = (val: number) => {
    updateStep(val);
  };

  return (
    <>
      <p className={styles.formText + " paragraph"}>
        Click here to redirect to the password reset link. You must ensure that you choose a password that you{" "}
        <br></br>
        haven't already used with this account. If you change or reset your password, you’ll be signed out{" "}
        <br></br>
        everywhere except devices you use to verify that it's you when you sign in.
      </p>
      <CustomButton
        className={styles.resetButton + " primaryBtn"}
        children="Reset password"
        type="button"
        onClick={() => handleUpdateStep(2)}
      />
    </>
  );
};

export default ResetPasswordText;
