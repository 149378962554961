import { FC, useState, useEffect, ChangeEvent, Fragment, useContext } from "react";
import { Container, Grid } from "@mui/material";
import styles from "../../../assets/styles/client.module.scss";
import { Search } from "../../../shared_elements";
import { CustomButton, NoResult } from "../../../shared_ui_components";
import { ClientUserListItem, Shimmer, ClientAddUpdateUserDialog } from ".";
import { useParams, useNavigate } from "react-router-dom";
import { getSingleClientUsers } from "../apiServices";
import { TUsers } from "../../../types";
import { toastFlashMessage, getImage } from "../../../utils";
import { GlobalContext } from "../../../context/GlobalContext";

type TSearchType = {
  val?: string;
  list?: TUsers[] | [];
};

const ClientUserLists: FC = () => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const userPermission = roleLists?.roleData?.permission;
  const [search, setSearch] = useState<TSearchType>({
    val: "",
    list: [],
  });
  const [openAddUser, setOpenAddUser] = useState(false);
  const [clientUsers, setClientUsers] = useState<TUsers[] | []>([]);
  const [loader, setLoader] = useState(true);

  const handleOpenAddUser = () => {
    setOpenAddUser(!openAddUser);
  };

  useEffect(() => {
    if (id) {
      getSingleClientUsers(`${id}`).then(
        (response: Partial<{ statusCode: number; data: TUsers[]; message: string }>) => {
          if (response.statusCode === 200) {
            setLoader(false);
            setClientUsers(response?.data as TUsers[]);
          } else {
            toastFlashMessage(response.message as string, "error");
            navigate(`/clients/${id}`);
            setLoader(false);
          }
        }
      );
    }
    return () => {
      setClientUsers([]);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSearch = (val: string) => {
    let str = val.toLowerCase();
    let searchList = clientUsers?.filter(
      (user) =>
        user?.email?.toLowerCase().includes(str) ||
        user?.first_name?.toLowerCase().includes(str) ||
        user?.role?.name?.toLowerCase().includes(str)
    );
    setSearch(() => ({
      val: str,
      list: searchList,
    }));
  };

  const handleClearSearch = () => {
    setSearch(() => ({
      val: "",
      list: [],
    }));
  };

  /* Add New User to the client list */
  const handleAddClientUsers = (user: TUsers) => {
    setClientUsers([...clientUsers, user]);
  };

  /* Update User to the client list */
  const handleUpdateClientUsers = (user: TUsers) => {
    setClientUsers(
      clientUsers.map((users) => {
        return users.uuid === user.uuid ? { ...users, ...user } : { ...users };
      })
    );
  };

  return (
    <>
      <Container maxWidth="lg">
        <div className={styles.userDashboardWrapper}>
          <div className={styles.userTitleWrapper}>
            <h2 className="heading3">All Users</h2>
            <div className={styles.userSearchAndViewWrapper}>
              {clientUsers?.length !== 0 && userPermission?.includes("ADD_USERS") && (
                <CustomButton
                  className=" primaryBtn"
                  children="Invite New User"
                  type="button"
                  onClick={handleOpenAddUser}
                />
              )}
            </div>
          </div>
          {clientUsers?.length !== 0 && (
            <div className={styles.userListWrapper}>
              <p className={styles.userListTitle + " heading6 headingTextOverflow"}>
                {search?.val ? (
                  `Showing ${search?.list?.length} ${
                    search?.list?.length === 1 || search?.list?.length === 0 ? "result" : "results"
                  } for "${search?.val}" `
                ) : (
                  <>
                    {clientUsers?.length > 1 ? "Users" : "User"} <span>{clientUsers?.length}</span>
                  </>
                )}
              </p>
              <div className={styles.userSearchAndViewWrapper}>
                {!loader && (
                  <>
                    {search.val ? (
                      <span onClick={handleClearSearch} className={styles.clearSearch + " paragraph"}>
                        Clear search
                      </span>
                    ) : (
                      ""
                    )}
                    <Search
                      text={search.val as string}
                      placeholder="Search for users"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          {!loader && clientUsers?.length === 0 ? (
            userPermission?.includes("ADD_USERS") ? (
              <NoResult
                image={getImage("no_file_found.svg")}
                title="No Client User Found"
                btnText="Invite New  User"
                onClick={handleOpenAddUser}
                link={`/clients/${id}`}
              />
            ) : (
              <NoResult image={getImage("no_file_found.svg")} title="No Client User Found" />
            )
          ) : (
            <>
              {" "}
              <div className={styles.userListWrapper}>
                <Grid container>
                  <Grid item md={3}>
                    <p>USER NAME</p>
                  </Grid>
                  <Grid item md={3}>
                    <p>EMAIL ID</p>
                  </Grid>
                  <Grid item md={3}>
                    <p>USER ROLE</p>
                  </Grid>
                  <Grid item md={2}>
                    <p>INVITE STATUS</p>
                  </Grid>
                  <Grid item md={1}></Grid>
                </Grid>
              </div>
              {loader ? (
                <Shimmer type="clientUsersList" />
              ) : (
                <>
                  {search?.val ? (
                    <>
                      {search?.list?.length === 0 ? (
                        <NoResult
                          image={getImage("no_file_found.svg")}
                          title="No Result Found"
                          subtext={
                            <Fragment>We didn't find any client users that matched your search</Fragment>
                          }
                        />
                      ) : (
                        <div className={styles.userListWrapperFooter}>
                          {search?.list?.map((users: TUsers) => {
                            return (
                              <Fragment key={users.uuid}>
                                <ClientUserListItem
                                  {...users}
                                  clientId={id}
                                  handleUpdateClientUsers={handleUpdateClientUsers}
                                  handleSearch={handleSearch}
                                />
                              </Fragment>
                            );
                          })}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className={styles.userListWrapperFooter}>
                      {clientUsers?.map((users) => {
                        return (
                          <Fragment key={users.uuid}>
                            <ClientUserListItem
                              {...users}
                              clientId={id}
                              handleUpdateClientUsers={handleUpdateClientUsers}
                            />
                          </Fragment>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Container>
      <ClientAddUpdateUserDialog
        type="Invite New Users"
        open={openAddUser}
        onClose={handleOpenAddUser}
        clientId={id}
        handleAddClientUsers={handleAddClientUsers}
        handleSearch={handleSearch}
      />
    </>
  );
};

export default ClientUserLists;
