import { FC, useState, useEffect, SyntheticEvent, useContext, useMemo } from "react";
import { Dialog, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { errorCodes } from "../../../constants";
import { CustomButton, CustomInput, CustomMultipleSelectDropdown, CustomSelect } from "../../../shared_ui_components";
import { Errors, TVulnerabilityResponse, TAutoComplete, TCwe, TGdpr } from "../../../types";
import { toastFlashMessage, getImage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidations";
import { addVulnerability, getCWEsList, getGdprList } from "../apiServices";
import { GlobalContext } from "../../../context/GlobalContext";
import { VULNERABILITY_ACTIONS } from "../../../context/VulnerabilityReducer";

type TAddVulnerabilityDialogProps = {
  open: boolean;
  onClose: (value: string) => void;
  categoryName?: string;
  categoryId?: string | number;
  type?: string;
  name?: string;
  description?: string;
  cwe?: any;
  gdpr?: any;
  handleAddVulnerabilityInCategory?: (a: TVulnerabilityResponse) => void;
};

export type IStateVulnerability = {
  name?: string;
  description?: string;
  category?: string | TAutoComplete;
  cwe?: any;
  gdpr?: any;
};

const AddVulnerabilityDialog: FC<TAddVulnerabilityDialogProps> = ({
  open,
  onClose,
  categoryName,
  categoryId,
  type,
  name,
  description,
  cwe,
  gdpr,
  handleAddVulnerabilityInCategory,
}) => {
  const {
    dispatch,
    state: { vulneralibityLists },
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IStateVulnerability>({
    name: "",
    description: "",
    category: "",
    cwe: [],
    gdpr: [],
  });
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);
  const [cweOptions, setCweOptions] = useState([]);
  const [gdprOptions, setGdprOptions] = useState([]);

  const handleChange = (key: string, val: TAutoComplete | string) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const resetFormData = () => {
    setFormData({
      ...formData,
      name: "",
      description: "",
      category: "",
      cwe: [],
      gdpr: [],
    });
  };

  const handleCancel = () => {
    if (formData?.category) {
      onClose("false");
      setError({});
    } else {
      onClose("false");
      resetFormData();
      setError({});
    }
    setLoader(false);
  };

  useEffect(() => {
    getCWEsList().then((response: Partial<{ statusCode: number; data: any; message: string }>) => {
      if (response.statusCode === 200) {
        if (response.data) {
          const data = response?.data?.map((item: {name: string, id: number}) => {
            return {id: item?.id, name: item?.name};
          });
          setCweOptions(data || []);
        }
      } else {
        toastFlashMessage(response.message as string, "error");
      }
    });
  }, []);

  const cweId = useMemo(() => {
    const data = cweOptions
      ?.filter((user: TCwe) => formData.cwe?.includes(user?.name))
      .map((ele: TCwe) => ele.id);
    return data;
  }, [formData.cwe]);

  useEffect(() => {
    getGdprList().then((response: Partial<{ statusCode: number; data: any; message: string }>) => {
      if (response.statusCode === 200) {
        if (response.data) {
          const data = response?.data?.map((item: {name: string, id: number}) => {
            return {id: item?.id, name: item?.name};
          });
          setGdprOptions(data || []);
        }
      } else {
        toastFlashMessage(response.message as string, "error");
      }
    });
  }, []);

  const gdprId = useMemo(() => {
    const data = gdprOptions
      ?.filter((user: TGdpr) => formData.gdpr?.includes(user?.name))
      .map((ele: TGdpr) => ele.id);
    return data;
  }, [formData.gdpr]);

  // /* To show the existing data while adding vulnerability*/
  useEffect(() => {
    if (categoryName) {
      setFormData({
        ...formData,
        category: {
          label: categoryName ? categoryName : "",
          value: categoryId ? categoryId : "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryName]);

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateNewInput: {
      [key: string]: string;
    } = {
      name: errorCode["title"][
        fieldValidation({
          ...errorCode["title_Obj"],
          fieldval: formData.name,
        })
      ],
      category:
        errorCode["category_name"][
          fieldValidation({
            ...errorCode["category_name_Obj"],
            fieldval: formData.category,
          })
        ],
      cwe: errorCode["cwe"][
        fieldValidation({
          ...errorCode["cwe_Obj"],
          fieldval: formData.cwe,
        })
      ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      const form_data: IStateVulnerability = {
        name: formData?.name,
        description: formData.description,
        category: formData?.category && (formData?.category as HTMLInputElement)["value"],
        cwe: cweId,
        gdpr: gdprId
      };
      addVulnerability(form_data).then(
        (response: Partial<{ statusCode: number; data: TVulnerabilityResponse; message: string }>) => {
          if (response.statusCode === 200) {
            dispatch({
              type: VULNERABILITY_ACTIONS.AddVulnerability,
              payload: response.data,
            });
            setLoader(false);
            toastFlashMessage(response.message as string, "success");
            handleCancel();
            if (categoryId && categoryName) {
              handleAddVulnerabilityInCategory?.(response?.data as TVulnerabilityResponse);
              navigate(`/vulnerability-category/${categoryId}/update`);
            }
          } else {
            setLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} sx={{ borderRadius: "4px" }}>
      <form onSubmit={handleSubmit}>
        <div className={styles.vulnerabilityDialogWrapper}>
          <div className={styles.headerTitle}>
            <p className="heading5">{type === "show-detail" ? "Details" : "Add New Vulnerability"}</p>
            <img src={getImage("close_icon.svg")} alt="close" onClick={handleCancel} />
          </div>
          <div className={styles.formDetail}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                {type === "show-detail" ? (
                  <div className={styles.detailDescWrapper}>
                    <p className={styles.formTitle + " supportText_semiBold"}>Title</p>
                    <p className={styles.desc + " paragraph"}>{name ? name : "N/A"}</p>
                  </div>
                ) : (
                  <CustomInput
                    label="Title *"
                    className={styles.inputBlk}
                    placeholder="Title for Vulnerability"
                    noMargin="noMargin"
                    value={formData.name}
                    disabled={name ? true : false}
                    onChange={(e) => handleChange("name", e.target.value)}
                    error={error.name}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                {type === "show-detail" ? (
                  <div className={styles.detailDescWrapper}>
                    <p className={styles.formTitle + " supportText_semiBold"}>Vulnerability Category</p>
                    <p className={styles.desc + " paragraph"}>{categoryName ? categoryName : "N/A"}</p>
                  </div>
                ) : (
                  <>
                    <p
                      className={
                        `${error.category ? styles.errorFormTitle : styles.formTitle}` +
                        " supportText_semiBold"
                      }
                    >
                      Vulnerability Category *
                    </p>
                    <CustomSelect
                      placeholder="Select Category"
                      className={styles.inputBlk}
                      options={
                        vulneralibityLists?.vulnerabilityData?.map((details) => {
                          return { label: details?.name, value: details?.id };
                        }) as TAutoComplete[]
                      }
                      value={formData.category as HTMLInputElement}
                      onChange={(event: SyntheticEvent, newValue: string | TAutoComplete | null) => {
                        handleChange("category", newValue as string | TAutoComplete);
                      }}
                      isDisable={categoryId ? true : false}
                      noMargin="noMargin"
                      error={error.category}
                    />
                  </>
                )}
              </Grid>
              <Grid item md={12} sx={{ marginBottom: "8px" }}>
                <p
                  className={error.cwe ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"}
                >
                  CWE *
                </p>
                <CustomMultipleSelectDropdown
                  options={cweOptions ? cweOptions : []}
                  onChange={(event: SyntheticEvent, value) => {
                    handleChange("cwe", value);
                  }}
                  placeholder="Add CWE"
                  value={formData.cwe}
                  error={error.cwe}
                />
              </Grid>
              <Grid item md={12} sx={{ marginBottom: "8px" }}>
                <p
                  className={
                    error.gdpr ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"
                  }
                >
                  GDPR *
                </p>
                <CustomMultipleSelectDropdown
                  options={gdprOptions ? gdprOptions : []}
                  onChange={(event: SyntheticEvent, value) => {
                    handleChange("gdpr", value);
                  }}
                  placeholder="Add GDPR"
                  value={formData.gdpr}
                  error={error.gdpr}
                />
              </Grid>
              <Grid item md={12}>
                {type === "show-detail" ? (
                  <div className={styles.detailDescWrapper}>
                    <p className={styles.formTitle + " supportText_semiBold"}>Description</p>
                    <p className={styles.desc + " paragraph"}>{description ? description : "N/A"}</p>
                  </div>
                ) : (
                  <CustomInput
                    className={styles.inputBlk}
                    multiline
                    label="Description"
                    placeholder="Description for the vulnerability"
                    noMargin="noMargin"
                    value={formData.description}
                    disabled={description ? true : false}
                    onChange={(e) => handleChange("description", e.target.value)}
                    error={error.description}
                  />
                )}
              </Grid>
            </Grid>
          </div>
          {type !== "show-detail" ? (
            <div className={styles.buttonWrapper} style={{ borderTop: "1px solid #E6E6E7" }}>
              <CustomButton className="outlinedBtn" children="Cancel" type="button" onClick={handleCancel} />
              <CustomButton
                className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
                children={`${loader ? "Creating..." : "Create"}`}
                type="submit"
              />
            </div>
          ) : null}
        </div>
      </form>
    </Dialog>
  );
};

export default AddVulnerabilityDialog;
