import { FC, useState, useEffect, useContext } from "react";
import { Box, Container } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { CustomButton, CustomLink } from "../shared_ui_components";
import { AddProjectDialog } from "../features/home/components";
import { AddNewClientDialog } from "../features/clients/components";
import { AddUpdateUserDialog } from "../features/users/components";
import { GlobalContext } from "../context/GlobalContext";
import { PROJECT_ACTIONS } from "../context/ProjectReducer";

const DashboardTabs: FC = () => {
  const { pathname } = useLocation();
  const { id, scanId, analysisId } = useParams();
  const {
    state: { clientLists, projectLists, userLists, roleLists },
    dispatch,
  } = useContext(GlobalContext);

  const role = roleLists?.roleData;

  const [openProject, setOpenProject] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [step, setStep] = useState(0);

  const handleOpenProject = () => {
    setOpenProject(!openProject);
  };
  const handleOpenAddUser = () => {
    setOpenAddUser(!openAddUser);
  };

  const handleOpenClient = () => {
    setOpenClient(!openClient);
  };

  useEffect(() => {
    if (pathname === "/projects") {
      setStep(1);
    } else if (pathname === "/vulnerabilities") {
      setStep(2);
    } else if (pathname === "/clients" || pathname === `/clients/${id}`) {
      setStep(3);
    } else if (pathname === "/users") {
      setStep(4);
    }
  }, [pathname, id]);

  return (
    <>
      <div className="dashboardTabWrapper">
        <Container maxWidth="lg">
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 56 }}>
            <div className="tabListItem">
              <CustomLink
                className={`tabLink ${
                  (step === 1 ||
                    pathname === `/projects/${id}` ||
                    pathname === `/projects/${id}/update-scan/${scanId}` ||
                    pathname === `/projects/${id}/scan-details/${scanId}` ||
                    pathname === `/projects/${id}/scan-details/${scanId}/add-findings` ||
                    pathname === `/projects/${id}/scan-details/${scanId}/update-findings/${analysisId}` ||
                    pathname === `/projects/${id}/update-project` ||
                    pathname === `/projects/${id}/reports`) &&
                  " active"
                } heading6`}
                to="/projects"
                onClick={() => {
                  if (step !== 1) {
                    dispatch({
                      type: PROJECT_ACTIONS.Loading,
                      payload: true,
                    });
                  }
                }}
              >
                Projects <span></span>
              </CustomLink>
              {role.id === 1 || role.id === 3 || role.id === 4 ? (
                <CustomLink
                  className={`tabLink ${
                    (step === 2 ||
                      pathname === `/vulnerabilities/${id}/update` ||
                      pathname === `/vulnerability-category/${id}/update` ||
                      pathname === `/vulnerabilities/${id}/update-scan/${scanId}` ||
                      pathname === `/vulnerabilities/${id}/scan-details/${scanId}` ||
                      pathname === `/vulnerabilities/${id}/scan-details/${scanId}/add-findings` ||
                      pathname ===
                        `/vulnerabilities/${id}/scan-details/${scanId}/update-findings/${analysisId}`) &&
                    " active"
                  } heading6`}
                  to="/vulnerabilities"
                >
                  Vulnerabilities <span></span>
                </CustomLink>
              ) : null}

              {role.id === 1 || role.id === 2 ? (
                <CustomLink className={`tabLink ${step === 3 && " active"} heading6`} to="/clients">
                  Clients <span></span>
                </CustomLink>
              ) : null}
              {role.id === 1 ? (
                <CustomLink className={`tabLink ${step === 4 && " active"} heading6`} to="/users">
                  Users <span></span>
                </CustomLink>
              ) : null}
            </div>
            <div>
              {step === 1 && !projectLists.showNoState && role.permission?.includes("ADD_PROJECT") ? (
                <CustomButton
                  className=" primaryBtn"
                  children="Create a Project"
                  type="button"
                  onClick={handleOpenProject}
                />
              ) : null}
              {step === 3 && !clientLists.showNoState && role?.permission?.includes("ADD_CLIENT") ? (
                <CustomButton
                  className=" primaryBtn"
                  children="Add New Client"
                  type="button"
                  onClick={handleOpenClient}
                />
              ) : null}
              {step === 4 && !userLists.showNoState && role?.permission?.includes("ADD_USER") ? (
                <CustomButton
                  className=" primaryBtn"
                  children="Add New User"
                  type="button"
                  onClick={handleOpenAddUser}
                />
              ) : null}
            </div>
          </Box>
        </Container>
        <AddProjectDialog type="Create New Project" open={openProject} onClose={handleOpenProject} />
        <AddNewClientDialog type="Add New Client" open={openClient} onClose={handleOpenClient} />
        <AddUpdateUserDialog type="Invite New User" open={openAddUser} onClose={handleOpenAddUser} />
      </div>
    </>
  );
};

export default DashboardTabs;
