import { FC, SyntheticEvent, useState, useEffect, useRef } from "react";
import { CustomButton, CustomInput, CustomLink } from "../../../shared_ui_components";
import { errorCodes } from "../../../constants";
import styles from "../../../assets/styles/auth.module.scss";
import { fieldValidation } from "../../../utils/formValidations";
import { Errors, TSigninType, TUserInfoProp } from "../../../types";
import { useNavigate } from "react-router-dom";
import { signInApi } from "../apiServices";
import {
  getUserInfo,
  toastFlashMessage,
  listingAllClients,
  listingAllUsers,
  listingAllVulnerabilityCategory,
  roleDetails,
  isAtob,
  getImage,
} from "../../../utils";
import { getStore } from "../../../store/GlobalStore";
import { ROLE_ACTIONS } from "../../../context/RoleReducer";

const SignInForm: FC = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<TSigninType>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const isMounted = useRef(true); // Initial value isMounted = true

  useEffect(() => {
    if (getUserInfo()?.user) {
      navigate("/projects");
    }
    return () => {
      isMounted.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (key: string, val: string) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateNewInput: {
      [key: string]: string;
    } = {
      password:
        errorCode["password"][
          fieldValidation({
            ...errorCode["passwordObj"],
            fieldval: formData.password,
          })
        ],
      email:
        errorCode["email"][
          fieldValidation({
            ...errorCode["emailObj"],
            fieldval: formData.email,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      signInApi(formData).then(
        (response: Partial<{ statusCode: number; data: TUserInfoProp; message: string }>) => {
          if (response.statusCode === 200 && isMounted.current) {
            toastFlashMessage(response.message as string, "success");
            localStorage.setItem("userInfo", JSON.stringify(response?.data));
            navigate("/projects");
            setLoader(false);
            const { dispatch } = getStore();
            const role = isAtob(
              `${response?.data?.access?.substring(
                response?.data?.access?.lastIndexOf("$") + 1,
                response?.data?.access?.length
              )}`
            );
            dispatch({
              type: ROLE_ACTIONS.Get,
              payload: roleDetails(role as string),
            });
            if (roleDetails(role as string)?.id === 1) {
              listingAllVulnerabilityCategory();
              listingAllUsers();
              listingAllClients();
            } else if (roleDetails(role as string)?.id === 2) {
              listingAllClients();
              listingAllUsers();
            } else if (roleDetails(role as string)?.id === 3 || roleDetails(role as string)?.id === 4) {
              listingAllVulnerabilityCategory();
            } else {
            }
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoader(false);
            navigate("/signin");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={styles.signInForm + " formWrapper"}>
          <h1 className={styles.title + " heading3 textCenter"}>Sign in to appknox</h1>
          <CustomInput
            className="paragraph"
            label="Email Address *"
            placeholder="abc@xyz.com"
            onChange={(e) => handleChange("email", e.target.value)}
            error={error.email}
            value={formData.email}
          />
          <div className={styles.passwordWrapper}>
            <CustomInput
              className="paragraph"
              label="Password *"
              placeholder="********"
              type={showPassword ? "text" : "password"}
              onChange={(e) => handleChange("password", e.target.value)}
              error={error.password}
              value={formData.password}
            />
            <span
              className={styles.showPassword}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? (
                <img src={getImage("eye-open.svg")} alt="" />
              ) : (
                <img src={getImage("eye-close.svg")} alt="" />
              )}
            </span>
            <CustomLink className={styles.customLink} children="Forgot Password?" to="/forgot-password" />
          </div>
          <CustomButton
            className={styles.signinButton + `${loader ? " primaryBtn disabledBtn" : " primaryBtn"}`}
            children={loader ? "Signing in" : "Sign in"}
            type="submit"
          />
        </div>
      </form>
    </>
  );
};
export default SignInForm;
