import { FC, SyntheticEvent, useContext, useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CustomButton, CustomInput, CustomMultipleSelectDropdown, CustomSelect } from "../../../shared_ui_components";
import styles from "../../../assets/styles/vulnerability.module.scss";
import { errorCodes } from "../../../constants";
import { AddVulnerabilityDialog, VulnerabilityInVulCategory, Shimmer } from ".";
import {
  Errors,
  TVulnerabilityCategory,
  TVulnerability,
  TVulnerabilityResponse,
  TAutoComplete,
  TOwasp,
} from "../../../types";
import {
  getSingleVulnerabilityCategory,
  updateVulnerabilityCategory,
  deleteSingleVulnerability,
} from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { fieldValidation } from "../../../utils/formValidations";
import { GlobalContext } from "../../../context/GlobalContext";
import { VULNERABILITY_ACTIONS } from "../../../context/VulnerabilityReducer";
import { getOwaspsList } from "../../home/apiServices";

type IStateVulCategory = {
  name?: string;
  description?: string;
  vulnerability?: TVulnerability[] | [];
  owasp?: any;
};

const UpdateVulnerabilityCategoryForm: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { dispatch } = useContext(GlobalContext);
  const [openAddVulnerability, setOpenAddVulnerability] = useState(false);
  const [owaspOptions, setOwaspOptions] = useState([]);
  const [formData, setFormData] = useState<IStateVulCategory>({
    name: "",
    description: "",
    vulnerability: [],
    owasp: [],
  });

  const [error, setError] = useState<Errors>({});
  const [errorCode] = useState(errorCodes);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getSingleVulnerabilityCategory(`${id}`).then(
        (response: Partial<{ statusCode: number; data: any; message: string }>) => {
          if (response.statusCode === 200) {
            const valCategoryDetails = response?.data;
            setFormData((form) => ({
              ...form,
              name: valCategoryDetails?.name,
              description: valCategoryDetails?.description,
              vulnerability: valCategoryDetails?.vulnerabilities,
              owasp: valCategoryDetails?.owasp?.map((item: { name: string }) => item.name),
            }));
            setLoading(false);
          } else {
            toastFlashMessage(response.message as string, "error");
            setLoading(false);
          }
        }
      );
    }
  }, [id]);

  useEffect(() => {
    getOwaspsList().then((response) => {
      const data = response?.data?.map((_item: { id: number; name: string }) => {
        return {id: _item?.id, name: _item?.name};
      });
      setOwaspOptions(data || []);
    });
  }, []);

  const owaspId = useMemo(() => {
    const data = owaspOptions
      ?.filter((user: TOwasp) => formData.owasp?.includes(user?.name))
      .map((ele: TOwasp) => ele.id);
    return data;
  }, [formData.owasp]);

  const handleDeleteVulnerability = (id: number) => {
    setLoader(true);
    deleteSingleVulnerability(`${id}`).then((response: Partial<{ statusCode: number; message: string }>) => {
      if (response.statusCode === 200) {
        toastFlashMessage(response.message as string, "success");
        setFormData((form) => ({
          ...form,
          vulnerability: form?.vulnerability?.filter((valDetails) => {
            return valDetails?.id !== id;
          }),
        }));
        setLoader(false);
      } else {
        toastFlashMessage(response.message as string, "error");
      }
    });
  };

  const handleOpenAddVulnerability = () => {
    setOpenAddVulnerability(!openAddVulnerability);
  };

  const handleChange = (key: string, val: any) => {
    setFormData({
      ...formData,
      [key]: val,
    });
    setError({
      ...error,
      [key]: "",
    });
  };

  /* Adding New Vulnerability */
  const handleAddVulnerabilityInCategory = (addNewVulnerability: TVulnerabilityResponse) => {
    setFormData((form) => ({
      ...form,
      vulnerability: form?.vulnerability && [addNewVulnerability, ...form?.vulnerability],
    }));
  };

  const handleSubmit = () => {
    const validateNewInput: {
      [key: string]: string;
    } = {
      name: errorCode["category_name"][
        fieldValidation({
          ...errorCode["category_name_Obj"],
          fieldval: formData.name,
        })
      ],
      owasp:
        errorCode["owasp"][
          fieldValidation({
            ...errorCode["owasp_Obj"],
            fieldval: formData.owasp,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] === "")) {
      setLoader(true);
      const form_data = {
        name: formData.name,
        description: formData.description,
        owasp: owaspId,
      };
      updateVulnerabilityCategory(`${id}`, form_data).then(
        (response: Partial<{ statusCode: number; data: TVulnerabilityCategory; message: string }>) => {
          if (response.statusCode === 200) {
            dispatch({
              type: VULNERABILITY_ACTIONS.Update,
              payload: response.data,
            });
            setLoader(false);
            toastFlashMessage(response.message as string, "success");
            navigate("/vulnerabilities");
          } else {
            setLoader(false);
            toastFlashMessage(response.message as string, "error");
          }
        }
      );
    } else {
      setError(validateNewInput);
    }
  };

  return (
    <>
      {loading ? (
        <Shimmer type="editVulnerabilityCategory" />
      ) : (
        <div className={styles.formDetail}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <CustomInput
                label="Category name *"
                className={styles.inputBlk}
                placeholder="Business Logic Flow"
                onChange={(e) => handleChange("name", e.target.value)}
                noMargin="noMargin"
                value={formData.name}
                error={error.name}
              />
            </Grid>
            <Grid item md={12}>
              <CustomInput
                multiline
                label="Description"
                className={styles.inputBlk}
                placeholder="Description for the vulnerability category"
                noMargin="noMargin"
                value={formData.description}
                onChange={(e) => handleChange("description", e.target.value)}
                error={error.description}
              />
            </Grid>

            <Grid item md={12} sx={{ marginTop: "-8px" }}>
              <p className={error.owasp ? styles.errorFormTitle : styles.formTitle + " supportText_semiBold"}>
                OWASP *
              </p>
              <CustomMultipleSelectDropdown
                options={owaspOptions}
                onChange={(event: SyntheticEvent, value) => {
                  handleChange("owasp", value);
                }}
                placeholder="Add OWASP"
                value={formData.owasp}
                error={error.owasp}
              />
            </Grid>
            <Grid item md={12}>
              {formData?.vulnerability && formData?.vulnerability?.length > 0 && (
                <>
                  <p className={styles.formTitle + " supportText_semiBold"}>
                    Vulnerabilities under this category
                  </p>
                  {formData?.vulnerability?.map((valDetails, idx) => {
                    return (
                      <VulnerabilityInVulCategory
                        key={idx}
                        {...valDetails}
                        handleDeleteVulnerability={handleDeleteVulnerability}
                      />
                    );
                  })}
                </>
              )}

              <CustomButton
                className={styles.addBtn + " outlinedBtn"}
                type="button"
                onClick={handleOpenAddVulnerability}
              >
                + Add a vulnerability
              </CustomButton>
              <AddVulnerabilityDialog
                  open={openAddVulnerability}
                  categoryName={formData?.name}
                  categoryId={id}
                  handleAddVulnerabilityInCategory={handleAddVulnerabilityInCategory}
                  onClose={handleOpenAddVulnerability} 
              />
            </Grid>
          </Grid>
          <div className={styles.buttonWrapper}>
            <CustomButton
              className="outlinedBtn"
              children="Cancel"
              type="button"
              onClick={() => navigate("/vulnerabilities")}
            />
            <CustomButton
              className={`${loader ? " primaryBtn disabledBtn" : "primaryBtn"}`}
              children={`${loader ? "Updating..." : "Update"}`}
              type="button"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default UpdateVulnerabilityCategoryForm;
