import React, { FC, HTMLInputTypeAttribute, FocusEventHandler, ChangeEvent, KeyboardEvent } from "react";
import { TextField } from "@mui/material";

type focusEvent = FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;

type FormControl = /*unresolved*/ any;

type TCustomInputProps = {
  className: string;
  label?: string;
  name?: string;
  onBlur?: focusEvent;
  onFocus?: focusEvent;
  autoFocus?: boolean;
  multiline?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  error?: string;
  noMargin?: string;
  disabled?: boolean;
  inputRef?: React.MutableRefObject<null>;
  onKeyPress?: (event: KeyboardEvent<FormControl>) => void;
};

const CustomInput: FC<TCustomInputProps> = ({
  className,
  label,
  name,
  onBlur,
  onFocus,
  autoFocus,
  multiline,
  onChange,
  placeholder,
  type,
  value,
  error,
  noMargin,
  disabled,
  onKeyPress,
}) => {
  return (
    <div className={`inputGroup ${error && "errorGroup"} ${noMargin && "noMargin"}`}>
      <TextField
        className={className}
        label={label}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        autoFocus={autoFocus}
        multiline={multiline}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        error={error ? true : false}
        autoComplete="off"
      />
      {error && <h6 className="errorMsg absolute">{error}</h6>}
    </div>
  );
};

export default CustomInput;
