import { FC, SyntheticEvent, FocusEventHandler } from "react";
import {
  Chip,
  Autocomplete,
  TextField,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@mui/material";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { T } from "../types";
import { getImage } from "../utils";

type TMutipleSelecProps = {
  className?: string;
  onChange?:
    | ((
        event: SyntheticEvent<Element, Event>,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any> | undefined,
        key?: string
      ) => void)
    | undefined;
  options: any;
  placeholder?: string;
  error?: string;
  noMargin?: string;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  value?: any;
};

const CustomMutipleSelectDropdown: FC<TMutipleSelecProps> = ({
  onChange,
  options,
  placeholder,
  error,
  onFocus,
  onBlur,
  value,
}) => {
  return (
    <>
      <Autocomplete
        multiple
        id="tags-outlined"
        onChange={onChange}
        options={options.map((ele: any) => ele.name)}
        value={value as any}
        className="multipleSelect"
        filterSelectedOptions
        ListboxProps={{
          style: {
            maxHeight: "100px",
            border: "1px solid #E6E6E7",
            boxShadow: "0px 2px 8px #EAEBEC",
            borderRadius: "4px",
          },
        }}
        renderTags={(name: any[], getTagProps) =>
          name.map((option: any, index: number) => (
            <Chip
              variant="outlined"
              deleteIcon={<img src={getImage("delete_blue_icon.svg")} alt=" " />}
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={placeholder}
              error={error ? true : false}
              onFocus={onFocus}
              onBlur={onBlur}
              style={
                error ? { backgroundColor: "#ffecee", border: "1px solid red", borderRadius: "4px" } : {}
              }
            />
          );
        }}
      />
      {error && <h6 className="errorMsg absolute">{error}</h6>}
    </>
  );
};

export default CustomMutipleSelectDropdown;
