import { FC, useState, useContext } from "react";
import { Grid, Tooltip } from "@mui/material";
import styles from "../../../assets/styles/home.module.scss";
import "chart.js/auto";
import { CustomButton } from "../../../shared_ui_components";
import { Chart } from "react-google-charts";
import { TAnalysis } from "../../../types";
import { toastFlashMessage, getImage } from "../../../utils";
import { reportNotification } from "../apiServices";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";

type IProps = {
  analysis?: TAnalysis[];
  startedAt?: string;
  securityScore?: number;
  version?: number;
  status?: number;
  reportFile?: null | string;
  reportPassword?: null | string;
  passed_count?: number;
};

const ScanDetailChart: FC<IProps> = (props) => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const roleId = roleLists?.roleData?.id;
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [toolTipOpen2, setToolTipOpen2] = useState(false);

  const analysis = props.analysis;
  const score = props.securityScore;
  const reportFile = props.reportFile;
  const reportPassword = props.reportPassword;
  const passed_count = props.passed_count;
  const scanId = useParams().scanId;

  const options = {
    pieSliceText: "value",
    pieHole: 0.5,
    is3D: false,
    legend: "none",
    width: 200,
    height: 200,
    colors: ["#cc0a00", "#fccb0c", "#df3d04", "#53aa33", "#f9a00a", "#77777c"],
  };

  const data = [
    ["Risk Name", "Count"],
    [
      "Critical",
      analysis?.filter(
        (ele) =>
          (ele.risk_status === 3 && ele?.is_fixed === false) ||
          (ele?.is_fixed === false &&
            ele?.risk_status === null &&
            ele?.score &&
            ele?.score >= 9 &&
            ele?.score <= 10)
      )?.length,
    ],
    [
      "Low",
      analysis?.filter(
        (ele) =>
          (ele.risk_status === 0 && ele?.is_fixed === false) ||
          (ele?.is_fixed === false &&
            ele?.risk_status === null &&
            ele?.score &&
            ele?.score >= 0.1 &&
            ele?.score <= 3.9)
      )?.length,
    ],
    [
      "High",
      analysis?.filter(
        (ele) =>
          (ele.risk_status === 2 && ele?.is_fixed === false) ||
          (ele?.is_fixed === false &&
            ele?.risk_status === null &&
            ele?.score &&
            ele?.score >= 7 &&
            ele?.score <= 8.9)
      )?.length,
    ],
    ["Passed", passed_count],
    [
      "Medium",
      analysis?.filter(
        (ele) =>
          (ele.risk_status === 1 && ele?.is_fixed === false) ||
          (ele?.is_fixed === false &&
            ele?.risk_status === null &&
            ele?.score &&
            ele?.score >= 4 &&
            ele?.score <= 6.9)
      )?.length,
    ],
  ];

  const scanDaysCount = () => {
    const todaysDate: number = +new Date() - +new Date(props?.startedAt as string);

    let seconds = (todaysDate / 1000).toFixed(1);
    let minutes = (todaysDate / (1000 * 60)).toFixed(1);
    let hours = (todaysDate / (1000 * 60 * 60)).toFixed(1);
    let days = (todaysDate / (1000 * 60 * 60 * 24)).toFixed(1);
    if (Number(seconds) < 60) {
      if (seconds.slice(0, 1) < String(2)) {
        return seconds.slice(0, 1) + " sec ago";
      } else {
        return seconds.slice(0, 1) + " secs ago";
      }
    } else if (Number(minutes) < 60) {
      if (minutes.slice(0, 1) < String(2)) {
        return minutes.slice(0, 1) + " min ago";
      } else {
        return minutes.slice(0, 1) + " mins ago";
      }
    } else if (Number(hours) < 24) {
      if (hours.slice(0, 1) < String(2)) {
        return hours.slice(0, 1) + " hr ago";
      } else {
        return hours.slice(0, 1) + " hrs ago";
      }
    } else
      return days.slice(0, 1) < String(2) ? days.slice(0, 1) + " day ago" : days.slice(0, 1) + " days ago";
  };

  const handleCopy = (text: string) => {
    setToolTipOpen(!toolTipOpen);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setToolTipOpen(false);
    }, 1000);
  };
  const handleCopy2 = (text: string) => {
    setToolTipOpen2(!toolTipOpen);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setToolTipOpen2(false);
    }, 1000);
  };

  const handleReportNotification = () => {
    reportNotification(`${scanId}`).then((response) => {
      if (response.statusCode === 200) {
        toastFlashMessage(response.message, "success");
      } else {
        toastFlashMessage(response.message, "error");
      }
    });
  };

  return (
    <div className={styles.scanDetailChartWrapper}>
      <div className={styles.chartHeading}>
        <p className="heading4">Scan Details</p>
        {reportFile && (
          <div className={styles.customButtonWrapper}>
            {roleId === 1 || roleId === 2 ? (
              <CustomButton
                type="button"
                className="outlinedBtn"
                children="Notify Client"
                leftIcon={<img src={getImage("bell_icon.svg")} alt=" " />}
                onClick={handleReportNotification}
              />
            ) : null}
            <a href={reportFile} attributes-list download target="_blank" rel="noreferrer">
              <CustomButton
                type="button"
                className="outlinedBtn"
                children="Download Report"
                leftIcon={<img src={getImage("download_blue_icon.svg")} alt=" " />}
              />
            </a>
            <Tooltip title={toolTipOpen ? "link copied" : ""} open={toolTipOpen} placement="top">
              <div onClick={() => handleCopy(reportFile ? reportFile : "No reportFile link")}>
                <CustomButton
                  type="button"
                  className="outlinedBtn"
                  children="Copy Link"
                  leftIcon={<img src={getImage("attachment_blue_icon.svg")} alt=" " />}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={2.5}>
          <div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="200px"
              data={data}
              options={options}
              rootProps={{ "data-testid": "2" }}
            />
          </div>
        </Grid>
        <Grid item md={2.5}>
          <div className={styles.graphSecurityScoreWrapper}>
            <p className={styles.graphHeader + " paragraph_semiBold"}>Security Levels</p>
            <div className={styles.graphCriticalLow}>
              <p className={styles.scoreColorCritical}>
                <span className={styles.graphDot}></span> Critical{" "}
                <span className={styles.graphScore}>
                  {
                    analysis?.filter(
                      (ele) =>
                        (ele.risk_status === 3 && ele?.is_fixed === false) ||
                        (ele?.is_fixed === false &&
                          ele?.risk_status === null &&
                          ele?.score &&
                          ele?.score >= 9 &&
                          ele?.score <= 10)
                    )?.length
                  }
                </span>
              </p>
              <p className={styles.scoreColorLow}>
                <span className={styles.graphDot}></span> Low{" "}
                <span className={styles.graphScore}>
                  {
                    analysis?.filter(
                      (ele) =>
                        (ele.risk_status === 0 && ele?.is_fixed === false) ||
                        (ele?.is_fixed === false &&
                          ele?.risk_status === null &&
                          ele?.score &&
                          ele?.score >= 0.1 &&
                          ele?.score <= 3.9)
                    )?.length
                  }
                </span>
              </p>
            </div>
            <div className={styles.graphHighPass}>
              <p className={styles.scoreColorHigh}>
                <span className={styles.graphDot}></span> High{" "}
                <span className={styles.graphScore}>
                  {
                    analysis?.filter(
                      (ele) =>
                        (ele.risk_status === 2 && ele?.is_fixed === false) ||
                        (ele?.is_fixed === false &&
                          ele?.risk_status === null &&
                          ele?.score &&
                          ele?.score >= 7 &&
                          ele?.score <= 8.9)
                    )?.length
                  }
                </span>
              </p>
              <p className={styles.scoreColorPass}>
                <span className={styles.graphDot}></span> Passed{" "}
                <span className={styles.graphScore}>
                  {" "}
                  {passed_count}
                </span>
              </p>
            </div>
            <div className={styles.graphMediumTested}>
              <p className={styles.scoreColorMedium}>
                <span className={styles.graphDot}></span> Medium{" "}
                <span className={styles.graphScore}>
                  {
                    analysis?.filter(
                      (ele) =>
                        (ele.risk_status === 1 && ele?.is_fixed === false) ||
                        (ele?.is_fixed === false &&
                          ele?.risk_status === null &&
                          ele?.score &&
                          ele?.score >= 4 &&
                          ele?.score <= 6.9)
                    )?.length
                  }
                </span>
              </p>
            </div>
          </div>
        </Grid>

        <Grid item md={4} className={styles.chartDescriptionWrapper}>
          <p className={styles.paraTitle + " supportText_semiBold"}>
            Started: <span className="paragraph_semiBold">{props?.startedAt ? scanDaysCount() : "N/A"}</span>
          </p>
          <p className={styles.paraTitle + " supportText_semiBold"}>
            version code :{" "}
            <span className="paragraph_semiBold">{String(props.version).split("").join(".")}</span>
          </p>
          {reportPassword && (
            <div className={styles.paraTitle + " supportText_semiBold"}>
              Report password: <span className="paragraph_semiBold">{reportPassword}</span>
              <Tooltip title={toolTipOpen2 ? "password copied" : ""} open={toolTipOpen2} placement="top">
                <div
                  className={styles.copyLink}
                  onClick={() => handleCopy2(reportPassword ? reportPassword : "No password link")}
                >
                  <img src={getImage("copy_link_icon.svg")} alt=" " />
                  copy
                </div>
              </Tooltip>
            </div>
          )}
        </Grid>
        {roleId === 5 && (
          <Grid item md={3} className={styles.chartDescriptionWrapper}>
            <p className={styles.paraTitle + " supportText_semiBold"}>SECURITY SCORE</p>
            <p className={styles.securityScore + " supportText_semiBold"}>
              <span
                className={
                  (typeof score === "number"
                    ? score >= 0 && score <= 6
                      ? styles.scoreClolorCritical
                      : score >= 6.1 && score <= 8
                      ? styles.scoreClolorHigh
                      : score >= 8.1 && score <= 9.9
                      ? styles.scoreClolorMedium
                      : score === 10
                      ? styles.scoreClolorNone
                      : ""
                    : styles.scoreClolorNone) + " heading1"
                }
              >
                {score === 0 || score === null ? "0.00" : score}
              </span>{" "}
              <span className={styles.secureScoreValue + " heading4"}>
                {typeof score === "number"
                  ? score >= 0 && score <= 6
                    ? "Highly Vulnerable"
                    : score >= 6.1 && score <= 8
                    ? "Vulnerable"
                    : score >= 8.1 && score <= 9.9
                    ? "Be Preventive"
                    : score === 10
                    ? "Secure"
                    : ""
                  : "Secure"}
              </span>
            </p>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ScanDetailChart;
