import { FC } from "react";
import { Container } from "@mui/material";
import { ProjectDashboard } from "../components";

const Home: FC = () => {
  return (
    <>
      <Container maxWidth="lg">
        <ProjectDashboard />
      </Container>
    </>
  );
};
export default Home;
