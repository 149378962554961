import { FC, Fragment, useContext } from "react";
import styles from "../../../assets/styles/home.module.scss";
import { Grid } from "@mui/material";
import { ProjectListCardItem, Shimmer } from ".";
import { GlobalContext } from "../../../context/GlobalContext";

type IProps = {
  search?: string;
};

const ProjectListCard: FC<IProps> = ({ search }) => {
  const {
    state: { projectLists, roleLists },
  } = useContext(GlobalContext);
  const roleId = roleLists?.roleData?.id;

  return (
    <>
      {roleId === 5 ? (
        <Grid container spacing={2} className={styles.listCardHeader + " labelText_semiBold"}>
          <Grid item md={3}>
            <p>Name</p>
          </Grid>
          <Grid item md={2}>
            <p>Total scans completed</p>
          </Grid>
          <Grid item md={2}>
            <p>Last Scan</p>
          </Grid>
          <Grid item md={2}>
            <p>Total issues in last scan </p>
          </Grid>
          <Grid item md={2}>
            Scan Status
          </Grid>
          <Grid item md={1}>
            Action
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} className={styles.listCardHeader + " labelText_semiBold"}>
          <Grid item md={3}>
            <p>Name</p>
          </Grid>
          <Grid item md={2}>
            <p>Scan ID</p>
          </Grid>
          <Grid item md={2}>
            <p>Security Researcher</p>
          </Grid>
          <Grid item md={2}>
            <p>Due Date</p>
          </Grid>
          <Grid item md={2}>
            Scan Status
          </Grid>
          <Grid item md={1}>
            Action
          </Grid>
        </Grid>
      )}

      {projectLists?.loading ? (
        <Shimmer type="projectList" />
      ) : (
        <div className={styles.listCardFooter}>
          {projectLists?.projectData?.map((project, idx) => {
            return (
              <Fragment key={idx}>
                <ProjectListCardItem {...project} search={search} />
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ProjectListCard;
