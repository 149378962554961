import { FC, FocusEventHandler } from "react";
import { Link } from "react-router-dom";

type TCustomLinkProps = {
  children: React.ReactNode;
  className: string;
  onClick?: () => void;
  to: string;
  onFocus?: FocusEventHandler<HTMLAnchorElement> | undefined;
};

const CustomLink: FC<TCustomLinkProps> = ({ children, className, onClick, to, onFocus }) => {
  return (
    <Link className={className} to={to} onClick={onClick} onFocus={onFocus}>
      {children}
    </Link>
  );
};

export default CustomLink;
