import { FC } from "react";
import { Grid } from "@mui/material";
import styles from "../../../assets/styles/home.module.scss";
import { format, parseISO } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { getImage } from "../../../utils";

type IProps = {
  id: number;
  created_at: string;
  version: number;
  status: number;
  report_file: string;
  projectName?: string;
  handleDeleteReport?: (p: number) => void;
};

const ReportListItem: FC<IProps> = ({
  id,
  created_at,
  status,
  version,
  report_file,
  projectName,
  handleDeleteReport,
}) => {
  const projectId = useParams().id;

  const handleDownloadReport = () => {
    window.open(report_file);
  };

  return (
    <>
      {report_file ? (
        <Link to={`projects/${projectId}/scan-details/${id}`}>
          <div className={styles.reportListCardContainer + " paragraph"}>
            <Grid container spacing={2} className={styles.reportListItem}>
              <Grid item md={1} className={styles.projectId}>
                <p>{id}</p>
              </Grid>
              <Grid item md={3} className={styles.projectName}>
                <p> {projectName}</p>
              </Grid>
              <Grid item md={1}>
                <p>{String(version).split("").join(".")}</p>
              </Grid>
              <Grid item md={2}>
                <p>{format(parseISO(created_at), "dd/MM/yyyy hh:mm a")}</p>
              </Grid>
              <Grid item md={2} sx={{ display: "flex" }}>
                <img
                  src={
                    status === 0
                      ? getImage("status_pending.svg")
                      : status === 1
                      ? getImage("status_progress.svg")
                      : status === 2
                      ? getImage("delete_user.svg")
                      : status === 3
                      ? getImage("status_success.svg")
                      : null
                  }
                  alt="status"
                />
                <span
                  className={
                    status === 0
                      ? styles.pending
                      : status === 1
                      ? styles.progress
                      : status === 2
                      ? styles.discarded
                      : status === 3
                      ? styles.success
                      : ""
                  }
                >
                  {status === 0
                    ? "Pending"
                    : status === 1
                    ? "In Progress"
                    : status === 2
                    ? "Discarded"
                    : status === 3
                    ? "Completed"
                    : ""}
                </span>
              </Grid>
              <Grid item md={3} className={styles.reportDetails}>
                <Link to="#">
                  <img
                    className={styles.downloadIcon}
                    src={getImage("download_icon.svg")}
                    onClick={handleDownloadReport}
                    alt=""
                  />
                  <img
                    className={styles.deleteIcon}
                    src={getImage("delete_icon_grey.svg")}
                    onClick={() => handleDeleteReport?.(id)}
                    alt=""
                  />
                </Link>
                <p className={styles.viewDetail + " paragraph"}>View details</p>
              </Grid>
            </Grid>
          </div>
        </Link>
      ) : null}
    </>
  );
};

export default ReportListItem;
