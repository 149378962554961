import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../../../assets/styles/home.module.scss";
import { Grid } from "@mui/material";
import { TProjectResponse } from "../../../types";
import { format, parseISO } from "date-fns";
import { GlobalContext } from "../../../context/GlobalContext";
import { getImage } from "../../../utils";

const ProjectListCardItem: FC<TProjectResponse> = ({
  uuid,
  name,
  url,
  client,
  users,
  status,
  version,
  search,
  researcher,
  last_scan,
  completed_scans,
}) => {
  const {
    state: { roleLists },
  } = useContext(GlobalContext);
  const roleId = roleLists?.roleData?.id;

  return (
    <>
      {
        <Link
          to={
            status === 2
              ? `/projects`
              : search
              ? { pathname: `/projects/${uuid}`, search: `?search=${name}` }
              : `/projects/${uuid}`
          }
          onClick={() => localStorage.removeItem("view-Project-status")}
        >
          <div className={styles.projectListCardContainer + " paragraph"}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              className={status === 2 ? styles.disableRow + " " + styles.listCardItem : styles.listCardItem}
            >
              <Grid item md={3} className={styles.projectName}>
                <img src={client?.logo ? client?.logo : getImage("profile_placeholder.svg")} alt="logo" />
                <span>{name}</span>
              </Grid>
              <Grid
                item
                md={2}
                className={roleId === 5 ? styles.clientProjectView : styles.othersProjectView}
              >
                {roleId === 5
                  ? completed_scans
                    ? completed_scans
                    : "N/A"
                  : last_scan?.id
                    ? last_scan?.id
                    : localStorage.getItem("view-Project-status") &&
                      JSON.parse(localStorage.getItem("view-Project-status") || "") === "status=completed"
                    ? last_scan?.id
                    : "N/A"
                }
              </Grid>

              <Grid item md={2}>
                <p>
                  {roleId === 5
                    ? last_scan?.updated_at && last_scan?.status === 3
                      ? format(parseISO(last_scan?.updated_at), "dd/MM/yyyy hh:mm a")
                      : "N/A"
                    : last_scan?.owner
                    ? last_scan?.owner?.last_name
                      ? last_scan?.owner?.first_name?.concat(" " + last_scan?.owner?.last_name)
                      : last_scan?.owner?.first_name
                    : "N/A"}
                </p>
              </Grid>
              <Grid item md={2} className={roleId === 5 ? styles.clientProjectView : ""}>
                {roleId === 5
                  ? last_scan?.analysis
                    ? last_scan?.analysis.length
                    : "N/A"
                  : last_scan?.due_date
                  ? last_scan?.due_date?.split("-").reverse().join("/")
                  : "N/A"}
              </Grid>
              <Grid item md={2}>
                <p className={styles.projectStatus}>
                  <img
                    src={
                      last_scan?.status === 0
                        ? getImage("status_pending.svg")
                        : last_scan?.status === 1
                        ? getImage("status_progress.svg")
                        : last_scan?.status === 2
                        ? getImage("delete_user.svg")
                        : last_scan?.status === 3
                        ? getImage("status_success.svg")
                        : null
                    }
                    alt=""
                  />
                  <span
                    className={
                      last_scan?.status === 0
                        ? styles.pending
                        : last_scan?.status === 1
                        ? styles.progress
                        : last_scan?.status === 2
                        ? styles.discarded
                        : last_scan?.status === 3
                        ? styles.success
                        : styles.none
                    }
                  >
                    {last_scan?.status === 0
                      ? "Pending"
                      : last_scan?.status === 1
                      ? "In Progress"
                      : last_scan?.status === 2
                      ? "Discarded"
                      : last_scan?.status === 3
                      ? "Completed"
                      : "N/A"}
                  </span>
                </p>
              </Grid>
              <Grid item md={1} className={styles.viewDetailLink + " paragraph"}>
                <p>View details</p>
              </Grid>
            </Grid>
          </div>
        </Link>
      }
    </>
  );
};

export default ProjectListCardItem;
