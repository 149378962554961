import { FC, useEffect, useState } from "react";
import { notificationList, notificationUpdate } from "../apiServices";
import { useNavigate } from "react-router-dom";
import { toastFlashMessage, getImage } from "../../../utils";
import { Shimmer } from ".";
import { TNotificationUpdate } from "../../../types";
import { CustomButton, NoResult } from "../../../shared_ui_components";
import { format, parseISO } from "date-fns";

type TNotificationData = {
  id: number;
  action: string;
  message: string;
  created_at: string;
  status: number;
  hide: boolean;
  type: string;
  redirect: string;
  project: string;
  redirect_id: number;
};

type TNotification = {
  list: TNotificationData[];
  pagination: { total: number; number_of_pages: number; current_page: number; per_page: number };
};

type TNotificationDrawer = {
  handleToggleDrawer?: () => void;
  updateCount?: () => void;
};

const Notifications: FC<TNotificationDrawer> = ({ handleToggleDrawer, updateCount }) => {
  const [notificationListData, setnotificationListData] = useState<TNotificationData[] | []>([]);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    notificationList().then(
      (response: Partial<{ statusCode: number; data: TNotification; message: string }>) => {
        if (response.statusCode === 200) {
          setnotificationListData(response?.data?.list as TNotificationData[] | []);
          setLoader(false);
        } else {
          toastFlashMessage(response.message as string, "error");
          setLoader(false);
        }
      }
    );
  }, []);

  const handleNotificationClick = (
    projectId: string,
    scanId: number,
    type: string,
    notification_id: number
  ) => {
    if (type === "project_scan") {
      navigate(`/projects/${projectId}/scan-details/${scanId}`);
    } else {
    }
    const updateNotificationData = {
      notification_id: notification_id,
      status: 1,
    };
    notificationUpdate(updateNotificationData).then(
      (response: Partial<{ statusCode: number; data: TNotificationUpdate; message: string }>) => {
        updateCount?.();
      }
    );
    handleToggleDrawer?.();
  };

  const handleAllClear = () => {
    const updateAllNotificationData = {
      notification_id: "all",
      status: 1,
    };
    notificationUpdate(updateAllNotificationData).then(
      (response: Partial<{ statusCode: number; data: TNotificationUpdate; message: string }>) => {
        updateCount?.();
      }
    );
    handleToggleDrawer?.();
  };

  const newNotificationCount = notificationListData
    ?.map((item) => {
      return item?.status;
    })
    .filter((status) => {
      return status === 0;
    }).length;

  return (
    <>
      {notificationListData?.length !== 0 && newNotificationCount >= 1 ? (
        <div className="drawerMessage">
          <p className="msgNotification paragraph">
            You have <span>{newNotificationCount} new</span>{" "}
            {newNotificationCount > 1 ? "notifications" : "notification"}
          </p>
          <CustomButton
            className="clearMsg linkBtn"
            children="Mark All as Read"
            type="button"
            onClick={handleAllClear}
          />
        </div>
      ) : null}
      {loader ? (
        <Shimmer type="notificationList" />
      ) : (
        <div className="drawerBody">
          {notificationListData?.length === 0 ? (
            <div className="noStateWrapper">
              <NoResult image={getImage("no_file_found.svg")} title="No Notifications Yet" />
            </div>
          ) : (
            notificationListData &&
            notificationListData?.map((item, index) => {
              return (
                <div
                  className={`outerBlk ${item?.status === 0 ? " unreadBackground" : " readBackground"}`}
                  key={index}
                >
                  <div
                    className="notificationListItem"
                    onClick={() =>
                      handleNotificationClick(
                        item?.project as string,
                        item?.redirect_id as number,
                        item?.type as string,
                        item?.id as number
                      )
                    }
                  >
                    <div className="drawerContent">
                      <div className="drawerContentLeft">
                        <img src={getImage("message_icon.svg")} alt="" />
                        <div className="contentMiddle">
                          <div className="mesaageContext paragragh">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.message,
                              }}
                            />
                          </div>
                          <p className="messageTime supportText_semiBold">
                            {item.created_at && format(parseISO(item.created_at), "dd/MM/yyyy hh:mm a")}
                          </p>
                        </div>
                      </div>
                      {item?.status === 0 ? <p className="unseenMsgColor"></p> : null}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </>
  );
};

export default Notifications;
