import { FC } from "react";
import styles from "../../../assets/styles/user.module.scss";
import { Skeleton } from "@mui/material";
import { Grid } from "@mui/material";
import { IShimmerProps } from "../../../types";

const Shimmer: FC<IShimmerProps> = ({ type }) => {
  return (
    <>
      {type === "usersList" && (
        <>
          {[...Array(10)].map((item, key) => {
            return (
              <div className={styles.userListItem} key={key}>
                <Grid container>
                  <Grid item md={3}>
                    <p className={styles.userName + " paragraph_semiBold"}>
                      <Skeleton variant="circular" height={24} width={16} />
                      <Skeleton
                        variant="text"
                        width={200}
                        height={25}
                        style={{
                          display: "block",
                          marginLeft: 10,
                        }}
                      />
                    </p>
                  </Grid>
                  <Grid item md={3}>
                    <p className={styles.userDetail + " supportText"}>
                      <Skeleton
                        variant="text"
                        width={200}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </p>
                  </Grid>
                  <Grid item md={3}>
                    <p className={styles.userDetail + " supportText"}>
                      <Skeleton
                        variant="text"
                        width={200}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </p>
                  </Grid>
                  <Grid item md={2}>
                    <p className={styles.userDetail + " supportText"}>
                      <Skeleton
                        variant="text"
                        width={160}
                        height={25}
                        style={{
                          display: "block",
                        }}
                      />
                    </p>
                  </Grid>
                  <Grid item md={1}>
                    <Skeleton
                      variant="text"
                      width={100}
                      height={25}
                      style={{
                        display: "block",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default Shimmer;
