import { globalGetService, globalPostService, globalDeleteService } from "../../utils/GlobalApiServices";
import { TAddScan } from "./components/CreateScanRequestDialog";
import { TUpdateScan } from "./components/EditScanForm";
import { IStateProject } from "./components/AddProjectDialog";
import { TAddAnalysis } from "./components/AddVulnerabilityForm";

export function getAllProjects(status?: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`projects/?${status}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllProjectsWithPage(status?: string, params?: string | number): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`projects/?${status}&page=${params}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllProjectsWithSearchPage(
  status?: string,
  params?: string | number,
  val?: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`projects/?${status}&page=${params}&q=${val}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function searchProjects(status?: string, params?: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`projects/?${status}&q=${params}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addNewProject(query: IStateProject): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`project/create/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateProject(query: IStateProject, params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`project/${params}/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deactivateProject(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`project/${params}/deactivate/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleProject(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`project/${params}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleProjectWithoutPage(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`project/${params}/scans/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleProjectWithPage(params: string, page: number): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`project/${params}/scans/?page=${page}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addNewScan(query: TAddScan, params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`/project/${params}/scan/create/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSingleScanDetails(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`scan/${params}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateSingleScanDetails(query: TUpdateScan, params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`scan/${params}/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getCsvvMetricsList(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`cvss-metrics/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getOwaspsList(): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`owasps/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addAnalysis(query: TAddAnalysis, params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`scan/${params}/analysis/create/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function uploadEditorFile(query: any): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`upload/file/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function editAnalysis(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`analysis/${params}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateAnalysis(query: TAddAnalysis, params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`analysis/${params}/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function generateReport(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`scan/${params}/generate-report/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function showReportLists(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalGetService(`/project/${params}/reports/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteReport(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalDeleteService(`scan/${params}/delete-report/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function reportNotification(params: string): Promise<any> {
  return new Promise((resolve, reject) => {
    globalPostService(`scan/${params}/report-notify/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteFindings(params: number): Promise<any> {
  return new Promise((resolve, reject) => {
    globalDeleteService(`analysis/${params}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
