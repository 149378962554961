import User from "./containers/User";
import { TRoutesType } from "../../types";
import PageLayoutHoc from "../../hocs/pageLayoutHoc";

const UserPage = PageLayoutHoc(User);

export const usersRoutes: Array<TRoutesType> = [
  {
    path: "/users",
    element: <UserPage />,
    key: "users",
  },
];
