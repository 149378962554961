import React, { FC, useEffect } from "react";
import "./assets/styles/app.scss";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { homeRoutes } from "./features/home/homeRoutes";
import { vulnerabilitiesRoutes } from "./features/vulnerabilities/vulnerabilitiesRoutes";
import { clientsRoutes } from "./features/clients/clientsRoutes";
import { usersRoutes } from "./features/users/usersRoutes";
import { authRoutes } from "./features/auth/authRoutes";
import { PageNotFound } from "./shared_elements";
import { theme } from "./theme/index";
import { ThemeProvider } from "@mui/material/styles";
import { ToastMessage } from "./shared_ui_components";
import ErrorBoundary from "./utils/ErrorBoundary";
import ScrollToTop from "./utils/ScrollToTop";
import PrivateRoute from "./hocs/PrivateRoute";
import { getStore } from "./store/GlobalStore";
import {
  getUserInfo,
  listingAllClients,
  listingAllUsers,
  listingAllVulnerabilityCategory,
  roleDetails,
} from "./utils";
import { ROLE_ACTIONS } from "./context/RoleReducer";

const App: FC = () => {
  const { search } = useLocation();
  const location = useLocation();
  const token = new URLSearchParams(search).get("token");
  const navigate = useNavigate();
  useEffect(() => {
    const userInfo = getUserInfo();
    if (userInfo?.access) {
      const { dispatch } = getStore();
      const role = roleDetails(
        window.atob(
          `${userInfo?.access?.substring(userInfo.access?.lastIndexOf("$") + 1, userInfo?.access?.length)}`
        )
      );
      dispatch({
        type: ROLE_ACTIONS.Get,
        payload: role,
      });
      if (role?.id === 1) {
        listingAllVulnerabilityCategory();
        listingAllUsers();
        listingAllClients();
      } else if (role?.id === 2) {
        listingAllClients();
        listingAllUsers();
      } else if (role?.id === 3 || role?.id === 4) {
        listingAllVulnerabilityCategory();
      }
    } else if (location.pathname === '/setpassword/'){
        if (!token) {
          navigate("/signin");
        }
    } else {
      navigate("/signin");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <ScrollToTop>
            <Routes>
              {[...authRoutes].map(({ path, element, key }, index) => (
                <React.Fragment key={index}>
                  <Route path={path} element={element} key={key} />
                </React.Fragment>
              ))}
              {[...homeRoutes, ...vulnerabilitiesRoutes, ...clientsRoutes, ...usersRoutes].map(
                ({ path, element, key }, index) => (
                  <React.Fragment key={index}>
                    <Route element={<PrivateRoute>{element}</PrivateRoute>}>
                      <Route path={path} element={element} key={key} />
                    </Route>
                  </React.Fragment>
                )
              )}
              <Route path="/" element={<Navigate to="/projects" />} />
              <Route path="*" element={<PageNotFound />} />;
            </Routes>
            <ToastMessage />
          </ScrollToTop>
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
};

export default App;
